import React from 'react';
import { Panel } from '@xyflow/react';
import { cn } from 'design';

import { componentTypes } from '@/constants/componentTypes';
import { getNewComponentTypeLabel } from '@/modules/components/helpers';
import { TableSubtype } from '@/modules/lineage/constants';
import type { CustomNode, NodeData } from '@/modules/lineage/rfTypes';

type LegendType =
  | NodeData['type']
  | (typeof TableSubtype)[keyof typeof TableSubtype]
  | (typeof componentTypes)[keyof typeof componentTypes];

const resolveType = (node: CustomNode) => {
  if (node.type === 'component') {
    return node.data.component?.type;
  }

  if (node.type === 'table') {
    return node.data.subtype;
  }

  return node.type;
};

const Legend = (props: { nodes: CustomNode[]; llmCalculated: boolean }) => {
  const nodeTypes = props.nodes.reduce<LegendType[]>((acc, node) => {
    const type = resolveType(node);

    if (type && !acc.includes(type)) {
      acc.push(type);
    }
    return acc;
  }, []);

  if (nodeTypes.length === 0) {
    return null;
  }

  return (
    <Panel position="bottom-left">
      <div className="tw-inline-flex tw-items-center tw-gap-8 tw-rounded-lg tw-bg-white tw-px-4 tw-py-3.5 tw-text-sm">
        {nodeTypes
          .sort((a, b) => getNewComponentTypeLabel(a).localeCompare(getNewComponentTypeLabel(b)))
          .map((type) => {
            return (
              <span key={type} className="tw-inline-flex tw-items-center tw-gap-2.5">
                <span
                  className={cn('tw-inline-flex tw-h-4 tw-w-4', {
                    'tw-bg-pink-300': type === 'project',
                    'tw-bg-secondary-200': type === 'table',
                    'tw-bg-yellow-200': type === TableSubtype.LINKED_FROM,
                    'tw-bg-purple-200': type === TableSubtype.LINKED_TO,
                    'tw-bg-warning-300': type === componentTypes.TRANSFORMATION,
                    'tw-bg-primary-200': type === componentTypes.EXTRACTOR,
                    'tw-bg-cyan-200': type === componentTypes.WRITER,
                    'tw-bg-error-200': type === componentTypes.APPLICATION,
                  })}
                />
                {getNewComponentTypeLabel(type.replace('-', ' '))}
              </span>
            );
          })}
        {props.llmCalculated && (
          <span className="tw-inline-flex tw-items-center tw-gap-2.5">
            <span className="tw-inline-flex tw-h-0.5 tw-w-4 tw-bg-purple-600" />
            LLM calculated
          </span>
        )}
      </div>
    </Panel>
  );
};

export default Legend;
