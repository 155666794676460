import React, { useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { components as selectComponents } from 'react-select';

import actions from '@/modules/stream/actions';
import type { Conditions } from '@/modules/stream/constants';
import type { StoreSink } from '@/modules/stream/store';
import Select from '@/react/common/Select';

const IMPORT_CONDITIONS = {
  'storage.level.target.import.trigger.interval': [
    { value: '1m0s', label: '1 minute', isDefault: true },
    { value: '5m0s', label: '5 minutes' },
    { value: '15m0s', label: '15 minutes' },
    { value: '30m0s', label: '30 minutes' },
    { value: '1h0m0s', label: '1 hour' },
    { value: '6h0m0s', label: '6 hours' },
    { value: '12h0m0s', label: '12 hours' },
    { value: '24h0m0s', label: '24 hours' },
  ],
  'storage.level.target.import.trigger.size': [
    { value: '5MB', label: '5 MB' },
    { value: '10MB', label: '10 MB' },
    { value: '25MB', label: '25 MB' },
    { value: '50MB', label: '50 MB', isDefault: true },
    { value: '100MB', label: '100 MB' },
    { value: '250MB', label: '250 MB' },
    { value: '500MB', label: '500 MB' },
  ],
  'storage.level.target.import.trigger.count': [
    { value: 1, label: '1' },
    { value: 10, label: '10' },
    { value: 100, label: '100' },
    { value: 1000, label: '1 000' },
    { value: 10000, label: '10 000' },
    { value: 50000, label: '50 000', isDefault: true },
    { value: 100000, label: '100 000' },
    { value: 1000000, label: '1 000 000' },
    { value: 10000000, label: '10 000 000' },
  ],
};

type Props = {
  sourceId: string;
  sink: StoreSink;
  isSavingSink: boolean;
  readOnly: boolean;
};

const ImportConditions = (props: Props) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const settings = props.sink.settings?.reduce(
    (settingsObject, option) => ({ ...settingsObject, [option.key]: option.value }),
    {} as Conditions,
  );
  const updateCondition = (key: keyof Conditions, value: Conditions[keyof Conditions]) => {
    setIsUpdating(true);

    return actions
      .updateSinkSettings(props.sourceId, props.sink.sinkId, {
        settings: [{ key, value }],
      })
      .finally(() => setIsUpdating(false));
  };

  return (
    <>
      <p className="mbp-6 text-muted f-12 line-height-16">
        Once any of these 3 conditions are met, events are instantly uploaded to the destination
        table.
      </p>
      <FormGroup>
        <div className="flex-container gap-24">
          <Select
            hideSelectAllOptions
            searchable={false}
            clearable={false}
            value={settings?.['storage.level.target.import.trigger.interval'] ?? '5m0s'}
            onChange={(time: string) =>
              updateCondition('storage.level.target.import.trigger.interval', time)
            }
            options={IMPORT_CONDITIONS['storage.level.target.import.trigger.interval']}
            disabled={isUpdating || props.isSavingSink || props.readOnly}
            isLoading={isUpdating || props.isSavingSink}
            className="fill-space"
          />
          <span className="text-muted">OR</span>
          <Select
            hideSelectAllOptions
            searchable={false}
            clearable={false}
            value={settings?.['storage.level.target.import.trigger.size'] ?? '50MB'}
            onChange={(size: string) =>
              updateCondition('storage.level.target.import.trigger.size', size)
            }
            options={IMPORT_CONDITIONS['storage.level.target.import.trigger.size']}
            disabled={isUpdating || props.isSavingSink || props.readOnly}
            isLoading={isUpdating || props.isSavingSink}
            className="fill-space"
          />
          <span className="text-muted">OR</span>
          <Select
            hideSelectAllOptions
            searchable={false}
            clearable={false}
            value={settings?.['storage.level.target.import.trigger.count'] ?? 50000}
            onChange={(count: string) =>
              updateCondition('storage.level.target.import.trigger.count', parseInt(count, 10))
            }
            options={IMPORT_CONDITIONS['storage.level.target.import.trigger.count']}
            disabled={isUpdating || props.isSavingSink || props.readOnly}
            isLoading={isUpdating || props.isSavingSink}
            className="fill-space"
            singleValueRenderer={(optionProps) => (
              <selectComponents.SingleValue {...optionProps}>
                {optionProps.data.label} record{optionProps.data.value > 1 ? 's' : ''}
              </selectComponents.SingleValue>
            )}
          />
        </div>
      </FormGroup>
    </>
  );
};

export default ImportConditions;
