import Promise from 'bluebird';
import { fromJS, List } from 'immutable';
import _ from 'underscore';
import { capitalize } from 'underscore.string';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { KEBOOLA_APP_TRANSFORMATION_MIGRATE, TRANSFORMATION } from '@/constants/componentIds';
import dispatcher from '@/Dispatcher';
import * as installedComponentsConstants from '@/modules/components/Constants';
import callDockerAction from '@/modules/components/DockerActionsApi';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import InstalledComponentsApi from '@/modules/components/InstalledComponentsApi';
import VersionActionCreators from '@/modules/components/VersionsActionCreators';
import ConfigurationRowsActionCreators from '@/modules/configurations/ConfigurationRowsActionCreators';
import RowVersionActionCreators from '@/modules/configurations/RowVersionsActionCreators';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import parseQueries from '@/modules/legacy-transformation/utils/parseQueries';
import RoutesStore from '@/stores/RoutesStore';
import SyncActionError from '@/utils/errors/SyncActionError';
import copyTransformationNotification from './react/components/notifications/copyTransformationNotification';
import deleteTransformationBucketNotification from './react/components/notifications/deleteTransformationBucketNotification';
import migrateTransformationNotification from './react/components/notifications/migrateTransformationNotification';
import restoreTransformationBucketNotification from './react/components/notifications/restoreTransformationBucketNotification';
import TransformationBucketsStore from './stores/TransformationBucketsStore';
import TransformationsStore from './stores/TransformationsStore';
import * as constants from './Constants';
import { routeNames } from './Constants';
import { reorderTransformationInputs, updateTransformationMappings } from './helpers';
import transformationsApi from './TransformationsApiAdapter';

const updateTransformationEditingFieldQueriesStringDebouncer = _.debounce(function (
  bucketId,
  transformationId,
  queriesString,
) {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.TRANSFORMATION_UPDATE_PARSE_QUERIES_START,
    bucketId: bucketId,
    transformationId: transformationId,
  });
  return parseQueries(queriesString)
    .then(function (splitQueries) {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.TRANSFORMATION_UPDATE_PARSE_QUERIES_SUCCESS,
        bucketId: bucketId,
        transformationId: transformationId,
        splitQueries: splitQueries,
      });
    })
    .catch(function () {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.TRANSFORMATION_UPDATE_PARSE_QUERIES_ERROR,
        bucketId: bucketId,
        transformationId: transformationId,
      });
    });
}, 1000);

const reloadVersions = function (configId, rowId) {
  let promises = [VersionActionCreators.loadVersionsForce(TRANSFORMATION, configId)];
  if (rowId) {
    promises.push(RowVersionActionCreators.loadVersionsForce(TRANSFORMATION, configId, rowId));
  }
  return Promise.all(promises);
};

export default {
  createTransformationBucket: function (data) {
    var changeDescription, newBucket;
    newBucket = {};
    changeDescription = 'Create transformation bucket ' + data.name;
    return transformationsApi
      .createTransformationBucket(data, changeDescription)
      .then(function (bucket) {
        newBucket = bucket;
        return InstalledComponentsActionCreators.loadInstalledComponentsForce();
      })
      .then(function () {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_BUCKET_CREATE_SUCCESS,
          bucket: newBucket,
        });
        VersionActionCreators.loadVersionsForce(TRANSFORMATION, newBucket.id);
        return RoutesStore.getRouter().transitionTo(routeNames.BUCKET, {
          config: newBucket.id,
        });
      });
  },
  createTransformation: function (bucketId, data) {
    var changeDescription;
    changeDescription = 'Create transformation ' + data.get('name');
    return transformationsApi
      .createTransformation(bucketId, data.toJS(), changeDescription)
      .then(function (transformation) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_CREATE_SUCCESS,
          bucketId: bucketId,
          transformation: transformation,
        });
        reloadVersions(bucketId, transformation.id).then(() => {
          RoutesStore.getRouter().transitionTo(routeNames.DETAIL, {
            row: transformation.id,
            config: bucketId,
          });
        });
        return null;
      });
  },
  createTransformationWithoutRedirect: function (bucketId, data) {
    const changeDescription = 'Create transformation ' + data.get('name');
    return transformationsApi
      .createTransformation(bucketId, data.toJS(), changeDescription)
      .then((transformation) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_CREATE_SUCCESS,
          bucketId: bucketId,
          transformation: transformation,
        });
        InstalledComponentsActionCreators.loadComponentConfigsData(TRANSFORMATION);
        ApplicationActionCreators.sendNotification({
          type: 'success',
          message: copyTransformationNotification(bucketId, fromJS(transformation)),
        });
        return reloadVersions(bucketId);
      });
  },
  deleteTransformationBucket: function (bucketId) {
    if (DevBranchesStore.isDevModeActive()) {
      return InstalledComponentsActionCreators.deleteConfiguration(TRANSFORMATION, bucketId).then(
        () => {
          return InstalledComponentsActionCreators.loadComponentConfigsData(TRANSFORMATION);
        },
      );
    }

    const bucket = TransformationBucketsStore.get(bucketId);
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_BUCKET_DELETE,
      bucketId: bucketId,
    });
    return InstalledComponentsApi.deleteConfiguration(TRANSFORMATION, bucketId)
      .then(() => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_BUCKET_DELETE_SUCCESS,
          bucketId: bucketId,
        });
        dispatcher.handleViewAction({
          type: installedComponentsConstants.ActionTypes
            .INSTALLED_COMPONENTS_DELETE_CONFIGURATION_SUCCESS,
          componentId: TRANSFORMATION,
          configurationId: bucketId,
          transition: false,
        });
        InstalledComponentsActionCreators.loadComponentConfigsData(TRANSFORMATION);
        return ApplicationActionCreators.sendNotification({
          type: 'info',
          message: deleteTransformationBucketNotification(bucket),
          button: {
            label: 'Restore',
            action: () => this.restoreTransformationBucket(bucket),
          },
        });
      })
      .catch((e) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_BUCKET_DELETE_ERROR,
          bucketId: bucketId,
        });
        throw e;
      });
  },
  restoreTransformationBucket: function (bucket) {
    const bucketId = bucket.get('id');
    dispatcher.handleViewAction({
      type: constants.ActionTypes.DELETED_TRANSFORMATION_BUCKET_RESTORE,
      bucketId: bucketId,
    });
    return transformationsApi
      .restoreTransformationBucket(bucketId)
      .then(function () {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.DELETED_TRANSFORMATION_BUCKET_RESTORE_SUCCESS,
          bucketId: bucketId,
        });
        return InstalledComponentsActionCreators.loadComponentConfigsData(TRANSFORMATION).then(
          function () {
            return ApplicationActionCreators.sendNotification({
              type: 'success',
              message: restoreTransformationBucketNotification(bucketId, bucket.get('name')),
            });
          },
        );
      })
      .catch(function (e) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.DELETED_TRANSFORMATION_BUCKET_RESTORE_ERROR,
          bucketId: bucketId,
        });
        throw e;
      });
  },
  deleteTransformation: function (bucketId, transformationId) {
    if (DevBranchesStore.isDevModeActive()) {
      return ConfigurationRowsActionCreators.delete(
        TRANSFORMATION,
        bucketId,
        transformationId,
      ).then(() => {
        return InstalledComponentsActionCreators.loadComponentConfigsData(TRANSFORMATION);
      });
    }

    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_DELETE,
      bucketId: bucketId,
      transformationId: transformationId,
    });
    const transformation = TransformationsStore.getTransformation(bucketId, transformationId);
    return InstalledComponentsApi.deleteConfigurationRow(
      TRANSFORMATION,
      bucketId,
      transformationId,
      'Delete transformation ' + transformation.get('name'),
    )
      .then(function () {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_DELETE_SUCCESS,
          transformationId: transformationId,
          bucketId: bucketId,
        });
        InstalledComponentsActionCreators.loadComponentConfigsData(TRANSFORMATION);
        return reloadVersions(bucketId);
      })
      .catch(function (error) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_DELETE_ERROR,
          transformationId: transformationId,
          bucketId: bucketId,
        });
        throw error;
      });
  },

  /*
    Request overview load from server
   */
  loadTransformationOverview: function (bucketId, transformationId, showDisabled) {
    return _.defer(function () {
      var tableId;
      dispatcher.handleViewAction({
        type: constants.ActionTypes.TRANSFORMATION_OVERVIEW_LOAD,
        transformationId: transformationId,
        bucketId: bucketId,
      });
      tableId = bucketId + '.' + transformationId;
      return transformationsApi
        .getGraph({
          tableId: tableId,
          direction: 'around',
          showDisabled: showDisabled,
          limit: {
            sys: [bucketId],
          },
        })
        .then(function (graphData) {
          dispatcher.handleViewAction({
            type: constants.ActionTypes.TRANSFORMATION_OVERVIEW_LOAD_SUCCESS,
            transformationId: transformationId,
            bucketId: bucketId,
            model: graphData,
          });
        })
        .catch(function (error) {
          dispatcher.handleViewAction({
            type: constants.ActionTypes.TRANSFORMATION_OVERVIEW_LOAD_ERROR,
            transformationId: transformationId,
            bucketId: bucketId,
          });
          throw error;
        });
    });
  },
  showTransformationOverviewDisabled: function (bucketId, transformationId, showDisabled) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_OVERVIEW_SHOW_DISABLED,
      transformationId: transformationId,
      bucketId: bucketId,
      showDisabled: showDisabled,
    });
    return this.loadTransformationOverview(bucketId, transformationId, showDisabled);
  },
  changeTransformationProperty: function (
    bucketId,
    transformationId,
    propertyName,
    newValue,
    changeDescription,
  ) {
    var pendingAction, transformation, finalChangeDescription;
    finalChangeDescription = changeDescription;
    pendingAction = 'save-' + propertyName;
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_START,
      transformationId: transformationId,
      bucketId: bucketId,
      pendingAction: pendingAction,
    });
    transformation = TransformationsStore.getTransformation(bucketId, transformationId);
    transformation = transformation.set(propertyName, newValue);
    if (!changeDescription) {
      finalChangeDescription =
        'Change ' + capitalize(propertyName) + ' in ' + transformation.get('name');
    }
    return transformationsApi
      .saveTransformation(bucketId, transformationId, transformation.toJS(), finalChangeDescription)
      .then(function (response) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_SUCCESS,
          transformationId: transformationId,
          bucketId: bucketId,
          editingId: propertyName,
          pendingAction: pendingAction,
          data: response,
        });
        return reloadVersions(bucketId, transformationId);
      })
      .catch(function (error) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_ERROR,
          transformationId: transformationId,
          bucketId: bucketId,
          editingId: propertyName,
          pendingAction: pendingAction,
          error: error,
        });
        throw error;
      });
  },
  deleteTransformationProperty: function (
    bucketId,
    transformationId,
    propertyName,
    changeDescription,
  ) {
    let finalChangeDescription = changeDescription;
    const pendingAction = 'save-' + propertyName;
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_START,
      transformationId: transformationId,
      bucketId: bucketId,
      pendingAction: pendingAction,
    });
    let transformation = TransformationsStore.getTransformation(bucketId, transformationId);
    transformation = transformation.delete(propertyName);
    if (!changeDescription) {
      finalChangeDescription =
        'Delete ' + capitalize(propertyName) + ' in ' + transformation.get('name');
    }
    return transformationsApi
      .saveTransformation(bucketId, transformationId, transformation.toJS(), finalChangeDescription)
      .then(function (response) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_SUCCESS,
          transformationId: transformationId,
          bucketId: bucketId,
          editingId: propertyName,
          pendingAction: pendingAction,
          data: response,
        });
        return reloadVersions(bucketId, transformationId);
      })
      .catch(function (error) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_ERROR,
          transformationId: transformationId,
          bucketId: bucketId,
          editingId: propertyName,
          pendingAction: pendingAction,
          error: error,
        });
        throw error;
      });
  },
  updateInputMappingOrder: function (bucketId, transformationId, oldIndex, newIndex) {
    const pendingAction = 'update-input-mapping-position';
    const changeDescription = 'Update Input Mapping position';
    const transformationBeforeReorder = TransformationsStore.getTransformation(
      bucketId,
      transformationId,
    );
    const transformation = transformationBeforeReorder.update('input', (inputs) =>
      reorderTransformationInputs(inputs, oldIndex, newIndex),
    );
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_START,
      transformationId,
      bucketId,
      pendingAction,
      // Emit also Transformation with reordered Input Mapping
      transformation,
    });
    return transformationsApi
      .saveTransformation(bucketId, transformationId, transformation.toJS(), changeDescription)
      .then((data) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_SUCCESS,
          transformationId,
          bucketId,
          pendingAction,
          data,
        });
        return reloadVersions(bucketId, transformationId);
      })
      .catch((error) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_ERROR,
          transformationId,
          bucketId,
          pendingAction,
          // Emit also Transformation in the previous state (save did't happen)
          transformation: transformationBeforeReorder,
        });
        throw error;
      });
  },
  updateTransformationEditingField: function (bucketId, transformationId, fieldId, newValue) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_UPDATE_EDITING_FIELD,
      bucketId: bucketId,
      transformationId: transformationId,
      fieldId: fieldId,
      newValue: newValue,
    });
  },
  updateTransformationEditingFieldQueriesString: function (
    bucketId,
    transformationId,
    queriesString,
  ) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_UPDATE_PARSE_QUERIES,
      bucketId: bucketId,
      transformationId: transformationId,
      queriesString: queriesString,
    });
    return updateTransformationEditingFieldQueriesStringDebouncer(
      bucketId,
      transformationId,
      queriesString,
    );
  },
  cancelTransformationEditingField: function (bucketId, transformationId, fieldId) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_CANCEL_EDITING_FIELD,
      bucketId: bucketId,
      transformationId: transformationId,
      fieldId: fieldId,
    });
  },

  saveTransformationQueries: function (bucketId, transformationId) {
    var transformation = TransformationsStore.getTransformation(bucketId, transformationId);
    const transformationEditingFields = TransformationsStore.getTransformationEditingFields(
      bucketId,
      transformationId,
    );
    const pendingAction = 'save-queries';
    const changeDescription = transformationEditingFields.get(
      'description',
      'Change Queries in ' + transformation.get('name'),
    );
    transformation = transformation.set('queries', transformationEditingFields.get('splitQueries'));
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_START,
      transformationId: transformationId,
      bucketId: bucketId,
      pendingAction: pendingAction,
    });
    return transformationsApi
      .saveTransformation(bucketId, transformationId, transformation.toJS(), changeDescription)
      .then(function (response) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_SUCCESS,
          transformationId: transformationId,
          bucketId: bucketId,
          editingId: 'queries',
          pendingAction: pendingAction,
          data: response,
        });
        return reloadVersions(bucketId, transformationId);
      })
      .catch(function (error) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_ERROR,
          transformationId: transformationId,
          bucketId: bucketId,
          editingId: 'queries',
          pendingAction: pendingAction,
          error: error,
        });
        throw error;
      });
  },

  saveTransformationScript: function (bucketId, transformationId) {
    var transformation = TransformationsStore.getTransformation(bucketId, transformationId);
    const transformationEditingFields = TransformationsStore.getTransformationEditingFields(
      bucketId,
      transformationId,
    );
    const pendingAction = 'save-queries';
    const changeDescription = transformationEditingFields.get(
      'description',
      'Change Scripts in ' + transformation.get('name'),
    );
    transformation = transformation.set(
      'queries',
      List([transformationEditingFields.get('queriesString')]),
    );
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_START,
      transformationId: transformationId,
      bucketId: bucketId,
      pendingAction: pendingAction,
    });
    return transformationsApi
      .saveTransformation(bucketId, transformationId, transformation.toJS(), changeDescription)
      .then(function (response) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_SUCCESS,
          transformationId: transformationId,
          bucketId: bucketId,
          editingId: 'queries',
          pendingAction: pendingAction,
          data: response,
        });
        return reloadVersions(bucketId, transformationId);
      })
      .catch(function (error) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_ERROR,
          transformationId: transformationId,
          bucketId: bucketId,
          editingId: 'queries',
          pendingAction: pendingAction,
          error: error,
        });
        throw error;
      });
  },

  saveTransformationEditingField: function (
    bucketId,
    transformationId,
    fieldId,
    changeDescription,
  ) {
    var pendingAction, transformation, value, finalChangeDescription;
    finalChangeDescription = changeDescription;
    value = TransformationsStore.getTransformationEditingFields(bucketId, transformationId).get(
      fieldId,
    );
    transformation = TransformationsStore.getTransformation(bucketId, transformationId);
    transformation = transformation.set(fieldId, value);
    if (!finalChangeDescription) {
      finalChangeDescription =
        'Change ' + capitalize(fieldId) + ' in ' + transformation.get('name');
    }
    pendingAction = 'save-' + fieldId;
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_START,
      transformationId: transformationId,
      bucketId: bucketId,
      pendingAction: pendingAction,
    });
    return transformationsApi
      .saveTransformation(bucketId, transformationId, transformation.toJS(), finalChangeDescription)
      .then(function (response) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_SUCCESS,
          transformationId: transformationId,
          bucketId: bucketId,
          editingId: fieldId,
          pendingAction: pendingAction,
          data: response,
        });
        return reloadVersions(bucketId, transformationId);
      })
      .catch(function (error) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_ERROR,
          transformationId: transformationId,
          bucketId: bucketId,
          editingId: fieldId,
          pendingAction: pendingAction,
          error: error,
        });
        throw error;
      });
  },

  saveTransformationMapping: function (
    bucketId,
    transformationId,
    mappingType,
    editingId,
    mappingIndex = null,
  ) {
    let changeDescription, mapping, transformation;
    mapping = TransformationsStore.getTransformationEditingFields(bucketId, transformationId).get(
      editingId,
    );

    if (!mapping) {
      return Promise.resolve();
    }

    transformation = TransformationsStore.getTransformation(bucketId, transformationId);
    if (mappingIndex === null) {
      changeDescription = `Create ${mappingType} mapping${
        mapping.has('tables') ? 's' : ''
      } in ${transformation.get('name')}`;
    } else {
      changeDescription = `Update ${mappingType} mapping in ${transformation.get('name')}`;
    }

    transformation = updateTransformationMappings(
      transformation,
      mappingType,
      mapping,
      mappingIndex,
    );

    const pendingAction = `save-${mappingType}`;
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_START,
      transformationId: transformationId,
      bucketId: bucketId,
      pendingAction,
    });
    return transformationsApi
      .saveTransformation(bucketId, transformationId, transformation.toJS(), changeDescription)
      .then(function (response) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_SUCCESS,
          transformationId: transformationId,
          bucketId: bucketId,
          editingId: editingId,
          data: response,
          pendingAction,
        });
        return reloadVersions(bucketId, transformationId);
      })
      .catch(function (error) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_ERROR,
          transformationId: transformationId,
          bucketId: bucketId,
          error: error,
          pendingAction,
        });
        throw error;
      });
  },
  deleteTransformationMapping: function (bucketId, transformationId, mappingType, mappingIndex) {
    var changeDescription, pendingAction, transformation;
    transformation = TransformationsStore.getTransformation(bucketId, transformationId);
    transformation = transformation.update(mappingType, function (mappings) {
      return mappings.delete(mappingIndex);
    });
    changeDescription = 'Delete ' + mappingType + ' mapping in ' + transformation.get('name');
    pendingAction = 'delete-' + mappingType + '-' + mappingIndex;
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_START,
      transformationId: transformationId,
      bucketId: bucketId,
      pendingAction: pendingAction,
    });
    return transformationsApi
      .saveTransformation(bucketId, transformationId, transformation.toJS(), changeDescription)
      .then(function (response) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_SUCCESS,
          transformationId: transformationId,
          bucketId: bucketId,
          data: response,
          pendingAction: pendingAction,
        });
        return reloadVersions(bucketId, transformationId);
      })
      .catch(function (error) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_ERROR,
          transformationId: transformationId,
          bucketId: bucketId,
          pendingAction: pendingAction,
          error: error,
        });
        throw error;
      });
  },
  migrateToNewTransformation: function (bucketId) {
    return callDockerAction(KEBOOLA_APP_TRANSFORMATION_MIGRATE, 'migrate', {
      configData: { parameters: { transformationId: bucketId } },
    }).then((response) => {
      if (response.status && response.status === 'error') {
        throw new SyncActionError(
          response.message || 'An error occurred while migrating transformations',
          response.code === 500 ? response.exceptionId : '',
        );
      }

      ApplicationActionCreators.sendNotification({
        type: 'success',
        message: migrateTransformationNotification(response.componentId, response.id),
      });

      InstalledComponentsActionCreators.loadComponentConfigsData(TRANSFORMATION);

      return null;
    });
  },
};
