import React from 'react';
import { Tooltip } from 'design';
import type { Map } from 'immutable';
import _ from 'underscore';

import MarkedText from '@/react/common/MarkedText';
import Truncated from '@/react/common/Truncated';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen,
} from '@/utils/windowOpen';
import Favourite from './Favourite';
import type { Project } from './types';

const ProjectsList = ({
  projects,
  currentProject,
  query,
  currentUser,
  urlTemplates,
  withOrganizationName = false,
  getOrganizationUrl,
}: {
  projects: Project[];
  currentProject: Map<string, any>;
  query: string;
  currentUser: Map<string, any>;
  urlTemplates: Map<string, any>;
  withOrganizationName?: boolean;
  getOrganizationUrl?: (organizationId: string) => string;
}) => {
  const projectUrlFromTemplate = (projectId: string | number) =>
    _.template(urlTemplates.get('project'))({
      projectId,
    });

  const sortedProjects = projects.sort((a, b) =>
    a.name.localeCompare(b.name, [], { numeric: true, sensitivity: 'base' }),
  );

  return (
    <ul className="list-unstyled p-0">
      {sortedProjects.map((project) => {
        const isCurrentProject = currentProject.get('id') === project.id;
        return (
          <li
            key={`proj-${project.id}`}
            onClick={(event) => {
              if (project.isDisabled) {
                return;
              }

              if (shouldUseNewWindow(event)) {
                return windowOpen(projectUrlFromTemplate(project.id));
              }

              window.location.href = projectUrlFromTemplate(project.id);
            }}
            className="tw-text-neutral-800"
            onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
            onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
          >
            <Favourite
              isCurrentProject={isCurrentProject}
              isDisabled={project.isDisabled ?? false}
              project={project}
              currentUser={currentUser}
            >
              <div className="tw-flex tw-flex-col">
                {project.isDisabled ? (
                  <Tooltip
                    placement="top"
                    type="explanatory"
                    tooltip={`Project is disabled. ${project.disabledReason ?? ''}`}
                  >
                    <MarkedText
                      source={project.name}
                      mark={query}
                      className="tw-text-sm tw-font-medium"
                    />
                  </Tooltip>
                ) : (
                  <MarkedText
                    source={isCurrentProject ? currentProject.get('name') : project.name}
                    mark={query}
                    className="tw-text-sm tw-font-medium"
                  />
                )}
                {withOrganizationName &&
                  project.organization &&
                  (project.organization.hasAccess && getOrganizationUrl ? (
                    <a
                      href={getOrganizationUrl(project.organization.id)}
                      className="tw-self-start tw-no-underline"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Truncated
                        tooltip={project.organization.name}
                        text={<MarkedText source={project.organization.name} mark={query} />}
                        className="tw-text-xs tw-text-neutral-400 hover:tw-text-secondary-500"
                      />
                    </a>
                  ) : (
                    <Truncated
                      tooltip={project.organization.name}
                      text={<MarkedText source={project.organization.name} mark={query} />}
                      className="tw-text-xs tw-text-neutral-400"
                    />
                  ))}
              </div>
            </Favourite>
          </li>
        );
      })}
    </ul>
  );
};

export default ProjectsList;
