import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';
import { startsWith, strRight } from 'underscore.string';

import ChangedSinceInput from '@/react/common/ChangedSinceInput';
import Select from '@/react/common/Select';
import {
  getCommonParameterForAllMapings,
  parseComponentConfigurationAndRowFromSourceTags,
} from './helpers';
import Mapping from './Mapping';

const filterOptions = [
  {
    value: '',
    label: 'All files (default)',
  },
  {
    value: 'limit:1',
    label: 'Last file for each data source',
  },
  {
    value: 'limit:5',
    label: 'Last 5 files for each data source',
  },
  {
    value: 'limit:10',
    label: 'Last 10 files for each data source',
  },
  {
    value: 'changed_since:adaptive',
    label: 'Only files changed since the last successful run',
  },
  {
    value: 'changed_since:',
    label: 'Only files changed in last ...',
  },
];

class SelectedDataSources extends React.Component {
  render() {
    return (
      <div className="box like-action-sidebar">
        <div className="box-content">
          <h3 className="f-14 font-semibold mt-0 mb-2 text-muted uppercase">
            Selected Data Sources
          </h3>
          {this.renderContent()}
        </div>
      </div>
    );
  }

  renderContent() {
    if (this.props.filesInputMapping.isEmpty()) {
      return <p className="text-muted">No sources selected.</p>;
    }

    const currentCommonParameter = getCommonParameterForAllMapings(this.props.filesInputMapping);
    const hasChangedSinceRange =
      startsWith(currentCommonParameter, 'changed_since') &&
      currentCommonParameter !== 'changed_since:adaptive';

    return (
      <>
        <Select
          value={hasChangedSinceRange ? 'changed_since:' : currentCommonParameter}
          onChange={(selected) => this.props.handleUpdateFilter(selected)}
          options={filterOptions}
          clearable={false}
        />
        {hasChangedSinceRange && (
          <div className="mt-1">
            <ChangedSinceInput
              onChange={(changedSince) =>
                this.props.handleUpdateFilter(`changed_since:${changedSince}`)
              }
              value={strRight(currentCommonParameter, 'changed_since:')}
              disabled={false}
            />
          </div>
        )}
        {this.props.filesInputMapping.map((mapping, index) => {
          const ids = parseComponentConfigurationAndRowFromSourceTags(
            mapping.getIn(['source', 'tags'], List()),
          );
          const component = this.props.allComponents.get(ids.get('componentId'), Map());
          const configuration = this.props.allConfigurations.getIn(
            [ids.get('componentId'), 'configurations', ids.get('configurationId')],
            Map(),
          );
          const configurationRow = configuration
            .get('rows', List())
            .find((row) => row.get('id') === ids.get('configurationRowId'), null, Map());

          return (
            <div key={index} className="box p-1 mt-1 flex-container">
              <div>
                <Mapping
                  iconSize="32"
                  component={component}
                  configuration={configuration}
                  configurationRow={configurationRow}
                  fileTag={ids.get('fileTag', '')}
                />
              </div>
              <Button
                bsSize="sm"
                bsStyle="link"
                className="btn-clear"
                onClick={() => this.props.handleDeleteSource(index)}
              >
                <FontAwesomeIcon icon="xmark" />
              </Button>
            </div>
          );
        })}
      </>
    );
  }
}

SelectedDataSources.propTypes = {
  filesInputMapping: PropTypes.instanceOf(List).isRequired,
  allConfigurations: PropTypes.instanceOf(Map).isRequired,
  allComponents: PropTypes.instanceOf(Map).isRequired,
  handleDeleteSource: PropTypes.func.isRequired,
  handleUpdateFilter: PropTypes.func.isRequired,
};

export default SelectedDataSources;
