import React from 'react';
import type { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';
import _ from 'underscore';

import * as componentFlags from '@/constants/componentFlags';
import { GENERIC_DOCKER_UI_FILE_OUTPUT } from '@/constants/componentFlags';
import { KEBOOLA_ORCHESTRATOR, KEBOOLA_SHARED_CODE } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { SIDEBAR } from '@/constants/external';
import { FEATURE_SANDBOXES_PYTHON_SPARK } from '@/constants/features';
import {
  features as componentFeatures,
  HIDE_PARALLELISM_BUTTON,
} from '@/modules/components/Constants';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import type { OptionValue } from '@/modules/components/ParallelismModal';
import ParallelismModal from '@/modules/components/ParallelismModal';
import DocumentationLink from '@/modules/components/react/components/DocumentationLink';
import ScheduleConfigurationButton from '@/modules/components/react/components/ScheduleConfigurationButton';
import SidebarJobs from '@/modules/components/react/components/SidebarJobs';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { routeNames as componentsRoutes } from '@/modules/components-directory/constants';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import LatestJobsStore from '@/modules/jobs/stores/LatestJobsStore';
import NotificationsStore from '@/modules/notifications/store';
import JobsStore from '@/modules/queue/store';
import StackFeaturesStore from '@/modules/stack-features/Store';
import CopyButton from '@/react/common/ConfigurationsTable/CopyButton';
import CreatedFrom from '@/react/common/CreatedFrom';
import DeleteConfigurationButton from '@/react/common/DeleteConfigurationButton';
import RouterLink from '@/react/common/RouterLink';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import AutoTypesSwitch from './AutoTypesSwitch';
import CreatedByUser from './CreatedByUser';
import DevBranchUnsafeSwitch from './DevBranchUnsafeSwitch';
import FileStorageOnlySwitch from './FileStorageOnlySwitch';
import type { Run } from './RunButton';
import RunButton from './RunButton';
import TagOverride from './TagOverride';
import Versions from './Versions';

type Props = {
  componentId: string;
  configId: string;
  rowId?: string;
  run?: Run;
  additionalPrimaryButtons?: boolean | ReactNode;
  additionalButtons?: boolean | ReactNode | ReactNode[];
  delete?: ReactNode;
  versionsLinkTo?: string;
  hideSchedule?: boolean;
  hideJobs?: boolean;
  hideRun?: boolean;
  hideCopy?: boolean;
  hideVersions?: boolean;
  createdFromConfiguration?: Map<string, any>;
  createdByUser?: Map<string, any>;
};

const Sidebar = (props: Props) => {
  const state = useStores(
    () => {
      const component = ComponentsStore.getComponent(props.componentId);
      const hasNewQueue = ApplicationStore.hasNewQueue();
      const configuration = InstalledComponentsStore.getConfig(props.componentId, props.configId);

      const latestJobs = hasNewQueue
        ? JobsStore.getLatestJobs(props.componentId, props.configId)
        : props.rowId
          ? LatestJobsStore.getRowJobs(props.componentId, props.configId, props.rowId)
          : LatestJobsStore.getJobs(props.componentId, props.configId);

      const allConfigurations = InstalledComponentsStore.getAll();

      return {
        component,
        hasNewQueue,
        configuration,
        latestJobs,
        allConfigurations,
        row: ConfigurationRowsStore.get(props.componentId, props.configId, props.rowId),
        flows: allConfigurations.getIn([KEBOOLA_ORCHESTRATOR, 'configurations'], Map()),
        componentFeatures: component.get('features', List()),
        configData: configuration.get('configuration', Map()),
        readOnly: ApplicationStore.isReadOnly(),
        isDevModeActive: DevBranchesStore.isDevModeActive(),
        admins: ApplicationStore.getAdmins(),
        currentAdmin: ApplicationStore.getCurrentAdmin(),
        notifications: NotificationsStore.getAll(),
        hasSparkSandboxes:
          StackFeaturesStore.hasStackFeature(FEATURE_SANDBOXES_PYTHON_SPARK) ||
          ApplicationStore.hasCurrentProjectFeature(FEATURE_SANDBOXES_PYTHON_SPARK),
        hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
        hasFlows: ApplicationStore.hasFlows(),
      };
    },
    [props.componentId, props.configId, props.rowId],
    [
      ComponentsStore,
      ApplicationStore,
      InstalledComponentsStore,
      JobsStore,
      LatestJobsStore,
      ConfigurationRowsStore,
      DevBranchesStore,
      NotificationsStore,
      StackFeaturesStore,
    ],
  );

  const renderRunButton = () => {
    if (
      props.hideRun ||
      (state.component.get('flags', List()).includes(componentFlags.EXCLUDE_RUN) && !props.run)
    ) {
      return null;
    }

    return (
      <RunButton
        isReadOnly={state.readOnly}
        run={props.run}
        componentId={props.componentId}
        rowId={props.rowId}
        configId={props.configId}
        additionalPrimaryButtons={props.additionalPrimaryButtons}
      />
    );
  };

  const updateComponentConfigurationParams = (params: string[], changeDescription: string) => {
    const isEnabled = state.configData.getIn(params, false);

    return InstalledComponentsActionCreators.updateComponentConfiguration(
      props.componentId,
      props.configId,
      {
        configuration: JSON.stringify(
          isEnabled ? state.configData.deleteIn(params) : state.configData.setIn(params, true),
        ),
      },
      changeDescription,
    );
  };

  const renderDevBranchUnsafeSwitch = () => {
    if (
      props.rowId ||
      !state.isDevModeActive ||
      state.hasProtectedDefaultBranch ||
      !state.componentFeatures.includes(componentFeatures.DEV_BRANCH_CONFIGURATION_UNSAFE)
    ) {
      return null;
    }

    const isEnabled = state.configData.getIn(['runtime', 'safe'], false);

    return (
      <DevBranchUnsafeSwitch
        isEnabled={isEnabled}
        isReadOnly={state.readOnly}
        onUpdateConfiguration={updateComponentConfigurationParams}
      />
    );
  };

  const renderTagOverride = () => {
    return (
      <TagOverride
        isReadOnly={state.readOnly}
        configData={state.configData}
        componentId={props.componentId}
        configId={props.configId}
        componentFeatures={state.componentFeatures}
      />
    );
  };

  const renderFileStorageOnlySwitch = () => {
    if (
      props.rowId ||
      !state.hasSparkSandboxes ||
      !!state.component.getIn(['emptyConfiguration', 'runtime', 'use_file_storage_only']) ||
      !state.componentFeatures.includes(componentFeatures.ALLOW_USE_FILE_STORAGE_ONLY) ||
      state.component.get('flags', List()).includes(GENERIC_DOCKER_UI_FILE_OUTPUT)
    ) {
      return null;
    }

    const isEnabled = state.configData.getIn(['runtime', 'use_file_storage_only'], false);

    return (
      <FileStorageOnlySwitch
        isReadOnly={state.readOnly}
        isEnabled={isEnabled}
        onUpdateConfiguration={updateComponentConfigurationParams}
      />
    );
  };

  const renderAdditionalButtons = () => {
    if (!props.additionalButtons) {
      return null;
    }

    if (_.isArray(props.additionalButtons)) {
      return props.additionalButtons.map((button, index) => {
        return <li key={index}>{button}</li>;
      });
    }

    return <li>{props.additionalButtons}</li>;
  };

  const renderCopyButton = () => {
    if (props.hideCopy || props.rowId || state.readOnly) {
      return null;
    }

    return (
      <li>
        <CopyButton
          mode="sidebar"
          configuration={state.configuration}
          component={state.component}
          hasFlows={state.hasFlows}
        />
      </li>
    );
  };

  const renderScheduleConfigurationButton = () => {
    if (
      props.hideSchedule ||
      props.rowId ||
      state.component.get('flags', List()).includes(componentFlags.EXCLUDE_RUN) ||
      state.readOnly ||
      state.isDevModeActive
    ) {
      return null;
    }

    return (
      <li>
        <ScheduleConfigurationButton
          flows={state.flows}
          component={state.component}
          config={state.configuration}
          hasNewQueue={state.hasNewQueue}
          hasFlows={state.hasFlows}
        />
      </li>
    );
  };

  const renderAutoTypesSwitch = () => {
    return (
      <AutoTypesSwitch
        isReadOnly={state.readOnly}
        rowId={props.rowId}
        configId={props.configId}
        componentId={props.componentId}
        configData={state.configData}
        configuration={state.configuration}
        component={state.component}
      />
    );
  };

  const renderRawConfigurationButton = () => {
    return (
      <li>
        <RouterLink
          to={
            props.rowId
              ? componentsRoutes.GENERIC_CONFIG_ROW_RAW
              : componentsRoutes.GENERIC_CONFIG_RAW
          }
          params={{
            component: props.componentId,
            config: props.configId,
            row: props.rowId,
          }}
          className="btn btn-link btn-block btn-link-inline"
        >
          <FontAwesomeIcon icon="bug" fixedWidth />
          Debug mode
        </RouterLink>
      </li>
    );
  };

  const renderParallelismButton = () => {
    if (
      state.configuration.get('rows', List()).isEmpty() ||
      props.rowId ||
      !state.hasNewQueue ||
      HIDE_PARALLELISM_BUTTON.includes(props.componentId)
    ) {
      return null;
    }

    return (
      <li>
        <ParallelismModal
          onChange={(parallelItemsCount: OptionValue) => {
            return InstalledComponentsActionCreators.updateComponentConfiguration(
              props.componentId,
              props.configId,
              {
                configuration: JSON.stringify(
                  state.configData.setIn(['runtime', 'parallelism'], parallelItemsCount),
                ),
              },
              `Change the number of configuration jobs in parallelization`,
            );
          }}
          savedValue={state.configData.getIn(['runtime', 'parallelism'], null)}
          readOnly={state.readOnly}
        />
      </li>
    );
  };

  const renderDeleteConfigurationButton = () => {
    if (state.readOnly) {
      return null;
    }

    if (props.delete) {
      return (
        <li>
          <hr />
          {props.delete}
        </li>
      );
    }

    return (
      <li>
        <hr />
        <DeleteConfigurationButton
          config={state.configuration}
          componentId={props.componentId}
          flows={state.flows}
        />
      </li>
    );
  };

  const renderDocumentationButton = () => {
    if (
      !state.component.get('documentationUrl') ||
      (props.rowId && state.component.get('id') !== KEBOOLA_SHARED_CODE) ||
      [
        componentTypes.EXTRACTOR,
        componentTypes.WRITER,
        componentTypes.APPLICATION,
        componentTypes.TRANSFORMATION,
      ].includes(state.component.get('type'))
    ) {
      return null;
    }

    return (
      <li>
        <DocumentationLink href={state.component.get('documentationUrl')} />
      </li>
    );
  };

  const renderCreatedByUser = () => {
    if (!props.createdByUser) {
      return null;
    }

    return (
      <CreatedByUser createdByUser={props.createdByUser.toJS()} admins={state.admins.toJS()} />
    );
  };

  const renderCreatedFromConfiguration = () => {
    return <CreatedFrom from={props.createdFromConfiguration} />;
  };

  const renderJobs = () => {
    if (props.hideJobs) {
      return null;
    }

    return (
      <>
        <SidebarJobs
          jobs={state.latestJobs}
          componentId={props.componentId}
          configId={props.configId}
          rowId={props.rowId}
          hasNewQueue={state.hasNewQueue}
          allConfigurations={state.allConfigurations}
          admins={state.admins}
          notifications={state.notifications}
          currentAdmin={state.currentAdmin}
        />
        <hr />
      </>
    );
  };

  const renderVersions = () => {
    if (props.hideVersions) {
      return null;
    }

    return (
      <Versions
        componentId={props.componentId}
        configId={props.configId}
        rowId={props.rowId}
        versionsLinkTo={props.versionsLinkTo}
        component={state.component}
        configuration={state.configuration}
        admins={state.admins}
        row={state.row}
      />
    );
  };

  return (
    <div className={`sidebar-content ${SIDEBAR}`}>
      <ul className="nav nav-stacked">
        {renderRunButton()}
        {renderParallelismButton()}
        {renderTagOverride()}
        {renderDevBranchUnsafeSwitch()}
        {renderFileStorageOnlySwitch()}
        {renderAdditionalButtons()}
        {renderCopyButton()}
        {renderScheduleConfigurationButton()}
        {renderAutoTypesSwitch()}
        {renderRawConfigurationButton()}
        {renderDeleteConfigurationButton()}
        {renderDocumentationButton()}
      </ul>
      {renderCreatedByUser()}
      {renderCreatedFromConfiguration()}
      {renderJobs()}
      {renderVersions()}
    </div>
  );
};

export default Sidebar;
