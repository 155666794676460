let _baseUrl = '';
let _authHeaders: Record<string, string> = {};
let _fetch: typeof window.fetch = window.fetch;
export function init(baseUrl: string, fetch: typeof window.fetch) {
  _baseUrl = baseUrl;
  _authHeaders = {};
  _fetch = fetch;
}
export type RuntimeType = 'julia' | 'python' | 'python-databricks' | 'python-mlflow' | 'r' | 'test';
export type Runtime = {
  id: number;
  description: string;
  type: 'julia' | 'python' | 'python-databricks' | 'python-mlflow' | 'r' | 'test';
  imageName: string;
  imageTag: string;
  releaseDate: string;
  endOfLifeDate?: string | undefined;
  isTypeDefault?: boolean | undefined;
  transformationComponentId?: string | undefined;
  transformationComponentTag?: string | undefined;
  sandboxImageName?: string | undefined;
  sandboxImageTag?: string | undefined;
};
/**
 * Checks the service is up & running.
 */
export function healthCheck(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/health-check';
  return _fetch(url.toString(), { method: 'post', headers: { ..._authHeaders } });
}
/**
 * List of registered runtimes
 */
export function runtimes(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/runtimes';
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
