import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'design';
import { List, Map } from 'immutable';

import { NEW_ENTITY_BUTTON } from '@/constants/external';
import SandboxesActions from '@/modules/sandboxes/Actions';
import AddSandboxModal from './AddSandboxModal';

class AddSandboxButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <>
        <Button className={NEW_ENTITY_BUTTON} onClick={() => this.setState({ openModal: true })}>
          <FontAwesomeIcon icon="plus" />
          Create Workspace
        </Button>
        <AddSandboxModal
          simple
          show={this.state.openModal}
          onHide={() => this.setState({ openModal: false })}
          sandboxComponent={this.props.sandboxComponent}
          allowedComponents={this.props.allowedComponents}
          hasPayAsYouGo={this.props.hasPayAsYouGo}
          onSubmit={this.handleSubmit}
          availableDatabricksClusters={this.props.availableDatabricksClusters}
        />
      </>
    );
  }

  handleSubmit(name, type, options, params, description) {
    const sandboxConfig = { name, description };
    return SandboxesActions.createSandbox(sandboxConfig, type, options, params);
  }
}

AddSandboxButton.propTypes = {
  sandboxComponent: PropTypes.instanceOf(Map).isRequired,
  allowedComponents: PropTypes.instanceOf(Map).isRequired,
  hasPayAsYouGo: PropTypes.bool.isRequired,
  availableDatabricksClusters: PropTypes.instanceOf(List),
};

export default AddSandboxButton;
