import { fromJS, Map } from 'immutable';

const getModelIdentifier = (model) => {
  return `${model.get('name')}-${model.get('stage')}-${model.get('version')}`;
};

const isProperlyDeployedModel = (model) => {
  return (
    model.has('deployment') &&
    // when created and updated timestamp are same, models is not properly deployed yet
    model.getIn(['deployment', 'createdTimestamp'], '') !==
      model.getIn(['deployment', 'updatedTimestamp'], '')
  );
};

const prepareDeployingModels = (processingJobs, models, hasNewQueue) => {
  return processingJobs
    .filter((job) => {
      const parameters = hasNewQueue
        ? job.getIn(['configData', 'parameters'], Map())
        : job.getIn(['params', 'configData', 'parameters'], Map());

      return (
        parameters.get('task') === 'start-deployment' &&
        !models.filter(isProperlyDeployedModel).some((model) => {
          return (
            model.get('name') === parameters.get('modelName') &&
            model.get('stage') === parameters.get('modelStage') &&
            model.get('version') === parameters.get('modelVersion')
          );
        })
      );
    })
    .map((job) => {
      const parameters = hasNewQueue
        ? job.getIn(['configData', 'parameters'], Map())
        : job.getIn(['params', 'configData', 'parameters'], Map());

      return fromJS({
        name: parameters.get('modelName', ''),
        stage: parameters.get('modelStage', ''),
        version: parameters.get('modelVersion', ''),
        deployment: { isCreating: true, createdTimestamp: job.get('startTime') },
      });
    });
};

export { getModelIdentifier, isProperlyDeployedModel, prepareDeployingModels };
