import React from 'react';

import { KEBOOLA_LEGACY_TRANSFORMATION } from '@/constants/componentIds';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import ComponentNameEdit from '@/modules/components/react/components/ComponentName';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import createVersionsPageRoute from '@/modules/components/utils/createVersionsPageRoute';
import VersionsActionCreators from '@/modules/components/VersionsActionCreators';
import RowVersions from '@/modules/configurations/react/pages/Versions';
import rowVersionsActions from '@/modules/configurations/RowVersionsActionCreators';
import * as NotificationActions from '@/modules/notifications/actions';
import { configPoll, rowPoll } from '@/utils/genericPolls';
import TransformationBucketButtons from './react/components/TransformationBucketButtons';
import TransformationNameEdit from './react/components/TransformationNameEditField';
import TransformationBucket from './react/pages/transformation-bucket/TransformationBucket';
import TransformationBucketHeaderButton from './react/pages/transformation-bucket/TransformationBucketHeaderButton';
import TransformationDetail from './react/pages/transformation-detail/TransformationDetail';
import TransformationsIndex from './react/pages/transformations-index/TransformationsIndex';
import TransformationBucketsStore from './stores/TransformationBucketsStore';
import TransformationsStore from './stores/TransformationsStore';
import { routeNames } from './Constants';

const routes = {
  name: routeNames.ROOT,
  title: 'Legacy Transformations',
  defaultRouteHandler: TransformationsIndex,
  headerButtonsHandler: TransformationBucketButtons,
  requireData: [
    () => InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_LEGACY_TRANSFORMATION),
    () => NotificationActions.loadNotifications(),
  ],
  childRoutes: [
    {
      name: routeNames.BUCKET,
      path: 'bucket/:config',
      title(routerState) {
        const configId = routerState.getIn(['params', 'config']);

        return TransformationBucketsStore.get(configId).get('name');
      },
      nameEdit(params) {
        if (parseInt(params.config, 10) > 0) {
          return (
            <ComponentNameEdit
              key={`legacy-transformation-${params.config}`}
              componentId={KEBOOLA_LEGACY_TRANSFORMATION}
              configId={params.config}
            />
          );
        } else {
          return TransformationBucketsStore.get(params.config).get('name');
        }
      },
      defaultRouteHandler: TransformationBucket,
      headerButtonsHandler: TransformationBucketHeaderButton,
      requireData: [
        (params) =>
          VersionsActionCreators.loadVersions(KEBOOLA_LEGACY_TRANSFORMATION, params.config),
      ],
      poll: configPoll(KEBOOLA_LEGACY_TRANSFORMATION),
      childRoutes: [
        createVersionsPageRoute(
          KEBOOLA_LEGACY_TRANSFORMATION,
          'config',
          routeNames.BUCKET_VERSIONS,
        ),
        {
          name: routeNames.DETAIL,
          path: 'transformation/:row',
          title(routerState) {
            const configId = routerState.getIn(['params', 'config']);
            const transformationId = routerState.getIn(['params', 'row']);
            return TransformationsStore.getTransformation(configId, transformationId).get('name');
          },
          nameEdit(params) {
            return (
              <TransformationNameEdit
                key={`${params.config}-${params.row}`}
                configId={params.config}
                rowId={params.row}
              />
            );
          },
          defaultRouteHandler: TransformationDetail,
          requireData: [
            () => StorageActionCreators.loadBucketsAndTables(),
            ({ config, row }) =>
              rowVersionsActions.loadVersions(KEBOOLA_LEGACY_TRANSFORMATION, config, row),
          ],
          poll: rowPoll(KEBOOLA_LEGACY_TRANSFORMATION),
          childRoutes: [
            {
              name: routeNames.BUCKET_ROW_VERSIONS,
              settings: { componentId: KEBOOLA_LEGACY_TRANSFORMATION },
              path: 'versions',
              title: 'Versions',
              defaultRouteHandler: RowVersions,
            },
          ],
        },
      ],
    },
  ],
};

export default routes;
