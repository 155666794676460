import { fromJS, List, Map } from 'immutable';

import {
  KDS_TEAM_WR_EXASOL,
  KDS_TEAM_WR_FIREBOLT,
  KDS_TEAM_WR_HIVE_CSAS,
  KDS_TEAM_WR_PAIRITY,
  KEBOOLA_WR_DB_HIVE,
  KEBOOLA_WR_DB_IMPALA,
  KEBOOLA_WR_DB_MSSQL_V_2,
  KEBOOLA_WR_DB_MYSQL,
  KEBOOLA_WR_DB_ORACLE,
  KEBOOLA_WR_DB_PGSQL,
  KEBOOLA_WR_DB_SNOWFLAKE,
  KEBOOLA_WR_DB_SNOWFLAKE_GCS,
  KEBOOLA_WR_DB_SNOWFLAKE_GCS_S3,
  KEBOOLA_WR_DB_SYNAPSE,
  KEBOOLA_WR_REDSHIFT_V_2,
  KEBOOLA_WR_SISENSE,
  KEBOOLA_WR_SNOWFLAKE_BLOB_STORAGE,
} from '@/constants/componentIds';
import { parse as parseTable } from '@/utils/tableIdParser';
import { DISABLED_NULLABLE } from './constants';

const supportConfigRows = (componentId) => {
  return [
    KEBOOLA_WR_DB_SNOWFLAKE,
    KEBOOLA_WR_DB_SNOWFLAKE_GCS,
    KEBOOLA_WR_DB_SNOWFLAKE_GCS_S3,
    KEBOOLA_WR_SNOWFLAKE_BLOB_STORAGE,
    KEBOOLA_WR_DB_MYSQL,
    KEBOOLA_WR_DB_MSSQL_V_2,
    KEBOOLA_WR_DB_ORACLE,
    KEBOOLA_WR_DB_PGSQL,
    KEBOOLA_WR_DB_IMPALA,
    KEBOOLA_WR_DB_HIVE,
    KEBOOLA_WR_DB_SYNAPSE,
    KEBOOLA_WR_REDSHIFT_V_2,
    KEBOOLA_WR_SISENSE,
    KDS_TEAM_WR_FIREBOLT,
    KDS_TEAM_WR_EXASOL,
    KDS_TEAM_WR_HIVE_CSAS,
  ].includes(componentId);
};

const supportFullLoadOnly = (componentId) => {
  return [KEBOOLA_WR_SISENSE].includes(componentId);
};

const supportLoadTables = (componentId, configData) => {
  return (
    [KEBOOLA_WR_DB_ORACLE].includes(componentId) &&
    !!configData.getIn(['parameters', 'db', 'schema'])
  );
};

const hasSshTunnel = (componentId) => {
  return [
    KEBOOLA_WR_REDSHIFT_V_2,
    KEBOOLA_WR_DB_MSSQL_V_2,
    KEBOOLA_WR_DB_MYSQL,
    KEBOOLA_WR_DB_IMPALA,
    KEBOOLA_WR_DB_HIVE,
    KEBOOLA_WR_DB_ORACLE,
    KEBOOLA_WR_DB_PGSQL,
    KDS_TEAM_WR_PAIRITY,
    KDS_TEAM_WR_HIVE_CSAS,
  ].includes(componentId);
};

const hasSsl = (componentId) => {
  return [KEBOOLA_WR_DB_MYSQL].includes(componentId);
};

const createConfigRows = (config) => {
  const state = config.getIn(['state', 'storage', 'input', 'tables'], List());
  const storage = config.getIn(['configuration', 'storage', 'input', 'tables'], List());
  const withSourceSearch = getStorageInputTablesWithSourceSearch(
    config.get('configuration', Map()),
  );

  return config
    .getIn(['configuration', 'parameters', 'tables'], List())
    .map((table) => {
      let configuration = fromJS({ parameters: table.delete('export') });

      const oldStorage = storage.find((item) => item.get('source') === table.get('tableId'));
      if (oldStorage) {
        configuration = configuration.setIn(
          ['storage', 'input', 'tables'],
          List([oldStorage.delete('limit')]),
        );
      } else if (withSourceSearch.has(table.get('tableId'))) {
        configuration = configuration.setIn(
          ['storage', 'input', 'tables'],
          List([withSourceSearch.get(table.get('tableId')).delete('id')]),
        );
      }

      let newState = Map();
      const oldState = state.find((item) => item.get('source') === table.get('tableId'));
      if (oldState) {
        newState = newState.setIn(['storage', 'input', 'tables'], List([oldState]));
      }

      return {
        name: parseTable(table.get('tableId')).parts.table,
        isDisabled: !table.get('export'),
        configuration: JSON.stringify(configuration.toJS()),
        state: JSON.stringify(newState.toJS()),
      };
    })
    .toJS();
};

const hasDisabledNullable = (componentId, primaryKey, column) => {
  return DISABLED_NULLABLE.includes(componentId) && primaryKey.includes(column);
};

const updateConfigurationAfterMigration = (configuration) => {
  return configuration.deleteIn(['parameters', 'tables']).delete('storage');
};

const updateStateAfterMigration = (state) => {
  return state.delete('storage');
};

const getStorageInputTablesWithSourceSearch = (config) => {
  return config
    .getIn(['storage', 'input', 'tables'], List())
    .filter((table) => table.has('source_search'))
    .map((table) => {
      const destination = table.get('destination');
      // create table id by removing ".csv" suffix from destination
      return table.set('id', destination.substring(0, destination.length - 4));
    })
    .toMap()
    .mapKeys((key, table) => {
      return table.get('id');
    });
};

// Returns true/false if credentials are from old provisioning
const isProvisioningCredentials = (credentials) => {
  return credentials.has('password');
};

const getHostnameValue = (host, port) => {
  if (!host && !port) {
    return '';
  }

  if (!port) {
    return host;
  }

  return `${host}:${port}`;
};

export {
  getStorageInputTablesWithSourceSearch,
  isProvisioningCredentials,
  createConfigRows,
  hasDisabledNullable,
  updateConfigurationAfterMigration,
  updateStateAfterMigration,
  supportConfigRows,
  supportFullLoadOnly,
  supportLoadTables,
  hasSshTunnel,
  hasSsl,
  getHostnameValue,
};
