import React, { useContext } from 'react';
import { Panel as RfPanel } from '@xyflow/react';
import classNames from 'classnames';

import DataLineageReactFlow from '@/modules/lineage/components/DataLineageReactFlow';
import { GraphContext } from '@/modules/lineage/components/GraphContext';
import NoGraphWrapper from '@/modules/lineage/components/NoGraphWrapper';
import Sidebar from '@/modules/lineage/components/Sidebar/Sidebar';
import { hasSidebar, resetNodeQueryParam } from '@/modules/lineage/helpers';
import type { NodeData, OnSelectProject } from '@/modules/lineage/rfTypes';
import type { EdgeInfo } from '@/modules/lineage/types';
import RoutesStore from '@/stores/RoutesStore';

const getNodeDetail = (nodes?: NodeData[]) => {
  const nodeId = new URLSearchParams(window.location.search).get('node');

  if (!nodeId || !nodes) {
    return null;
  }

  return nodes.find((node) => node.fqid === nodeId) ?? null;
};

const Content = ({
  isLoading,
  isError,
  graph,
  isLocked,
  inModal = false,
  withColumns = false,
  projectId,
  onSelectProject,
}: {
  isLoading: boolean;
  isError: boolean;
  graph: { nodes: NodeData[]; edges: EdgeInfo[]; computeTimestamp: string } | null;
  isLocked: boolean;
  inModal?: boolean;
  withColumns?: boolean;
  projectId: string | null;
  onSelectProject: OnSelectProject;
}) => {
  const { setNodeDetail, nodeDetail } = useContext(GraphContext);

  React.useEffect(() => {
    const node = getNodeDetail(graph?.nodes);

    if (node?.fqid !== nodeDetail?.fqid) {
      setNodeDetail(node);
    }
  }, [graph?.nodes, nodeDetail, setNodeDetail]);

  const handleOpenSidebar = React.useCallback(
    (nodeId: string | null) => {
      setNodeDetail(graph?.nodes.find((node) => node.fqid === nodeId) ?? null);
      RoutesStore.getRouter().updateQuery({ node: nodeId });
    },
    [graph?.nodes, setNodeDetail],
  );

  const handleCloseSidebar = React.useCallback(() => {
    setNodeDetail(null);
    resetNodeQueryParam();
  }, [setNodeDetail]);

  const withSidebar = !!nodeDetail && hasSidebar(nodeDetail.type);

  if (isLocked) {
    return null;
  }

  return (
    <>
      {withSidebar && (
        <RfPanel
          data-sidebar
          position="top-right"
          className={classNames('tw-z-20 tw-m-0', inModal ? 'tw-h-full' : 'tw-h-[calc(100%+14px)]')}
        >
          <Sidebar
            onSelectProject={onSelectProject}
            node={nodeDetail}
            onCloseSidebar={handleCloseSidebar}
            projectId={projectId}
          />
        </RfPanel>
      )}
      <NoGraphWrapper isLoading={isLoading} isError={isError}>
        {graph && (
          <DataLineageReactFlow
            key={graph.computeTimestamp}
            nodesData={graph.nodes}
            edgesData={graph.edges}
            openedSidebar={withSidebar}
            onOpenSidebar={handleOpenSidebar}
            inModal={inModal}
            withColumns={withColumns}
            onSelectProject={onSelectProject}
          />
        )}
      </NoGraphWrapper>
    </>
  );
};

export default Content;
