import React from 'react';
import { cn } from 'design';

import CircleIcon from '@/react/common/CircleIcon';

type Size = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type Props = {
  size?: Size;
  className?: string;
  disableAnimation?: boolean;
};

const sizeClassName = {
  xxs: 'tw-h-3 tw-rounded',
  xs: 'tw-h-4 tw-rounded',
  sm: 'tw-h-5 tw-rounded',
  md: 'tw-h-6 tw-rounded-md',
  lg: 'tw-h-8 tw-rounded-lg',
  xl: 'tw-h-10 tw-rounded-lg',
};

export const SkeletonBar = ({ size = 'md', disableAnimation = false, className }: Props) => (
  <div
    className={cn(
      'tw-bg-neutral-150',
      !disableAnimation && 'animation-bar-core',
      sizeClassName[size],
      className,
    )}
  />
);

export const SkeletonBox = ({ className }: Props) => (
  <div className={cn('animation-wrapper box box-panel', className)}>
    <div className="box-header tw-mb-2">
      <div className="tw-flex">
        <CircleIcon className="tw-mr-2 !tw-bg-neutral-150" bigger />
        <div className="tw-flex tw-flex-col tw-justify-center">
          <SkeletonBar className="tw-mb-2.5 tw-w-40" size="xs" disableAnimation />
          <SkeletonBar className="tw-w-20" size="xxs" disableAnimation />
        </div>
      </div>
    </div>
    <div className="box-panel-content">
      <SkeletonBar className="tw-mb-2 tw-w-full" size="xxs" disableAnimation />
      <SkeletonBar className="tw-w-28" size="xxs" disableAnimation />
      <SkeletonBar className="tw-mt-6 tw-w-52" disableAnimation />
    </div>
  </div>
);
