import React from 'react';
import { Map } from 'immutable';

import {
  KEBOOLA_LEGACY_TRANSFORMATION,
  KEBOOLA_NO_CODE_DBT_TRANSFORMATION,
  KEBOOLA_SANDBOXES,
} from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { getAllowedTransformations, getFolderFromMetadata } from '@/modules/components/helpers';
import TransformationConfigs from '@/modules/components/react/pages/TransformationConfigs';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import NotificationsStore from '@/modules/notifications/store';
import JobsStore from '@/modules/queue/store';
import { hasSandbox, prepareSandboxes } from '@/modules/sandboxes/helpers';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import NoEntityCreated from '@/react/common/NoEntityCreated';
import NoResultsFound from '@/react/common/NoResultsFound';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import matchByWords from '@/utils/matchByWords';

const TransformationsConfigs = ({ filterQuery }: { filterQuery: string }) => {
  const state = useStores(
    () => {
      const isDevModeActive = DevBranchesStore.isDevModeActive();

      return {
        isDevModeActive,
        hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
        readOnly: ApplicationStore.isReadOnly(),
        admins: ApplicationStore.getAdmins(),
        currentAdmin: ApplicationStore.getCurrentAdmin(),
        notifications: NotificationsStore.getAll(),
        sandboxComponent: ComponentsStore.getComponent(KEBOOLA_SANDBOXES),
        hasNewQueue: ApplicationStore.hasNewQueue(),
        hasFlows: ApplicationStore.hasFlows(),
        sandboxes: prepareSandboxes(
          SandboxesStore.getSandboxes(),
          InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SANDBOXES),
        ),
        latestJobs: JobsStore.getLatestConfigJobs(),
        allowedCreateWorkspace: getAllowedTransformations(
          ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
          ApplicationStore.getSapiToken(),
          ApplicationStore.getCurrentProjectFeatures(),
          StackFeaturesStore.getAll(),
        ).filter((component: Map<string, any>, componentId: string) => hasSandbox(componentId)),
        availableDatabricksClusters: InstalledComponentsStore.getLocalState(
          KEBOOLA_SANDBOXES,
          null,
        ).get('clusters'),
        allComponents: ComponentsStore.getAll(),
        allConfigurations: InstalledComponentsStore.getAll(),
        allTables: StorageTablesStore.getAll(),
        installedComponents: InstalledComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
        componentsMetadata: InstalledComponentsStore.getAllMetadata() as Map<string, any>,
        expandedFolders: InstalledComponentsStore.getExpandedFolders(componentTypes.TRANSFORMATION),
        hasSnowflakeDynamicBackendSize: ApplicationStore.hasSnowflakeDynamicBackendSize(),
        hasJobsDynamicBackendSize: ApplicationStore.hasJobsDynamicBackendSize(),
      };
    },
    [],
    [
      ApplicationStore,
      StackFeaturesStore,
      DevBranchesStore,
      InstalledComponentsStore,
      StorageTablesStore,
      SandboxesStore,
      NotificationsStore,
      ComponentsStore,
      JobsStore,
    ],
  );

  const transformationsFiltered = !filterQuery
    ? state.installedComponents
    : state.installedComponents
        .map((component: Map<string, any>, componentId: string) => {
          if (matchByWords(component.get('name'), filterQuery)) {
            return component;
          }

          return component.set(
            'configurations',
            component
              .get('configurations', Map())
              .filter((configuration: Map<string, any>, configurationId: string) => {
                const folder = getFolderFromMetadata(
                  state.componentsMetadata.getIn([componentId, configurationId]),
                );

                return matchByWords(
                  [configuration.get('name'), configuration.get('description'), folder],
                  filterQuery,
                );
              }),
          );
        })
        .filter((component: Map<string, any>) => component.get('configurations').count() > 0);

  if (!!filterQuery && transformationsFiltered.isEmpty()) {
    return <NoResultsFound entityName="transformations" />;
  }

  if (transformationsFiltered.isEmpty()) {
    return <NoEntityCreated entityName="transformations" />;
  }

  return (
    <TransformationConfigs
      readOnly={state.readOnly}
      admins={state.admins}
      currentAdmin={state.currentAdmin}
      notifications={state.notifications}
      latestJobs={state.latestJobs}
      allConfigurations={state.allConfigurations}
      allTables={state.allTables}
      configurations={transformationsFiltered
        .flatMap((component: Map<string, any>) => {
          return component.get('configurations').map((config: Map<string, any>) => {
            return config.set('component', component);
          });
        })
        .map((configuration: Map<string, any>) => {
          const isDummyItem =
            state.isDevModeActive &&
            [KEBOOLA_NO_CODE_DBT_TRANSFORMATION, KEBOOLA_LEGACY_TRANSFORMATION].includes(
              configuration.getIn(['component', 'id']),
            );

          return configuration.set('isDummyItem', isDummyItem).set('isUnselectable', isDummyItem);
        })}
      availableConfigurations={state.installedComponents}
      isSearching={!!filterQuery}
      sandboxComponent={state.sandboxComponent}
      allowedCreateWorkspace={state.allowedCreateWorkspace}
      availableDatabricksClusters={state.availableDatabricksClusters}
      sandboxes={state.sandboxes}
      hasPayAsYouGo={state.hasPayAsYouGo}
      hasNewQueue={state.hasNewQueue}
      hasFlows={state.hasFlows}
      isDevModeActive={state.isDevModeActive}
      hasSnowflakeDynamicBackendSize={state.hasSnowflakeDynamicBackendSize}
      hasJobsDynamicBackendSize={state.hasJobsDynamicBackendSize}
      componentsMetadata={state.componentsMetadata}
      expandedFolders={state.expandedFolders}
    />
  );
};

export default TransformationsConfigs;
