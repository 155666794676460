import { library } from '@fortawesome/fontawesome-svg-core';
import { faStripe } from '@fortawesome/free-brands-svg-icons/faStripe';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faArrowRotateLeft } from '@fortawesome/free-solid-svg-icons/faArrowRotateLeft';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons/faArrowsRotate';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faBarsStaggered } from '@fortawesome/free-solid-svg-icons/faBarsStaggered';
import { faBolt } from '@fortawesome/free-solid-svg-icons/faBolt';
import { faBox } from '@fortawesome/free-solid-svg-icons/faBox';
import { faBug } from '@fortawesome/free-solid-svg-icons/faBug';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faCircleArrowUp } from '@fortawesome/free-solid-svg-icons/faCircleArrowUp';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck';
import { faCircleDot } from '@fortawesome/free-solid-svg-icons/faCircleDot';
import { faCircleDown } from '@fortawesome/free-solid-svg-icons/faCircleDown';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons/faCircleInfo';
import { faCirclePause } from '@fortawesome/free-solid-svg-icons/faCirclePause';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons/faCirclePlay';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons/faCirclePlus';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons/faCircleQuestion';
import { faCircleStop } from '@fortawesome/free-solid-svg-icons/faCircleStop';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons/faCircleXmark';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faClone } from '@fortawesome/free-solid-svg-icons/faClone';
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons/faCloudArrowDown';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons/faCrosshairs';
import { faDatabase } from '@fortawesome/free-solid-svg-icons/faDatabase';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { faDownLeftAndUpRightToCenter } from '@fortawesome/free-solid-svg-icons/faDownLeftAndUpRightToCenter';
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeDropper } from '@fortawesome/free-solid-svg-icons/faEyeDropper';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { faFaceMeh } from '@fortawesome/free-solid-svg-icons/faFaceMeh';
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons/faFileExcel';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons/faFileInvoice';
import { faFileLines } from '@fortawesome/free-solid-svg-icons/faFileLines';
import { faFlag } from '@fortawesome/free-solid-svg-icons/faFlag';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons/faFloppyDisk';
import { faFolder } from '@fortawesome/free-solid-svg-icons/faFolder';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons/faFolderOpen';
import { faGauge } from '@fortawesome/free-solid-svg-icons/faGauge';
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { faGift } from '@fortawesome/free-solid-svg-icons/faGift';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { faLaptop } from '@fortawesome/free-solid-svg-icons/faLaptop';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons/faLayerGroup';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faLinkSlash } from '@fortawesome/free-solid-svg-icons/faLinkSlash';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faMoon } from '@fortawesome/free-solid-svg-icons/faMoon';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faReply } from '@fortawesome/free-solid-svg-icons/faReply';
import { faRightLeft } from '@fortawesome/free-solid-svg-icons/faRightLeft';
import { faRocket } from '@fortawesome/free-solid-svg-icons/faRocket';
import { faRotate } from '@fortawesome/free-solid-svg-icons/faRotate';
import { faShare } from '@fortawesome/free-solid-svg-icons/faShare';
import { faSliders } from '@fortawesome/free-solid-svg-icons/faSliders';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';
import { faTableCells } from '@fortawesome/free-solid-svg-icons/faTableCells';
import { faTableColumns } from '@fortawesome/free-solid-svg-icons/faTableColumns';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons/faThumbsDown';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons/faThumbsUp';
import { faToolbox } from '@fortawesome/free-solid-svg-icons/faToolbox';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons/faUpRightAndDownLeftFromCenter';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faWarehouse } from '@fortawesome/free-solid-svg-icons/faWarehouse';
import { faWindowRestore } from '@fortawesome/free-solid-svg-icons/faWindowRestore';
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { faSitemap } from '@fortawesome/pro-duotone-svg-icons/faSitemap';
import { faSort } from '@fortawesome/pro-duotone-svg-icons/faSort';
import { faSortDown } from '@fortawesome/pro-duotone-svg-icons/faSortDown';
import { faSortUp } from '@fortawesome/pro-duotone-svg-icons/faSortUp';
import { faUpload as FaUploadDuotone } from '@fortawesome/pro-duotone-svg-icons/faUpload';
import { faXmark as faXmarkLight } from '@fortawesome/pro-light-svg-icons/faXmark';
import { faAngleDown as faAngleDownRegular } from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import { faAngleRight as faAngleRightRegular } from '@fortawesome/pro-regular-svg-icons/faAngleRight';
import { faAngleUp as faAngleUpRegular } from '@fortawesome/pro-regular-svg-icons/faAngleUp';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons/faArrowDownToLine';
import { faArrowLeftToLine } from '@fortawesome/pro-regular-svg-icons/faArrowLeftToLine';
import { faArrowRightFromBracket } from '@fortawesome/pro-regular-svg-icons/faArrowRightFromBracket';
import { faBook } from '@fortawesome/pro-regular-svg-icons/faBook';
import { faCalculatorAlt } from '@fortawesome/pro-regular-svg-icons/faCalculatorAlt';
import { faCalendarCheck } from '@fortawesome/pro-regular-svg-icons/faCalendarCheck';
import { faCalendarDays } from '@fortawesome/pro-regular-svg-icons/faCalendarDays';
import { faChevronRight as faChevronRightRegular } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faChevronsRight as faChevronsRightRegular } from '@fortawesome/pro-regular-svg-icons/faChevronsRight';
import { faCircleArrowDown } from '@fortawesome/pro-regular-svg-icons/faCircleArrowDown';
import { faCodeBranch } from '@fortawesome/pro-regular-svg-icons/faCodeBranch';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons/faEllipsis';
import { faEmptySet } from '@fortawesome/pro-regular-svg-icons/faEmptySet';
import { faFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
import { faFolder as faFolderRegular } from '@fortawesome/pro-regular-svg-icons/faFolder';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons/faGlobe';
import { faGraduationCap } from '@fortawesome/pro-regular-svg-icons/faGraduationCap';
import { faHeadset } from '@fortawesome/pro-regular-svg-icons/faHeadset';
import { faHourglass } from '@fortawesome/pro-regular-svg-icons/faHourglass';
import { faMap } from '@fortawesome/pro-regular-svg-icons/faMap';
import { faNoteSticky as faNoteStickyRegular } from '@fortawesome/pro-regular-svg-icons/faNoteSticky';
import { faSquareKanban } from '@fortawesome/pro-regular-svg-icons/faSquareKanban';
import { faSync } from '@fortawesome/pro-regular-svg-icons/faSync';
import { faXmark as faXmarkRegular } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { faArrowsLeftRight } from '@fortawesome/pro-solid-svg-icons/faArrowsLeftRight';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons/faArrowUpRightFromSquare';
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons/faBadgeCheck';
import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell';
import { faBellOn } from '@fortawesome/pro-solid-svg-icons/faBellOn';
import { faBookBlank } from '@fortawesome/pro-solid-svg-icons/faBookBlank';
import { faBookOpen } from '@fortawesome/pro-solid-svg-icons/faBookOpen';
import { faBracketsCurly } from '@fortawesome/pro-solid-svg-icons/faBracketsCurly';
import { faBrainCircuit } from '@fortawesome/pro-solid-svg-icons/faBrainCircuit';
import { faBrowser } from '@fortawesome/pro-solid-svg-icons/faBrowser';
import { faCabinetFiling } from '@fortawesome/pro-solid-svg-icons/faCabinetFiling';
import { faCameraViewfinder } from '@fortawesome/pro-solid-svg-icons/faCameraViewfinder';
import { faChartNetwork } from '@fortawesome/pro-solid-svg-icons/faChartNetwork';
import { faChartSimple } from '@fortawesome/pro-solid-svg-icons/faChartSimple';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import { faCircleArrowRight } from '@fortawesome/pro-solid-svg-icons/faCircleArrowRight';
import { faCircleCaretDown } from '@fortawesome/pro-solid-svg-icons/faCircleCaretDown';
import { faCircleCaretUp } from '@fortawesome/pro-solid-svg-icons/faCircleCaretUp';
import { faCircleChevronDown } from '@fortawesome/pro-solid-svg-icons/faCircleChevronDown';
import { faCircleUser } from '@fortawesome/pro-solid-svg-icons/faCircleUser';
import { faCode } from '@fortawesome/pro-solid-svg-icons/faCode';
import { faCodeMerge } from '@fortawesome/pro-solid-svg-icons/faCodeMerge';
import { faCodePullRequest } from '@fortawesome/pro-solid-svg-icons/faCodePullRequest';
import { faCreditCard } from '@fortawesome/pro-solid-svg-icons/faCreditCard';
import { faCreditCardBlank } from '@fortawesome/pro-solid-svg-icons/faCreditCardBlank';
import { faDownFromDottedLine } from '@fortawesome/pro-solid-svg-icons/faDownFromDottedLine';
import { faDownToLine } from '@fortawesome/pro-solid-svg-icons/faDownToLine';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons/faEllipsisVertical';
import { faFileImport } from '@fortawesome/pro-solid-svg-icons/faFileImport';
import { faFiles } from '@fortawesome/pro-solid-svg-icons/faFiles';
import { faFileSpreadsheet } from '@fortawesome/pro-solid-svg-icons/faFileSpreadsheet';
import { faFolderArrowUp } from '@fortawesome/pro-solid-svg-icons/faFolderArrowUp';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons/faGripDotsVertical';
import { faLineColumns } from '@fortawesome/pro-solid-svg-icons/faLineColumns';
import { faLineHeight } from '@fortawesome/pro-solid-svg-icons/faLineHeight';
import { faListRadio } from '@fortawesome/pro-solid-svg-icons/faListRadio';
import { faListTimeline } from '@fortawesome/pro-solid-svg-icons/faListTimeline';
import { faLockKeyhole } from '@fortawesome/pro-solid-svg-icons/faLockKeyhole';
import { faLockKeyholeOpen } from '@fortawesome/pro-solid-svg-icons/faLockKeyholeOpen';
import { faMessageLines } from '@fortawesome/pro-solid-svg-icons/faMessageLines';
import { faMessageQuestion } from '@fortawesome/pro-solid-svg-icons/faMessageQuestion';
import { faNetworkWired } from '@fortawesome/pro-solid-svg-icons/faNetworkWired';
import { faNoteSticky } from '@fortawesome/pro-solid-svg-icons/faNoteSticky';
import { faPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons/faPaperPlaneTop';
import { faPartyHorn } from '@fortawesome/pro-solid-svg-icons/faPartyHorn';
import { faRotateExclamation } from '@fortawesome/pro-solid-svg-icons/faRotateExclamation';
import { faSort as faSortSolid } from '@fortawesome/pro-solid-svg-icons/faSort';
import { faSquareChevronRight } from '@fortawesome/pro-solid-svg-icons/faSquareChevronRight';
import { faSquareExclamation } from '@fortawesome/pro-solid-svg-icons/faSquareExclamation';
import { faTableRows } from '@fortawesome/pro-solid-svg-icons/faTableRows';
import { faToggleLargeOff } from '@fortawesome/pro-solid-svg-icons/faToggleLargeOff';
import { faToggleLargeOn } from '@fortawesome/pro-solid-svg-icons/faToggleLargeOn';
import { faUpFromLine } from '@fortawesome/pro-solid-svg-icons/faUpFromLine';
import { faUserGear } from '@fortawesome/pro-solid-svg-icons/faUserGear';
import { faUserGroup } from '@fortawesome/pro-solid-svg-icons/faUserGroup';
import { faWebhook } from '@fortawesome/pro-solid-svg-icons/faWebhook';

library.add(
  faBolt,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faCircleArrowUp,
  faCircleDown,
  faUpFromLine,
  faDownToLine,
  faAngleDown,
  faAngleLeft,
  faAngleUp,
  faAngleRight,
  faAngleRightRegular,
  faAngleUpRegular,
  faAngleDownRegular,
  faBadgeCheck,
  faBell,
  faBellOn,
  faBan,
  faBars,
  faBox,
  faBug,
  faBook,
  faBookBlank,
  faBookOpen,
  faBracketsCurly,
  faBrainCircuit,
  faChartSimple,
  faBrowser,
  faCaretDown,
  faCabinetFiling,
  faCircleCaretDown,
  faCircleCaretUp,
  faCircleArrowRight,
  faGraduationCap,
  faHeadset,
  faSquareExclamation,
  faTableRows,
  faToggleLargeOff,
  faToggleLargeOn,
  faDownFromDottedLine,
  faArrowUpRightFromSquare,
  faGlobe,
  faUpload,
  faExclamation,
  faCircleExclamation,
  faChartBar,
  faCircle,
  faGear,
  faCode,
  faEmptySet,
  faFiles,
  faFilter,
  faCopy,
  faClock,
  faClone,
  faCloudArrowDown,
  faCalendar,
  faCalendarDays,
  faCalculatorAlt,
  faCalendarCheck,
  faDollarSign,
  faCircleDot,
  faCheck,
  faCircleCheck,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleChevronDown,
  faSquareChevronRight,
  faSort,
  faSortUp,
  FaUploadDuotone,
  faSortDown,
  faSortSolid,
  faStripe,
  faSync,
  faArrowDownToLine,
  faArrowLeftToLine,
  faCircleArrowDown,
  faChevronRightRegular,
  faChevronsRightRegular,
  faTableColumns,
  faThumbsUp,
  faThumbsDown,
  faCodeBranch,
  faCodeMerge,
  faCodePullRequest,
  faCreditCardBlank,
  faCreditCard,
  faDatabase,
  faFolder,
  faFolderOpen,
  faFolderRegular,
  faCircleQuestion,
  faLayerGroup,
  faSliders,
  faLock,
  faLink,
  faNetworkWired,
  faRotateExclamation,
  faLineColumns,
  faListRadio,
  faListTimeline,
  faLineHeight,
  faMessageLines,
  faMessageQuestion,
  faSquareKanban,
  faUpRightAndDownLeftFromCenter,
  faDownLeftAndUpRightToCenter,
  faEllipsis,
  faEllipsisVertical,
  faEye,
  faEyeSlash,
  faFaceMeh,
  faKey,
  faLaptop,
  faMinus,
  faMoon,
  faRightLeft,
  faGauge,
  faCrosshairs,
  faXmark,
  faXmarkRegular,
  faXmarkLight,
  faSitemap,
  faCircleXmark,
  faToolbox,
  faTrash,
  faTriangleExclamation,
  faPlus,
  faCirclePlus,
  faPlay,
  faCirclePlay,
  faCirclePause,
  faCircleStop,
  faReply,
  faFloppyDisk,
  faMagnifyingGlass,
  faMap,
  faShare,
  faArrowsRotate,
  faRotate,
  faBarsStaggered,
  faPen,
  faUser,
  faUsers,
  faCircleUser,
  faUserGear,
  faLinkSlash,
  faTable,
  faTableCells,
  faFlag,
  faFile,
  faFileLines,
  faFileExcel,
  faFileSpreadsheet,
  faFolderArrowUp,
  faGripDotsVertical,
  faFileImport,
  faCircleInfo,
  faWarehouse,
  faWindowRestore,
  faWrench,
  faArrowRotateLeft,
  faSpinner,
  faSquare,
  faStar,
  faArrowRightFromBracket,
  faRocket,
  faGift,
  faFileInvoice,
  faHourglass,
  faUserGroup,
  faNoteStickyRegular,
  faNoteSticky,
  faPaperPlaneTop,
  faPartyHorn,
  faCameraViewfinder,
  faWebhook,
  faChartNetwork,
  faLockKeyhole,
  faLockKeyholeOpen,
  faArrowsLeftRight,
  faEyeDropper,
);
