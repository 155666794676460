import { strLeftBack, strRightBack } from 'underscore.string';

import { resolveRouterLinkParams } from '@/modules/components/helpers';
import type { ParsedFQID } from '@/modules/lineage/fqid';
import { constructFQID, FQID_TYPES } from '@/modules/lineage/fqid';
import type { TableData } from '@/modules/lineage/rfTypes';
import { routeNames as storageRouteNames } from '@/modules/storage/constants';
import RoutesStore from '@/stores/RoutesStore';

export const constructBucketFqidFromTableNode = (node: TableData) => {
  return constructFQID({
    type: FQID_TYPES.bucket,
    data: { projectId: node.fqid.match(/\d+/)?.[0] ?? '', bucketId: node.bucket.id },
  });
};

const createHref = (params: ParsedFQID | Record<string, never>) => {
  switch (params.type) {
    case FQID_TYPES.config: {
      const linkParams = resolveRouterLinkParams(params.componentId, params.configId, null, true);

      return linkParams
        ? RoutesStore.getRouter().createHref(linkParams.to, linkParams.params)
        : null;
    }

    case FQID_TYPES.table: {
      return params.tableId
        ? RoutesStore.getRouter().createHref(storageRouteNames.TABLE, {
            bucketId: strLeftBack(params.tableId, '.'),
            tableName: strRightBack(params.tableId, '.'),
          })
        : null;
    }

    case FQID_TYPES.bucket: {
      return params.bucketId
        ? RoutesStore.getRouter().createHref(storageRouteNames.BUCKET, {
            bucketId: params.bucketId,
          })
        : null;
    }

    default:
      return null;
  }
};

export const prepareHref = (params: ParsedFQID | Record<string, never>) => {
  const href = createHref(params);

  if (!href) {
    return null;
  }

  return window.location.origin + href.replace(/\d+/, params.projectId);
};
