import React from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { HelpBlock } from 'design';
import _ from 'underscore';

import dayjs from '@/date';
import {
  AUTO_SLEEP_NOTE,
  EXPIRATION_OPTIONS,
  MINIMUM_EXPIRTION,
} from '@/modules/data-apps/constants';
import { prepareExpirationOption } from '@/modules/data-apps/helpers';
import InfoTooltip from '@/react/common/InfoTooltip';
import Select from '@/react/common/Select';

const HOURS_OPTIONS = _.range(0, 24).map((hour) => prepareExpirationOption(hour));
const MINUTES_OPTIONS = _.range(0, 60).map((minute) => prepareExpirationOption(minute));

const ExpirationControl = (props: {
  value: number;
  onChange: (value: number, isCustom: boolean) => void;
  showCustom: boolean;
  isDisabled: boolean;
}) => {
  const hours = Math.floor(props.value / 3600);
  const minutes = Math.floor((props.value % 3600) / 60);

  return (
    <>
      <FormGroup>
        <ControlLabel>
          Inactivity Timeout
          <InfoTooltip tooltip={AUTO_SLEEP_NOTE} />
        </ControlLabel>
        <Select
          clearable={false}
          options={EXPIRATION_OPTIONS}
          value={props.showCustom ? 0 : props.value}
          onChange={(value: number) => props.onChange(value === 0 ? 3600 : value, value === 0)}
          disabled={props.isDisabled}
        />
      </FormGroup>
      {props.showCustom && (
        <FormGroup className="tw-flex-1">
          <div className="tw-flex tw-items-center tw-justify-between tw-gap-2">
            <span className="tw-flex-1">
              <ControlLabel>Hours</ControlLabel>
              <Select
                clearable={false}
                value={hours}
                onChange={(value: number) => props.onChange(value * 3600 + minutes * 60, true)}
                options={HOURS_OPTIONS}
              />
            </span>
            <span className="tw-inline-flex tw-self-end tw-leading-10">:</span>
            <span className="tw-flex-1">
              <ControlLabel>Minutes</ControlLabel>
              <Select
                clearable={false}
                value={minutes}
                onChange={(value: number) => props.onChange(hours * 3600 + value * 60, true)}
                options={MINUTES_OPTIONS}
              />
            </span>
          </div>
          <HelpBlock variant={props.value < MINIMUM_EXPIRTION ? 'danger' : 'default'}>
            The minimum inactivity timeout is{' '}
            {dayjs.duration(MINIMUM_EXPIRTION, 'second').humanize()}.
          </HelpBlock>
        </FormGroup>
      )}
    </>
  );
};

export default ExpirationControl;
