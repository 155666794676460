import { useEffect } from 'react';

import keyCodes from '@/constants/keyCodes';

const useResetFocusedNode = (
  focusedNodeId: string | null,
  resetFocusedNode: () => void,
  openedSidebar?: boolean,
) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (focusedNodeId && !openedSidebar && event.key === keyCodes.ESCAPE) {
        event.stopPropagation();

        resetFocusedNode();

        // revert focus back the lineage modal
        if (document.body.classList.contains('full-screen-modal-open')) {
          (document.querySelector('.lineage-modal') as HTMLElement)?.focus();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown, true);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [focusedNodeId, openedSidebar, resetFocusedNode]);
};

export default useResetFocusedNode;
