import { Map } from 'immutable';

import { KEBOOLA_DATA_APPS, KEBOOLA_SANDBOXES, TRANSFORMATION } from '@/constants/componentIds';
import dayjs from '@/date';
import {
  hasGenericDockerUI,
  hasGenericTemplatesUI,
  hasGenericUI,
} from '@/modules/components/helpers';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import matchByWords from '@/utils/matchByWords';
import { FILTERS } from './constants';
import type { FilterValues } from './types';

const getDisabledRestoreReason = (componentId: string) => {
  if ([KEBOOLA_SANDBOXES, KEBOOLA_DATA_APPS].includes(componentId)) {
    return `${
      componentId === KEBOOLA_SANDBOXES ? 'Workspaces' : 'Data Apps'
    } are shortlived interactive development environments. Once deleted they cannot be restored.`;
  }

  if (isObsoleteComponent(componentId)) {
    return 'The configuration of an obsolete component cannot be restored.';
  }

  return '';
};

const hasDisabledRestore = (componentId: string) => !!getDisabledRestoreReason(componentId);

const isObsoleteComponent = (componentId: string) => {
  const component = ComponentsStore.getComponent(componentId);

  if (!component || [TRANSFORMATION, KEBOOLA_SANDBOXES].includes(componentId)) {
    return false;
  }

  return (
    component.get('uri') &&
    !hasGenericUI(component) &&
    !hasGenericDockerUI(component) &&
    !hasGenericTemplatesUI(component)
  );
};

const getFilteredConfigurations = (
  component: Map<string, any>,
  searchQuery: string,
): Map<string, any> => {
  let configurations = component.get('configurations', Map());

  if (searchQuery) {
    configurations = configurations.filter((configuration: Map<string, any>) => {
      return matchByWords(
        [configuration.get('id'), configuration.get('name'), configuration.get('description')],
        searchQuery,
      );
    });
  }

  if (!configurations.count()) {
    configurations = component.get('configurations');
  }

  return configurations.sortBy((configuration: Map<string, any>) => {
    return -dayjs(configuration.getIn(['currentVersion', 'created'])).unix();
  });
};

const getFilteredComponents = (
  filter: FilterValues,
  searchQuery: string,
  deletedComponents: Map<string, any>,
  adminEmail: string,
) => {
  let components = deletedComponents;

  if (filter === FILTERS.COMPONENTS) {
    components = components
      .map((component) =>
        component.update('configurations', Map(), (configs: Map<string, any>) =>
          configs.filter((config) => config.getIn(['creatorToken', 'description']) === adminEmail),
        ),
      )
      .filter((component) => component.get('configurations').count() > 0)
      .toMap();
  }

  if (searchQuery) {
    components = components
      .filter((component) => {
        if (matchByWords([component.get('id'), component.get('name')], searchQuery)) {
          return true;
        }

        return !!component.get('configurations', Map()).find((configuration: Map<string, any>) => {
          return matchByWords(
            [configuration.get('id'), configuration.get('name'), configuration.get('description')],
            searchQuery,
          );
        });
      })
      .toMap();
  }

  return components.sortBy((component) => component.get('name').toLowerCase());
};

export {
  getDisabledRestoreReason,
  hasDisabledRestore,
  isObsoleteComponent,
  getFilteredConfigurations,
  getFilteredComponents,
};
