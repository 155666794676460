import Promise from 'bluebird';

import { splitSqlQueries } from './splitSqlQueries';

export default (queries) => {
  const parsedQueries = splitSqlQueries(queries);

  if (parsedQueries === null || queries !== parsedQueries.join('')) {
    return Promise.reject(new Error('Query is not valid'));
  }

  const normalizedQueries = parsedQueries.map((query) => query.trim()).filter(Boolean);

  return Promise.resolve(normalizedQueries);
};
