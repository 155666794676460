import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Flow from '@keboola/flow-builder';
import classNames from 'classnames';
import { Tooltip } from 'design';
import type { Map } from 'immutable';

import { routeNames as templateRouteNames } from '@/modules/templates/constants';
import RoutesStore from '@/stores/RoutesStore';

const EmptyNode = (props: {
  phase: Map<string, any>;
  isLone: boolean;
  isLast: boolean;
  readOnly: boolean;
  newTaskPosition: null | string;
  handleShowAddTaskModal: (position: string | false) => void;
  hasTemplates: boolean;
  isDevModeActive: boolean;
}) => {
  const offerTemplates =
    !props.isDevModeActive && props.hasTemplates && props.isLone && !props.readOnly;

  return (
    <div className={classNames({ 'flex-container justify-center gap-24': offerTemplates })}>
      <Flow.Node
        key="empty-node"
        name="empty-node"
        className={classNames('with-border add-task-inline-container', {
          clickable: !props.readOnly,
        })}
        onSelect={() => {
          if (props.readOnly) {
            return;
          }

          props.handleShowAddTaskModal(props.isLast ? '[[end]]' : props.phase.get('id').toString());
        }}
      >
        <Tooltip
          tooltip="Add Task"
          placement="top"
          forceHide={props.isLone || props.readOnly}
          triggerClassName={classNames('node add-task-inline', {
            'is-read-only': props.readOnly,
            'is-lone': props.isLone,
          })}
        >
          {props.readOnly ? (
            <h4 className="user-select-none fill-space text-center tw-my-0">Empty phase</h4>
          ) : (
            <>
              <div className="add-icon f-18">
                <FontAwesomeIcon
                  fixedWidth
                  icon={
                    ['[[end]]', props.phase.get('id').toString()].includes(props.newTaskPosition)
                      ? 'xmark'
                      : 'plus'
                  }
                />
              </div>
              {props.isLone && (
                <h4 className="user-select-none tw-my-0">
                  Select first step
                  <div className="f-12 font-normal text-muted">500+ sources supported</div>
                </h4>
              )}
            </>
          )}
        </Tooltip>
      </Flow.Node>
      {offerTemplates && (
        <>
          <span className="text-muted f-12 font-medium letter-spacing-wider">OR</span>
          <Flow.Node
            key="empty-node-2"
            name="empty-node-2"
            className="with-border add-task-inline-container clickable"
            onSelect={() => {
              RoutesStore.getRouter().transitionTo(templateRouteNames.TEMPLATES, null, {
                flowId: RoutesStore.getRouterState().getIn(['params', 'config']),
              });
            }}
          >
            <div className="node add-task-inline is-lone">
              <div className="add-icon f-18">
                <FontAwesomeIcon fixedWidth icon="book-open" />
              </div>
              <h4 className="user-select-none tw-my-0">
                Use template
                <div className="f-12 font-normal text-muted">20+ templates</div>
              </h4>
            </div>
          </Flow.Node>
        </>
      )}
    </div>
  );
};

export default EmptyNode;
