import React from 'react';
import type Promise from 'bluebird';
import { Map } from 'immutable';
import { capitalize } from 'underscore.string';

import { prepareBackendLabel } from '@/modules/storage/helpers';
import CredentialsBox from '@/react/common/CredentialsBox';
import FileSize from '@/react/common/FileSize';
import InfoTooltip from '@/react/common/InfoTooltip';
import RowsCount from '@/react/common/RowsCount';
import UpdateStorageButton from './UpdateStorageButton';

const StorageBackend = (props: {
  user: Map<string, any>;
  project: Map<string, any>;
  handleUpdateStorage: (data: Map<string, any>) => Promise<any>;
}) => {
  const sizeBytes = props.project.getIn(['metrics', 'storage.dataSizeBytes', 'value'], 0);
  const rowsCount = props.project.getIn(['metrics', 'storage.rowsCount', 'value'], 0);

  const renderBackend = (name: string) => {
    const backend = props.project.getIn(['backends', name]);

    return (
      <>
        {prepareBackendLabel(name)}
        {backend && (
          <InfoTooltip
            key={name}
            tooltip={
              <div className="tw-text-xs">
                <p className="tooltip-title">{backend.get('host')}</p>
                <div className="tw-flex tw-justify-between">
                  <span>Id:</span>
                  <span>{backend.get('id')}</span>
                </div>
                <div className="tw-flex tw-justify-between">
                  <span>Region:</span>
                  <span>{backend.get('region')}</span>
                </div>
                <div className="tw-flex tw-justify-between">
                  <span>Owner:</span>
                  <span>{capitalize(backend.get('owner'))}</span>
                </div>
              </div>
            }
          />
        )}
      </>
    );
  };

  const rows: Map<string, any> = Map({
    Usage: {
      noCopy: true,
      component: (
        <>
          <FileSize size={sizeBytes} /> (<RowsCount count={rowsCount} /> Rows)
        </>
      ),
    },
    'Assigned Backends': {
      noCopy: true,
      component: props.project
        .get('backends', Map())
        .keySeq()
        .filter((name: string) => props.project.get(`has${capitalize(name)}`, false))
        .map(renderBackend)
        .reduce((prev: React.ReactNode, curr: React.ReactNode) => [prev, ', ', curr]),
    },
    'Default Backend': {
      noCopy: true,
      component: renderBackend(props.project.get('defaultBackend')),
    },
    ...(props.project.get('hasSnowflake', false) && {
      'Data Retention': {
        noCopy: true,
        text: `${props.project.get('dataRetentionTimeInDays')} days`,
      },
    }),
  });

  return (
    <div className="well pp-6">
      <h3 className="tw-mb-6 tw-mt-0">
        Storage
        {props.user.get('isSuperAdmin', false) && (
          <UpdateStorageButton project={props.project} onSubmit={props.handleUpdateStorage} />
        )}
      </h3>
      <CredentialsBox rows={rows} noBorder />
    </div>
  );
};

export default StorageBackend;
