import React, { useState } from 'react';

import SwitchStateLink from '@/react/common/ActionControls/SwitchStateLink';
import ConfirmModal from '@/react/common/ConfirmModal';

type Props = {
  isReadOnly: boolean;
  isEnabled: boolean;
  onUpdateConfiguration: (params: string[], description: string) => Promise<void>;
};

const FileStorageOnlySwitch = ({ isReadOnly, isEnabled, onUpdateConfiguration }: Props) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const toggleFileStorageOnly = () => {
    setIsSaving(true);
    return onUpdateConfiguration(
      ['runtime', 'use_file_storage_only'],
      `"${isEnabled ? 'Disable' : 'Enable'}" file storage only mode.`,
    ).finally(() => setIsSaving(false));
  };

  return (
    <li>
      <SwitchStateLink
        label="File storage only"
        onChange={() => (isEnabled ? toggleFileStorageOnly() : setShowConfirmModal(true))}
        isActive={isEnabled}
        isPending={isSaving}
        readOnly={isReadOnly}
      />
      <ConfirmModal
        buttonType="success"
        buttonLabel="Confirm"
        icon="file-lines"
        title="Set File Storage Only Mode"
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        text={<p>Are you sure you want to turn on File Storage Mode Only?</p>}
        onConfirm={toggleFileStorageOnly}
      />
    </li>
  );
};

export default FileStorageOnlySwitch;
