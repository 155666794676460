import React from 'react';
import { Button, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { STACKS } from '@keboola/constants';
import createReactClass from 'create-react-class';
import { HelpBlock, ProgressBar } from 'design';

import { canDeleteDevBranch } from '@/modules/admin/privileges';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import Checkbox from '@/react/common/Checkbox';
import Confirm from '@/react/common/Confirm';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import string from '@/utils/string';
import { deleteBranch, initializeSelectedChanges, loadAll, merge } from './actions';
import { WAIT_AFTER_MERGE } from './constants';
import DevBranchesStore from './DevBranchesStore';
import {
  filterSelectedUpdatedComponents,
  findUpdatedConfigurations,
  findUpdatedMetadata,
} from './helpers';

const SimpleMergeHeaderButton = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, DevBranchesStore, InstalledComponentsStore)],

  getStateFromStores() {
    const sapiToken = ApplicationStore.getSapiToken();
    const updatedMetadata = findUpdatedMetadata(
      InstalledComponentsStore.getAllMetadata(),
      DevBranchesStore.getProductionComponentsMetadata(),
    );
    const productionComponents = DevBranchesStore.getProductionComponents();
    const allUpdatedComponents = findUpdatedConfigurations(
      InstalledComponentsStore.getAll(),
      productionComponents,
      InstalledComponentsStore.getAllDeleted(),
      DevBranchesStore.getProductionDeletedComponents(),
      updatedMetadata,
    );
    const selectedUpdatedComponents = filterSelectedUpdatedComponents(
      allUpdatedComponents,
      DevBranchesStore.getSelectedConfigurationChanges(),
    );
    const activeBranchName = DevBranchesStore.getCurrentDevBranch().get('name');

    return {
      sapiToken,
      activeBranchName,
      updatedMetadata,
      allUpdatedComponents,
      productionComponents,
      selectedUpdatedComponents,
      canDeleteDevBranch: canDeleteDevBranch(sapiToken),
      stackId: ApplicationStore.getCurrentStackId(),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      isLoadingData: DevBranchesStore.getIsLoadingData(),
      readOnly: ApplicationStore.isReadOnly(),
      projectBaseUrl: ApplicationStore.getProjectBaseUrl(),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      activeBranchId: DevBranchesStore.getCurrentId(),
      deleteAfterMerge: !canDeleteDevBranch(sapiToken)
        ? false
        : this.state?.isProcessing
          ? this.state.deleteAfterMerge
          : allUpdatedComponents.flatMap((component) => component.get('configurations')).count() ===
            selectedUpdatedComponents
              .flatMap((component) => component.get('configurations'))
              .count(),
      changeDescription: `Merge '${activeBranchName}' version`,
    };
  },

  getInitialState() {
    return {
      isProcessing: false,
      isDone: false,
      processed: 0,
    };
  },

  render() {
    if (!this.state.isDevModeActive || this.state.readOnly) {
      return null;
    }

    return (
      <Confirm
        closeAfterResolve
        buttonType="success"
        icon="code-merge"
        title="Merge to production"
        text={this.renderBody()}
        buttonLabel={this.renderButtonLabel()}
        isLoading={this.state.isProcessing}
        isDisabled={this.state.isProcessing}
        isDisabledConfirmButton={!this.state.changeDescription.trim?.()}
        onConfirm={this.handleMerge}
      >
        <Button
          bsStyle="success"
          disabled={
            this.state.isLoadingData ||
            this.state.selectedUpdatedComponents.isEmpty() ||
            this.state.isProcessing ||
            this.state.isDone
          }
        >
          <FontAwesomeIcon icon="circle-check" className="icon-addon-right" />
          Merge to production
        </Button>
      </Confirm>
    );
  },

  renderBody() {
    if (this.state.isDone) {
      return (
        <div className="p-2 text-center">
          <FontAwesomeIcon icon="circle-check" className="text-success" size="3x" />
          <h2>Merge Successful</h2>
          <p className="mb-2">
            {this.state.deleteAfterMerge
              ? 'Development branch will be now deleted and you will be redirected back to production.'
              : 'Development branch was successfully merged into the production.'}
          </p>
        </div>
      );
    }

    if (this.state.isProcessing) {
      const totalConfigurations = this.state.selectedUpdatedComponents.reduce(
        (sum, component) => sum + component.get('configurations').count(),
        0,
      );

      return (
        <>
          {this.state.processed} of {totalConfigurations}{' '}
          {string.pluralize(totalConfigurations, 'configuration')} has been merged
          <ProgressBar
            type="success"
            progress={Math.ceil((this.state.processed / totalConfigurations) * 100)}
          />
        </>
      );
    }

    return (
      <>
        <p>Merge message</p>
        <FormControl
          rows={4}
          componentClass="textarea"
          value={this.state.changeDescription}
          onChange={({ target }) => this.setState({ changeDescription: target.value })}
        />
        <HelpBlock className="mb-1">
          This message will be used as a description of a change for every merged configuration.
        </HelpBlock>
        {this.state.canDeleteDevBranch && (
          <Checkbox
            checked={this.state.deleteAfterMerge}
            onChange={(checked) => this.setState({ deleteAfterMerge: checked })}
          >
            Delete current development branch after merge.
          </Checkbox>
        )}
      </>
    );
  },

  handleMerge() {
    this.setState({ isProcessing: true, isDone: false, progress: 0 });
    return merge(
      this.state.activeBranchName,
      this.state.selectedUpdatedComponents,
      this.state.productionComponents,
      this.state.updatedMetadata,
      this.onProgress,
      this.state.deleteAfterMerge,
      this.prepareChangeDescription(),
    )
      .then(() => {
        this.setState({ isDone: true });

        if (this.state.deleteAfterMerge) {
          return deleteBranch(this.state.activeBranchId)
            .delay(WAIT_AFTER_MERGE)
            .then(() => window.location.replace(this.state.projectBaseUrl));
        }

        return loadAll()
          .delay(WAIT_AFTER_MERGE)
          .then(() => initializeSelectedChanges(this.state.allUpdatedComponents));
      })
      .finally(() => this.setState(this.getInitialState()));
  },

  onProgress() {
    this.setState((prevState) => ({ processed: prevState.processed + 1 }));
  },

  prepareChangeDescription() {
    if (this.state.stackId === STACKS.GROUPON.id) {
      return `[${this.state.activeBranchId}-${this.state.currentAdmin.get('id')}] ${
        this.state.changeDescription
      }`;
    }

    return this.state.changeDescription;
  },

  renderButtonLabel() {
    if (this.state.isDone) {
      if (this.state.deleteAfterMerge) {
        return 'Redirecting...';
      }

      return 'Refreshing...';
    }

    if (this.state.isProcessing) {
      return 'Merging...';
    }

    return 'Merge';
  },
});

export default SimpleMergeHeaderButton;
