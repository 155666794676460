import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import type { List } from 'immutable';
import { Map } from 'immutable';

import dayjs from '@/date';
import { BOX_ROWS_LIMITS } from '@/modules/home/constants';
import {
  getActiveUsers,
  getCreatedTime,
  moveUserToFirstPlaceByModifyingActivity,
  splitIntoChunks,
} from '@/modules/home/helpers';
import { routeNames as settingsRouteNames } from '@/modules/settings/constants';
import Gravatar from '@/react/common/Gravatar';
import PaginatedBox from '@/react/common/PaginatedBox/PaginatedBox';
import Link from '@/react/common/RouterLink';
import Truncated from '@/react/common/Truncated';

const ACTIVE_LIMIT_DAYS = 7;
const ONLINE_LIMIT_MINUTES = 30;

const getIsActive = (createdTime: string) => {
  return dayjs(createdTime).isAfter(dayjs().subtract(ACTIVE_LIMIT_DAYS, 'd'));
};

const getIsOnline = (createdTime: string) => {
  return dayjs(createdTime).isAfter(dayjs().subtract(ONLINE_LIMIT_MINUTES, 'm'));
};

const NameAndStatus = ({ name, latestActivity }: { name: string; latestActivity: string }) => {
  const createdTime = getCreatedTime(latestActivity);
  const isActive = getIsActive(createdTime);
  const isOnline = getIsOnline(createdTime);

  if (isOnline) {
    return (
      <>
        <Truncated text={name} />
        <div className="text-success">Online</div>
      </>
    );
  }

  return (
    <>
      <Truncated className={classnames({ 'text-muted': !isActive })} text={name} />
      <div className="text-muted">
        {isActive
          ? `Active ${dayjs(createdTime).fromNow()}`
          : `Offline for ${dayjs(createdTime).fromNow(true)}`}
      </div>
    </>
  );
};

type ActiveUsersProps = {
  readOnly: boolean;
  admins: Map<string, any>;
  currentAdmin: Map<string, any>;
  activities: List<any>;
  adminsInvitedToProject: Map<string, any>;
};

const ActiveUsers: React.FC<ActiveUsersProps> = ({
  readOnly,
  currentAdmin,
  activities,
  admins,
  adminsInvitedToProject,
}) => {
  const renderUser = (activities: List<any>, email: string) => {
    if (adminsInvitedToProject.has(email)) {
      return (
        <div key={email} className="flex-container latest-configuration-edit-item">
          <span className="icon-addon-right">
            {' '}
            <Gravatar user={adminsInvitedToProject.get(email)} fallback={email} />
          </span>{' '}
          <div className="component-meta text-left">
            <Truncated
              className="text-muted"
              text={adminsInvitedToProject.getIn([email, 'name'])?.trim() || email}
            />
            <div className="text-muted">Invited</div>
          </div>
        </div>
      );
    }

    const name = admins.getIn([email, 'name'])?.trim() || email;
    const latestActivity = activities.first();

    if (!latestActivity) {
      return (
        <div key={email} className="flex-container latest-configuration-edit-item">
          <span className="icon-addon-right">
            <Gravatar user={admins.get(email)} fallback={email} />
          </span>
          <div className="component-meta text-left">
            <Truncated className="text-muted" text={name} />
            <div className="text-muted">Offline</div>
          </div>
        </div>
      );
    }

    return (
      <div key={email} className="flex-container latest-configuration-edit-item">
        <span className="icon-addon-right">
          <Gravatar user={admins.get(email)} fallback={email} />{' '}
        </span>
        <div className="component-meta text-left">
          <NameAndStatus name={name} latestActivity={latestActivity} />
        </div>
      </div>
    );
  };

  if (admins.count() === 1 && adminsInvitedToProject.count() === 0) {
    return (
      <div className="box box-panel">
        <div className="box-header">
          <h2 className="box-title">Project Users</h2>
        </div>
        <div className="box-panel-content text-center p-2 mt-2">
          <h3 className="mt-2">Invite Users</h3>
          <p className="text-muted mb-1">
            Looks like you are the only one in this project. Invite more team members to help you
            with your data puzzles.
          </p>
          {!readOnly && (
            <Link to={settingsRouteNames.USERS} className="btn btn-success font-semibold">
              <FontAwesomeIcon icon="plus" className="icon-addon-right f-14" /> Invite users
            </Link>
          )}
        </div>
      </div>
    );
  }

  let activeUsers = getActiveUsers(admins, activities).slice(0, BOX_ROWS_LIMITS);

  admins.forEach((detail, admin) => {
    if (!activeUsers.has(admin)) {
      activeUsers = activeUsers.set(admin, Map());
    }
  });

  const updatedActiveUsers = moveUserToFirstPlaceByModifyingActivity(
    activeUsers,
    currentAdmin.get('email'),
    // invited users have no activity
  ).merge(adminsInvitedToProject.map(() => Map()));

  return (
    <div className="box box-panel">
      <div className="box-header">
        <h2 className="box-title">Project Users</h2>
        {!readOnly && (
          <Link to={settingsRouteNames.USERS} className="btn btn-primary font-semibold">
            Manage users
          </Link>
        )}
      </div>
      <PaginatedBox>
        {splitIntoChunks(updatedActiveUsers)
          .map((chunk, index) => (
            <PaginatedBox.Item key={index}>{chunk.map(renderUser).toArray()}</PaginatedBox.Item>
          ))
          .toArray()}
      </PaginatedBox>
    </div>
  );
};

export default ActiveUsers;
