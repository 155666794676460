import type { Runtime } from '@/api/routes/dataScienceService';
import Dispatcher from '@/Dispatcher';
import StoreUtils, { initStore } from '@/utils/StoreUtils';
import { ActionTypes } from './constants';

type Runtimes = Record<string, Runtime[]>;

export type Store = {
  runtimes: Runtimes;
};

let _store = initStore<Store>('RuntimesStore', { runtimes: {} });

const RuntimesStore = StoreUtils.createStore({
  getRuntimes(componentId: string): Runtime[] {
    return _store.runtimes[componentId] ?? [];
  },
});

Dispatcher.register(
  ({
    action,
  }: {
    action: {
      type: string;
      runtimes: Runtime[];
    };
  }) => {
    switch (action.type) {
      case ActionTypes.LOAD_RUNTIMES_SUCCESS:
        _store = {
          ..._store,
          runtimes: (action.runtimes ?? []).reduce<Record<string, Runtime[]>>((acc, value) => {
            if (!value.transformationComponentId) {
              return acc;
            }
            // If the id doesn't exist in the accumulator, initialize it with an empty array
            if (!acc[value.transformationComponentId]) {
              acc[value.transformationComponentId] = [];
            }

            // Push the rest of the object into the array for this id
            acc[value.transformationComponentId].push(value);

            return acc;
          }, {}),
        };

        return RuntimesStore.emitChange();

      default:
        break;
    }
  },
);

export default RuntimesStore;
