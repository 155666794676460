import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import createReactClass from 'create-react-class';
import { Clipboard, Tooltip } from 'design';
import { List, Map } from 'immutable';

import { MetadataKeys, ObjectTypes } from '@/modules/components/MetadataConstants';
import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import { createTablePrimaryKey, removeTablePrimaryKey } from '@/modules/storage/actions';
import { routeNames } from '@/modules/storage/constants';
import { tableName } from '@/modules/storage/helpers';
import ConfirmModal from '@/react/common/ConfirmModal';
import CreatedDate from '@/react/common/CreatedDate';
import FileSize from '@/react/common/FileSize';
import Loader from '@/react/common/Loader';
import Link from '@/react/common/RouterLink';
import RowsCount from '@/react/common/RowsCount';
import TableUpdatedBy from '@/react/common/TableUpdatedBy';
import CreatePrimaryKeyModal from './CreatePrimaryKeyModal';
import DevBranchStorageWarning from './DevBranchStorageWarning';
import ExternalProjectTableLink from './ExternalProjectTableLink';
import LatestImports from './LatestImports';
import NotAvailable from './NotAvailable';
import ProjectAliasLink from './ProjectAliasLink';
import StorageDescription from './StorageDescription';
import AliasFilter from './TableAliasFilter';

const TableOverview = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    canManageBuckets: PropTypes.bool.isRequired,
    hasFlows: PropTypes.bool.isRequired,
    table: PropTypes.instanceOf(Map).isRequired,
    tables: PropTypes.instanceOf(Map).isRequired,
    components: PropTypes.instanceOf(Map).isRequired,
    configurations: PropTypes.instanceOf(Map).isRequired,
    tableAliases: PropTypes.array.isRequired,
    tableLinks: PropTypes.array.isRequired,
    sapiToken: PropTypes.instanceOf(Map).isRequired,
    urlTemplates: PropTypes.instanceOf(Map).isRequired,
    creatingPrimaryKey: PropTypes.bool.isRequired,
    deletingPrimaryKey: PropTypes.bool.isRequired,
    settingAliasFilter: PropTypes.bool.isRequired,
    removingAliasFilter: PropTypes.bool.isRequired,
    canWriteTable: PropTypes.bool.isRequired,
  },

  getInitialState() {
    return {
      showCreatePrimaryKeyModal: false,
      showRemovePrimaryKeyModal: false,
    };
  },

  render() {
    const table = this.props.table;
    const isExternal = table.getIn(['bucket', 'hasExternalSchema']);

    return (
      <>
        <div className="box info-row">
          <div
            className={classnames('info-row-section horizontal', {
              'more-space': !table.get('isAlias', false),
            })}
          >
            <div className="first-line font-medium flex-container">
              <span>ID</span>
              <Clipboard
                tooltipPlacement="top"
                label={table.get('id')}
                text={table.get('id')}
                showIcon={false}
              />
            </div>
            <div className="first-line font-medium flex-container">
              <span>Name</span>
              <Clipboard
                tooltipPlacement="top"
                className="font-normal"
                label={table.get('displayName')}
                text={table.get('displayName')}
                showIcon={false}
              />
            </div>
            <div className="first-line font-medium flex-container">
              <span>
                Primary key
                {this.renderPrimaryKeyAction()}
              </span>
              <span className="text-muted font-normal">
                {table.get('primaryKey').isEmpty() ? 'Not set' : table.get('primaryKey').join(', ')}
              </span>
            </div>
            {!isExternal && (
              <div className="first-line font-medium flex-container">
                <span>Recently updated by</span>
                <span className="font-normal">
                  <TableUpdatedBy
                    table={table}
                    components={this.props.components}
                    configurations={this.props.configurations}
                    hasFlows={this.props.hasFlows}
                  />
                </span>
              </div>
            )}
            {table.get('isAlias') &&
              (!table.get('selectSql') ? (
                <div className="first-line font-medium flex-container">
                  <AliasFilter
                    table={table}
                    canEdit={this.props.canWriteTable}
                    settingAliasFilter={this.props.settingAliasFilter}
                    removingAliasFilter={this.props.removingAliasFilter}
                  />
                </div>
              ) : (
                <div className="first-line font-medium flex-container">
                  <span>Alias SQL</span>
                  <code>{table.get('selectSql')}</code>
                </div>
              ))}
          </div>
          <div className="info-row-section horizontal">
            <p className="first-line f-14 font-medium flex-container">
              <span>Stage</span>
              <span className="font-normal text-muted">
                {table.getIn(['bucket', 'stage']).toUpperCase()}
              </span>
            </p>
            <p className="first-line f-14 font-medium flex-container">
              <span>Created</span>
              <CreatedDate className="font-normal text-muted" createdTime={table.get('created')} />
            </p>
            {table.get('sourceTable') && (
              <p className="first-line f-14 font-medium flex-container">
                <span>Source table</span>
                {this.renderSourceTable()}
              </p>
            )}
            {!isExternal && (
              <>
                <p className="first-line f-14 font-medium flex-container">
                  <span>Last import</span>
                  <CreatedDate
                    className="font-normal text-muted"
                    createdTime={table.get('lastImportDate')}
                    fallback="Not yet imported"
                  />
                </p>
                <p className="first-line f-14 font-medium flex-container">
                  <span>Last change</span>
                  <CreatedDate
                    className="font-normal text-muted"
                    createdTime={table.get('lastChangeDate')}
                  />
                </p>
              </>
            )}
            <p className="first-line f-14 font-medium flex-container">
              <span>Row count</span>
              <span className="font-normal text-muted">
                {isExternal ? (
                  <NotAvailable entity="external tables" />
                ) : (
                  <RowsCount count={table.get('rowsCount')} />
                )}
              </span>
            </p>
            <p className="first-line f-14 font-medium flex-container">
              <span>Data size</span>
              <span className="font-normal text-muted">
                {isExternal ? (
                  <NotAvailable entity="external tables" />
                ) : (
                  <FileSize size={table.get('dataSizeBytes')} />
                )}
              </span>
            </p>
            {(this.props.tableAliases.length > 0 || this.props.tableLinks.length > 0) && (
              <div className="first-line f-14 font-medium flex-container mt-1 align-top">
                <span>Alias tables</span>
                {this.renderTableAliases()}
              </div>
            )}
          </div>
          {!table.get('isAlias', false) && !isExternal && (
            <div className="info-row-section horizontal tw-flex !tw-flex-grow-0 !tw-basis-1/3 tw-flex-col tw-justify-between">
              <LatestImports table={table} key={table.get('lastImportDate') || 'table-imports'} />
            </div>
          )}
        </div>
        {this.renderDescription()}
        {this.renderCreatePrimaryKeyModal()}
        {this.renderRemovePrimaryKeyModal()}
      </>
    );
  },

  renderDescription() {
    return (
      <StorageDescription
        objectType={ObjectTypes.TABLE}
        objectId={this.props.table.get('id')}
        metadata={this.props.table.get('metadata', List())}
        metadataKey={MetadataKeys.DESCRIPTION}
        readOnly={!this.props.canManageBuckets}
        isDevBucket={isCreatedInDevBranch(this.props.table.get('bucket'))}
      />
    );
  },

  renderPrimaryKeyAction() {
    if (!this.props.canWriteTable || this.props.table.get('isAlias', false)) {
      return null;
    }

    if (!this.props.table.get('primaryKey').isEmpty()) {
      return (
        <Tooltip tooltip="Delete primary key" placement="top">
          <Button
            bsStyle="link"
            className="icon-addon-left btn-link-inline text-muted"
            onClick={this.openRemovePrimaryKeyModal}
            disabled={this.props.deletingPrimaryKey}
          >
            {this.props.deletingPrimaryKey ? (
              <Loader />
            ) : (
              <FontAwesomeIcon icon="trash" fixedWidth />
            )}
          </Button>
        </Tooltip>
      );
    }

    return (
      <Tooltip tooltip="Create primary key" placement="top">
        <Button
          bsStyle="link"
          className="icon-addon-left btn-link-inline text-muted"
          onClick={this.openCreatePrimaryKeyModal}
          disabled={this.props.creatingPrimaryKey}
        >
          {this.props.creatingPrimaryKey ? <Loader /> : <FontAwesomeIcon icon="pen" fixedWidth />}
        </Button>
      </Tooltip>
    );
  },

  renderSourceTable() {
    const { sapiToken, table } = this.props;

    if (sapiToken.getIn(['owner', 'id']) !== table.getIn(['sourceTable', 'project', 'id'])) {
      return (
        <ExternalProjectTableLink
          table={table.get('sourceTable')}
          urlTemplates={this.props.urlTemplates}
        />
      );
    }

    const sourceTable = this.props.tables.get(table.getIn(['sourceTable', 'id']));

    if (!sourceTable) {
      return 'N/A';
    }

    return (
      <Link
        to={routeNames.TABLE}
        params={{
          bucketId: sourceTable.getIn(['bucket', 'id']),
          tableName: sourceTable.get('name'),
        }}
      >
        {tableName(sourceTable)}
      </Link>
    );
  },

  renderTableAliases() {
    return (
      <div className="text-right">
        {this.props.tableAliases.map((alias) => (
          <div key={alias.get('id')}>
            <Link
              to={routeNames.TABLE}
              params={{ bucketId: alias.getIn(['bucket', 'id']), tableName: alias.get('name') }}
            >
              {tableName(alias)}
            </Link>
          </div>
        ))}

        {this.props.tableLinks.map((alias) => (
          <div key={alias.get('id')}>
            <ProjectAliasLink
              sapiToken={this.props.sapiToken}
              urlTemplates={this.props.urlTemplates}
              alias={alias}
            />
          </div>
        ))}
      </div>
    );
  },

  renderCreatePrimaryKeyModal() {
    return (
      <CreatePrimaryKeyModal
        show={this.state.showCreatePrimaryKeyModal}
        table={this.props.table}
        onSubmit={this.handleCreatePrimaryKey}
        onHide={this.closeCreatePrimaryKeyModal}
      />
    );
  },

  renderRemovePrimaryKeyModal() {
    return (
      <ConfirmModal
        show={this.state.showRemovePrimaryKeyModal}
        icon="trash"
        title="Delete Primary Key"
        buttonType="danger"
        buttonLabel="Remove"
        text={
          <>
            <p>Are you sure you want to delete the table primary key?</p>
            <DevBranchStorageWarning
              message="The primary key will also be deleted from the table in production."
              hasProductionEntity={!isCreatedInDevBranch(this.props.table.get('bucket'))}
            />
          </>
        }
        onConfirm={this.handleRemovePrimaryKey}
        onHide={this.closeRemovePrimaryKeyModal}
      />
    );
  },

  handleCreatePrimaryKey(primaryKeys) {
    const tableId = this.props.table.get('id');
    const params = {
      columns: primaryKeys,
    };

    return createTablePrimaryKey(tableId, params);
  },

  handleRemovePrimaryKey() {
    const tableId = this.props.table.get('id');

    return removeTablePrimaryKey(tableId);
  },

  openCreatePrimaryKeyModal() {
    this.setState({
      showCreatePrimaryKeyModal: true,
    });
  },

  closeCreatePrimaryKeyModal() {
    this.setState({
      showCreatePrimaryKeyModal: false,
    });
  },

  openRemovePrimaryKeyModal() {
    this.setState({
      showRemovePrimaryKeyModal: true,
    });
  },

  closeRemovePrimaryKeyModal() {
    this.setState({
      showRemovePrimaryKeyModal: false,
    });
  },
});

export default TableOverview;
