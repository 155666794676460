import React from 'react';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { canResetState } from '@/modules/admin/privileges';
import ComponentDescription from '@/modules/components/react/components/ComponentDescription';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import TablesStore from '@/modules/components/stores/StorageTablesStore';
import Actions from '@/modules/configurations/ConfigurationRowsActionCreators';
import Store from '@/modules/configurations/ConfigurationRowsStore';
import dockerActions from '@/modules/configurations/DockerActionsActionCreators';
import DockerActionsStore from '@/modules/configurations/DockerActionsStore';
import ClearStateButton from '@/modules/configurations/react/components/ClearStateButton';
import DeleteConfigurationRowButton from '@/modules/configurations/react/components/DeleteConfigurationRowButton';
import JsonConfiguration from '@/modules/configurations/react/components/JsonConfiguration';
import { isEmptyComponentState } from '@/modules/configurations/utils/configurationState';
import isParsableConfiguration from '@/modules/configurations/utils/isParsableConfiguration';
import sections from '@/modules/configurations/utils/sections';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import ActivateDeactivateButton from '@/react/common/ActivateDeactivateButton';
import CatchUnsavedRunWarning from '@/react/common/CatchUnsavedRunWarning';
import ConfigurationInfoPanel from '@/react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import Sidebar from '@/react/layout/Sidebar/Sidebar';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import fromJSOrdered from '@/utils/fromJSOrdered';

const Row = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      StackFeaturesStore,
      ComponentsStore,
      InstalledComponentsStore,
      Store,
      TablesStore,
      DockerActionsStore,
    ),
  ],

  getStateFromStores() {
    const settings = RoutesStore.getRouteSettings();
    const sapiToken = ApplicationStore.getSapiToken();
    const configurationId = RoutesStore.getCurrentRouteParam('config');
    const rowId = RoutesStore.getCurrentRouteParam('row');
    const componentId = settings.get('componentId');
    const row = Store.get(componentId, configurationId, rowId);
    const rowConfiguration = Store.getConfiguration(componentId, configurationId, rowId);
    const createBySectionsFn = sections.makeCreateFn(settings.getIn(['row', 'sections'], List()));
    const conformFn = settings.getIn(['row', 'onConform'], (config) => config);
    const saveFn = sections.makeCreateFn(
      settings.getIn(['row', 'sections'], List()),
      settings.getIn(['row', 'onSave']),
    );
    let context = InstalledComponentsStore.getConfigurationContext(componentId, configurationId);
    const parseTableIdFn = settings.getIn(['row', 'parseTableId']);
    if (parseTableIdFn) {
      const tableId = parseTableIdFn(rowConfiguration);
      const table = TablesStore.getTable(tableId);
      context = context.set('table', table).set('tableId', tableId);
    }
    const parseBySectionsFn = sections.makeParseFn(
      settings.getIn(['row', 'sections'], List()),
      conformFn,
      context,
    );
    const storedConfigurationSections = parseBySectionsFn(rowConfiguration);
    const pendingActions = Store.getPendingActions(componentId, configurationId, rowId);
    const configurationBySections = Store.getEditingConfiguration(
      componentId,
      configurationId,
      rowId,
      parseBySectionsFn,
    );
    const component = ComponentsStore.getComponent(componentId);

    return {
      context,
      componentId,
      settings,
      configurationId,
      component,
      configuration: InstalledComponentsStore.getConfig(componentId, configurationId),
      rowId: rowId,
      row: row,
      jsonConfigurationValue: Store.getEditingJsonConfigurationString(
        componentId,
        configurationId,
        rowId,
      ),
      isJsonConfigurationSaving: pendingActions.has('save-json'),
      isJsonConfigurationChanged: Store.isEditingJsonConfiguration(
        componentId,
        configurationId,
        rowId,
      ),
      isParsableConfiguration: isParsableConfiguration(
        conformFn(rowConfiguration),
        parseBySectionsFn,
        createBySectionsFn,
      ),
      isJsonEditorOpen: Store.hasJsonEditor(
        componentId,
        configurationId,
        rowId,
        parseBySectionsFn,
        createBySectionsFn,
        conformFn,
      ),
      createBySectionsFn,
      parseBySectionsFn,
      storedConfigurationSections,
      configurationBySections,
      rowConfiguration: saveFn(configurationBySections),
      isSaving: pendingActions.has('save-configuration'),
      isChanged: Store.isEditingConfiguration(componentId, configurationId, rowId),
      isDeletePending: pendingActions.has('delete'),
      isEnableDisablePending: pendingActions.has('enable') || pendingActions.has('disable'),
      stackFeatures: StackFeaturesStore.getAll(),
      readOnly: ApplicationStore.isReadOnly(),
      canResetState: canResetState(sapiToken, component, settings),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
    };
  },

  componentDidMount() {
    dockerActions.reloadIndexSyncActions(this.state.componentId, this.state.configurationId);
    dockerActions.reloadRowSyncActions(
      this.state.componentId,
      this.state.configurationId,
      this.state.rowId,
    );
  },

  renderAdditionalButtons() {
    let actions = [];

    actions.push(
      <ActivateDeactivateButton
        key="activate"
        isActive={!this.state.row.get('isDisabled', false)}
        isPending={this.state.isEnableDisablePending}
        onChange={() => {
          return Actions[this.state.row.get('isDisabled', false) ? 'enable' : 'disable'](
            this.state.componentId,
            this.state.configurationId,
            this.state.rowId,
          );
        }}
        readOnly={this.state.readOnly}
      />,
    );

    if (this.state.canResetState) {
      actions.push(
        <ClearStateButton
          key="clear-state"
          onClick={() =>
            Actions.clearComponentState(
              this.state.componentId,
              this.state.configurationId,
              this.state.rowId,
            )
          }
          disabled={isEmptyComponentState(this.state.row.get('state', Map()))}
        />,
      );
    }

    return actions;
  },

  render() {
    return (
      <>
        <ConfigurationTabs
          componentId={this.state.componentId}
          configId={this.state.configurationId}
          rowId={this.state.rowId}
        />
        <ConfigurationInfoPanel
          component={this.state.component}
          config={this.state.configuration}
        />

        <div className="row box-separator">
          <div className="col-sm-9">
            <ComponentDescription
              componentId={this.state.componentId}
              configId={this.state.configurationId}
              rowId={this.state.rowId}
            />
            {this.renderConfiguration()}
          </div>
          <div className="col-sm-3">
            <Sidebar
              componentId={this.state.componentId}
              configId={this.state.configurationId}
              rowId={this.state.rowId}
              run={{
                forceModal:
                  this.state.row.get('isDisabled') ||
                  this.state.isJsonConfigurationChanged ||
                  this.state.isChanged,
                text: this.renderRunModalContent(),
              }}
              delete={
                <DeleteConfigurationRowButton
                  onClick={() => {
                    return Actions.delete(
                      this.state.componentId,
                      this.state.configurationId,
                      this.state.rowId,
                      true,
                    );
                  }}
                  isPending={this.state.isDeletePending}
                  mode="link"
                />
              }
              additionalButtons={this.renderAdditionalButtons()}
            />
          </div>
        </div>
      </>
    );
  },

  renderConfiguration() {
    if (this.state.isJsonEditorOpen || !this.state.isParsableConfiguration) {
      return this.renderJsonEditor();
    }

    return <div className="form-horizontal box-separator">{this.renderForm()}</div>;
  },

  onUpdateSection(sectionKey, diff) {
    const { configurationBySections, componentId, configurationId, rowId } = this.state;
    const newConfigurationBySections = configurationBySections.set(
      sectionKey,
      configurationBySections.get(sectionKey).merge(fromJSOrdered(diff)),
    );
    const created = this.state.createBySectionsFn(newConfigurationBySections);
    const parsed = this.state.parseBySectionsFn(created);
    Actions.updateConfiguration(componentId, configurationId, rowId, parsed);
  },

  renderForm() {
    const { storedConfigurationSections } = this.state;
    const returnTrue = () => true;

    let actionsData = Map();
    this.state.settings.getIn(['index', 'actions'], List()).forEach((action) => {
      actionsData = actionsData.set(
        action.get('name'),
        DockerActionsStore.get(
          this.state.settings.get('componentId'),
          action,
          this.state.configuration.get('configuration'),
        ),
      );
    });
    this.state.settings.getIn(['row', 'actions'], List()).forEach((action) => {
      actionsData = actionsData.set(
        action.get('name'),
        DockerActionsStore.get(
          this.state.settings.get('componentId'),
          action,
          this.state.configuration.get('configuration'),
          this.state.rowConfiguration,
        ),
      );
    });

    return this.state.settings.getIn(['row', 'sections'], List()).map((section, key) => {
      const SectionComponent = section.get('render');
      const onSectionSave = section.get('onSave');
      const sectionIsCompleteFn = section.get('isComplete') || returnTrue;
      const isComplete = sectionIsCompleteFn(onSectionSave(storedConfigurationSections.get(key)));

      return (
        <SectionComponent
          key={key}
          context={this.state.context}
          stackFeatures={this.state.stackFeatures}
          isComplete={isComplete}
          actions={actionsData}
          state={this.state.row.get('state', Map())}
          onResetState={this.clearState}
          onChange={(diff) => this.onUpdateSection(key, diff)}
          value={this.state.configurationBySections.get(key).toJS()}
          isDevModeActive={this.state.isDevModeActive}
          allValues={this.state.configurationBySections.toMap().flatten(true).toJS()}
          disabled={this.state.isSaving || this.state.readOnly}
        />
      );
    });
  },

  renderRunModalContent() {
    const rowName = this.state.row.get('name', 'Untitled');

    return (
      <>
        {(this.state.isJsonConfigurationChanged || this.state.isChanged) && (
          <CatchUnsavedRunWarning />
        )}
        {this.state.row.get('isDisabled') ? (
          <p>
            You are about to run {rowName}. Configuration {rowName} is disabled and will be forced
            to run.
          </p>
        ) : (
          <p>You are about to run {rowName}.</p>
        )}
      </>
    );
  },

  renderJsonEditor() {
    return (
      <JsonConfiguration
        readOnly={this.state.readOnly}
        isSaving={this.state.isJsonConfigurationSaving}
        value={this.state.jsonConfigurationValue}
        onEditChange={(parameters) =>
          Actions.updateJsonConfiguration(
            this.state.componentId,
            this.state.configurationId,
            this.state.rowId,
            parameters,
          )
        }
      />
    );
  },

  clearState() {
    return Actions.clearComponentState(
      this.state.componentId,
      this.state.configurationId,
      this.state.rowId,
    );
  },
});

export default Row;
