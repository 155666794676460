import React from 'react';
import { Form, Modal } from 'react-bootstrap';

import { updateTopUp } from '@/modules/billing/actions';
import { CREDIT_OPTIONS } from '@/modules/billing/constants';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import { TopUpFormInputs, type TopUpSetting } from './TopUpForm';

const TopUpModal = (props: {
  show: boolean;
  onHide: () => void;
  creditPrice: number;
  setting?: TopUpSetting;
}) => {
  const [isSaving, setSaving] = React.useState(false);
  const [data, setData] = React.useState<TopUpSetting>({
    amount: CREDIT_OPTIONS[0],
    limit: 1,
  });

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setSaving(true);
    updateTopUp({ enabled: true, ...data })
      .then(props.onHide)
      .finally(() => setSaving(false));
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      onEnter={() => {
        if (props.setting) {
          setData(props.setting);
        }
      }}
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Automatic Top-Up Settings</Modal.Title>
          <ModalIcon icon="arrows-rotate" color="green" bold />
        </Modal.Header>
        <Modal.Body className="top-up-form">
          <TopUpFormInputs
            value={data}
            onChange={setData}
            disabled={isSaving}
            creditPrice={props.creditPrice}
          />
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveButtonType="submit"
            isSaving={isSaving}
            isDisabled={data.limit === 0}
            saveLabel={
              !!props.setting
                ? isSaving
                  ? 'Saving Changes...'
                  : 'Save Changes'
                : isSaving
                  ? 'Enabling auto top-up...'
                  : 'Enable auto top-up'
            }
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default TopUpModal;
