import { Promise as BluebirdPromise } from 'bluebird';
import type { Map } from 'immutable';

import Api from '@/api';
import type {
  ConversationExecuteTransformationRequest,
  Feedback,
  PostMessageRequest,
} from '@/api/routes/aiService';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';

const getBranchId = () =>
  `${
    DevBranchesStore.isDevModeActive()
      ? DevBranchesStore.getCurrentId()
      : DevBranchesStore.getDefaultBranchId()
  }`;

const getErrorExplanation = (job: Map<string, any>) => {
  return Api.aiService.explainError({
    jobId: job.get('id'),
    error: job.getIn(['result', 'message']),
  });
};

const getConfigDescription = (componentId: string, configId: string, rowId?: string) => {
  return Api.aiService.describeConfiguration({
    branchId: getBranchId(),
    componentId,
    configId,
    rowId,
  });
};

const sendFeedback = (data: Feedback) => {
  return Api.aiService.feedback(data);
};

const createConversation = (componentId: string, configId: string) => {
  return Api.aiService.createConversation({
    branchId: getBranchId(),
    componentId,
    configId,
  });
};

const getConversation = (conversationId: string) => {
  return Api.aiService.getConversation({ conversationId });
};

const deleteConversation = (conversationId: string) => {
  return getConversation(conversationId).then((conversation) => {
    if (conversation.status !== 'ready') return;

    return Api.aiService.deleteConversation({ conversationId });
  });
};

const generateQuery = (conversationId: string, prompt: string, existingQuery?: string) => {
  return Api.aiService.generateQuery(
    { conversationId },
    { prompt, ...(existingQuery && { existingQuery }) },
  );
};

const executeTransformation = (
  conversationId: string,
  transformation: ConversationExecuteTransformationRequest['transformation'],
) => {
  return Api.aiService
    .executeTransformation({ conversationId }, { transformation })
    .then(() => getLastTransformationExecutionResult(conversationId));
};

const getLastTransformationExecutionResult = (
  conversationId: string,
): ReturnType<typeof Api.aiService.getLastResult> => {
  return getConversation(conversationId).then((conversation) => {
    if (conversation.status === 'executing') {
      return BluebirdPromise.delay(500).then(() =>
        getLastTransformationExecutionResult(conversationId),
      );
    }

    return Api.aiService.getLastResult({ conversationId });
  });
};

const suggestComponent = (prompt: string) => {
  return Api.aiService.suggestComponent({ prompt });
};

const getAutomations = () => {
  return Api.aiService.getAutomations();
};

const getAutomation = (automationId: string) => {
  return Api.aiService.getAutomation({ automationId });
};

const createAutomation = () => {
  return Api.aiService.createAutomation();
};

const sendMessage = (automationId: string, params: PostMessageRequest) => {
  return Api.aiService.postMessageToAutomation({ id: automationId }, params);
};

export {
  getErrorExplanation,
  getConfigDescription,
  sendFeedback,
  createConversation,
  getConversation,
  deleteConversation,
  generateQuery,
  executeTransformation,
  suggestComponent,
  getAutomations,
  getAutomation,
  createAutomation,
  sendMessage,
};
