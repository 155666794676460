import type React from 'react';
import type { ReactFlowState } from '@xyflow/react';
import type { Map } from 'immutable';
import colors from 'tailwind-config/colors';

import dayjs, { TIME_FORMAT } from '@/date';
import { MetadataKeys } from '@/modules/components/MetadataConstants';
import RoutesStore from '@/stores/RoutesStore';
import { windowOpen } from '@/utils/windowOpen';
import { routeNames, TableSubtype } from './constants';
import { constructFQID, FQID_TYPES, parseFQID } from './fqid';
import type { Component, NodeData } from './rfTypes';
import type { Configuration, EdgeInfo, Metadata, Table, TablesLineage } from './types';

export const zoomOutSelector = (state: ReactFlowState) => state.transform[2] < 0.2;

export const resetNodeQueryParam = () => {
  RoutesStore.getRouter().updateQuery({ node: null });
};

export const hasSidebar = (type?: string) => {
  return !!(type && ['table', 'component'].includes(type));
};

export const getMetadataValue = (metadata: Metadata[], key: string) => {
  return metadata.find((row) => row.key === key)?.value || null;
};

export const isTableAlias = (metadata: Metadata[]) => {
  return getMetadataValue(metadata, MetadataKeys.IS_ALIAS) === '1';
};

export const countMetadata = (metadata: Metadata[], key: string) => {
  return metadata.filter((row) => row.key.startsWith(`${key}.`)).length || null;
};

export const mapTablesDataToGraph = (
  data: TablesLineage,
  components: Map<string, any>,
  showTablesOnly: boolean,
  currentProjectId: string | null,
) => {
  const nodes: NodeData[] = [];
  const edges: EdgeInfo[] = [];

  // remove this check when backend stop sending external tables and columns
  const isValidEdge = (source: string, target: string) => {
    return (
      !source.startsWith(FQID_TYPES.externalTable) &&
      !source.startsWith(FQID_TYPES.externalColumn) &&
      !target.startsWith(FQID_TYPES.externalTable) &&
      !target.startsWith(FQID_TYPES.externalColumn)
    );
  };

  data.relations.forEach((relation) => {
    const {
      type: sourceType,
      projectId: sourceProjectId,
      tableId: sourceTableId,
      column: sourceColumnName,
    } = parseFQID(relation.sourceFqid);
    const {
      type: targetType,
      projectId: targetProjectId,
      tableId: targetTableId,
      column: targetColumnName,
    } = parseFQID(relation.targetFqid);

    const isSourceColumn = sourceType === FQID_TYPES.column;
    const isTargetColumn = targetType === FQID_TYPES.column;

    const source = isSourceColumn
      ? constructFQID({
          type: FQID_TYPES.table,
          data: { projectId: sourceProjectId ?? '', tableId: sourceTableId ?? '' },
        })
      : relation.sourceFqid;
    const target = isTargetColumn
      ? constructFQID({
          type: FQID_TYPES.table,
          data: { projectId: targetProjectId ?? '', tableId: targetTableId ?? '' },
        })
      : relation.targetFqid;

    const sourceHandle = isSourceColumn ? `${sourceColumnName}-source` : 'main';
    const targetHandle = isTargetColumn ? `${targetColumnName}-target` : 'main';

    // if 2 nodes are connected through configuration, we split the edge into 2
    if (!showTablesOnly && relation.configurationFqid) {
      if (isValidEdge(source, relation.configurationFqid)) {
        edges.push({
          id: `${source}/${sourceHandle}-${relation.configurationFqid}/main/${target}/${targetHandle}`,
          source,
          target: relation.configurationFqid,
          originalTarget: target,
          sourceHandle,
          targetHandle: 'main',
          lineageSource: relation.lineageSource ?? [],
        });
      }
      if (isValidEdge(relation.configurationFqid, target)) {
        edges.push({
          id: `${relation.configurationFqid}/main/${source}/${sourceHandle}-${target}/${targetHandle}`,
          source: relation.configurationFqid,
          originalSource: source,
          target,
          sourceHandle: 'main',
          targetHandle,
          lineageSource: relation.lineageSource ?? [],
        });
      }
      return;
    }

    if (isValidEdge(source, target)) {
      edges.push({
        id: `${source}/${sourceHandle}-${target}/${targetHandle}`,
        source,
        target,
        sourceHandle,
        targetHandle,
        lineageSource: relation.lineageSource ?? [],
      });
    }
  });

  [
    ...Object.values(data.tables),
    ...(showTablesOnly ? [] : Object.values(data.configurations)),
  ].forEach((value) => {
    const { type, projectId, componentId } = parseFQID(value?.fqid);
    const projectName = projectId ? data.projects[`PRJ/${projectId}`]?.name : 'unknown';

    if (type === FQID_TYPES.table) {
      return nodes.push({
        ...(value as Table),
        type: 'table',
        projectName,
        subtype:
          currentProjectId !== projectId
            ? edges.some((edge) => edge.source === value?.fqid)
              ? TableSubtype.LINKED_FROM
              : TableSubtype.LINKED_TO
            : TableSubtype.TABLE,
      });
    }

    if (type === FQID_TYPES.config) {
      const component: Component | null = components.get(componentId ?? '')?.toJS() ?? null;

      return nodes.push({ ...(value as Configuration), type: 'component', projectName, component });
    }
  });

  return { nodes, edges, computeTimestamp: new Date().getTime().toString() };
};

export const getExportOptions = (graphId: string) => {
  const flowPane = document.getElementById(graphId);

  return {
    flowPane,
    font: false,
    backgroundColor: colors.neutral['50'],
    filter: (node: Node) => {
      if (!(node instanceof HTMLElement)) {
        return true;
      }

      return (
        node.style.display !== 'none' &&
        !(
          node.classList.contains('react-flow__panel') ||
          node.classList.contains('react-flow__handle') ||
          node.classList.contains('row-dropdown') ||
          node.classList.contains('component-icon')
        )
      );
    },
  } as const;
};

export const onProjectOpenInNewWindow = (
  name: string,
  projectId: string | number,
  nodeId: string | undefined,
  event?: React.MouseEvent<HTMLAnchorElement>,
) => {
  event?.preventDefault();
  event?.stopPropagation();

  return windowOpen(
    RoutesStore.getRouter().createHref(
      routeNames.LINEAGE,
      { projectId: projectId, projectName: name },
      { ...(nodeId && { node: nodeId }) },
    ),
  );
};

export const isEdgeCalculatedWithLlm = (edge: { lineageSource?: string[] }) => {
  return edge.lineageSource?.includes('llm_analysis');
};

export const getLastUpdate = () => {
  const minutes = dayjs().minute();
  const hours = dayjs().hour();

  return dayjs()
    .minute(minutes > 35 || minutes < 5 ? 35 : 5)
    .hour(minutes > 5 ? hours : hours - 1)
    .format(TIME_FORMAT);
};
