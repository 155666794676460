import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { ORCHESTRATOR, TRANSFORMATION } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import { routeNames as ComponentsRouteNames } from '@/modules/components-directory/constants';
import { routeNames as OrchestrationsRouteNames } from '@/modules/orchestrations/Constants';
import { routeNames } from '@/modules/transformations/Constants';
import { routeNames as TransformationsRouteNames } from '@/modules/transformations-v2/constants';
import Link from '@/react/common/RouterLink';
import ApplicationStore from '@/stores/ApplicationStore';

const versionsMishmashDetected = (componentId, configId, latestVersion) => {
  return createReactClass({
    propTypes: {
      onClick: PropTypes.func.isRequired,
    },

    render() {
      const creator = latestVersion.getIn(['creatorToken', 'description']);

      if (ApplicationStore.getCurrentAdmin().get('email') === creator) {
        return (
          <p>
            This configuration is also being edited by you from another place. Editing the same
            configuration from multiple places is not supported. If you save your changes here, you
            will overwrite the other changes. To see the latest changes, go to {this.renderLink()}.
          </p>
        );
      }

      const admin = ApplicationStore.getAdmins().get(creator);

      return (
        <p>
          New changes by <b>{admin ? admin.get('name') || admin.get('email') : creator}</b> to this
          configuration were detected. Editing the same configuration by several users is not
          supported. If you save your changes, you will overwrite their changes. To see the latest
          changes, go to {this.renderLink()}.
        </p>
      );
    },

    renderLink() {
      if (componentId === ORCHESTRATOR) {
        return (
          <Link
            to={OrchestrationsRouteNames.VERSIONS}
            params={{ orchestrationId: configId }}
            onClick={this.props.onClick}
          >
            versions
          </Link>
        );
      }

      const component = ComponentsStore.getComponent(componentId);

      if (!component) {
        return 'versions';
      }

      return (
        <Link
          to={
            componentId === TRANSFORMATION
              ? routeNames.BUCKET_VERSIONS
              : component.get('type') === componentTypes.TRANSFORMATION
                ? TransformationsRouteNames.GENERIC_TRANSFORMATION_VERSIONS
                : ComponentsRouteNames.GENERIC_CONFIG_VERSIONS
          }
          params={{
            component: componentId,
            config: configId,
          }}
          onClick={this.props.onClick}
        >
          versions
        </Link>
      );
    },
  });
};

export default versionsMishmashDetected;
