import React, { useEffect, useState } from 'react';
import Textarea from 'react-textarea-autosize';

import type { REQUESTABLE_FEATURE } from '@/constants/features';
import { isFeatureRequested, requestFeature } from '@/modules/sapi-events/actions';
import Confirm from './Confirm';

const FeatureRequestModal = ({
  feature,
  children,
}: {
  feature: REQUESTABLE_FEATURE;
  children: React.ReactNode;
}) => {
  const [message, setMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isAlreadyRequested, setIsAlreadyRequested] = useState(false);

  useEffect(() => {
    isFeatureRequested(feature.key).then(setIsAlreadyRequested);
  }, [feature.key]);

  return (
    <Confirm
      icon={feature.buttonIcon}
      title={`Request ${feature.label}`}
      buttonLabel="Request feature"
      buttonType="success"
      text={
        isAlreadyRequested ? (
          'You already requested this feature.'
        ) : (
          <>
            Thank you for your interest in the {feature.label} feature! We will get in touch with
            you as soon as possible. In the meantime, we&apos;d love to learn more about your needs.
            Could you please describe your use case?
            <Textarea
              autoFocus
              className="form-control mt-1"
              minRows={4}
              placeholder="Provide a reason or use case"
              value={message}
              onChange={({ target }) => setMessage(target.value)}
            />
          </>
        )
      }
      isDisabledConfirmButton={!message.trim() || isAlreadyRequested}
      isLoading={isSaving}
      onConfirm={() => {
        setIsSaving(true);

        return requestFeature(feature.key, message)
          .then(() => setIsAlreadyRequested(true))
          .finally(() => setIsSaving(false));
      }}
      closeAfterResolve
    >
      {children}
    </Confirm>
  );
};

export default FeatureRequestModal;
