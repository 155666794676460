const FEEDBACK_STATUS = {
  CANCEL: 'cancel',
  GOOD: 'good',
  BAD: 'bad',
} as const;

type FeedbackStatusType = (typeof FEEDBACK_STATUS)[keyof typeof FEEDBACK_STATUS];

const COMPONENT_SUGGESTION_FALLBACK_SOURCE = 'genericFallback';

export { FEEDBACK_STATUS, FeedbackStatusType, COMPONENT_SUGGESTION_FALLBACK_SOURCE };
