import React from 'react';
import type { ReactNode } from 'react';
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Map } from 'immutable';

import { canManageBuckets, canWriteBucket } from '@/modules/admin/privileges';
import { routeNames as bucketBrowserRouteNames } from '@/modules/bucket-browser/constants';
import SapiTableLink from '@/modules/components/react/components/StorageApiTableLink';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import BetaTitle from '@/modules/lineage/BetaTitle';
import { constructFQID, FQID_TYPES } from '@/modules/lineage/fqid';
import TablesLineage from '@/modules/lineage/TablesLineage/TablesLineage';
import { factory as eventsFactory } from '@/modules/sapi-events/TableEventsService';
import StackFeaturesStore from '@/modules/stack-features/Store';
import ColumnsPreview from '@/modules/storage/components/ColumnsPreview';
import DataSample from '@/modules/storage/components/DataSample';
import DataSampleColumnOrderInfo from '@/modules/storage/components/DataSampleColumnOrderInfo';
import DataSampleHelp from '@/modules/storage/components/DataSampleHelp';
import TableEvents from '@/modules/storage/components/Events';
import NativeTypesLabel from '@/modules/storage/components/NativeTypesLabel';
import Snapshots from '@/modules/storage/components/Snapshots';
import TableActions from '@/modules/storage/components/TableActions';
import TableGraph from '@/modules/storage/components/TableGraph';
import TableOverview from '@/modules/storage/components/TableOverview';
import TableSchema from '@/modules/storage/components/TableSchema';
import TableUsage from '@/modules/storage/components/TableUsage';
import { getTableAliases, getTableLinks } from '@/modules/storage/helpers';
import ExternalTableLabel from '@/react/common/ExternalTableLabel';
import FullScreenModal from '@/react/common/FullScreenModal';
import Loader from '@/react/common/Loader';
import RouterLink from '@/react/common/RouterLink';
import TabLink from '@/react/common/TabLink';
import TabNav from '@/react/common/TabNav';
import Truncated from '@/react/common/Truncated';
import useForceUpdate from '@/react/hooks/useForceUpdate';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { parseLocationContext } from '@/utils/modalRoutes';
import { routeNames } from './constants';

const tabs = {
  OVERVIEW: 'overview',
  SCHEMA: 'schema',
  EVENTS: 'events',
  DATA_SAMPLE: 'data-sample',
  SNAPSHOT_AND_RESTORE: 'snapshot-and-restore',
  GRAPH: 'graph',
  USAGE: 'usage',
  LINEAGE: 'lineage',
};

const Index = () => {
  const forceUpdate = useForceUpdate();
  const [isReloadingData, setIsReloadingData] = React.useState(false);
  const state = useStores(
    () => {
      const routerState = RoutesStore.getRouterState();
      const tableId = RoutesStore.getCurrentRouteParam('tableId');
      const table = StorageTablesStore.getTable(tableId, Map(), { caseInsensitiveFallback: true });
      const bucket = table.get('bucket', Map());
      const sapiToken = ApplicationStore.getSapiToken();
      const hasShowTransformationMigration = ApplicationStore.hasShowTransformationMigration();

      return {
        table,
        bucket,
        tableId,
        routerState,
        sapiToken,
        hasShowTransformationMigration,
        activeTab: RoutesStore.getCurrentRouteParam('tableTab') || tabs.OVERVIEW,
        tables: StorageTablesStore.getAll(),
        buckets: StorageBucketsStore.getAll(),
        snapshots: StorageTablesStore.getTableSnapshots(tableId),
        canWriteTable: canWriteBucket(sapiToken, bucket),
        addingColumn: StorageTablesStore.getAddingColumn(),
        deletingColumn: StorageTablesStore.getDeletingColumn().get(table.get('id'), Map()),
        creatingPrimaryKey: StorageTablesStore.getIsCreatingPrimaryKey(table.get('id')),
        deletingPrimaryKey: StorageTablesStore.getIsDeletingPrimaryKey(table.get('id')),
        settingAliasFilter: StorageTablesStore.getIsSettingAliasFilter(table.get('id')),
        removingAliasFilter: StorageTablesStore.getIsRemovingAliasFilter(table.get('id')),
        updatingTable: StorageTablesStore.getIsUpdatingTable(table.get('id')),
        creatingFromSnapshot: StorageTablesStore.getIsCreatingFromSnapshot(),
        deletingSnapshot: StorageTablesStore.getIsDeletingSnapshot(),
        configurations: InstalledComponentsStore.getAll(),
        components: ComponentsStore.getAll() as Map<string, any>,
        hasGraph: !ApplicationStore.hasNewTransformationsOnly(),
        urlTemplates: ApplicationStore.getUrlTemplates(),
        canManageBuckets: canManageBuckets(sapiToken),
        hasUsage: ApplicationStore.hasNewTransformationsOnly() || hasShowTransformationMigration,
        admins: ApplicationStore.getAdmins(),
        hasFlows: ApplicationStore.hasFlows(),
        currentProjectId: ApplicationStore.getCurrentProjectId(),
        hasAiLineage: ApplicationStore.hasAiLineage(),
        isDevModeActive: DevBranchesStore.isDevModeActive(),
        hasAllowedAi: StackFeaturesStore.hasAllowedAi(),
      };
    },
    [],
    [
      StorageTablesStore,
      StorageBucketsStore,
      InstalledComponentsStore,
      ComponentsStore,
      ApplicationStore,
      StackFeaturesStore,
      DevBranchesStore,
      RoutesStore,
    ],
  );

  const onHide = () => {
    const { pathname, query, hash, routeState } = parseLocationContext(
      state.routerState.get('location', Map()),
    );

    RoutesStore.getRouter().transitionTo(pathname, null, query, hash, routeState);
  };

  const renderTabLink = (tab: string, label: ReactNode) => {
    return (
      <TabLink
        active={state.activeTab === tab}
        to={routeNames.ROOT}
        params={{ tableId: state.tableId, tableTab: tab }}
        query={{ context: state.routerState.getIn(['location', 'query', 'context']) }}
      >
        {label}
      </TabLink>
    );
  };

  const renderTabContent = () => {
    const tableAliases = getTableAliases(state.table, state.tables, state.sapiToken);
    const tableLinks = getTableLinks(
      state.table,
      state.buckets.get(state.table.getIn(['bucket', 'id']), Map()),
    );

    if (state.activeTab === tabs.SCHEMA) {
      return (
        <TableSchema
          sapiToken={state.sapiToken}
          tables={state.tables}
          table={state.table}
          canWriteTable={state.canWriteTable}
          addingColumn={state.addingColumn}
          deletingColumn={state.deletingColumn}
          tableAliases={tableAliases}
          tableLinks={tableLinks}
          urlTemplates={state.urlTemplates}
          components={state.components}
        />
      );
    }

    if (state.activeTab === tabs.EVENTS) {
      return <TableEvents eventsFactory={eventsFactory(state.tableId)} admins={state.admins} />;
    }

    if (state.activeTab === tabs.DATA_SAMPLE) {
      return (
        <DataSample
          backend={state.bucket.get('backend')}
          table={state.table}
          bucket={state.bucket}
          onChangeColumnOrder={forceUpdate}
        />
      );
    }

    if (state.activeTab === tabs.SNAPSHOT_AND_RESTORE) {
      return (
        <Snapshots
          snapshots={state.snapshots}
          sapiToken={state.sapiToken}
          admins={state.admins}
          buckets={state.buckets}
          tables={state.tables}
          table={state.table}
          creatingFromSnapshot={state.creatingFromSnapshot}
          deletingSnapshot={state.deletingSnapshot}
        />
      );
    }

    if (state.hasGraph && state.activeTab === tabs.GRAPH) {
      return (
        <TableGraph key={state.table.get('lastImportDate') || tabs.GRAPH} table={state.table} />
      );
    }

    if (state.hasUsage && state.activeTab === tabs.USAGE) {
      return (
        <TableUsage
          admins={state.admins}
          tableId={state.table.get('id')}
          configurations={state.configurations}
          hasShowTransformationMigration={state.hasShowTransformationMigration}
          hasFlows={state.hasFlows}
        />
      );
    }

    if (state.activeTab === tabs.LINEAGE) {
      const startingPointFqid = constructFQID({
        type: FQID_TYPES.table,
        data: { projectId: state.currentProjectId, tableId: state.table.get('id') },
      });

      return (
        <TablesLineage
          isLocked={!state.hasAiLineage}
          allComponents={state.components}
          startingPointFqid={startingPointFqid}
          projectId={`${state.currentProjectId}`}
          nodeName={state.table.get('name', '')}
        />
      );
    }

    return (
      <>
        <TableOverview
          canManageBuckets={state.canManageBuckets}
          table={state.table}
          tables={state.tables}
          components={state.components}
          configurations={state.configurations}
          tableAliases={tableAliases}
          tableLinks={tableLinks}
          sapiToken={state.sapiToken}
          urlTemplates={state.urlTemplates}
          creatingPrimaryKey={state.creatingPrimaryKey}
          deletingPrimaryKey={state.deletingPrimaryKey}
          settingAliasFilter={state.settingAliasFilter}
          removingAliasFilter={state.removingAliasFilter}
          updatingTable={state.updatingTable}
          canWriteTable={state.canWriteTable}
          hasFlows={state.hasFlows}
        />
        <ColumnsPreview key={state.table.get('lastChangeDate')} tableId={state.tableId} />
      </>
    );
  };

  const renderTabs = () => {
    if (state.table.isEmpty()) {
      return <p>Table not found</p>;
    }

    return (
      <>
        <div className="tabs-with-border-wrapper flex-container flex-nowrap">
          <TabNav className="no-shrink pr-2">
            {renderTabLink(tabs.OVERVIEW, 'Overview')}
            {renderTabLink(tabs.SCHEMA, 'Schema')}
            {renderTabLink(tabs.EVENTS, 'Events')}
            {renderTabLink(tabs.DATA_SAMPLE, 'Data Sample')}
            {!state.table.get('isAlias') && renderTabLink(tabs.SNAPSHOT_AND_RESTORE, 'Snapshots')}
            {state.hasGraph && renderTabLink(tabs.GRAPH, 'Graph')}
            {state.hasUsage && renderTabLink(tabs.USAGE, 'Usage')}
            {state.hasAiLineage &&
              state.hasAllowedAi &&
              state.hasFlows &&
              !state.isDevModeActive &&
              renderTabLink(tabs.LINEAGE, <BetaTitle>Data Lineage</BetaTitle>)}
          </TabNav>
          <div className="ml-auto flex-container flex-end">
            {state.activeTab === tabs.DATA_SAMPLE && (
              <div className="flex-container flex-end">
                <DataSampleColumnOrderInfo
                  tableId={state.table.get('id')}
                  onResetColumnsOrder={forceUpdate}
                />
                <DataSampleHelp backend={state.bucket.get('backend')} />
              </div>
            )}
            <TableActions table={state.table} onDeleteTable={onHide} />
          </div>
        </div>
        {renderTabContent()}
      </>
    );
  };

  const renderTopButton = () => {
    return (
      <ButtonToolbar>
        {!state.table.isEmpty() && (
          <>
            <SapiTableLink tableId={state.tableId} className="btn btn-default">
              <FontAwesomeIcon icon="warehouse" className="icon-addon-right" />
              Explore in Storage
            </SapiTableLink>
            <Button
              onClick={() => {
                setIsReloadingData(true);
                StorageActionCreators.loadTableDetailForce(state.tableId).finally(() => {
                  setIsReloadingData(false);
                });
              }}
              disabled={isReloadingData}
            >
              {isReloadingData ? (
                <Loader className="icon-addon-right" />
              ) : (
                <FontAwesomeIcon icon="rotate" className="icon-addon-right" fixedWidth />
              )}
              Reload Data
            </Button>
            <span className="btn-separator" />
          </>
        )}
        <Button onClick={onHide}>
          <FontAwesomeIcon icon="xmark" />
        </Button>
      </ButtonToolbar>
    );
  };

  const renderBreadcrumb = () => {
    const location = state.routerState.get('location', Map());

    return (
      <div className="breadcrumb">
        <RouterLink
          className="active dark muted"
          to={bucketBrowserRouteNames.BUCKET_PREVIEW}
          state={location.get('state')}
          query={{ context: location.getIn(['query', 'context']) }}
          params={{ bucketId: state.bucket.get('id') }}
        >
          <FontAwesomeIcon
            icon="folder"
            className={classNames('text-muted f-16 icon-addon-right', {
              'dev-bucket': isCreatedInDevBranch(state.bucket),
            })}
          />
          {state.bucket.get('displayName')}
        </RouterLink>
      </div>
    );
  };

  return (
    <FullScreenModal className="full-screen-generic-overview" onHide={onHide}>
      <Modal.Header>
        <div className="flex-container">
          {state.table.isEmpty() ? (
            <h4 className="modal-title">{state.tableId}</h4>
          ) : (
            <div className="modal-title">
              {renderBreadcrumb()}
              <h4 className="flex-container flex-start">
                <FontAwesomeIcon icon="table" className="text-muted icon-addon-right" />
                <Truncated text={state.table.get('displayName')} className="tw-mr-2.5" />
                <NativeTypesLabel isTyped={state.table.get('isTyped', false)} />
                <ExternalTableLabel
                  tableType={state.table.get('tableType')}
                  hasExternalSchema={state.table.getIn(['bucket', 'hasExternalSchema'])}
                />
              </h4>
            </div>
          )}
          {renderTopButton()}
        </div>
      </Modal.Header>
      <Modal.Body>{renderTabs()}</Modal.Body>
    </FullScreenModal>
  );
};

export default Index;
