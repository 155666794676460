import React from 'react';
import { Button, Form } from 'react-bootstrap';

const NegativeFeedbackForm = ({ onBad }: { onBad: (message?: string) => void }) => {
  const [selectedReason, setSelectedReason] = React.useState('');

  return (
    <Form
      inline
      className="ptp-2"
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onBad(selectedReason);
        setSelectedReason('');
      }}
    >
      <p className="f-14 line-height-20 tw-mb-1.5">What was the issue with the explanation?</p>
      <div className="tw-flex tw-flex-wrap tw-gap-2">
        {['Is not relevant', 'Is not accurate', 'I don’t understand'].map((reason, index) => {
          return (
            <Button
              key={index}
              type="submit"
              bsSize="sm"
              className="f-12 font-semibold line-height-20"
              onClick={() => setSelectedReason(reason)}
            >
              {reason}
            </Button>
          );
        })}
      </div>
    </Form>
  );
};

export default NegativeFeedbackForm;
