import Bluebird from 'bluebird';

import type { Automation } from '@/api/routes/aiService';
import Dispatcher from '@/Dispatcher';
import * as AiApi from '@/modules/ai/api';
import { actionTypes, type MessageObject } from './constants';

const createAutomation = () => {
  const resolveWhenReady = (id: string): Promise<Automation> => {
    return AiApi.getAutomation(id).then((response) => {
      if (response.status === 'ready') return response;
      if (response.status === 'failed') throw new Error('Automation initialization failed');
      return Bluebird.delay(500).then(() => resolveWhenReady(id));
    });
  };

  return AiApi.createAutomation()
    .then(({ id }) => resolveWhenReady(id))
    .then((automation) => {
      Dispatcher.handleViewAction({ type: actionTypes.CREATE_AUTOMATION, automation });
      return automation;
    });
};

const loadAutomations = () => {
  return AiApi.getAutomations().then((automations) => {
    Dispatcher.handleViewAction({ type: actionTypes.LOAD_AUTOMATIONS, automations });
  });
};

const loadAutomation = (id: string) => {
  return AiApi.getAutomation(id).then((automation) => {
    Dispatcher.handleViewAction({ type: actionTypes.LOAD_AUTOMATION, automation });
  });
};

const deleteAutomation = (id: string) => {
  // TODO

  Dispatcher.handleViewAction({ type: actionTypes.DELETE_AUTOMATION, id });
};

const sendMessage = (automationId: string, messageObject: MessageObject) => {
  return AiApi.sendMessage(automationId, { data: {}, ...messageObject });
};

export { createAutomation, loadAutomations, loadAutomation, deleteAutomation, sendMessage };
