import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fromJS } from 'immutable';

import dayjs from '@/date';
import Gravatar from '@/react/common/Gravatar';

type Props = {
  createdByUser: {
    user: string;
    createdAt: string;
  };
  admins: {
    [email: string]: {
      id: string;
      name: string;
      email: string;
    };
  };
};

const CreatedByUser = ({ createdByUser, admins }: Props) => {
  const user = createdByUser?.user;
  const admin = admins[user];
  const createdAt = dayjs(createdByUser.createdAt);

  return (
    <>
      <div className="sidebar-box created-by">
        <h4 className="mb-1">Created by</h4>
        <div className="overflow-break-anywhere">
          {admin ? (
            <>
              <Gravatar className="icon-addon-right" size={14} user={fromJS(admin)} />{' '}
              {admin.name || admin.email}
            </>
          ) : (
            <>
              <FontAwesomeIcon icon="circle-user" className="icon-addon-right" /> {user}
            </>
          )}
        </div>
        <div className="text-muted overflow-break-anywhere">
          <FontAwesomeIcon icon="clock" className="icon-addon-right" /> {createdAt.fromNow()}
        </div>
      </div>
      <hr />
    </>
  );
};

export default CreatedByUser;
