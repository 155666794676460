import React from 'react';
import { Alert } from 'design';
import { capitalize } from 'underscore.string';

const RunUnsavedWarning = (props: { section: string; entity: string }) => {
  return (
    <Alert variant="warning" className="tw-mb-5">
      You have unsaved changes in the <strong>{capitalize(props.section)}</strong> section! Running
      the {props.entity} will use the last saved version.
    </Alert>
  );
};

export default RunUnsavedWarning;
