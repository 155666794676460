import CodeMirror from 'codemirror';

import { parse, parser } from './parser';

CodeMirror.registerHelper('lint', 'json', (text) => {
  const found = [];

  parser.parseError = (str, hash) => {
    found.push({
      from: CodeMirror.Pos(hash.loc.first_line - 1, hash.loc.first_column),
      to: CodeMirror.Pos(hash.loc.last_line - 1, hash.loc.last_column),
      message: str,
    });
  };

  try {
    parse(text);
  } catch {
    // it is handled with code above
  }

  return found;
});
