export type Provider = 'aws' | 'azure' | 'gcp';
export type Continent = 'us' | 'europe';

export type Stack = {
  id: string;
  host: string;
  isSingleTenant?: boolean;
  isTesting?: boolean;
  isPayg?: boolean;
  provider?: Provider;
  continent?: Continent;
  region?: string;
};

const STACKS: Record<string, Readonly<Stack>> = {
  NORTH_EUROPE_AZURE: {
    id: 'com-keboola-azure-north-europe',
    region: 'north-europe',
    host: 'connection.north-europe.azure.keboola.com',
    provider: 'azure',
    continent: 'europe',
    isPayg: true,
  },
  EU_CENTRAL_1_AWS: {
    id: 'kbc-eu-central-1',
    region: 'eu-central-1',
    host: 'connection.eu-central-1.keboola.com',
    provider: 'aws',
    continent: 'europe',
  },
  US_EAST_1_AWS: {
    id: 'kbc-us-east-1',
    region: 'us-east-1',
    host: 'connection.keboola.com',
    provider: 'aws',
    continent: 'us',
  },
  EUROPE_WEST2_GCP: {
    id: 'com-keboola-gcp-europe-west2',
    region: 'europe-west2',
    host: 'connection.europe-west2.gcp.keboola.com',
    provider: 'gcp',
    continent: 'europe',
  },
  EUROPE_WEST3_GCP: {
    id: 'com-keboola-gcp-europe-west3',
    region: 'europe-west3',
    host: 'connection.europe-west3.gcp.keboola.com',
    provider: 'gcp',
    continent: 'europe',
  },
  US_EAST_4_GCP: {
    id: 'com-keboola-gcp-us-east4',
    region: 'us-east4',
    host: 'connection.us-east4.gcp.keboola.com',
    provider: 'gcp',
    continent: 'us',
    isPayg: true,
  },

  /* PRIVATE SINGLE TENANT STACKS
     ---------------------------- */
  HCI: {
    id: 'cloud-keboola-hci',
    host: 'connection.hci.keboola.cloud',
    isSingleTenant: true,
  },
  CSAS_PROD: {
    id: 'cloud-keboola-csas',
    host: 'connection.csas.keboola.cloud',
    isSingleTenant: true,
  },  
  CSAS_PROD_CS: {
    id: 'cloud-keboola-cs',
    host: 'connection.cs.keboola.cloud',
    isSingleTenant: true,
  },
  CSAS_TEST: {
    id: 'cloud-keboola-csas-test',
    host: 'connection.csas-test.keboola.cloud',
    isSingleTenant: true,
  },
  INNOGY: {
    id: 'cloud-keboola-innogy-hub',
    host: 'connection.innogy-hub.keboola.cloud',
    isSingleTenant: true,
  },
  GROUPON: {
    id: 'cloud-keboola-groupon',
    host: 'connection.groupon.keboola.cloud',
    isSingleTenant: true,
  },
  CREDIT_INFO: {
    id: 'cloud-keboola-credit-info',
    host: 'connection.creditinfo.keboola.cloud',
    isSingleTenant: true,
  },
  COATES: {
    id: 'cloud-keboola-coates',
    host: 'connection.coates.keboola.cloud',
    isSingleTenant: true,
  },
  SLSP: {
    id: 'cloud-keboola-slsp',
    host: 'connection.slsp.keboola.cloud',
    isSingleTenant: true,
  },
  DEV_US_CENTRAL_1_GCP: {
    id: 'dev-keboola-gcp-us-central1',
    host: 'connection.us-central1.gcp.keboola.dev',
    isTesting: true,
  },
  DEV_EU_WEST_1_AWS: {
    id: 'dev-keboola-aws-eu-west-1',
    host: 'connection.eu-west-1.aws.keboola.dev',
    isTesting: true,
  },
  DEV_US_EAST_2_AZURE: {
    id: 'kbc-testing-azure-east-us-2',
    host: 'connection.east-us-2.azure.keboola-testing.com',
    isTesting: true,
  },
};

const URLS = {
  USER_DOCUMENTATION: 'https://help.keboola.com',
  COMPONENTS: 'https://components.keboola.com',
  DEVELOPERS_DOCUMENTATION: 'https://developers.keboola.com',
  DEMO_PROJECT: 'https://demo.keboola.com',
  ACADEMY: 'https://academy.keboola.com',
  REGISTRATION_WIZARD: 'https://connection.north-europe.azure.keboola.com/wizard',
} as const;

export { STACKS, URLS };
