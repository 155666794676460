import { fromJS, List, Map, OrderedMap } from 'immutable';

import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import Dispatcher from '@/Dispatcher';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StoreUtils, { initStore } from '@/utils/StoreUtils';
import { ActionTypes, JOBS_LIMIT } from './constants';

let _store = initStore(
  'QueueJobsStore',
  Map({
    jobs: OrderedMap(),
    params: Map(),
    latestJobs: Map(),
    latestConfigJobs: Map(),
    pendingActions: Map(),
    isLoading: false,
    isLoadMore: false,
  }),
);

const JobsStore = StoreUtils.createStore({
  getAll() {
    return _store.get('jobs').filter((job) => {
      if (job.get('component') !== KEBOOLA_SANDBOXES) return true;
      return !InstalledComponentsStore.getInternalWorkspaceConfigurations().has(job.get('config'));
    });
  },

  getIsLoading() {
    return _store.get('isLoading', false);
  },

  getIsLoaded() {
    return JobsStore.getAll().count() > 0;
  },

  getIsJobLoaded(id) {
    return JobsStore.getAll().has(id, false);
  },

  getIsLoadMore() {
    return _store.get('isLoadMore');
  },

  get(id) {
    return JobsStore.getAll().get(id, Map());
  },

  getLatestJobs(componentId, configurationId) {
    return _store.getIn(['latestJobs', componentId, configurationId], Map());
  },

  getLatestConfigJobs(componentId) {
    if (!componentId) {
      return _store.get('latestConfigJobs', Map());
    }

    return _store.getIn(['latestConfigJobs', componentId], Map());
  },

  getPendingActions() {
    return _store.get('pendingActions');
  },

  getParams() {
    return _store.get('params');
  },
});

JobsStore.dispatchToken = Dispatcher.register((payload) => {
  const { action } = payload;

  switch (action.type) {
    case ActionTypes.JOBS_LOAD_START: {
      const newParams = fromJS(action.params).delete('offset').delete('limit');
      if (action.options?.resetPreviousJobs && !_store.get('params', Map()).equals(newParams)) {
        _store = _store.set('jobs', OrderedMap());
      }
      _store = _store.set('params', newParams);
      _store = _store.set('isLoading', true);
      return JobsStore.emitChange();
    }

    case ActionTypes.JOBS_LOAD_SUCCESS:
      _store = _store.withMutations((store) => {
        if (action.options?.resetPreviousJobs) {
          store.set('jobs', OrderedMap());
        }

        store.mergeIn(
          ['jobs'],
          fromJS(action.jobs)
            .toOrderedMap()
            .mapKeys((key, job) => job.get('id')),
        );

        if (!action.withoutLoading) {
          store
            .set('isLoading', false)
            .set(
              'isLoadMore',
              action.jobs.length === _store.getIn(['params', 'limit'], JOBS_LIMIT),
            );
        }
      });
      return JobsStore.emitChange();

    case ActionTypes.JOBS_LOAD_ERROR:
      _store = _store.set('isLoading', false);
      return JobsStore.emitChange();

    case ActionTypes.JOB_LOAD:
      _store = _store.setIn(['pendingActions', 'loading', action.id], true);
      return JobsStore.emitChange();

    case ActionTypes.JOB_LOAD_SUCCESS:
      _store = _store.setIn(['jobs', action.job.id], fromJS(action.job));
      _store = _store.deleteIn(['pendingActions', 'loading', action.job.id]);
      return JobsStore.emitChange();

    case ActionTypes.JOB_TERMINATE:
      _store = _store.setIn(['pendingActions', 'terminating', action.id], true);
      return JobsStore.emitChange();

    case ActionTypes.JOB_TERMINATE_SUCCESS:
    case ActionTypes.JOB_TERMINATE_ERROR:
      _store = _store.deleteIn(['pendingActions', 'terminating', action.id], true);
      return JobsStore.emitChange();

    case ActionTypes.JOBS_LATEST_LOAD_START:
      _store = _store.updateIn(
        ['latestJobs', action.componentId, action.configurationId],
        Map({ isLoaded: false, jobs: Map() }),
        (jobs) => jobs.set('isLoading', true),
      );
      return JobsStore.emitChange();

    case ActionTypes.JOBS_LATEST_LOAD_ERROR:
      _store = _store.setIn(
        ['latestJobs', action.componentId, action.configurationId, 'isLoading'],
        false,
      );
      return JobsStore.emitChange();

    case ActionTypes.JOBS_LATEST_LOAD_SUCCESS: {
      const newJobs = fromJS(action.jobs);

      _store = _store
        .updateIn(
          ['latestJobs', action.componentId, action.configurationId],
          Map(),
          (configJobs) => {
            return Map({
              isLoading: false,
              isLoaded: true,
              jobs: action.append ? newJobs.concat(configJobs.get('jobs', List())) : newJobs,
            });
          },
        )
        .setIn(['latestConfigJobs', action.componentId, action.configurationId], newJobs.take(1));

      return JobsStore.emitChange();
    }

    case ActionTypes.CONFIG_LATEST_JOB_LOAD_SUCCESS: {
      const latestJobs = fromJS(action.jobs)
        .groupBy((job) => job.get('component'))
        .map((componentJobs) => componentJobs.groupBy((job) => job.get('config')));

      const noUpdatedJobs = latestJobs.every((jobs, componentId) => {
        return jobs.equals(_store.getIn(['latestConfigJobs', componentId], Map()));
      });

      if (noUpdatedJobs) {
        return;
      }

      latestJobs.forEach((jobs, componentId) => {
        _store = _store.setIn(['latestConfigJobs', componentId], jobs);
      });
      return JobsStore.emitChange();
    }

    default:
      break;
  }
});

export default JobsStore;
