import React from 'react';
import { ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { Alert, HelpBlock } from 'design';
import { Map } from 'immutable';

import type { PARAM_VALUE, VALUE_TYPE } from '@/modules/ex-generic/constants';
import {
  NEW_FUNCTION_BOILERPLATE,
  PARAMETER_TYPE,
  RESERVED_CONFIG_NAMES,
} from '@/modules/ex-generic/constants';
import {
  parameterType,
  preparePropertyName,
  updateAllParametersReferences,
} from '@/modules/ex-generic/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import InfoTooltip from '@/react/common/InfoTooltip';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import fromJSOrdered from '@/utils/fromJSOrdered';
import string from '@/utils/string';
import { isValidJsonConfig } from '@/utils/validation';
import ParameterTypeInput from './ParameterTypeInput';
import SwitchControl from './SwitchControl';

const prepareName = (name: string, type: VALUE_TYPE) => {
  return type === PARAMETER_TYPE.ENCRYPTED ? `#${name}` : `${name}`;
};

const NewParameter = (props: {
  show: boolean;
  parameters: Map<string, any>;
  onSave: (parameters: Map<string, any>, changeDescription: string) => Promise<any>;
  onHide: () => void;
  onCreated?: (name: string, userParameter?: PARAM_VALUE) => void;
  initParams?: Record<string, any> | null;
  existingParameter?: string | null;
}) => {
  const [formData, setFormData] = React.useState<Map<string, any>>(Map());
  const [isSaving, setSaving] = React.useState(false);
  const isLocalParameter = !!formData.get('local', false);
  const isEditing = !!props.existingParameter || (!!props.initParams?.type && isLocalParameter);

  const checkName = (name: string) => {
    if (RESERVED_CONFIG_NAMES.includes(name)) {
      return 'This name is reserved and cannot be used.';
    }

    const alreadyUsed = props.parameters
      .get('config', Map())
      .some((value: PARAM_VALUE, key: string) => {
        if (key === props.existingParameter) {
          return false;
        }

        return prepareName(name, formData.get('type', PARAMETER_TYPE.STRING) as VALUE_TYPE) === key;
      });

    if (alreadyUsed) {
      return 'Parameter with the same name already exists.';
    }

    return false;
  };

  const showError = props.show && checkName(formData.get('name') as string);

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSaving(true);
    const type = formData.get('type', PARAMETER_TYPE.STRING) as VALUE_TYPE;
    const name = prepareName(formData.get('name') as string, type);
    const value =
      type === PARAMETER_TYPE.FUNCTION
        ? fromJSOrdered(JSON.parse(formData.get('value') as string))
        : type === PARAMETER_TYPE.NUMBER
          ? Number(formData.get('value'))
          : formData.get('value');

    if (props.onCreated && isLocalParameter) {
      props.onHide();
      return props.onCreated(value);
    }

    const parameters = !props.existingParameter
      ? props.parameters.setIn(['config', name], value)
      : updateAllParametersReferences(
          props.parameters
            .deleteIn(['config', props.existingParameter])
            .setIn(['config', name], value),
          props.existingParameter,
          name,
          props.parameters.getIn(['config', props.existingParameter]),
          value,
        );

    return props
      .onSave(parameters, `${isEditing ? 'Update' : 'Add'} user parameter`)
      .then(props.onHide)
      .then(() => props.onCreated?.(name, value))
      .finally(() => setSaving(false));
  };

  const isDisabled = () => {
    if (isLocalParameter) {
      return !formData.has('value');
    }

    if (!formData.get('name') || !formData.has('value') || showError) {
      return true;
    }

    if (formData.get('type') === PARAMETER_TYPE.FUNCTION) {
      return !isValidJsonConfig(formData.get('value'));
    }

    return false;
  };

  const renderLocalParameterSwitch = () => {
    if (!props.onCreated) {
      return null;
    }

    return (
      <SwitchControl
        readOnly={false}
        label={
          <>
            Local Parameter
            <InfoTooltip tooltip="Local parameter is not saved into User parameters" />
          </>
        }
        checked={isLocalParameter}
        onChange={(checked: boolean) => setFormData(formData.set('local', checked))}
      />
    );
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      onEnter={() => {
        if (!props.existingParameter) {
          return setFormData(Map({ name: '', ...props.initParams }));
        }

        const value = props.parameters.getIn(['config', props.existingParameter]);

        return setFormData(
          Map({
            name: preparePropertyName(props.existingParameter),
            type: parameterType(value, props.existingParameter),
            value: Map.isMap(value) ? JSON.stringify(value, null, 2) : value,
          }),
        );
      }}
    >
      <Form onSubmit={handleSave}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? 'Update' : 'New'} User Parameter</Modal.Title>
          {isEditing ? <ModalIcon.Edit /> : <ModalIcon.Plus />}
        </Modal.Header>
        <Modal.Body>
          {props.existingParameter && (
            <Alert className="tw-mb-5">All references to this parameter will be updated.</Alert>
          )}
          {renderLocalParameterSwitch()}
          {!isLocalParameter && (
            <FormGroup validationState={showError ? 'error' : null}>
              <ControlLabel>Name</ControlLabel>
              <FormControl
                type="text"
                autoFocus={!isEditing}
                value={formData.get('name', '')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const name = string.sanitizeDiacritics(e.target.value);

                  setFormData(formData.set('name', name));
                }}
              />
              {showError && <HelpBlock variant="danger">{showError}</HelpBlock>}
            </FormGroup>
          )}
          <FormGroup>
            <ControlLabel>Type</ControlLabel>
            <Select
              value={formData.get('type', PARAMETER_TYPE.STRING)}
              onChange={(value: string) => {
                const newFormData = formData.withMutations((data) => {
                  data.set('type', value);

                  value === PARAMETER_TYPE.FUNCTION
                    ? data.set('value', NEW_FUNCTION_BOILERPLATE)
                    : data.delete('value');
                });

                setFormData(newFormData);
              }}
              options={[
                { label: 'String', value: PARAMETER_TYPE.STRING },
                { label: 'Number', value: PARAMETER_TYPE.NUMBER },
                { label: 'Boolean', value: PARAMETER_TYPE.BOOLEAN },
                ...(!isLocalParameter
                  ? [
                      { label: 'Encrypted Value', value: PARAMETER_TYPE.ENCRYPTED },
                      { label: 'Function', value: PARAMETER_TYPE.FUNCTION },
                    ]
                  : []),
              ]}
            />
          </FormGroup>
          <ParameterTypeInput
            autoFocus={isEditing}
            type={formData.get('type', PARAMETER_TYPE.STRING)}
            value={formData.get('value', '')}
            onChange={(value) => setFormData(formData.set('value', value))}
            parameters={props.parameters}
          />
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            showCancel={false}
            isSaving={isSaving}
            saveButtonType="submit"
            saveLabel={`${isEditing ? 'Update' : 'Create'} Parameter`}
            isDisabled={isDisabled()}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default NewParameter;
