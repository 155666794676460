import React from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import Switch from 'rc-switch';

const SwitchToggle = ({
  onClick,
  checked,
  label,
}: {
  onClick: (newValue: boolean) => void;
  checked: boolean;
  label: string;
}) => {
  return (
    <FormGroup
      className="flex-container flex-start tw-mb-0"
      onClick={() => {
        onClick(!checked);
      }}
    >
      <Switch prefixCls="switch" className="wider btn-icon" checked={checked} />
      <ControlLabel className="clickable mb-0 tw-w-max">{label}</ControlLabel>
    </FormGroup>
  );
};

export default SwitchToggle;
