import React from 'react';
import { Button as BootstrapButton, Table, Well } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from 'design';
import type { Map } from 'immutable';

import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import { filterProductionAndCurrentDevBranchTables } from '@/modules/dev-branches/helpers';
import { tableDisplayNameWithBucketAndStage, tableName } from '@/modules/storage/helpers';
import BucketStageLabel from '@/react/common/BucketStageLabel';
import CreatedDate from '@/react/common/CreatedDate';
import DevBranchLabel from '@/react/common/DevBranchLabel';
import Select from '@/react/common/Select';
import { tableLabel } from '@/react/common/selectLabels';
import Truncated from '@/react/common/Truncated';
import useStores from '@/react/hooks/useStores';

const TableSelection = (props: {
  tablesIds?: string[];
  onClick: (tablesIds: string[]) => void;
}) => {
  const [selected, setSelected] = React.useState<string[]>(props.tablesIds || []);
  const store = useStores(
    () => {
      return {
        allTables: StorageTablesStore.getAll() as Map<string, any>,
        allBuckets: StorageBucketsStore.getAll() as Map<string, any>,
      };
    },
    [],
    [StorageTablesStore, StorageBucketsStore],
  );

  return (
    <>
      <Select
        clearable={false}
        menuPlacement="top"
        placeholder="Add tables"
        className="tw-min-w-[600px]"
        options={filterProductionAndCurrentDevBranchTables(store.allTables, store.allBuckets)
          .filter((table: Map<string, any>) => !selected.includes(table.get('id')))
          .sortBy((table: Map<string, any>) => tableDisplayNameWithBucketAndStage(table))
          .map((table: Map<string, any>) => {
            return { value: table.get('id'), label: tableLabel(table), name: tableName(table) };
          })
          .toArray()}
        onChange={(table: string) => setSelected([...selected, table])}
      />
      {selected.length > 0 && (
        <Well className="with-table !tw-m-0">
          <Table>
            <thead>
              <tr>
                <th>Selected Tables</th>
                <th className="no-wrap">Last Import</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {selected.map((tableId) => {
                const table = store.allTables.get(tableId);

                if (!table) {
                  return null;
                }

                return (
                  <tr key={table.get('id')}>
                    <td className="overflow-break-anywhere">
                      <div className="tw-flex tw-items-center">
                        <FontAwesomeIcon icon="table" className="icon-addon-right text-muted" />
                        <BucketStageLabel
                          placement="left"
                          stage={table.getIn(['bucket', 'stage'])}
                        />
                        <DevBranchLabel bucket={table.get('bucket')} />
                        <Truncated text={tableName(table)} />
                      </div>
                    </td>
                    <td className="no-wrap text-muted f-13">
                      <CreatedDate
                        createdTime={table.get('lastImportDate')}
                        fallback="Not yet imported"
                      />
                    </td>
                    <td className="pl-0 pr-0">
                      <Tooltip placement="top" tooltip="Deselect table">
                        <BootstrapButton
                          bsStyle="link"
                          className="text-muted"
                          onClick={() =>
                            setSelected(selected.filter((id) => id !== table.get('id')))
                          }
                        >
                          <FontAwesomeIcon icon="trash" />
                        </BootstrapButton>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Well>
      )}
      <Button
        variant="primary"
        className="tw-flex"
        disabled={selected.length === 0}
        onClick={() => props.onClick(selected)}
      >
        Submit Selected Tables
      </Button>
    </>
  );
};

export default TableSelection;
