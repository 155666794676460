import React from 'react';
import { Button } from 'design';

const IntentConfirmation = ({ onClick }: { onClick: (confirm?: string) => void }) => {
  return (
    <div className="tw-flex tw-flex-wrap tw-gap-3">
      <Button onClick={() => onClick('yes')} className="tw-self-start">
        Accept suggested automation
      </Button>
      <Button variant="outline" onClick={() => onClick('no')}>
        Continue chat
      </Button>
    </div>
  );
};

export default IntentConfirmation;
