import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { URLS } from '@keboola/constants';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import { Alert, HelpBlock, Link } from 'design';
import { List, Map } from 'immutable';

import {
  KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION,
  KEBOOLA_ORACLE_TRANSFORMATION,
} from '@/constants/componentIds';
import { ioType } from '@/modules/components/Constants';
import { shouldDisableExternalBuckets } from '@/modules/components/helpers';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { backends } from '@/modules/storage/constants';
import { prepareUniqueDestination } from '@/modules/transformations/helpers';
import InputMappingSource from '@/modules/transformations/react/components/mapping/InputMappingSource';
import { DBT_COMPONENTS } from '@/modules/transformations-v2/constants';
import DataTypes from '@/modules/wr-db/templates/dataTypes';
import Checkbox from '@/react/common/Checkbox';
import ApplicationStore from '@/stores/ApplicationStore';
import DatatypesEditor from './datatypes/DatatypesEditor';
import {
  getCustomColumns,
  getDatatypesComponentId,
  getDefaultDatatype,
  prepareDatatypes,
} from './datatypes/helpers';
import ChangedSinceFilterInput from './ChangedSinceFilterInput';
import ColumnsSelectRow from './ColumnsSelectRow';
import DataFilterRow from './DataFilterRow';
import {
  canForceSourceBranch,
  getFileLocationHint,
  hasInputMappingTables,
  hasWorkspaceViewLoad,
} from './helpers';

const TableInputMappingEditor = createReactClass({
  propTypes: {
    componentId: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    editingNonExistentTable: PropTypes.bool.isRequired,
    isDestinationDuplicate: PropTypes.bool.isRequired,
    destinationType: PropTypes.oneOf(Object.values(ioType)).isRequired,
    otherMappings: PropTypes.object.isRequired,
    savedValue: PropTypes.object,
    replacementComponentId: PropTypes.string,
  },

  render() {
    if (DBT_COMPONENTS.includes(this.props.componentId)) {
      return this.renderSourceSelector();
    }

    if (
      [this.props.componentId, this.props.replacementComponentId].includes(
        KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION,
      )
    ) {
      return (
        <>
          {this.renderSourceSelector()}
          {this.renderDestinationInput()}
        </>
      );
    }

    const multipleTables = hasInputMappingTables(this.props.value);
    const isTypedTable = this.isTypedTable();
    const supportsOptions = !isTypedTable || this.isSnowflakeBackend();

    return (
      <>
        {this.renderSourceSelector()}
        {!multipleTables && (
          <>
            {this.renderDestinationInput()}
            {supportsOptions && (
              <>
                <ColumnsSelectRow
                  value={this.props.value}
                  disabled={this.props.disabled}
                  onChange={this.props.onChange}
                  allTables={this.props.tables}
                  componentId={this.props.componentId}
                  formType="normal"
                />
                {this.renderKeepInternalTimestampColumnCheckbox()}
              </>
            )}
          </>
        )}
        {supportsOptions && (
          <ChangedSinceFilterInput
            mapping={this.props.value}
            disabled={this.props.disabled}
            onChange={this.props.onChange}
            formType="normal"
          />
        )}
        {!multipleTables && supportsOptions && (
          <>
            <DataFilterRow
              value={this.props.value}
              disabled={this.props.disabled}
              onChange={this.props.onChange}
              allTables={this.props.tables}
              componentId={this.props.componentId}
              formType="normal"
            />
            {!isTypedTable && this.renderDatatypesEditor(this.props.disabled)}
          </>
        )}
        {isTypedTable && !this.isSnowflakeBackend() && (
          <Alert className="tw-mb-4">
            Currently, our filtering option in the input mapping section is not available for tables
            with defined data types.{' '}
            <Link href={`${URLS.USER_DOCUMENTATION}/storage/tables/data-types/#pros-and-cons`}>
              Learn more
            </Link>
          </Alert>
        )}
      </>
    );
  },

  renderSourceSelector() {
    return (
      <>
        {DevBranchesStore.isDevModeActive() && !ApplicationStore.hasProtectedDefaultBranch() && (
          <Alert variant="warning" title="Branch Input Mapping" className="tw-mb-5">
            In the development branch, storage tables created within the branch are used instead of
            those from production. Production tables are used only when there is no development
            branch alternative. You can find more information in our{' '}
            <Link href={`${URLS.USER_DOCUMENTATION}/components/branches/#data-pipelines`}>
              documentation
            </Link>
            .
          </Alert>
        )}
        {this.props.editingNonExistentTable && this.props.value.has('source') && (
          <Alert variant="warning" className="tw-mb-5">
            The source table does not exist
          </Alert>
        )}
        <div className="form-group">
          <ControlLabel>Source</ControlLabel>
          <InputMappingSource
            mode={this.props.mode}
            value={this.props.value}
            tables={this.props.tables}
            buckets={this.props.buckets}
            onChange={this.handleChangeSource}
            disabled={this.props.disabled}
            disableBuckets={this.props.componentId === KEBOOLA_ORACLE_TRANSFORMATION}
            isDestinationDuplicate={this.props.isDestinationDuplicate}
            disableExternalBuckets={shouldDisableExternalBuckets(this.props.componentId)}
            otherMappings={this.props.otherMappings}
          />
        </div>
        {hasWorkspaceViewLoad(this.props.componentId, this.props.replacementComponentId) && (
          <div className="form-group">
            <Checkbox
              checked={this.props.value.get('use_view', false)}
              onChange={(checked) => this.props.onChange(this.props.value.set('use_view', checked))}
            >
              Load using view
            </Checkbox>
          </div>
        )}
        {canForceSourceBranch() && (
          <div className="form-group">
            <Checkbox
              checked={!!this.props.value.get('source_branch_id')}
              onChange={(checked) => {
                this.props.onChange(
                  checked
                    ? this.props.value.set(
                        'source_branch_id',
                        DevBranchesStore.getDefaultBranchId(),
                      )
                    : this.props.value.delete('source_branch_id'),
                );
              }}
            >
              Force production table
            </Checkbox>
          </div>
        )}
      </>
    );
  },

  renderDestinationInput() {
    return (
      <FormGroup validationState={this.props.isDestinationDuplicate ? 'error' : null}>
        <ControlLabel>
          {this.props.destinationType === ioType.TABLE ? 'Table name' : 'File name'}
        </ControlLabel>
        <FormControl
          type="text"
          value={this.props.value.get('destination', '')}
          disabled={this.props.disabled}
          placeholder={this.props.destinationType === ioType.TABLE ? 'Table name' : 'File name'}
          onChange={this.handleChangeDestination}
          className={classNames({
            uppercase: this.props.componentId === KEBOOLA_ORACLE_TRANSFORMATION,
          })}
        />
        {this.props.isDestinationDuplicate && (
          <HelpBlock variant="danger">
            Duplicate destination <code>{this.props.value.get('destination')}</code>
          </HelpBlock>
        )}
        {!this.props.isDestinationDuplicate && this.props.destinationType === ioType.FILE && (
          <HelpBlock>
            The file will be available at{' '}
            <code>{`${getFileLocationHint(
              this.props.componentId,
            )}/tables/${this.getFileName()}`}</code>
          </HelpBlock>
        )}
      </FormGroup>
    );
  },

  renderKeepInternalTimestampColumnCheckbox() {
    const allMapings = this.props.otherMappings.push(this.props.savedValue).filter(Boolean);

    if (
      allMapings.every((mapping) => !mapping.has('keep_internal_timestamp_column')) ||
      allMapings.every((mapping) => mapping.get('keep_internal_timestamp_column') === false)
    ) {
      return null;
    }

    return (
      <FormGroup>
        <Checkbox
          checked={this.props.value.get('keep_internal_timestamp_column', false)}
          onChange={(checked) => {
            this.props.onChange(this.props.value.set('keep_internal_timestamp_column', checked));
          }}
        >
          Keep internal <code>_timestamp</code> column.
        </Checkbox>
      </FormGroup>
    );
  },

  renderDatatypesEditor(disabled) {
    const datatypesComponentId = getDatatypesComponentId(
      this.props.replacementComponentId || this.props.componentId,
    );
    const datatypes = DataTypes[datatypesComponentId];

    if (!datatypes) {
      return null;
    }

    return (
      <DatatypesEditor
        value={this.props.value}
        tables={this.props.tables}
        onChange={this.props.onChange}
        datatypesComponentId={datatypesComponentId}
        datatypes={prepareDatatypes(datatypes.typesList)}
        defaultDatatype={getDefaultDatatype(this.props.componentId, datatypes)}
        customColumns={getCustomColumns(this.props.componentId)}
        componentId={this.props.componentId}
        disabled={disabled}
      />
    );
  },

  handleChangeSource(selectedTables) {
    const selected = List.isList(selectedTables)
      ? selectedTables.count((value) => this.props.tables.has(value)) === 1
        ? selectedTables.first()
        : selectedTables
      : selectedTables;
    const table = this.props.tables.get(selected);
    const isSelectedMultipleOptions = List.isList(selected);
    const isFile = this.props.destinationType === ioType.FILE;
    const otherDestinations = this.props.otherMappings.map((mapping) => mapping.get('destination'));

    if (!isSelectedMultipleOptions && table) {
      const destination = `${table.get('displayName', '')}${isFile ? '.csv' : ''}`;
      let mapping = this.props.value.withMutations((mapping) => {
        mapping.set('source', selected);
        mapping.set('destination', destination);
        mapping.set('where_column', '');
        mapping.set('where_values', List());
        mapping.set('where_operator', 'eq');
        mapping.set('columns', List());
        mapping.delete('tables');
      });

      if (otherDestinations.includes(destination)) {
        mapping = mapping.set('destination', prepareUniqueDestination(mapping, otherDestinations));
      }

      return this.props.onChange(mapping);
    }

    const mapping = this.props.value.withMutations((mapping) => {
      mapping.set('source', selected);
      mapping.set(
        'tables',
        selected
          .filter((value) => this.props.tables.has(value))
          .map((tableId) =>
            Map({
              source: this.props.tables.getIn([tableId, 'id']),
              destination: `${this.props.tables.getIn([tableId, 'displayName'])}${
                isFile ? '.csv' : ''
              }`,
            }),
          ),
      );
      mapping.set('where_column', '');
      mapping.set('where_values', List());
      mapping.set('where_operator', 'eq');
      mapping.set('columns', List());
    });
    return this.props.onChange(mapping);
  },

  handleChangeDestination(e) {
    const value = this.props.value.set('destination', e.target.value.trim());
    return this.props.onChange(value);
  },

  getFileName() {
    if (this.props.value.get('destination') && this.props.value.get('destination') !== '') {
      return this.props.value.get('destination');
    }

    if (this.props.value.get('source') && this.props.value.get('source') !== '') {
      return this.props.value.get('source');
    }

    return '';
  },

  isTypedTable() {
    return this.props.tables.getIn([this.props.value.get('source'), 'isTyped'], false);
  },

  isSnowflakeBackend() {
    return (
      this.props.tables.getIn([this.props.value.get('source'), 'bucket', 'backend']) ===
      backends.SNOWFLAKE
    );
  },
});

export default TableInputMappingEditor;
