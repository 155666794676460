import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonToolbar,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Table,
  Well,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import { Alert } from 'design';
import { List, Map } from 'immutable';

import DataCatalogActions from '@/modules/data-catalog/actions';
import { SHARED_TYPES } from '@/modules/data-catalog/constants';
import { getDuplicateTableIds } from '@/modules/data-catalog/helpers';
import { filterProductionBuckets } from '@/modules/dev-branches/helpers';
import NativeTypesLabel from '@/modules/storage/components/NativeTypesLabel';
import PredefinedInput from '@/modules/storage/components/PredefinedInput';
import { EXTERNAL_DATASET_DISABLE_TOOLTIP, STAGE } from '@/modules/storage/constants';
import { validateBucketName } from '@/modules/storage/helpers';
import BucketLabels from '@/react/common/BucketLabels';
import Checkbox from '@/react/common/Checkbox';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import CreatedDate from '@/react/common/CreatedDate';
import ExternalTableLabel from '@/react/common/ExternalTableLabel';
import { isValidName } from '@/react/common/helpers';
import MarkedText from '@/react/common/MarkedText';
import ModalIcon from '@/react/common/ModalIcon';
import SearchBar from '@/react/common/SearchBar';
import Select from '@/react/common/Select';
import SortIcon from '@/react/common/SortIcon';
import Truncated from '@/react/common/Truncated';
import matchByWords from '@/utils/matchByWords';
import ShareWithSelect from './ShareWithSelect';

const FORM_STEPS = {
  TABLE_SELECTION: 'TABLE_SELECTION',
  SHARING_OPTIONS: 'SHARING_OPTIONS',
};

const FORM_INITIAL_STATE = {
  displayName: '',
  stage: 'in',
  description: '',
  tables: Map(),
  sharedType: SHARED_TYPES.ORGANIZATION_MEMBER,
};

const INITIAL_STATE = {
  step: FORM_STEPS.TABLE_SELECTION,
  tablesFilter: '',
  bucketsOpen: Map(),
  isSharing: false,
  error: null,
  warning: null,
  form: FORM_INITIAL_STATE,
  sortBy: 'name',
  sortDesc: false,
  targetUsers: List(),
  targetProjects: List(),
};

const ShareSelectedTables = createReactClass({
  propTypes: {
    onHide: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    sapiToken: PropTypes.instanceOf(Map).isRequired,
    allTables: PropTypes.instanceOf(Map).isRequired,
    allBuckets: PropTypes.instanceOf(Map).isRequired,
    availableUsersOptions: PropTypes.array.isRequired,
    availableProjectsOptions: PropTypes.array.isRequired,
  },

  getInitialState() {
    return INITIAL_STATE;
  },

  render() {
    if (this.state.step === FORM_STEPS.TABLE_SELECTION) {
      return (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Select Tables To Share</Modal.Title>
            <ModalIcon icon="table" color="green" bold />
          </Modal.Header>
          <Modal.Body>{this.renderTableSelection()}</Modal.Body>
          <Modal.Footer>{this.renderButtons()}</Modal.Footer>
        </>
      );
    }

    return (
      <Form onSubmit={this.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Set Sharing and Bucket Options</Modal.Title>
          <ModalIcon icon="table" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          {this.state.error && (
            <Alert variant="error" className="tw-mb-5">
              {this.state.error}
            </Alert>
          )}
          {this.renderSharingOptions()}
        </Modal.Body>
        <Modal.Footer>{this.renderSubmitButton()}</Modal.Footer>
      </Form>
    );
  },

  renderTableSelection() {
    const duplicatedTables = getDuplicateTableIds(this.state.form.tables);
    const buckets = this.filteredTables();
    const tables = buckets.flatten(1);
    const isAllTablesSelected = tables.every(this.isTableSelected);
    const isSomeTablesSelected = !isAllTablesSelected && tables.some(this.isTableSelected);

    return (
      <>
        <SearchBar
          autoFocus
          className="as-input condensed"
          onChange={this.handleTableFilterQuery}
          query={this.state.tablesFilter}
          placeholder="Search buckets or tables"
        />
        {!buckets.isEmpty() ? (
          <Table hover className="react-table stretch-modal">
            <thead>
              <tr>
                <th>
                  <Checkbox
                    checked={isAllTablesSelected}
                    indeterminate={isSomeTablesSelected}
                    onChange={() => {
                      isAllTablesSelected || isSomeTablesSelected
                        ? this.deselectAllTable(tables)
                        : this.selectAllTable(tables);
                    }}
                  />
                  <span
                    className="clickable tw-ml-3"
                    title="Sort by name"
                    onClick={() =>
                      this.setState({
                        sortBy: 'name',
                        sortDesc: this.state.sortBy === 'name' ? !this.state.sortDesc : false,
                      })
                    }
                  >
                    Table Name
                    <SortIcon
                      className="icon-addon-left"
                      isSorted={this.state.sortBy === 'name'}
                      isSortedDesc={this.state.sortDesc}
                    />
                  </span>
                </th>
                <th>
                  <span
                    className="clickable"
                    title="Sort by last change"
                    onClick={() =>
                      this.setState({
                        sortBy: 'last-change',
                        sortDesc:
                          this.state.sortBy === 'last-change' ? !this.state.sortDesc : false,
                      })
                    }
                  >
                    <SortIcon
                      isSorted={this.state.sortBy === 'last-change'}
                      isSortedDesc={this.state.sortDesc}
                    />
                    Last change
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {buckets
                .sort((tablesA, tablesB) => {
                  const bucketA = tablesA.first().get('bucket');
                  const bucketB = tablesB.first().get('bucket');
                  const desc = this.state.sortDesc ? -1 : 1;

                  return this.state.sortBy === 'last-change'
                    ? this.compareLastChange(bucketA, bucketB) * desc
                    : this.compareName(bucketA, bucketB) * desc;
                })
                .map((tables, name) => (
                  <React.Fragment key={name}>
                    {this.renderBucketRow(name, tables)}
                    {(this.state.bucketsOpen.has(name) || this.state.tablesFilter !== '') &&
                      tables
                        .sort((tableA, tableB) => {
                          const desc = this.state.sortDesc ? -1 : 1;

                          return this.state.sortBy === 'last-change'
                            ? this.compareLastChange(tableA, tableB) * desc
                            : this.compareName(tableA, tableB) * desc;
                        })
                        .map(this.renderTableRow)
                        .toArray()}
                  </React.Fragment>
                ))
                .toArray()}
            </tbody>
          </Table>
        ) : (
          <p>No buckets or tables are matching your criteria.</p>
        )}
        {duplicatedTables.count() > 0 && (
          <Alert variant="warning">
            <p>Sharing multiple tables with the same name is not supported.</p>
            <p>Duplicate tables:</p>
            <ul>{duplicatedTables.map((tableId) => <li key={tableId}>{tableId}</li>).toArray()}</ul>
          </Alert>
        )}
      </>
    );
  },

  renderSharingOptions() {
    return (
      <>
        <div className="tw-grid tw-grid-cols-2 tw-gap-8">
          <div>
            <PredefinedInput
              autoFocus
              label="Shared Bucket Name"
              entity="bucketName"
              value={this.state.form.displayName}
              warning={this.state.warning}
              onChange={this.handleDisplayName}
            />
            <FormGroup controlId="formDescriptionConrol">
              <ControlLabel>Description</ControlLabel>
              <FormControl
                rows={3}
                componentClass="textarea"
                value={this.state.form.description}
                onChange={(e) =>
                  this.setState({ form: { ...this.state.form, description: e.target.value } })
                }
              />
            </FormGroup>
            <FormGroup controlId="formStageControl">
              <ControlLabel>Data Stage</ControlLabel>
              <Select
                clearable={false}
                value={this.state.form.stage}
                onChange={(value) =>
                  this.setState(
                    { form: { ...this.state.form, stage: value } },
                    this.validateDisplayName,
                  )
                }
                options={Object.values(STAGE).map((stage) => ({
                  value: stage,
                  label: stage.toUpperCase(),
                }))}
              />
            </FormGroup>
          </div>
          <FormGroup controlId="formBucketTablesControl">
            <ControlLabel>Selected tables</ControlLabel>
            <Well className="m-0 overflow-auto">
              {this.state.form.tables
                .map((table, tableId) => (
                  <p key={tableId} className="no-wrap">
                    <FontAwesomeIcon icon="table" className="icon-addon-right text-muted" />
                    {table}
                  </p>
                ))
                .toArray()}
            </Well>
          </FormGroup>
        </div>
        <ShareWithSelect
          value={this.state.form.sharedType}
          onChange={(type) => this.setState({ form: { ...this.state.form, sharedType: type } })}
          hasUsersOptions={this.props.availableUsersOptions.length > 0}
          hasProjectsOptions={this.props.availableProjectsOptions.length > 0}
        />
        {this.renderAdditionalControls()}
      </>
    );
  },

  renderAdditionalControls() {
    if (this.state.form.sharedType === SHARED_TYPES.SELECTED_PEOPLE) {
      return (
        <Select
          multi
          placeholder="Select users"
          options={this.props.availableUsersOptions}
          value={this.state.targetUsers}
          onChange={(targetUsers) => this.setState({ targetUsers })}
        />
      );
    }

    if (this.state.form.sharedType === SHARED_TYPES.SELECTED_PROJECT) {
      return (
        <Select
          multi
          placeholder="Select projects"
          options={this.props.availableProjectsOptions}
          value={this.state.targetProjects}
          onChange={(targetProjects) => this.setState({ targetProjects })}
        />
      );
    }

    return null;
  },

  renderBucketRow(name, tables) {
    const isBucketOpen = this.state.bucketsOpen.has(name) || this.state.tablesFilter !== '';
    const isAllTablesSelected = tables.every(this.isTableSelected);
    const isSomeTablesSelected = !isAllTablesSelected && tables.some(this.isTableSelected);
    const bucket = tables.first().get('bucket');
    const hasExternalSchema = bucket.get('hasExternalSchema', false);

    return (
      <tr
        className={classNames({ clickable: !hasExternalSchema })}
        onClick={() => {
          if (hasExternalSchema) {
            return;
          }

          this.setState({
            bucketsOpen: isBucketOpen
              ? this.state.bucketsOpen.delete(name)
              : this.state.bucketsOpen.set(name, true),
          });
        }}
      >
        <td>
          <div className="tw-flex tw-items-center tw-justify-start">
            <Checkbox
              checked={isAllTablesSelected}
              indeterminate={isSomeTablesSelected}
              onChange={() => {
                isAllTablesSelected || isSomeTablesSelected
                  ? this.deselectAllTable(tables)
                  : this.selectAllTable(tables);
              }}
              disabled={hasExternalSchema}
              tooltip={hasExternalSchema ? EXTERNAL_DATASET_DISABLE_TOOLTIP.DATA_CATALOG_TABLE : ''}
            />
            <FontAwesomeIcon
              fixedWidth
              icon={isBucketOpen ? 'folder-open' : 'folder'}
              className="tw-ml-3 tw-mr-3 tw-text-neutral-400"
            />
            <Truncated
              className="tw-mr-2.5"
              tooltip={bucket.get('displayName')}
              text={
                <MarkedText source={bucket.get('displayName')} mark={this.state.tablesFilter} />
              }
            />
            <BucketLabels bucket={bucket} withSharedLabel={false} />
          </div>
        </td>
        <td>
          <CreatedDate createdTime={this.getLastChange(bucket)} />
        </td>
      </tr>
    );
  },

  renderTableRow(table, tableId) {
    return (
      <tr key={tableId} onClick={() => this.toggleTableSelection(table)} className="clickable">
        <td>
          <div className="tw-ml-4 tw-flex tw-items-center tw-justify-start">
            <Checkbox
              onChange={() => this.toggleTableSelection(table)}
              checked={this.state.form.tables.has(tableId)}
            />
            <FontAwesomeIcon icon="table" className="tw-ml-3 tw-mr-3 tw-text-neutral-400" />
            <Truncated
              tooltip={table.get('displayName')}
              text={<MarkedText source={table.get('displayName')} mark={this.state.tablesFilter} />}
            />
            <NativeTypesLabel isTyped={table.get('isTyped')} className="tw-ml-2.5" />
            <ExternalTableLabel
              tableType={table.get('tableType')}
              hasExternalSchema={table.getIn(['bucket', 'hasExternalSchema'])}
            />
          </div>
        </td>
        <td>
          <CreatedDate createdTime={this.getLastChange(table)} />
        </td>
      </tr>
    );
  },

  renderButtons() {
    return (
      <ButtonToolbar className="block">
        <Button onClick={this.props.onBack}>
          <FontAwesomeIcon icon="arrow-left" fixedWidth />
        </Button>
        <Button bsStyle="success" onClick={this.goToNextStep} disabled={this.isNextDisabled()}>
          <FontAwesomeIcon icon="arrow-right" fixedWidth className="icon-addon-right" />
          Next step
        </Button>
      </ButtonToolbar>
    );
  },

  renderSubmitButton() {
    return (
      <ButtonToolbar className="block">
        <Button onClick={this.goToPreviousStep}>
          <FontAwesomeIcon icon="arrow-left" fixedWidth />
        </Button>
        <ConfirmButtons
          block
          saveButtonType="submit"
          saveLabel={this.state.isSharing ? 'Sharing bucket...' : 'Share a bucket'}
          isSaving={this.state.isSharing}
          isDisabled={this.isDisabled()}
        />
      </ButtonToolbar>
    );
  },

  isNextDisabled() {
    return (
      !this.state.form.tables.count() || getDuplicateTableIds(this.state.form.tables).count() > 0
    );
  },

  filteredTables() {
    let allBuckets = filterProductionBuckets(this.props.allBuckets)
      .map((bucket, bucketId) =>
        this.props.allTables.filter((table) => table.getIn(['bucket', 'id']) === bucketId),
      )
      .filter((tables) => tables.count() > 0);

    if (this.state.tablesFilter) {
      return allBuckets
        .map((allTables, bucketNameWithStage) => {
          const tables = allTables.filter((table) =>
            matchByWords(table.get('displayName'), this.state.tablesFilter),
          );

          if (!tables.count() && matchByWords(bucketNameWithStage, this.state.tablesFilter)) {
            return allTables;
          }

          return tables;
        })
        .filter((tables) => tables.count() > 0);
    }

    return allBuckets;
  },

  handleTableFilterQuery(query) {
    this.setState({ tablesFilter: query });
  },

  handleDisplayName(displayName) {
    this.setState({ form: { ...this.state.form, displayName } }, this.validateDisplayName);
  },

  validateDisplayName() {
    this.setState({
      warning: validateBucketName(
        this.state.form.displayName,
        this.state.form.stage,
        this.props.allBuckets,
      ),
    });
  },

  selectAllTable(bucketTables) {
    let tables = this.state.form.tables;

    bucketTables.forEach((table, tableId) => {
      if (!tables.has(tableId) && !table.getIn(['bucket', 'hasExternalSchema'], false)) {
        tables = tables.set(tableId, table.get('displayName'));
      }
    });

    this.setState({ form: { ...this.state.form, tables } });
  },

  deselectAllTable(bucketTables) {
    this.setState({
      form: {
        ...this.state.form,
        tables: this.state.form.tables.filter((table, tableId) => !bucketTables.has(tableId)),
      },
    });
  },

  toggleTableSelection(table) {
    const isTableSelected = this.state.form.tables.has(table.get('id'));

    this.setState({
      form: {
        ...this.state.form,
        tables: isTableSelected
          ? this.state.form.tables.delete(table.get('id'))
          : this.state.form.tables.set(table.get('id'), table.get('displayName')),
      },
    });
  },

  handleSubmit(e) {
    e.preventDefault();

    const formData = {
      ...this.state.form,
      name: this.state.form.displayName,
      targetProjectIds: this.state.targetProjects.toJS(),
      targetUsers: this.state.targetUsers.toJS(),
    };

    this.setState({ isSharing: true });
    DataCatalogActions.shareBucket(formData, this.props.sapiToken)
      .then(this.props.onHide)
      .catch((error) => {
        this.setState({ error, isSharing: false });
        return null;
      });
  },

  goToNextStep() {
    this.setState({ step: FORM_STEPS.SHARING_OPTIONS });
  },

  goToPreviousStep() {
    this.setState({ step: FORM_STEPS.TABLE_SELECTION });
  },

  getLastChange(entity) {
    return entity.get('lastChangeDate') || entity.get('created');
  },

  compareName(a, b) {
    return a.get('displayName').toLowerCase().localeCompare(b.get('displayName').toLowerCase());
  },

  compareLastChange(a, b) {
    return new Date(this.getLastChange(b)).getTime() - new Date(this.getLastChange(a)).getTime();
  },

  isTableSelected(table) {
    return this.state.form.tables.has(table.get('id'));
  },

  isDisabled() {
    if (
      this.state.isSharing ||
      !this.state.form.displayName ||
      !isValidName(this.state.form.displayName) ||
      this.state.warning
    ) {
      return true;
    }

    if (this.state.form.sharedType === SHARED_TYPES.SELECTED_PEOPLE) {
      return !this.state.targetUsers.count();
    }

    if (this.state.form.sharedType === SHARED_TYPES.SELECTED_PROJECT) {
      return !this.state.targetProjects.count();
    }

    return false;
  },
});

export default ShareSelectedTables;
