import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useReactFlow } from '@xyflow/react';
import classNames from 'classnames';

import type { CustomEdge, CustomNode } from '@/modules/lineage/rfTypes';
import FilterPanel from '@/react/common/FilterPanel';
import matchByWords from '@/utils/matchByWords';
import { GraphContext } from './GraphContext';
import { getFitViewOptions } from './initConfig';

const Search = (props: { inModal: boolean }) => {
  const { searchQuery, onSearch } = useContext(GraphContext);
  const { fitView, getNodes } = useReactFlow<CustomNode, CustomEdge>();
  const [focusNode, setFocusNode] = useState<number | null>(null);

  const matchedNodes = React.useMemo(() => {
    if (!searchQuery) {
      return [];
    }

    return getNodes().filter(({ data }) => {
      return matchByWords('displayName' in data ? data.displayName : data.name, searchQuery);
    });
  }, [searchQuery, getNodes]);

  React.useEffect(() => {
    const node = focusNode && matchedNodes[focusNode - 1];

    if (node) {
      fitView(getFitViewOptions({ id: node.id }));
    }
  }, [focusNode, matchedNodes, fitView]);

  const handleSearch = React.useCallback(
    (value: string) => {
      onSearch(value);
      setFocusNode(value ? 1 : null);
    },
    [onSearch],
  );

  const withControls = !!searchQuery;
  const results = matchedNodes.length;

  return (
    <span className="tw-relative">
      <FilterPanel
        asBox={false}
        placeholder="Search in Data lineage"
        className="tw-flex tw-items-center"
        searchBarClassName={classNames(
          'condensed nodrag tw-mb-0 tw-w-80',
          props.inModal ? 'as-input' : 'tw-rounded tw-border tw-border-solid tw-border-neutral-200',
          { '[&_input]:!tw-pr-40': withControls },
        )}
        onChange={handleSearch}
      />
      {withControls && (
        <div className="tw-absolute tw-right-12 tw-top-1 tw-z-10 tw-inline-flex tw-h-8 tw-items-center tw-gap-3">
          <span className="tw-text-xs tw-text-neutral-400">
            {results > 0 ? focusNode : 0}/{results}
          </span>
          <span className="tw-inline-flex tw-h-4 tw-w-[1px] tw-bg-neutral-200" />
          <Button
            bsStyle="link"
            className="btn-link-inline dark muted"
            onClick={() => {
              if (focusNode) {
                setFocusNode(focusNode === results ? 1 : focusNode + 1);
              }
            }}
            disabled={!results}
          >
            <FontAwesomeIcon icon="angle-up" fixedWidth />
          </Button>
          <Button
            bsStyle="link"
            className="btn-link-inline dark muted"
            onClick={() => {
              if (focusNode) {
                setFocusNode(focusNode < 2 ? results : focusNode - 1);
              }
            }}
            disabled={!results}
          >
            <FontAwesomeIcon icon="angle-down" fixedWidth className="!tw-bottom-0" />
          </Button>
        </div>
      )}
    </span>
  );
};

export default Search;
