import Promise from 'bluebird';
import { Map } from 'immutable';
import qs from 'qs';
import { rtrim, strLeft } from 'underscore.string';

import { INTERNAL_PREFIX } from '@/constants/helpers';
import { SHARED_TYPES } from '@/modules/data-catalog/constants';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import ApplicationStore from '@/stores/ApplicationStore';
import request from '@/utils/request';
import StorageBucketsStore from './stores/StorageBucketsStore';
import StorageTablesStore from './stores/StorageTablesStore';
import { ObjectTypes } from './MetadataConstants';

const getBranchId = (options) => {
  return DevBranchesStore.isDevModeActive() && !options?.forceProduction
    ? DevBranchesStore.getCurrentId()
    : DevBranchesStore.getDefaultBranchId();
};

export const withProductionFallback = (entityId, request) => {
  const branchId = StorageTablesStore.hasTable(entityId)
    ? StorageTablesStore.getTable(entityId).getIn(['bucket', 'idBranch'])
    : StorageBucketsStore.getBucket(entityId, Map()).get('idBranch');
  const isProduction = branchId === DevBranchesStore.getDefaultBranchId();

  return request({ forceProduction: isProduction }).catch((error) => {
    if (!ApplicationStore.hasProtectedDefaultBranch() || isProduction) {
      throw error;
    }

    return request({ forceProduction: true });
  });
};

const prepareProductionAndBranchData = ({ branch, production }) => {
  const branchIds = branch.map((row) => row.id);
  return branch.concat(production.filter((row) => !branchIds.includes(row.id)));
};

const createUrl = (path, options) => {
  let baseUrl = `${ApplicationStore.getSapiUrl()}/v2/storage`;

  const branchEndpoints = ['components', 'workspaces', 'search', 'events'].concat(
    ApplicationStore.hasProtectedDefaultBranch()
      ? ['buckets', 'tables', 'columns', 'files', 'snapshots']
      : [],
  );

  if (!branchEndpoints.includes(strLeft(path, '/'))) {
    return rtrim(`${baseUrl}/${path}`, '/');
  }

  return rtrim(`${baseUrl}/branch/${getBranchId(options)}/${path}`, '/');
};

export const createRequest = (method, path, options) => {
  return request(method, createUrl(path, options)).set(
    'X-StorageApi-Token',
    ApplicationStore.getSapiTokenString(),
  );
};

export default {
  loadStackInfo() {
    return request('GET', createUrl(''))
      .query({ exclude: 'componentDetails' })
      .promise()
      .then((response) => response.body);
  },

  verifyToken() {
    return createRequest('GET', 'tokens/verify')
      .promise()
      .then((response) => response.body);
  },

  generateUniqueId() {
    return createRequest('POST', 'tickets')
      .promise()
      .then((response) => response.body?.id);
  },

  getBuckets() {
    const loadBuckets = (options) => {
      return createRequest('GET', 'buckets', options)
        .query({ include: 'metadata,linkedBuckets' })
        .promise()
        .then((response) => response.body);
    };

    if (ApplicationStore.hasProtectedDefaultBranch() && DevBranchesStore.isDevModeActive()) {
      return Promise.props({
        branch: loadBuckets(),
        production: loadBuckets({ forceProduction: true }),
      }).then(prepareProductionAndBranchData);
    }

    return loadBuckets();
  },

  getTable(tableId) {
    return withProductionFallback(tableId, (options) => {
      return createRequest('GET', `tables/${tableId}`, options)
        .promise()
        .then((response) => response.body);
    });
  },

  getBucket(bucketId) {
    return withProductionFallback(bucketId, (options) => {
      return createRequest('GET', `buckets/${bucketId}`, options)
        .query({ include: 'metadata,columns' })
        .promise()
        .then((response) => response.body);
    });
  },

  getProductionTable(tableId) {
    return createRequest('GET', `tables/${tableId}`, { forceProduction: true })
      .promise()
      .then((response) => response.body);
  },

  getTables(params) {
    const loadTables = (options, include = 'columns,metadata') => {
      return createRequest('GET', 'tables', options)
        .query({ include, ...params })
        .promise()
        .then((response) => response.body);
    };

    if (ApplicationStore.hasProtectedDefaultBranch() && DevBranchesStore.isDevModeActive()) {
      return Promise.props({
        branch: loadTables(),
        production: loadTables({ forceProduction: true }, 'buckets,columns,metadata'),
      }).then(prepareProductionAndBranchData);
    }

    return loadTables();
  },

  globalSearch(params) {
    return createRequest('GET', 'global-search')
      .query(qs.stringify(params, { encodeValuesOnly: true }))
      .promise()
      .then((response) => response.body);
  },

  updateToken(tokenId, params) {
    return createRequest('PUT', `tokens/${tokenId}`)
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  createToken(params) {
    return createRequest('POST', 'tokens')
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  createLegacyTriggerToken(componentId) {
    return this.createToken({
      canManageBuckets: false,
      canReadAllFileUploads: false,
      componentAccess: [componentId],
      description: 'Token for triggering an orchestrator',
    });
  },

  createTriggerToken(configId) {
    return this.createToken({
      ...(ApplicationStore.hasProtectedDefaultBranch()
        ? { canCreateJobs: true }
        : { canManageBuckets: true, canReadAllFileUploads: true }),
      description: `${INTERNAL_PREFIX} Token for triggering ${configId}`,
    });
  },

  deleteToken(tokenId) {
    return createRequest('DELETE', `tokens/${tokenId}`)
      .promise()
      .then((response) => response.body);
  },

  refreshToken(tokenId) {
    return createRequest('POST', `tokens/${tokenId}/refresh`)
      .promise()
      .then((response) => response.body);
  },

  shareToken(tokenId, email, message) {
    const params = {
      recipientEmail: email,
      message: message,
    };
    return createRequest('POST', `tokens/${tokenId}/share`)
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  sharedBuckets() {
    return createRequest('GET', 'shared-buckets')
      .query({ include: 'metadata' })
      .promise()
      .then((response) => response.body);
  },

  getTokens() {
    return createRequest('GET', 'tokens')
      .promise()
      .then((response) => response.body);
  },

  getFiles(params) {
    return createRequest('GET', 'files')
      .query(params)
      .promise()
      .then((response) => response.body);
  },

  getFilesWithRetry(params) {
    const maxRetries = 3; // total attempts = max retries + 1

    const withRetry = (attempt = 1) => {
      return this.getFiles(params)
        .then((files) => {
          if (files.length === 0 && attempt <= maxRetries) {
            return Promise.reject(new Error('No files found yet'));
          }
          return Promise.resolve(files);
        })
        .catch(() => {
          return new Promise((res) => {
            return setTimeout(res, Math.pow(2, attempt) * 500);
          }).then(() => {
            return withRetry(attempt + 1);
          });
        });
    };
    return withRetry();
  },

  deleteFile(fileId) {
    return createRequest('DELETE', `files/${fileId}`)
      .promise()
      .then((response) => response.body);
  },

  getRunIdStats(runId) {
    return createRequest('GET', 'stats')
      .query({ runId })
      .promise()
      .then((response) => response.body);
  },

  loadTableSnapshots(tableId, params) {
    return withProductionFallback(tableId, (options) => {
      return createRequest('GET', `tables/${tableId}/snapshots`, options)
        .query(params)
        .promise()
        .then((response) => response.body);
    });
  },

  tableDataJsonPreview(tableId, params) {
    return withProductionFallback(tableId, (options) => {
      const queryParams = { ...params, format: 'json' };
      return createRequest('GET', `tables/${tableId}/data-preview`, options)
        .type('json')
        .query(
          queryParams.whereFilters || queryParams.orderBy
            ? qs.stringify(queryParams, { encodeValuesOnly: true })
            : queryParams,
        )
        .promise()
        .then((response) => response.body);
    });
  },

  prepareFileUpload(params) {
    return createRequest('POST', 'files/prepare')
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  createBucket(params, options) {
    return createRequest('POST', 'buckets')
      .query(options?.async ? { async: true } : {})
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  refreshExternalBucket(bucketId) {
    return createRequest('PUT', `buckets/${bucketId}/refresh`)
      .promise()
      .then((response) => response.body);
  },

  deleteBucket(bucketId, params) {
    return createRequest('DELETE', `buckets/${bucketId}`)
      .query(params)
      .promise()
      .then((response) => response.body);
  },

  updateBucket(bucketId, params) {
    return createRequest('PUT', `buckets/${bucketId}`)
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  registerBucket(params) {
    return createRequest('POST', 'buckets/register')
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  getBucketRegistrationGuide(params) {
    return createRequest('GET', 'buckets/register-guide')
      .query(qs.stringify(params))
      .promise()
      .then((response) => response.body);
  },

  shareBucket(sharingType, bucketId, params) {
    switch (sharingType) {
      case SHARED_TYPES.ORGANIZATION_MEMBER:
        return this.shareBucketToOrganization(bucketId, params);

      case SHARED_TYPES.PROJECT_MEMBERS:
        return this.shareBucketToOrganizationProject(bucketId, params);

      case SHARED_TYPES.SELECTED_PROJECT:
        return this.shareBucketToProjects(bucketId, params);

      case SHARED_TYPES.SELECTED_PEOPLE:
        return this.shareBucketToUsers(bucketId, params);
    }
  },

  shareBucketToOrganization(bucketId, params) {
    return createRequest('POST', `buckets/${bucketId}/share-organization`)
      .query({ async: true })
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  shareBucketToOrganizationProject(bucketId, params) {
    return createRequest('POST', `buckets/${bucketId}/share-organization-project`)
      .query({ async: true })
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  shareBucketToProjects(bucketId, params) {
    return createRequest('POST', `buckets/${bucketId}/share-to-projects`)
      .query({ async: true })
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  shareBucketToUsers(bucketId, params) {
    return createRequest('POST', `buckets/${bucketId}/share-to-users`)
      .query({ async: true })
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  unshareBucket(bucketId, params) {
    return createRequest('DELETE', `buckets/${bucketId}/share`)
      .query({ async: true })
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  forceUnlinkBucket(bucketId, linkedProjectId) {
    return createRequest('DELETE', `buckets/${bucketId}/links/${linkedProjectId}`)
      .query({ async: true })
      .promise()
      .then((response) => response.body);
  },

  createTable(bucketId, params) {
    return createRequest('POST', `buckets/${bucketId}/tables-async`)
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  createTableDefinition(bucketId, params) {
    return createRequest('POST', `buckets/${bucketId}/tables-definition`)
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  truncateTable(tableId) {
    return createRequest('DELETE', `tables/${tableId}/rows`)
      .send({ allowTruncate: true })
      .promise()
      .then((response) => response.body);
  },

  pullTable(tableId) {
    return createRequest('POST', `tables/${tableId}/pull`)
      .promise()
      .then((response) => response.body);
  },

  deleteTable(tableId, params) {
    return createRequest('DELETE', `tables/${tableId}`)
      .query(params)
      .promise()
      .then((response) => response.body);
  },

  createAliasTable(bucketId, params) {
    return createRequest('POST', `buckets/${bucketId}/table-aliases`)
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  setAliasTableFilter(tableId, params) {
    return createRequest('POST', `tables/${tableId}/alias-filter`)
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  removeAliasTableFilter(tableId) {
    return createRequest('DELETE', `tables/${tableId}/alias-filter`)
      .promise()
      .then((response) => response.body);
  },

  updateTable(tableId, params) {
    return createRequest('PUT', `tables/${tableId}`)
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  loadTable(tableId, params) {
    return createRequest('POST', `tables/${tableId}/import-async`)
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  createTablePrimaryKey(tableId, params) {
    return createRequest('POST', `tables/${tableId}/primary-key`)
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  removeTablePrimaryKey(tableId) {
    return createRequest('DELETE', `tables/${tableId}/primary-key`)
      .promise()
      .then((response) => response.body);
  },

  createSnapshot(tableId, params) {
    return createRequest('POST', `tables/${tableId}/snapshots`)
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  deleteSnapshot(snapshotId) {
    return createRequest('DELETE', `snapshots/${snapshotId}`)
      .promise()
      .then((response) => response.body);
  },

  addTableColumn(tableId, params) {
    return createRequest('POST', `tables/${tableId}/columns`)
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  deleteTableColumn(tableId, column, params) {
    return createRequest('DELETE', `tables/${tableId}/columns/${column}`)
      .query(params)
      .promise()
      .then((response) => response.body);
  },

  saveBucketMetadata(bucketId, data, provider) {
    return createRequest('POST', `buckets/${bucketId}/metadata`)
      .send(this.prepareMetadataPayload(data, provider))
      .promise()
      .then((response) => response.body);
  },

  saveTableMetadata(tableId, data, provider) {
    return createRequest('POST', `tables/${tableId}/metadata`)
      .send(this.prepareMetadataPayload(data, provider))
      .promise()
      .then((response) => response.body);
  },

  saveColumnMetadata(columnId, data, provider) {
    return createRequest('POST', `columns/${columnId}/metadata`)
      .send(this.prepareMetadataPayload(data, provider))
      .promise()
      .then((response) => response.body);
  },

  saveMetadata(objectType, objectId, data) {
    return createRequest('POST', this.getMetadataSaveUrl(objectType, objectId))
      .send(this.prepareMetadataPayload(data))
      .promise()
      .then((response) => response.body);
  },

  saveBranchesMetadata(branchId, key, value) {
    return createRequest('POST', `branch/${branchId}/metadata`)
      .send({ metadata: [{ key, value }] })
      .promise()
      .then((response) => response.body);
  },

  deleteMetadata(objectType, objectId, metadataId) {
    return createRequest('DELETE', `${this.getMetadataSaveUrl(objectType, objectId)}/${metadataId}`)
      .promise()
      .then((response) => response.body);
  },

  getDevBranchMetaData(id) {
    return createRequest('GET', `branch/${id}/metadata`)
      .promise()
      .then((response) => response.body);
  },

  getJob(jobId) {
    return createRequest('GET', `jobs/${jobId}`)
      .promise()
      .then((response) => response.body);
  },

  getJobs(params) {
    return createRequest('GET', 'jobs')
      .query(params)
      .promise()
      .then((response) => response.body);
  },

  getMetadataSaveUrl(objectType, objectId) {
    switch (objectType) {
      case ObjectTypes.BUCKET:
        return `buckets/${objectId}/metadata`;
      case ObjectTypes.TABLE:
        return `tables/${objectId}/metadata`;
      case ObjectTypes.COLUMN:
        return `columns/${objectId}/metadata`;
      case ObjectTypes.BRANCH:
        return `branch/${objectId}/metadata`;
      default:
    }
  },

  prepareMetadataPayload(data, provider = 'user') {
    let metadata = [];

    data.map((value, key) => {
      if (typeof value !== 'undefined') {
        metadata = metadata.concat({ key: key, value: value });
      }
    });

    return {
      provider: provider,
      metadata: metadata,
    };
  },

  createTrigger(data) {
    return createRequest('POST', 'triggers')
      .send(data)
      .promise()
      .then((response) => response.body);
  },

  listTriggers() {
    return createRequest('GET', 'triggers')
      .promise()
      .then((response) => response.body);
  },

  updateTrigger(id, data) {
    return createRequest('PUT', `triggers/${id}`)
      .send(data)
      .promise()
      .then((response) => response.body);
  },

  deleteTrigger(id) {
    return createRequest('DELETE', `triggers/${id}`)
      .promise()
      .then((response) => response.body);
  },

  getDevBranches() {
    return createRequest('GET', 'dev-branches')
      .promise()
      .then((response) => response.body);
  },

  createDevBranch(params) {
    return createRequest('POST', 'dev-branches')
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  updateDevBranch(id, params) {
    return createRequest('PUT', `dev-branches/${id}`)
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  deleteDevBranch(id) {
    return createRequest('DELETE', `dev-branches/${id}`)
      .promise()
      .then((response) => response.body);
  },

  loadMergeRequests() {
    return createRequest('GET', 'merge-request')
      .promise()
      .then((response) => response.body);
  },

  loadMergeRequest(id) {
    return createRequest('GET', `merge-request/${id}`)
      .promise()
      .then((response) => response.body);
  },

  createDevBranchMergeRequest(title, description) {
    return createRequest('POST', 'merge-request')
      .send({
        title,
        description,
        branchFromId: DevBranchesStore.getCurrentId(),
        branchIntoId: DevBranchesStore.getDefaultBranchId(),
      })
      .promise()
      .then((response) => response.body);
  },

  updateDevBranchMergeRequest(id, title, description) {
    return createRequest('PUT', `merge-request/${id}`)
      .send({ title, description })
      .promise()
      .then((response) => response.body);
  },

  submitDevBranchChanges(id) {
    return createRequest('PUT', `merge-request/${id}/request-review`)
      .promise()
      .then((response) => response.body);
  },

  approveDevBranchChanges(id) {
    return createRequest('PUT', `merge-request/${id}/approve`)
      .promise()
      .then((response) => response.body);
  },

  rejectDevBranchChanges(id) {
    return createRequest('PUT', `merge-request/${id}/request-changes`)
      .promise()
      .then((response) => response.body);
  },

  mergeDevBranchChanges(id) {
    return createRequest('PUT', `merge-request/${id}/merge`)
      .promise()
      .then((response) => response.body);
  },

  getComponent(componentId) {
    return createRequest('GET', `components/${componentId}`)
      .promise()
      .then((response) => response.body);
  },

  addFileTag(fileId, tag) {
    return createRequest('POST', `files/${fileId}/tags`)
      .send({
        tag,
      })
      .promise()
      .then((response) => response.body);
  },

  deleteFileTag(fileId, tag) {
    return createRequest('DELETE', `files/${fileId}/tags/${tag}`)
      .send({
        tag,
      })
      .promise()
      .then((response) => response.body);
  },
};
