import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HelpBlock } from 'design';
import { List, Map } from 'immutable';

import Checkbox from '@/react/common/Checkbox';
import Loader from '@/react/common/Loader';
import Select from '@/react/common/Select';
import string from '@/utils/string';
import RefreshTables from './RefreshTables';

class Quickstart extends React.Component {
  state = {
    loadColumns: true,
  };

  render() {
    if (this.props.isLoadingSourceTables) {
      return (
        <div className="box">
          <div className="box-content text-center">
            <Loader className="icon-addon-right" />
            Fetching list of tables from source database...
          </div>
        </div>
      );
    }

    const tables = this.props.quickstart.get('tables', List());

    return (
      <div className="box">
        <div className="box-content">
          <p>
            Select the tables you&apos;d like to import to autogenerate your configuration.
            Selecting a schema will add all tables from the schema.
          </p>
          <FormGroup>
            <Select
              multi
              placeholder="Select tables to copy"
              value={tables.map((table) => `${table.get('schema')}.${table.get('tableName')}`)}
              onChange={this.handleSelectChange}
              options={this.getOptions()}
            />
            <RefreshTables refresh={this.props.refreshMethod} />
          </FormGroup>
          {this.props.supportSplitLoading && (
            <FormGroup>
              <Checkbox
                checked={this.state.loadColumns}
                onChange={(loadColumns) => this.setState({ loadColumns })}
              >
                Set primary key based on source table
              </Checkbox>
              <HelpBlock>
                Data is retrieved from the source database for each table individually, which may
                result in a slower process.
              </HelpBlock>
            </FormGroup>
          )}
          <Button
            block
            bsStyle="success"
            onClick={() => this.props.onSubmit(tables, this.state.loadColumns)}
            disabled={this.props.isSaving || tables.isEmpty()}
          >
            {this.props.isSaving ? (
              <>
                <Loader className="icon-addon-right" />
                Creating...
              </>
            ) : (
              <>
                <FontAwesomeIcon icon="plus" className="icon-addon-right" />
                Create {string.pluralize(tables.count(), 'table')}
              </>
            )}
          </Button>
        </div>
      </div>
    );
  }

  handleSelectChange = (selected) => {
    const schemas = this.props.sourceTables.groupBy((table) => table.get('schema'));
    const selectedTables = selected
      .reduce((tables, option) => {
        const [schema, tableName] = option.split('.');

        if (tableName) {
          return tables.set(option, Map({ schema, tableName }));
        }

        // if value is schema, all tables from schema will be added
        schemas.get(schema, List()).forEach((table) => {
          tables = tables.set(
            `${table.get('schema')}.${table.get('name')}`,
            Map({
              schema: table.get('schema'),
              tableName: table.get('name'),
            }),
          );
        });

        return tables;
      }, Map())
      .toList();

    this.props.onChange(selectedTables);
  };

  getOptions = () => {
    if (!this.props.sourceTables || !this.props.sourceTables.count()) {
      return [];
    }

    return this.props.sourceTables
      .groupBy((table) => table.get('schema'))
      .map((tables, schema) => {
        return [
          { value: schema, label: `Schema: ${schema}`, className: 'font-semibold' },
          ...tables
            .map((table) => {
              const value = `${table.get('schema')}.${table.get('name')}`;
              const hasCdc = table.get('cdcEnabled');

              return { value, label: hasCdc ? `${value} [CDC]` : value };
            })

            .toArray(),
        ];
      })
      .reduce((all, options) => all.concat(options), []);
  };
}

Quickstart.propTypes = {
  isLoadingSourceTables: PropTypes.bool.isRequired,
  supportSplitLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  sourceTables: PropTypes.object,
  quickstart: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  refreshMethod: PropTypes.func.isRequired,
};

export default Quickstart;
