import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Tooltip } from 'design';
import { List, type Map } from 'immutable';
import _ from 'underscore';

import type { ACTIVE_MENU } from '@/modules/ex-generic/constants';
import { JOB_NAME } from '@/modules/ex-generic/constants';
import { getMenuAfterEndpointDeletion, prepareRealJobPath } from '@/modules/ex-generic/helpers';
import ConnectorIcon from '@/react/common/ConnectorIcon';
import Loader from '@/react/common/Loader';
import Truncated from '@/react/common/Truncated';
import MenuItem from './MenuItem';
import NewEndpoint from './NewEndpoint';

const Endpoints = (props: {
  menu: ACTIVE_MENU;
  setMenu: (menu: ACTIVE_MENU) => void;
  parameters: Map<string, any>;
  readOnly: boolean;
  onSave: (
    parameters: Map<string, any>,
    changeDescription: string,
    newMenu?: ACTIVE_MENU,
  ) => Promise<any>;
}) => {
  const [show, setShow] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const jobs = props.parameters.getIn(['config', 'jobs'], List());
  const hasBaseURL = !!props.parameters.getIn(['api', 'baseUrl']);

  const handleDelete = (jobPath: number[]) => {
    setDeleting(true);

    return props
      .onSave(
        props.parameters.deleteIn(['config', 'jobs', ...prepareRealJobPath(jobPath)]),
        'Delete endpoint',
        getMenuAfterEndpointDeletion(jobPath, props.menu),
      )
      .finally(() => setDeleting(false));
  };

  const renderEndpoints = (jobs: List<any>, path: number[] = []): any[] => {
    return jobs
      .map((job: Map<string, any>, index) => {
        const endpoint = job.get('endpoint');
        const fullPath = [...path, index || 0];

        return (
          <React.Fragment key={endpoint}>
            <MenuItem
              onClick={() => props.setMenu(fullPath)}
              isActive={_.isEqual(props.menu, fullPath)}
              additionalActions={
                !props.readOnly && (
                  <Tooltip tooltip="Delete endpoint" placement="top">
                    <Button
                      bsStyle="link"
                      className="btn-link-inline text-muted"
                      onClick={(e: React.SyntheticEvent) => {
                        e.stopPropagation();

                        handleDelete(fullPath);
                      }}
                    >
                      {deleting ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
                    </Button>
                  </Tooltip>
                )
              }
            >
              {fullPath.length > 1 && (
                <ConnectorIcon
                  className="tw-mr-2"
                  style={{ marginLeft: 4 + (fullPath.length - 2) * 8 }}
                />
              )}
              <Truncated text={job.get(JOB_NAME, endpoint)} />
            </MenuItem>
            {!job.get('children', List()).isEmpty() &&
              renderEndpoints(job.get('children'), fullPath)}
          </React.Fragment>
        );
      })
      .toArray();
  };

  return (
    <>
      <h4 className="tw-flex tw-justify-between tw-px-2 tw-text-xs tw-font-semibold tw-uppercase tw-tracking-widest">
        Endpoints
        {!props.readOnly && !jobs.isEmpty() && (
          <Button
            bsStyle="link"
            className="btn-link-inline dark muted !tw-text-xs !tw-font-medium"
            onClick={() => setShow(true)}
          >
            <FontAwesomeIcon icon="plus" className="btn-icon tw-text-primary-500" />
            Create
          </Button>
        )}
      </h4>
      {!props.readOnly && jobs.isEmpty() ? (
        <div className="tw-my-4 tw-flex tw-justify-center">
          <Tooltip
            placement="top"
            type="explanatory"
            tooltip="Please set up the base URL first."
            forceHide={hasBaseURL}
          >
            <Button
              bsSize="sm"
              bsStyle="success"
              className={classNames({ 'btn-disabled': !hasBaseURL })}
              onClick={() => hasBaseURL && setShow(true)}
            >
              <FontAwesomeIcon icon="plus" className="btn-icon" />
              New Endpoint
            </Button>
          </Tooltip>
        </div>
      ) : (
        <div className="tw-flex tw-flex-col tw-gap-1">{renderEndpoints(jobs)}</div>
      )}
      <NewEndpoint
        show={show}
        parameters={props.parameters}
        onSave={props.onSave}
        setMenu={props.setMenu}
        onHide={() => setShow(false)}
      />
    </>
  );
};

export default Endpoints;
