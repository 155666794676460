import React, { useEffect } from 'react';
import type { ReactNode } from 'react';
import { Button, Form } from 'react-bootstrap';
import Textarea from 'react-textarea-autosize';
import { cn } from 'design';

import keyCodes from '@/constants/keyCodes';
import Loader from '@/react/common/Loader';
import { AiIcon } from './svgGradient';

const PromptInput = (props: {
  placeholder: string;
  previousPrompt?: string | null;
  prompt: string;
  onChange: (prompt: string) => void;
  isLoading: boolean;
  isDisabled?: boolean;
  isDisabledInput?: boolean;
  onSubmit?: () => void;
  label?: string;
  loadingLabel?: string;
  autoFocus?: boolean;
  highlight?: boolean;
  multiLine?: boolean;
  className?: string;
  children?: ReactNode;
}) => {
  const inputRef = React.useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    const focusAiGenerator = () => inputRef.current?.focus();

    document.addEventListener('focus-ai-generator', focusAiGenerator);

    return () => {
      document.removeEventListener('focus-ai-generator', focusAiGenerator);
    };
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === keyCodes.ENTER && !event.shiftKey) {
      event.preventDefault();
      props.onSubmit?.();
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onChange(event.target.value);
  };

  return (
    <Form
      className={cn(props.className)}
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        props.onSubmit?.();
      }}
    >
      <div
        className={cn(
          'tw-relative tw-rounded-lg tw-bg-[linear-gradient(135deg,#0975E0_0%,#6F36E0_100%)] has-[.form-control:focus]:tw-shadow-outline',
          props.highlight ? 'tw-p-[3px]' : 'tw-p-[2px]',
        )}
      >
        <AiIcon className="tw-pointer-events-none tw-absolute tw-bottom-0 tw-left-[18px] tw-top-0 tw-my-auto" />
        <Textarea
          ref={(ref) => (inputRef.current = ref)}
          minRows={1}
          maxRows={props.multiLine ? 5 : 1}
          value={props.prompt}
          autoFocus={props.autoFocus}
          disabled={props.isDisabledInput}
          placeholder={props.previousPrompt ?? props.placeholder}
          onKeyDown={handleKeyDown}
          onChange={handleOnChange}
          className={cn(
            'form-control without-decorations tw-w-full tw-resize-none !tw-rounded-md tw-bg-white tw-pl-[50px]',
            props.children ? 'tw-pr-56' : 'tw-pr-40',
            props.highlight ? 'tw-py-[15px]' : 'tw-py-3',
          )}
        />

        <div className="tw-absolute tw-bottom-2 tw-right-2 tw-flex tw-gap-4">
          {props.children}
          {props.onSubmit && (
            <Button
              type="submit"
              bsSize="small"
              bsStyle="primary"
              className={cn('', {
                'tw-cursor-not-allowed tw-opacity-65 hover|focus:tw-bg-secondary-500':
                  props.isDisabled,
                '!tw-py-2.5 !tw-leading-5': props.highlight,
              })}
            >
              {props.isLoading ? (
                <>
                  <Loader className={cn(props.loadingLabel && 'tw-mr-2')} />
                  {props.loadingLabel}
                </>
              ) : (
                (props.label ?? 'Submit')
              )}
            </Button>
          )}
        </div>
      </div>
    </Form>
  );
};

export default PromptInput;
