import type { SinkFile } from '@/api/routes/streamService';
import type { ExampleLanguageName } from './constants';
import { EXAMPLES_LANGUAGES } from './constants';
import type { Store, StoreSource } from './store';

export const findSource = (allSources: Store['sources'], sourceId: string) => {
  return allSources.find((source) => source.sourceId === sourceId);
};

export const findSink = (allSinks: Store['sources'][number]['sinks'], sinkId: string) => {
  if (!allSinks) return;
  return allSinks.find((sink) => sink.sinkId === sinkId);
};

export const getLatestChange = (source: StoreSource) => {
  if (!source.sinks?.length) return source.version.at;

  // If source has sinks return the latest sink's `at` date
  return new Date(
    Math.max(...source.sinks.map((sink) => new Date(sink.version.at).getTime())),
  ).toISOString();
};

export const getLatestImport = (source: StoreSource) => {
  if (!source.sinks?.length) return;

  // If source has sinks return the latest sink's `target.lastRecordAt` date
  const allLastRecordAtDates = source.sinks
    .map((sink) => sink.statisticsTotal?.levels.target?.lastRecordAt)
    .filter(Boolean) as string[];

  if (!allLastRecordAtDates.length) return;

  return new Date(
    Math.max(...allLastRecordAtDates.map((date) => new Date(date).getTime())),
  ).toISOString();
};

export const getTotalStatistics = (source: StoreSource) => {
  if (!source.sinks?.length) return;

  return source.sinks.reduce(
    (statistics, sink) => {
      if (!sink.statisticsTotal) return statistics;

      return {
        data: {
          waiting:
            statistics.data.waiting +
            (sink.statisticsTotal.levels.local?.compressedSize ?? 0) +
            (sink.statisticsTotal.levels.staging?.compressedSize ?? 0),
          imported:
            statistics.data.imported + (sink.statisticsTotal.levels.target?.compressedSize ?? 0),
        },
        rows: {
          waiting:
            statistics.rows.waiting +
            (sink.statisticsTotal.levels.local?.recordsCount ?? 0) +
            (sink.statisticsTotal.levels.staging?.recordsCount ?? 0),
          imported:
            statistics.rows.imported + (sink.statisticsTotal.levels.target?.recordsCount ?? 0),
        },
      };
    },
    { data: { waiting: 0, imported: 0 }, rows: { waiting: 0, imported: 0 } },
  );
};

export const getFileStatistics = (file: SinkFile) => {
  return {
    state: getFileStatus(file),
    data: file.statistics?.total?.compressedSize ?? 0,
    rows: file.statistics?.total?.recordsCount ?? 0,
    openedAt: file.openedAt,
    closingAt: file.closingAt,
    retryAttempt: file.retryAttempt,
    importedAt: file.importedAt,
    importingAt: file.importingAt,
  } as const;
};

export const getFileStatus = (file: SinkFile) => {
  if (file.state === 'imported') return 'success';
  if (file.retryAttempt) return 'error';
  return 'in-progress';
};

export const getExample = (
  language: (typeof EXAMPLES_LANGUAGES)[keyof typeof EXAMPLES_LANGUAGES] | null,
  sourceUrl: string,
) => {
  switch (language) {
    case EXAMPLES_LANGUAGES.PYTHON:
      return `import requests
import json

webhook_url = "${sourceUrl}"

payload = {
    "id": "example-event-id",
    "time": "2023-07-10T12:34:56Z",
    "data": "Example data body",
    }

response = requests.post(webhook_url, json=payload)

if response.status_code == 200:
    print("Payload sent successfully!")
else:
    print("Failed to send payload. Status code: {response.status_code}, Error: {response.text}")`;

    case EXAMPLES_LANGUAGES.JAVASCRIPT:
      return `const webhookUrl = '${sourceUrl}';

const payload = {
  id: 'example-event-id',
  datetime: '2023-07-10T12:34:56Z',
  data: 'Example data body',
  };

fetch(webhookUrl, { method: 'POST', body: JSON.stringify(payload) })
  .then((response) => {
    console.log('Payload sent successfully!');
  })
  .catch((error) => {
    console.error(\`Failed to send payload. Status code: \${error.response.status}, Error: \${error.response.data}\`);
  });`;

    case EXAMPLES_LANGUAGES.BASH:
      return `#!/bin/bash

webhook_url="${sourceUrl}"

payload='{
  "id": "example-event-id",
  "datetime": "2023-07-10T12:34:56Z",
  "data": "Example data body",
  }'

response=$(curl -X POST -H "Content-Type: application/json" -d "$payload" "$webhook_url")

if [ $? -eq 0 ]; then
  echo "Payload sent successfully!"
else
  echo "Failed to send payload. Error: $response"
fi`;

    default:
      return null;
  }
};

export const resolveExampleEditorMode = (languageName: ExampleLanguageName) => {
  switch (languageName) {
    case EXAMPLES_LANGUAGES.BASH:
      return 'text/x-sh';

    case EXAMPLES_LANGUAGES.PYTHON:
      return 'text/x-python';

    case EXAMPLES_LANGUAGES.JAVASCRIPT:
    default:
      return 'text/javascript';
  }
};
