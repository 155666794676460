import React from 'react';
import createReactClass from 'create-react-class';
import { Alert } from 'design';
import { Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import ComponentDescription from '@/modules/components/react/components/ComponentDescription';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import OauthStore from '@/modules/oauth-v2/Store';
import { prepareTablesMetadataMap } from '@/modules/storage/helpers';
import ConfigurationInfoPanel from '@/react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import Sidebar from '@/react/layout/Sidebar/Sidebar';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const LegacyIndex = createReactClass({
  mixins: [
    createStoreMixin(
      InstalledComponentsStore,
      OauthStore,
      ApplicationStore,
      ComponentsStore,
      StorageTablesStore,
    ),
  ],

  getStateFromStores() {
    const componentId = RoutesStore.getCurrentRouteComponentId();
    const configId = RoutesStore.getCurrentRouteParam('config');
    const config = InstalledComponentsStore.getConfig(componentId, configId);
    const configData = InstalledComponentsStore.getConfigData(componentId, configId);

    return {
      config,
      configId,
      componentId,
      allTables: StorageTablesStore.getAll(),
      component: ComponentsStore.getComponent(componentId),
      allComponents: ComponentsStore.getAll(),
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      oauthCredentials: OauthStore.getCredentials(componentId, configData) || Map(),
      readOnly: ApplicationStore.isReadOnly(),
      admins: ApplicationStore.getAdmins(),
    };
  },

  render() {
    return (
      <>
        <ConfigurationTabs componentId={this.state.componentId} configId={this.state.configId} />
        <ConfigurationInfoPanel
          component={this.state.component}
          allComponents={this.state.allComponents}
          config={this.state.config}
          flows={this.state.flows}
          tablesMetadataMap={prepareTablesMetadataMap(this.state.allTables)}
          metadata={this.state.componentsMetadata}
        />
        <div className="row">
          <div className="col-sm-9">
            <ComponentDescription
              componentId={this.state.componentId}
              configId={this.state.configId}
            />
            <Alert
              className="tw-mb-5 tw-mt-6"
              variant="warning"
              title="The configuration has legacy format"
            >
              It cannot be further edited. To automatically migrate to the new format, please run
              your configuration.
            </Alert>
          </div>
          <div className="col-sm-3">
            <Sidebar
              hideCopy
              hideSchedule
              componentId={this.state.componentId}
              configId={this.state.configId}
            />
          </div>
        </div>
      </>
    );
  },
});

export default LegacyIndex;
