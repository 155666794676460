import React from 'react';
import { Button } from 'react-bootstrap';
import { createRoot } from 'react-dom/client';
import { JSONEditor } from '@json-editor/json-editor';
import { Alert } from 'design';

import callDockerAction from '@/modules/components/DockerActionsApi';
import Loader from '@/react/common/Loader';
import Markdown from '@/react/common/Markdown';
import RoutesStore from '@/stores/RoutesStore';
import { getCacheKey, getCurrentConfigData, shouldAutoload } from '@/utils/json-editor/helpers';
import { setStoreData, store } from '@/utils/json-editor/store';
import nextTick from '@/utils/nextTick';

const SyncActionButtonApp = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [result, setResult] = React.useState(null);

  const performAction = React.useCallback(
    (event) => {
      const componentId = RoutesStore.getCurrentRouteComponentId();
      const configData = getCurrentConfigData();
      const action = props.action || 'action';
      const useCache = props.cache ?? true;
      const autoload = shouldAutoload(props.autoload, getCurrentConfigData());
      const cacheKey = useCache
        ? getCacheKey(componentId, action, configData, props.autoload)
        : null;

      if (!event && useCache && store.has(cacheKey)) {
        return nextTick(() => setResult(store.get(cacheKey)));
      }

      if (!event && !autoload) {
        return;
      }

      setLoading(true);
      setResult(null);
      return callDockerAction(componentId, action, { configData: configData.toJS() })
        .then((response) => {
          const result = {
            type:
              response?.status === 'error'
                ? 'error'
                : ['success', 'info', 'warning', 'error', 'table'].includes(response?.type)
                  ? response.type
                  : 'success',
            message: response.message,
          };

          if (useCache && response?.status !== 'error') {
            setStoreData(cacheKey, result);
          }

          return setResult(result);
        })
        .finally(() => setLoading(false));
    },
    [props.action, props.autoload, props.cache],
  );

  React.useEffect(() => {
    performAction();
  }, [performAction]);

  const renderResult = () => {
    if (!result) {
      return null;
    }

    if (result.type === 'table') {
      return (
        <Markdown collapsible={false} overflow={false} className="mt-1" source={result.message} />
      );
    }

    return (
      <Alert variant={result.type} onClose={() => setResult(null)} className="tw-mb-5 tw-mt-3.5">
        <Markdown collapsible={false} source={result.message} />
      </Alert>
    );
  };

  return (
    <>
      <Button disabled={loading} onClick={performAction}>
        {loading && <Loader className="icon-addon-right" />}
        {props.label || 'Perform action'}
      </Button>
      {renderResult()}
    </>
  );
};

export default class SyncActionButtonEditor extends JSONEditor.AbstractEditor {
  build() {
    const buttonRoot = window.document.createElement('span');

    this.container.appendChild(buttonRoot);

    this.root = createRoot(buttonRoot);

    this.root.render(
      <SyncActionButtonApp
        action={this.options.async?.action}
        label={this.options.async?.label}
        autoload={this.options.async?.autoload}
        cache={this.options.async?.cache}
      />,
    );
  }

  destroy() {
    setTimeout(() => this.root?.unmount());

    super.destroy();
  }
}
