import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'design';

const PredefinedExamples = ({ onClick }: { onClick: (prompt: string) => void }) => (
  <>
    <h3 className="tw-mb-6 tw-mt-12 tw-text-base">Try our examples</h3>
    <div className="tw-grid tw-grid-cols-3 tw-gap-6">
      <Example
        onClick={onClick}
        prompt="I aim to collect YouTube comments, apply natural language processing, and save the data in Power BI."
      />
      <Example
        onClick={onClick}
        prompt="Our team aims to gather customer support tickets from Jira, analyze them for urgency levels, and push the data to Looker."
      />
      <Example
        onClick={onClick}
        prompt="I want to have a report in Looker for most often sold product in our inventory from Shopify."
      />
    </div>
  </>
);

const Example = ({ prompt, onClick }: { prompt: string; onClick: (prompt: string) => void }) => (
  <Button
    onClick={() => onClick(prompt)}
    variant="outline"
    className="tw-max-h-none tw-px-4 tw-py-3 tw-text-left tw-text-sm tw-normal-case tw-text-neutral-400"
  >
    <span>{prompt}</span>
    <FontAwesomeIcon icon="angle-right" />
  </Button>
);

export default PredefinedExamples;
