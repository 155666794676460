import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type Map } from 'immutable';

import { updateApp } from '@/modules/data-apps/actions';
import type { DataApp } from '@/modules/data-apps/DataAppsStore';
import Confirm from '@/react/common/Confirm';
import ConfirmMenuItem from '@/react/common/ConfirmMenuItem';
import Loader from '@/react/common/Loader';

const TerminateDataApp = (props: {
  app: DataApp;
  config: Map<string, any>;
  mode: 'sidebar' | 'menuitem';
  readOnly: boolean;
  onKeyDown?: (event: React.KeyboardEvent) => void;
}) => {
  const isProcessing = props.app.state === 'stopping';

  const handleConfirm = () => updateApp(props.app, props.config, 'stopped');

  const renderBody = () => {
    return (
      <p>
        Are you sure you want to terminate the data app <strong>{props.config.get('name')}</strong>?
      </p>
    );
  };

  if (props.app.state !== 'running' && !isProcessing) {
    return null;
  }

  if (props.mode === 'sidebar') {
    return (
      <Confirm
        icon="circle-pause"
        buttonType="danger"
        title="Terminate Data App"
        buttonLabel="Terminate Data App"
        childrenRootElement="a"
        text={renderBody()}
        isLoading={isProcessing}
        onConfirm={handleConfirm}
        isDisabled={isProcessing || props.readOnly}
      >
        {isProcessing ? <Loader /> : <FontAwesomeIcon icon="circle-pause" fixedWidth />}
        Terminate data app
      </Confirm>
    );
  }

  return (
    <ConfirmMenuItem
      icon="circle-pause"
      buttonType="danger"
      title="Terminate Data App"
      buttonLabel="Terminate Data App"
      text={renderBody()}
      onConfirm={handleConfirm}
      isLoading={isProcessing}
      isDisabled={isProcessing || props.readOnly}
      onKeyDown={props.onKeyDown}
    >
      {isProcessing ? <Loader /> : <FontAwesomeIcon icon="circle-pause" fixedWidth />}
      Terminate data app
    </ConfirmMenuItem>
  );
};

export default TerminateDataApp;
