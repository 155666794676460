import { createRequest } from '@/modules/components/StorageApi';

const WorkspacesApi = {
  loadConfigurationWorkspaces(componentId, configId) {
    return createRequest('GET', `components/${componentId}/configs/${configId}/workspaces`, {
      forceProduction: true,
    })
      .promise()
      .then((response) => response.body);
  },

  createConfigurationWorkspace(componentId, configId, params) {
    return createRequest('POST', `components/${componentId}/configs/${configId}/workspaces`, {
      forceProduction: true,
    })
      .send({
        ...params,
        ...(params.backend === 'snowflake' && { networkPolicy: 'user' }),
      })
      .promise()
      .then((response) => response.body);
  },

  deleteWorkspace(workspaceId) {
    return createRequest('DELETE', `workspaces/${workspaceId}`, { forceProduction: true })
      .query({ async: true })
      .promise()
      .then((response) => response.body);
  },

  resetPassword(workspaceId) {
    return createRequest('POST', `workspaces/${workspaceId}/password`, { forceProduction: true })
      .promise()
      .then((response) => response.body);
  },
};

export default WorkspacesApi;
