import React from 'react';

import type { PostMessageResponse } from '@/api/routes/aiService';
import { type MessageObject, roles } from '@/modules/automations/constants';
import ComponentSelection from './ComponentSelection';
import IntentConfirmation from './IntentConfirmation';
import TableSelection from './TableSelection';

const InteractiveElement = ({
  messageObject,
  submitMessage,
}: {
  messageObject: MessageObject | PostMessageResponse;
  submitMessage: (messageObject: MessageObject) => void;
}) => {
  const [wasSubmitted, setWasSubmitted] = React.useState(false);

  const submit = (data?: MessageObject['data']) => {
    submitMessage({
      text: null,
      type: messageObject.type.replace('Request', 'Response') as MessageObject['type'],
      role: roles.USER,
      data,
    });
    setWasSubmitted(true);
  };

  const prepareOptions = () => {
    if (messageObject.data?.options) {
      return messageObject.data.options;
    }

    if (messageObject.data?.selectedValues) {
      return Array.isArray(messageObject.data.selectedValues)
        ? messageObject.data.selectedValues
        : [messageObject.data.selectedValues];
    }

    return [];
  };

  if (wasSubmitted) return null;

  switch (messageObject.type) {
    case 'componentSelectionRequest':
      return (
        <ComponentSelection
          componentsIds={prepareOptions()}
          onClick={(componentId) =>
            componentId ? submit({ selectedValue: componentId }) : submit()
          }
        />
      );

    case 'tableSelectionRequest':
      return (
        <TableSelection
          tablesIds={prepareOptions()}
          onClick={(tablesIds) => submit({ selectedValues: tablesIds })}
        />
      );

    case 'intentConfirmationRequest':
      return <IntentConfirmation onClick={(confirm) => submit({ selectedValue: confirm })} />;

    default:
      return null;
  }
};

export default InteractiveElement;
