import React from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cn } from '../utils/classNames';

const SIZE_CLASSES_TEXT = {
  small: 'tw-px-4 tw-py-1.5 tw-max-h-8',
  medium: 'tw-px-4 tw-py-2.5 tw-max-h-10',
  large: 'tw-px-4 tw-py-3.5 tw-max-h-12',
};

const SIZE_CLASSES_ICON = {
  small: 'tw-p-0 tw-w-8 tw-h-8',
  medium: 'tw-p-0 tw-w-10 tw-h-10',
  large: 'tw-p-0 tw-w-12 tw-h-12',
};

const VARIANT_CLASSES = {
  secondary:
    'tw-text-white tw-bg-secondary-500 [&:not(:disabled)]:hover:tw-bg-secondary-700 [&:not(:disabled)]:active:tw-bg-secondary-800',
  primary:
    'tw-text-white tw-bg-primary-500 [&:not(:disabled)]:hover:tw-bg-primary-600 [&:not(:disabled)]:active:tw-bg-primary-800',
  danger:
    'tw-text-white tw-bg-error-500 [&:not(:disabled)]:hover:tw-bg-error-600 [&:not(:disabled)]:active:tw-bg-error-700',
  outline:
    'tw-text-neutral-800 [&_svg]:tw-text-neutral-400 tw-bg-white tw-border tw-border-neutral-200 [&:not(:disabled)]:hover:tw-border-neutral-300 [&:not(:disabled)]:hover:tw-bg-neutral-100 [&:not(:disabled)]:active:tw-bg-neutral-150 [&:not(:disabled)_svg]:hover:tw-text-secondary-600',
  invisible:
    'tw-text-neutral-800 [&_svg]:tw-text-neutral-400 tw-rounded-full [&:not(:disabled):not(:active)]:focus:tw-shadow-none [&:not(:disabled):not(:active)]:focus-visible:tw-shadow-[0_0_0_3px_rgba(31,143,255,0.2)] tw-bg-transparent [&:not(:disabled)]:hover:tw-bg-neutral-100 [&:not(:disabled)]:focus:tw-bg-neutral-100 [&:not(:disabled)]:active:tw-bg-neutral-150 [&:not(:disabled)_svg]:hover:tw-text-secondary-600 [&:not(:disabled)_svg]:focus:tw-text-secondary-600',
};

const BASE_CLASSES = {
  common: 'tw-items-center tw-justify-center tw-uppercase tw-gap-2 tw-rounded tw-shadow-none',
  text: 'tw-text-xs tw-leading-5 tw-font-medium tw-tracking-[1px]',
  border:
    'tw-box-border tw-border-solid tw-border tw-border-transparent [&:not(:disabled):not(:active)]:focus-visible:tw-outline [&:not(:disabled):not(:active)]:focus-visible:tw-outline-secondary-500 [&:not(:disabled):not(:active)]:focus-visible:tw-outline-1 [&:not(:disabled):not(:active)]:focus-visible:tw-shadow-[0_0_0_4px_rgba(31,143,255,0.2)]',
  svg: '[&_svg]:tw-text-base',
};

type Variants =
  | { variant: 'invisible'; icon: IconProp; children?: never }
  | {
      variant?: 'primary' | 'secondary' | 'outline' | 'danger';
      icon?: IconProp | null;
      children?: React.ReactNode | React.ReactNode[];
    };

type Props = {
  onClick?: (e: React.MouseEvent) => void;
  size?: 'large' | 'medium' | 'small';
  disabled?: boolean;
  isFilledIcon?: boolean;
  isLoading?: boolean;
  className?: string;
} & Variants;

export const Button = ({
  children,
  onClick,
  size = 'medium',
  variant = 'primary',
  disabled = false,
  icon = null,
  isFilledIcon = false,
  isLoading = false,
  className,
}: Props) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        ...Object.values(BASE_CLASSES),
        VARIANT_CLASSES[variant],
        icon ? SIZE_CLASSES_ICON[size] : SIZE_CLASSES_TEXT[size],
        icon ? 'tw-block' : 'tw-flex tw-flex-row',
        { '[&_svg]:tw-text-secondary-500': isFilledIcon },
        disabled ? '!tw-cursor-not-allowed tw-opacity-40' : 'tw-cursor-pointer',
        className,
      )}
      disabled={disabled}
    >
      {icon ? (
        <FontAwesomeIcon
          spin={isLoading}
          icon={isLoading ? 'spinner' : icon}
          className="tw-inline-block tw-h-4 tw-w-4 tw-align-middle"
        />
      ) : (
        children
      )}
    </button>
  );
};
