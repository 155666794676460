import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn, Tooltip } from 'design';

const NoAssessIcon = (props: { classNames?: string }) => {
  return (
    <Tooltip
      placement="left"
      tooltip="You don't have access to this component's project."
      type="explanatory"
    >
      <FontAwesomeIcon
        icon="lock"
        className={cn('tw-ml-auto tw-text-base tw-text-warning-500', props.classNames)}
      />
    </Tooltip>
  );
};

export default NoAssessIcon;
