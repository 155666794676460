import React from 'react';
import PropTypes from 'prop-types';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import * as componentFlags from '@/constants/componentFlags';
import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import MappingsMultiActionsHeader, {
  MappingsHeader,
} from '@/react/common/MappingsMultiActionsHeader';
import CollapsibleMapping from './CollapsibleMapping';
import FileInputMappingModal from './FileInputMappingModal';
import FileInputMappingRow from './FileInputMappingRow';
import {
  getFileLocationHint,
  prepareConfigDataWithDeletedMapping,
  prepareConfigDataWithEditedMapping,
  saveMapping,
} from './helpers';

const FileInputMapping = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    onDeleteMappings: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    allComponents: PropTypes.instanceOf(Map).isRequired,
    sandboxes: PropTypes.instanceOf(Map).isRequired,
    availableDatabricksClusters: PropTypes.instanceOf(List),
    allowedComponents: PropTypes.instanceOf(Map),
    hasPayAsYouGo: PropTypes.bool,
    rowId: PropTypes.string,
  },

  getInitialState() {
    return {
      selectedMappings: Map(),
      sortBy: null,
      sortDesc: false,
    };
  },

  renderActions() {
    return (
      !this.props.readOnly && (
        <FileInputMappingModal onSave={(editing) => this.onSaveMapping('new-mapping', editing)} />
      )
    );
  },

  render() {
    return (
      <CollapsibleMapping
        title={<MappingsHeader type="input" componentId={this.props.componentId} storage="files" />}
        entity="mapping"
        readOnly={this.props.readOnly}
        componentId={this.props.componentId}
        configId={this.props.configId}
        rowId={this.props.rowId}
        mappingKey="file-input"
        isEmpty={this.props.value.isEmpty()}
        actions={this.renderActions()}
        header={
          <>
            <MappingsMultiActionsHeader
              hide={this.props.readOnly}
              disabled={this.props.value.isEmpty()}
              componentId={this.props.componentId}
              configurationId={this.props.configId}
              rowId={this.props.rowId}
              sandboxComponent={this.props.allComponents.get(KEBOOLA_SANDBOXES)}
              allowedComponents={this.props.allowedComponents?.filter((component) =>
                component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_FILE_INPUT),
              )}
              sandboxes={this.props.sandboxes}
              hasPayAsYouGo={this.props.hasPayAsYouGo}
              type="input"
              storage="files"
              allMappings={this.props.value}
              selectedMappings={this.state.selectedMappings}
              updateMappingsSelection={(selectedMappings) =>
                this.setState(() => ({ selectedMappings }))
              }
              deleteMappings={this.props.onDeleteMappings}
              availableDatabricksClusters={this.props.availableDatabricksClusters}
              isSorted={this.state.sortBy === 'source'}
              isSortedDesc={this.state.sortDesc}
              onClick={() => this.handleChangeSort('source')}
            />
            <div className="tw-font-medium">Destination</div>
          </>
        }
      >
        {this.renderContent()}
      </CollapsibleMapping>
    );
  },

  renderContent() {
    if (this.props.value.isEmpty()) {
      return null;
    }

    return (
      <div className="box-content p-0">
        <div className="table table-hover overflow-break-anywhere">
          <div className="tbody">
            {this.props.value
              .map((input, index) => [input, index])
              .sort(this.handleSort)
              .map(([input, index]) => this.renderRow(input, index))
              .toArray()}
          </div>
        </div>
        <p className="mb-0 pl-2 pr-2 pb-1 help-block">
          Multiple files may match the given criteria. All files will be stored in the folder{' '}
          <code>{`${getFileLocationHint(this.props.componentId)}/files/`}</code> named with the
          pattern <code>fileId_fileName</code>. All metadata will be stored in a manifest file.
        </p>
      </div>
    );
  },

  renderRow(input, key) {
    return (
      <FileInputMappingRow
        key={key}
        value={input}
        readOnly={this.props.readOnly}
        componentId={this.props.componentId}
        onSave={(editing) => this.onSaveMapping(key, editing)}
        onDelete={() => {
          return this.onDeleteMapping(key).then(() => this.setState({ selectedMappings: Map() }));
        }}
        isSelected={!!this.state.selectedMappings.get(key)}
        toggleSelection={(checked) => {
          return this.setState({ selectedMappings: this.state.selectedMappings.set(key, checked) });
        }}
      />
    );
  },

  handleChangeSort(type) {
    if (type !== this.state.sortBy) {
      return this.setState({
        sortBy: type,
        sortDesc: false,
      });
    }

    this.setState({
      sortDesc: !this.state.sortDesc,
    });
  },

  handleSort([inputA], [inputB]) {
    if (!this.state.sortBy) {
      return 0;
    }

    const nameA = inputA.getIn(['source', 'tags', 0, 'name'], '');
    const nameB = inputB.getIn(['source', 'tags', 0, 'name'], '');

    const sort = this.state.sortDesc ? -1 : 1;

    return nameA?.localeCompare(nameB) * sort;
  },

  onSaveMapping(key, editing) {
    const configData = prepareConfigDataWithEditedMapping(
      this.props.componentId,
      this.props.configId,
      this.props.rowId,
      'input',
      'files',
      key,
      editing,
    );

    return this.handleSave(configData, `${key === 'new-mapping' ? 'Add' : 'Update'} file input`);
  },

  onDeleteMapping(key) {
    const configData = prepareConfigDataWithDeletedMapping(
      this.props.componentId,
      this.props.configId,
      this.props.rowId,
      'input',
      'files',
      key,
    );

    return this.handleSave(configData, 'Delete file input');
  },

  handleSave(configData, changeDescription) {
    return saveMapping(
      this.props.componentId,
      this.props.configId,
      this.props.rowId,
      configData,
      changeDescription,
    );
  },
});

export default FileInputMapping;
