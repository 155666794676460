import React, { startTransition } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { URLS } from '@keboola/constants';
import { Link } from 'design';
import { List, Map } from 'immutable';
import memoizeOne from 'memoize-one';
import { strLeft } from 'underscore.string';

import { filterProductionAndCurrentDevBranchBuckets } from '@/modules/dev-branches/helpers';
import { EXTERNAL_DATASET_DISABLE_TOOLTIP } from '@/modules/storage/constants';
import {
  bucketDisplayNameWithStage,
  getCurrentBranchTableWithProductionFallback,
  tableDisplayNameWithBucketAndStage,
  tableName,
} from '@/modules/storage/helpers';
import BucketStageLabel from '@/react/common/BucketStageLabel';
import DevBranchLabel from '@/react/common/DevBranchLabel';
import Select from '@/react/common/Select';
import { bucketLabel, tableLabel } from '@/react/common/selectLabels';
import matchByWords from '@/utils/matchByWords';
import string from '@/utils/string';

const getAlreadyAddedTablesFromOtherMappings = (otherMappings) => {
  if (!otherMappings) return List();

  return otherMappings.map((mapping) => mapping.get('source', ''));
};

const prepareData = memoizeOne((buckets, tables) => {
  return filterProductionAndCurrentDevBranchBuckets(buckets)
    .filter((bucket, bucketId) => {
      return tables.some((table) => table.getIn(['bucket', 'id']) === bucketId);
    })
    .sortBy(bucketDisplayNameWithStage)
    .map((bucket, bucketId) => {
      return bucket.set(
        'tables',
        tables
          .filter((table) => table.getIn(['bucket', 'id']) === bucketId)
          .sortBy(tableDisplayNameWithBucketAndStage),
      );
    });
});

const prepareOptions = memoizeOne(
  (searchValue, mode, buckets, tables, disableBuckets, disableExternalBuckets, otherMappings) => {
    const alreadyAddedTables = getAlreadyAddedTablesFromOtherMappings(otherMappings);
    const externalTableDisabledTooltipBody = () => (
      <>
        {EXTERNAL_DATASET_DISABLE_TOOLTIP.INPUT_MAPPING}{' '}
        <Link
          href={`${URLS.USER_DOCUMENTATION}/storage/byodb/external-buckets/#using-an-external-dataset`}
        >
          Learn more
        </Link>
        .
      </>
    );

    return prepareData(buckets, tables)
      .map((bucket, bucketId) => {
        const noExternalBucket = disableExternalBuckets && bucket.get('hasExternalSchema', false);
        const tablesOptions = bucket
          .get('tables')
          .map((table, tableId) => ({
            parentOption: bucketId,
            value: tableId,
            name: tableName(table),
            label: tableLabel(table, {
              showTableIcon: true,
              hideStage: true,
              alreadyAddedSuffix: alreadyAddedTables.includes(tableId),
            }),
            isDisabled: noExternalBucket,
            disabledReasonWithLink: externalTableDisabledTooltipBody,
          }))
          .toArray();

        return [
          {
            isGroupOption: true,
            value: bucketId,
            name: `${bucket.get('displayName')} ${tablesOptions
              .map((option) => option.name)
              .join(' ')}`,
            label: bucketLabel(bucket, { showBucketIcon: true }),
            isDisabled: disableBuckets || mode === 'edit' || noExternalBucket,
            disabledReasonWithLink: noExternalBucket ? externalTableDisabledTooltipBody : '',
          },
          ...tablesOptions,
        ];
      })
      .reduce((all, options) => all.concat(options), []);
  },
);

class InputMappingSourceInput extends React.PureComponent {
  state = {
    inputValue: '',
    searchValue: '',
  };

  render() {
    const allowMultipleOptions =
      !this.props.disableMultiSelect &&
      this.props.mode === 'create' &&
      (List.isList(this.props.value) || !this.props.disableBuckets);

    return (
      <Select
        autoFocus
        hideSelectAllOptions
        clearable={allowMultipleOptions}
        multi={allowMultipleOptions}
        {...(allowMultipleOptions && { className: 'table-input-mapping-multiselect' })}
        placeholder={`Type to search a table${this.props.disableBuckets ? '' : ' or a bucket'}`}
        value={
          allowMultipleOptions && !List.isList(this.props.value)
            ? this.props.value === ''
              ? List()
              : List([this.props.value])
            : this.props.value
        }
        inputValue={this.state.inputValue}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        options={prepareOptions(
          this.state.searchValue,
          this.props.mode,
          this.props.buckets,
          this.props.tables,
          this.props.disableBuckets,
          this.props.disableExternalBuckets,
          this.props.otherMappings,
        )}
        singleValueRenderer={this.renderSingleValue}
        multiValueRenderer={this.renderMultiValue}
        disabled={this.props.isDisabled}
      />
    );
  }

  renderSingleValue = (props) => {
    const styles = props.getStyles?.('singleValue', props) ?? {};
    const table = getCurrentBranchTableWithProductionFallback(this.props.tables, props.data.value);
    const tableId = table.get('id', props.data.value);
    const bucket = this.props.buckets.get(tableId);

    if (!bucket && !table) {
      return (
        <div style={styles} className="flex-container flex-start">
          <FontAwesomeIcon icon="table" className="color-base icon-addon-right" />
          <BucketStageLabel placement="left" stage={strLeft(tableId, '.')} />
          {tableId}
        </div>
      );
    }

    if (bucket) {
      return (
        <div style={styles} className="flex-container flex-start">
          <FontAwesomeIcon icon="folder" className="color-base icon-addon-right" />
          <BucketStageLabel placement="left" stage={bucket.get('stage')} />
          <DevBranchLabel bucket={bucket} />
          {bucket.get('displayName')}
        </div>
      );
    }

    return (
      <div style={styles} className="flex-container flex-start">
        <FontAwesomeIcon icon="table" className="text-muted icon-addon-right" />
        <BucketStageLabel placement="left" stage={table.getIn(['bucket', 'stage'])} />
        <DevBranchLabel bucket={table.get('bucket')} />
        {tableName(table)}
      </div>
    );
  };

  renderMultiValue = ({ selectProps, data }) => {
    if (!this.props.tables.has(data.value)) return '';

    const selectedTablesCount =
      selectProps.value.filter((selected) => this.props.tables.has(selected.value))?.length ?? 0;

    if (selectedTablesCount === 1 && !selectProps.inputValue)
      return this.renderSingleValue({ data });
    if (selectProps.value[0].value !== data.value) return '';

    return (
      <>
        <FontAwesomeIcon icon="table" className="text-muted icon-addon-right" />
        {selectedTablesCount} {string.pluralize(selectedTablesCount, 'table')} selected
        <span className="icon-addon-right icon-addon-left text-muted-light">|</span>
      </>
    );
  };

  handleChange = (selectedValues, actionMeta) => {
    if (!List.isList(selectedValues)) {
      return this.props.onChange(selectedValues);
    }

    const tablesByBucket = this.props.tables.groupBy((table) => table.getIn(['bucket', 'id']));
    let filteredSelectedValues = selectedValues
      .filter((value) => {
        if (
          tablesByBucket
            .get(value, List())
            .some((table) => selectedValues.includes(table.get('id')))
        )
          return false;
        if (this.props.tables.getIn([value, 'bucket', 'id']) === actionMeta.option?.value)
          return false;
        if (actionMeta.action !== 'deselect-option') return true;
        if (actionMeta.option.value === value) return false;
        return this.props.tables.getIn([actionMeta.option.value, 'bucket', 'id']) !== value;
      })
      .map((value) => {
        if (this.props.tables.get(value)) return List([value]);

        return tablesByBucket
          .get(value, List())
          .filter((table) => {
            return matchByWords(
              [table.getIn(['bucket', 'displayName']), table.get('displayName'), table.get('id')],
              this.state.searchValue,
            );
          })
          .map((table) => table.get('id'));
      })
      .flatten()
      .toSet()
      .toList();

    tablesByBucket.forEach((tables, bucketId) => {
      if (tables.every((table) => filteredSelectedValues.includes(table.get('id')))) {
        filteredSelectedValues = filteredSelectedValues.push(bucketId);
      }
    });

    return this.props.onChange(filteredSelectedValues);
  };

  handleInputChange = (inputValue, { action }) => {
    if ((List.isList(this.props.value) || !this.props.disableBuckets) && action === 'set-value')
      return;

    this.setState({ inputValue });
    startTransition(() => this.setState({ searchValue: inputValue }));
  };
}

InputMappingSourceInput.propTypes = {
  buckets: PropTypes.instanceOf(Map).isRequired,
  tables: PropTypes.instanceOf(Map).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(List)]).isRequired,
  onChange: PropTypes.func.isRequired,
  disableMultiSelect: PropTypes.bool,
  otherMappings: PropTypes.object,
  disableExternalBuckets: PropTypes.bool,
  isDisabled: PropTypes.bool,
  mode: PropTypes.string,
};

InputMappingSourceInput.defaultProps = {
  disableBuckets: false,
};

export default InputMappingSourceInput;
