import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import ConfigurationRowsActionCreators from '@/modules/configurations/ConfigurationRowsActionCreators';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';

/** @type {any} */
const ConfigurationRowEditField = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, ConfigurationRowsStore)],

  propTypes: {
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    rowId: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    editElement: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    fallbackValue: PropTypes.string,
    tooltipPlacement: PropTypes.string,
  },

  getDefaultProps() {
    return { tooltipPlacement: 'top' };
  },

  getInitialState() {
    const config = ConfigurationRowsStore.get(
      this.props.componentId,
      this.props.configId,
      this.props.rowId,
    );

    return {
      value: config.get(this.props.fieldName, this.props.fallbackValue || ''),
      isEditing: false,
      isSaving: false,
    };
  },

  getStateFromStores() {
    const config = ConfigurationRowsStore.get(
      this.props.componentId,
      this.props.configId,
      this.props.rowId,
    );

    return {
      originalValue: config.get(this.props.fieldName, this.props.fallbackValue || ''),
      readOnly: ApplicationStore.isReadOnly(),
    };
  },

  componentDidUpdate() {
    if (
      !this.state.isEditing &&
      !this.state.isSaving &&
      this.state.originalValue !== this.state.value
    ) {
      this.setState({ value: this.state.originalValue });
    }
  },

  render() {
    const EditElement = this.props.editElement;

    return (
      <EditElement
        readOnly={this.state.readOnly}
        text={this.state.value}
        placeholder={this.props.placeholder}
        tooltipPlacement={this.props.tooltipPlacement}
        isSaving={this.state.isSaving}
        isEditing={this.state.isEditing}
        isValid={this.isValid()}
        isChanged={this.state.value !== this.state.originalValue}
        onEditStart={this.handleEditStart}
        onEditCancel={this.handleEditCancel}
        onEditChange={this.handleEditChange}
        onEditSubmit={this.handleEditSubmit}
      />
    );
  },

  handleEditStart() {
    this.setState({ isEditing: true });
  },

  handleEditCancel() {
    this.setState({ isEditing: false, value: this.state.originalValue });
  },

  handleEditChange(newValue) {
    this.setState({ value: newValue });
  },

  handleEditSubmit() {
    this.setState({ isSaving: true });
    ConfigurationRowsActionCreators.updateSimple(
      this.props.componentId,
      this.props.configId,
      this.props.rowId,
      { [this.props.fieldName]: this.state.value.trim() },
      `Update ${this.props.fieldName}`,
    ).finally(() => {
      this.setState({ isSaving: false, isEditing: false });
    });
  },

  isValid() {
    if (this.props.fieldName === 'name') {
      return !!this.state.value.trim();
    }

    return true;
  },
});

export default ConfigurationRowEditField;
