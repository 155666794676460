import React from 'react';
import { Button } from 'react-bootstrap';
import { Tooltip } from 'design';
import { Map } from 'immutable';

import BetaTitle from '@/modules/lineage/BetaTitle';
import { routeNames as lineageRouteNames } from '@/modules/lineage/constants';
import LineageIcon from '@/react/common/LineageIcon';
import RoutesStore from '@/stores/RoutesStore';
import { prepareLocationContext } from '@/utils/modalRoutes';

const LineageButton = ({
  currentProjectId,
  currentProjectName,
}: {
  currentProjectId: string | number | null;
  currentProjectName: string | null;
}) => {
  return (
    <div className="tw-mx-2 tw-flex">
      <Tooltip placement="bottom" tooltip="Data lineage">
        <Button
          bsStyle="link"
          className="text-muted tw-group tw-p-0"
          onClick={() => {
            const location = RoutesStore.getRouterState().get('location', Map());
            RoutesStore.getRouter().transitionTo(
              lineageRouteNames.LINEAGE,
              {
                projectId: currentProjectId,
                projectName: currentProjectName,
              },
              {
                context: prepareLocationContext(location),
              },
            );
          }}
        >
          <BetaTitle small>
            <LineageIcon className="tw-w-7 group-hover:tw-text-secondary-500" />
          </BetaTitle>
        </Button>
      </Tooltip>
    </div>
  );
};

export default LineageButton;
