import { Promise } from 'bluebird';
import { Map } from 'immutable';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { KEBOOLA_DATA_APPS } from '@/constants/componentIds';
import dispatcher from '@/Dispatcher';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import JobsActionCreators from '@/modules/queue/actions';
import { ActionTypes } from './constants';
import DataAppsApi from './DataAppsApi';
import type { DataApp } from './DataAppsStore';
import DataAppsStore from './DataAppsStore';

const loadDataApps = () => {
  return DataAppsApi.getDataApps().then((dataApps) => {
    dispatcher.handleViewAction({
      type: ActionTypes.DATA_APPS_LOAD_SUCCESS,
      dataApps,
    });

    return null;
  });
};

const loadDataApp = (id: string) => {
  if (!!DataAppsStore.getDataApp(id)) {
    loadDataAppForce(id);
    return Promise.resolve();
  }

  return loadDataAppForce(id);
};

const loadDataAppForce = (id: string) => {
  return DataAppsApi.getDataApp(id).then((dataApp) => {
    dispatcher.handleViewAction({
      type: ActionTypes.DATA_APP_LOAD_SUCCESS,
      dataApp,
    });

    return null;
  });
};

const createApp = (name: string, description: string, configuration: Record<string, any>) => {
  return DataAppsApi.createDataApp(name, description, configuration)
    .tap((dataApp) => {
      dispatcher.handleViewAction({
        type: ActionTypes.DATA_APPS_CREATE_SUCCESS,
        dataApp,
      });

      return InstalledComponentsActionCreators.loadComponentConfigData(
        KEBOOLA_DATA_APPS,
        dataApp.configId,
      );
    })
    .then((dataApp) => dataApp.configId);
};

const updateApp = (app: DataApp, config: Map<string, any>, desiredState: 'running' | 'stopped') => {
  return Promise.all([
    desiredState === 'running' &&
      InstalledComponentsActionCreators.updateComponentConfiguration(
        KEBOOLA_DATA_APPS,
        config.get('id'),
        { configuration: JSON.stringify(config.get('configuration', Map()).toJS()) },
        'Change data app parameters',
      ),
  ])
    .then(() => {
      return DataAppsApi.updateDataApp(
        app.id,
        desiredState === 'running'
          ? {
              desiredState,
              restartIfRunning: true,
              configVersion: config.get('version').toString(),
            }
          : { desiredState: 'stopped' },
      );
    })
    .then(() => loadDataAppForce(app.id))
    .delay(3000) // wait before another update is allowed
    .then(() => {
      JobsActionCreators.loadComponentConfigurationLatestJobs(KEBOOLA_DATA_APPS, config.get('id'));
    });
};

const deleteApp = (id: string) => {
  return DataAppsApi.deleteDataApp(id).then(() => {
    dispatcher.handleViewAction({
      type: ActionTypes.DATA_APP_DELETE_SUCCESS,
      id,
    });

    ApplicationActionCreators.sendNotification({
      type: 'success',
      message: 'Data app has been successfully deleted.',
    });

    return null;
  });
};

const resetPassword = (id: string) => {
  return DataAppsApi.resetPassword(id).then((dataApp) => {
    dispatcher.handleViewAction({
      type: ActionTypes.DATA_APP_LOAD_SUCCESS,
      dataApp,
    });

    ApplicationActionCreators.sendNotification({
      type: 'success',
      message:
        'Your password has been successfully reset. Please note that this change may take effect within the next minute.',
    });

    return null;
  });
};

export { loadDataApps, loadDataApp, createApp, updateApp, deleteApp, resetPassword };
