import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import Promise from 'bluebird';
import { HelpBlock } from 'design';
import { fromJS, Map } from 'immutable';

import type { Input, InputValue } from '@/api/routes/templatesService';
import { parseCredentialsId } from '@/modules/oauth-v2/OauthUtils';
import Authorization from '@/modules/oauth-v2/react/Authorization';
import AuthorizedBox from '@/modules/oauth-v2/react/AuthorizedBox';
import SyncAccountsSelect from '@/modules/simplified-ui/components/SyncAccountsSelect';
import Checkbox from '@/react/common/Checkbox';
import Select from '@/react/common/Select';

const EMPTY_VALUES = {
  string: '',
  int: 0,
  double: 0,
  bool: false,
  'string[]': [''],
  object: {},
};

type Props = {
  index?: number;
  input: Input;
  onChange: (inputId: string, newValue: InputValue['value']) => void;
  allInputs: Input[];
  allValues: Record<string, InputValue['value']> | null;
  allValidation?: Record<string, string | undefined> | null;
  allOauthCredentials: Map<string, any>;
  allAdmins: Map<string, any>;
  adminEmail?: string;
  templateInfo: {
    repositoryName: string;
    id: string;
    name?: string | undefined;
    version: string;
  };
};

const InstanceConfiguratorInput = ({
  index,
  input,
  onChange,
  allInputs,
  allValues,
  allValidation,
  allOauthCredentials,
  allAdmins,
  adminEmail,
  templateInfo,
}: Props) => {
  const inputValue = allValues?.[input.id];
  const oauthParentInput =
    input.kind === 'oauthAccounts' ? allInputs.find(({ id }) => id === input.oauthInputId) : null;
  const oauthAccountSelectInput =
    input.kind === 'oauth'
      ? allInputs.find(
          ({ oauthInputId, kind }) => kind === 'oauthAccounts' && oauthInputId === input.id,
        )
      : null;
  const validationError = allValidation?.[input.id];

  if (
    input.kind === 'oauthAccounts' &&
    oauthParentInput &&
    !allOauthCredentials.hasIn([
      parseCredentialsId(allValues?.[oauthParentInput.id]?.id),
      'authorizedFor',
    ])
  )
    return null;

  return (
    <FormGroup key={input.id} validationState={validationError ? 'error' : null}>
      {['input', 'hidden', 'textarea'].includes(input.kind) && (
        <>
          <ControlLabel>{input.name}</ControlLabel>
          <FormControl
            autoFocus={index === 0}
            type={
              input.kind === 'hidden'
                ? 'password'
                : ['int', 'double'].includes(input.type)
                  ? 'number'
                  : 'text'
            }
            {...(input.type === 'double' && { step: '0.01' })}
            {...(input.kind === 'textarea' && { componentClass: 'textarea' })}
            value={inputValue || EMPTY_VALUES[input.type]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              let value: InputValue['value'] = event.target.value;

              if ('int' === input.type) {
                value = parseInt(value, 10) || 0;
              }

              if ('double' === input.type) {
                value = parseFloat(value) || 0;
              }

              onChange(input.id, value);
            }}
          />
        </>
      )}
      {['select', 'multiselect'].includes(input.kind) && (
        <>
          <ControlLabel>{input.name}</ControlLabel>
          <Select
            multi={input.kind === 'multiselect'}
            options={input.options}
            value={inputValue}
            onChange={(value: InputValue['value'] & { toJS: () => InputValue['value'] }) =>
              onChange(input.id, value.toJS?.() || value)
            }
          />
        </>
      )}
      {input.kind === 'confirm' && (
        <Checkbox checked={inputValue || false} onChange={(checked) => onChange(input.id, checked)}>
          {input.name}
        </Checkbox>
      )}
      {input.kind === 'oauth' && (
        <>
          <ControlLabel>{input.name}</ControlLabel>
          {inputValue?.id &&
          allOauthCredentials.hasIn([parseCredentialsId(inputValue?.id), 'authorizedFor']) ? (
            <AuthorizedBox
              simpleMode
              credentials={allOauthCredentials.get(parseCredentialsId(inputValue?.id)).toJS()}
              admins={allAdmins}
              onReset={() =>
                new Promise((resolve) => {
                  if (oauthAccountSelectInput) {
                    onChange(
                      oauthAccountSelectInput.id,
                      EMPTY_VALUES[oauthAccountSelectInput.type],
                    );
                  }

                  resolve(onChange(input.id, EMPTY_VALUES[input.type]));
                })
              }
              suffixToTrim={templateInfo.name ? ` (${templateInfo.name})` : ''}
            />
          ) : (
            <Authorization
              componentId={input.componentId}
              fallbackAuthorizedFor={
                templateInfo.name ? `${adminEmail} (${templateInfo.name})` : ''
              }
              onCompleteFn={(credentials: { id: string; version: number }) =>
                onChange(input.id, credentials)
              }
            />
          )}
        </>
      )}
      {input.kind === 'oauthAccounts' && (
        <>
          <ControlLabel>{input.name}</ControlLabel>
          <SyncAccountsSelect
            componentId={oauthParentInput?.componentId}
            oauthCredentials={
              oauthParentInput
                ? allOauthCredentials.get(
                    parseCredentialsId(allValues?.[oauthParentInput.id]?.id),
                    Map(),
                  )
                : Map()
            }
            saved={fromJS(inputValue) || Map()}
            onChange={(data: Map<string, any>) => {
              return onChange(
                input.id,
                data.has('parameters') ? data.get('parameters').toJS() : data.toJS(),
              );
            }}
            readOnly={false}
          />
        </>
      )}
      {!!input.description && <HelpBlock>{input.description}</HelpBlock>}
      {!!validationError && <HelpBlock variant="danger">{validationError}</HelpBlock>}
    </FormGroup>
  );
};

export default InstanceConfiguratorInput;
