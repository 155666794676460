import React from 'react';
import { createRoot } from 'react-dom/client';
import { JSONEditor } from '@json-editor/json-editor';
import { TooltipProvider } from 'design';
import { fromJS } from 'immutable';

import SshForm from '@/react/common/SshForm';

const SshFormApp = React.forwardRef(({ initialValue, onChange }, ref) => {
  const [data, setData] = React.useState(fromJS(initialValue || {}));

  const handleChange = React.useCallback(
    (value) => {
      setData(value);
      onChange(value.toJS());
    },
    [onChange],
  );

  React.useImperativeHandle(ref, () => {
    return { setValue: (value) => setData(fromJS(value || {})) };
  }, []);

  return (
    <SshForm
      isEnabled
      showHelp={false}
      readOnly={false}
      horizontal={false}
      data={data}
      onChange={handleChange}
    />
  );
});

export default class SshFormEditor extends JSONEditor.AbstractEditor {
  renderApp = () => {
    if (this.root) {
      this.root.render(
        <TooltipProvider>
          <SshFormApp
            ref={this.formRef}
            initialValue={this.value}
            onChange={(value) => {
              this.value = value;
              this.onChange(true);
            }}
          />
        </TooltipProvider>,
      );
    }
  };

  build() {
    const editorRoot = window.document.createElement('span');

    this.container.appendChild(editorRoot);

    this.root = createRoot(editorRoot);

    this.formRef = React.createRef();

    window.requestAnimationFrame(this.renderApp);
  }

  setValue(value) {
    super.setValue(value);

    this.formRef?.current?.setValue(value);
  }

  destroy() {
    super.destroy();

    setTimeout(() => {
      this.root?.unmount();
      this.root = null;
    });
  }
}
