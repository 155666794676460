import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Tooltip } from 'design';
import { List, Map } from 'immutable';
import { truncate } from 'underscore.string';

import { loadMergeRequestsForce } from '@/modules/dev-branches/actions';
import {
  isMergeRequestApproved,
  isMergeRequestInReview,
  prepareBranchHref,
  prepareProductionHref,
} from '@/modules/dev-branches/helpers';
import CreatedDate from '@/react/common/CreatedDate';
import DropdownToggleIcon from '@/react/common/DropdownToggleIcon';
import ProductionIcon from '@/react/common/ProductionIcon';
import SearchBar from '@/react/common/SearchBar';
import Truncated from '@/react/common/Truncated';
import matchByWords from '@/utils/matchByWords';
import DropdownContent from './DropdownContent';

class ProjectSelect extends React.Component {
  static propTypes = {
    readOnly: PropTypes.bool.isRequired,
    organizations: PropTypes.object.isRequired,
    currentProject: PropTypes.object.isRequired,
    urlTemplates: PropTypes.object.isRequired,
    projectTemplates: PropTypes.object.isRequired,
    projectBaseUrl: PropTypes.string.isRequired,
    devBranches: PropTypes.instanceOf(Map).isRequired,
    mergeRequests: PropTypes.instanceOf(Map).isRequired,
    hasProtectedDefaultBranch: PropTypes.bool.isRequired,
    hasInvalidCustomBackend: PropTypes.bool.isRequired,
    canDeleteDevBranch: PropTypes.bool.isRequired,
    openCreateDevBranchModalHandler: PropTypes.func.isRequired,
    openEditDevBranchModalHandler: PropTypes.func.isRequired,
    openDeleteDevBranchModalHandler: PropTypes.func.isRequired,
    isDevModeActive: PropTypes.bool.isRequired,
    isDemoPreview: PropTypes.bool.isRequired,
    currentDevBranchId: PropTypes.number,
  };

  state = {
    isProjectOpen: false,
    isBranchOpen: false,
    isProjectListScrolled: false,
    query: '',
  };

  projectsRef = null;
  searchInputRef = null;

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isProjectOpen && this.state.isProjectOpen && this.searchInputRef) {
      this.searchInputRef.focus();
    }
  }

  componentDidMount() {
    if (this.projectsRef) {
      this.projectsRef.addEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = (e) => {
    const { isProjectListScrolled } = this.state;

    const isScrolled = e.target.scrollTop > 0;
    if (isScrolled !== isProjectListScrolled) {
      this.setState({ isProjectListScrolled: isScrolled });
    }
  };

  render() {
    const currentProject = this.props.currentProject;
    const currentOrganization = this.props.currentOrganization;
    const disableProjectsDropdown =
      this.props.isDemoPreview ||
      (this.props.organizations.count() < 2 &&
        currentOrganization.get('projects', List()).count() < 2);
    const hasDevBranches = !this.getDevBranchesWithoutProduction().isEmpty();

    return (
      <>
        <Dropdown
          id="select-project-dropdown"
          rootCloseEvent="mousedown"
          className="project-select"
          open={this.state.isProjectOpen}
          onToggle={(isOpen, event, { source }) => {
            if (
              !event ||
              (source === 'rootClose' &&
                event.composedPath?.().some((el) => el.id === 'select-project-dropdown'))
            ) {
              return;
            }

            this.setState({ isProjectOpen: isOpen });
          }}
          disabled={disableProjectsDropdown}
        >
          <Dropdown.Toggle noCaret bsStyle="link">
            <div
              className="project-name"
              title={`${currentOrganization.getIn([
                'maintainer',
                'name',
              ])} / ${currentOrganization.get('name')} / ${currentProject.get('name')}`}
            >
              <div>
                <span>{this.renderName(currentOrganization.get('name'))}</span>
                <strong>{this.renderName(currentProject.get('name'))}</strong>
              </div>
              {!disableProjectsDropdown && (
                <DropdownToggleIcon isOpen={this.state.isProjectOpen} withBackground />
              )}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="list-unstyled p-0">
            <div
              className={classnames(
                'tw-border-0 tw-border-b tw-border-solid',
                this.state.isProjectListScrolled
                  ? 'tw-border-b-neutral-200'
                  : 'tw-border-b-transparent',
              )}
            >
              <SearchBar
                className="as-input condensed"
                inputRef={(element) => (this.searchInputRef = element)}
                onChange={(query) => this.setState({ query })}
                query={this.state.query}
                placeholder="Search your projects"
              />
            </div>
            <div
              ref={(node) => (this.projectsRef = node)}
              className="tw-max-h-96 tw-overflow-y-auto"
            >
              <DropdownContent
                organizations={this.getFilteredProjects()}
                currentOrganization={currentOrganization}
                currentProject={currentProject}
                query={this.state.query}
                currentUser={this.props.currentUser}
                urlTemplates={this.props.urlTemplates}
              />
            </div>
          </Dropdown.Menu>
        </Dropdown>
        {(!this.props.readOnly || hasDevBranches) && (
          <Dropdown
            id="select-branch-dropdown"
            rootCloseEvent="mousedown"
            className={classnames('branch-select', { 'new-branch-button': !hasDevBranches })}
            open={this.state.isBranchOpen}
            disabled={this.props.hasInvalidCustomBackend}
            onToggle={(isOpen, event, { source }) => {
              if (
                source === 'rootClose' &&
                event.composedPath?.().some((el) => {
                  return el.id === 'select-branch-dropdown' || el.classList?.contains('modal');
                })
              ) {
                return;
              }

              this.setState({ isBranchOpen: isOpen });
              if (!hasDevBranches) {
                this.props.openCreateDevBranchModalHandler(event);
                return this.setState({ isBranchOpen: false });
              }

              if (this.props.hasProtectedDefaultBranch && isOpen) {
                loadMergeRequestsForce();
              }
            }}
          >
            <Dropdown.Toggle noCaret bsStyle="link">
              <div
                className={classnames(
                  'branch-name',
                  this.props.hasInvalidCustomBackend && 'tw-pointer-events-none tw-opacity-60',
                )}
                title={
                  hasDevBranches
                    ? this.props.devBranches.getIn([this.props.currentDevBranchId, 'name'])
                    : ''
                }
              >
                {this.renderBranchesToggle()}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className={classnames('list-unstyled', { hidden: !hasDevBranches })}>
              {this.renderBranchesList()}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </>
    );
  }

  renderBranchesList() {
    const devBranches = this.getDevBranchesWithoutProduction();

    if (devBranches.isEmpty()) {
      return null;
    }

    return (
      <>
        <li>
          {this.props.isDevModeActive && (
            <>
              <a
                href={prepareProductionHref(
                  this.props.projectBaseUrl,
                  location.pathname,
                  this.props.currentDevBranchId,
                )}
              >
                <ProductionIcon className="icon-addon-right" width={16} height={16} />
                Back to Production
              </a>
              <hr />
            </>
          )}
        </li>
        {devBranches
          .sortBy((branch) => -1 * new Date(branch.get('created')).getTime())
          .map((branch) => {
            const mergeRequest = this.props.mergeRequests.get(branch.get('id'), Map());
            const inReview = isMergeRequestInReview(mergeRequest);
            const isApproved = isMergeRequestApproved(mergeRequest);

            const branchLinkContent = (
              <>
                <FontAwesomeIcon
                  icon={['far', 'code-branch']}
                  className={classnames('icon-addon-right', {
                    'color-warning': !inReview && !isApproved,
                    'color-purple': inReview,
                    'color-success': isApproved,
                  })}
                  fixedWidth
                />
                <Truncated text={branch.get('name')} className="mr-1" />
                <CreatedDate
                  className="branch-info text-muted font-normal no-wrap"
                  createdTime={branch.get('created')}
                />
                {!this.props.readOnly && (
                  <span className="branch-buttons">
                    <Tooltip placement="top" tooltip="Edit">
                      <Button
                        bsStyle="link"
                        className="btn-link-inline text-muted"
                        onClick={(event) => {
                          event.stopPropagation();
                          this.props.openEditDevBranchModalHandler(event, branch.get('id'));
                        }}
                      >
                        <FontAwesomeIcon icon="pen" fixedWidth />
                      </Button>
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      type={this.props.canDeleteDevBranch ? 'action' : 'explanatory'}
                      tooltip={
                        this.props.canDeleteDevBranch
                          ? 'Delete'
                          : "You don't have permission to delete development branch"
                      }
                    >
                      <Button
                        bsStyle="link"
                        className={classnames('btn-link-inline text-muted', {
                          disabled: !this.props.canDeleteDevBranch,
                        })}
                        onClick={(event) => {
                          event.stopPropagation();

                          if (this.props.canDeleteDevBranch) {
                            this.props.openDeleteDevBranchModalHandler(event, branch.get('id'));
                          }
                        }}
                      >
                        <FontAwesomeIcon icon="trash" fixedWidth />
                      </Button>
                    </Tooltip>
                  </span>
                )}
              </>
            );

            return (
              <li key={branch.get('id')} className="branch-item">
                {this.props.currentDevBranchId === branch.get('id') ? (
                  <span className="active">{branchLinkContent}</span>
                ) : (
                  <a
                    href={prepareBranchHref(
                      this.props.projectBaseUrl,
                      location.pathname,
                      branch.get('id'),
                      this.props.currentDevBranchId,
                    )}
                  >
                    {branchLinkContent}
                  </a>
                )}
              </li>
            );
          })
          .toArray()}
        {!this.props.readOnly && (
          <li>
            <hr />
            <Button
              bsStyle="link"
              className="btn-link-inline"
              onClick={(event) => {
                event.stopPropagation();
                this.props.openCreateDevBranchModalHandler(event);
              }}
            >
              <FontAwesomeIcon fixedWidth icon="plus" className="icon-addon-right color-success" />
              New Development Branch
            </Button>
          </li>
        )}
      </>
    );
  }

  renderBranchesToggle() {
    const devBranches = this.getDevBranchesWithoutProduction();

    if (devBranches.isEmpty()) {
      return (
        <strong>
          <FontAwesomeIcon
            icon={['far', 'code-branch']}
            className="icon-addon-right color-warning"
            fixedWidth
          />
          New Development Branch
        </strong>
      );
    }

    if (this.props.isDevModeActive) {
      const mergeRequest = this.props.mergeRequests.get(this.props.currentDevBranchId, Map());
      const inReview = isMergeRequestInReview(mergeRequest);
      const isApproved = isMergeRequestApproved(mergeRequest);

      return (
        <>
          <FontAwesomeIcon
            icon={['far', 'code-branch']}
            className={classnames('icon-addon-right', {
              'color-warning': !inReview && !isApproved,
              'color-purple': inReview,
              'color-success': isApproved,
            })}
            fixedWidth
          />
          <strong>
            {this.renderName(devBranches.getIn([this.props.currentDevBranchId, 'name']), 24)}
          </strong>
          <DropdownToggleIcon isOpen={this.state.isBranchOpen} withBackground />
        </>
      );
    }

    return (
      <>
        <ProductionIcon className="icon-addon-right" />
        <strong>Production</strong>
        <DropdownToggleIcon isOpen={this.state.isBranchOpen} withBackground />
      </>
    );
  }

  renderName(name, truncateLimit = 20) {
    return truncate(name, truncateLimit);
  }

  getFilteredProjects() {
    let organizations = this.props.organizations
      .sortBy((organization) => organization.get('name'))
      .update('projects', List(), (projects) => projects.sortBy((project) => project.get('name')));

    if (!this.state.query) {
      return organizations;
    }

    return organizations.reduce((filteredOrganizations, organization) => {
      if (matchByWords(organization.get('name'), this.state.query)) {
        return filteredOrganizations.push(organization);
      }

      const matchedProjects = organization
        .get('projects', List())
        .filter((project) => matchByWords(project.get('name'), this.state.query));

      if (!matchedProjects.isEmpty()) {
        return filteredOrganizations.push(organization.set('projects', matchedProjects));
      }

      return filteredOrganizations;
    }, List());
  }

  getDevBranchesWithoutProduction() {
    return this.props.devBranches.delete(
      this.props.devBranches.findKey((branch) => !!branch.get('isDefault')),
    );
  }
}

export default ProjectSelect;
