const routeNames = {
  WORKSPACES: 'workspaces',
  WORKSPACE: 'workspace',
  GENERIC_WORKSPACE_FILE_INPUT_MAPPING: 'generic-workspace-file-input-mapping',
  WORKSPACE_VERSIONS: 'workspace-versions',
};

const ActionTypes = {
  PROJECT_SETTINGS_LOAD_SUCCESS: 'PROJECT_SETTINGS_LOAD_SUCCESS',

  SANDBOXES_LOAD: 'SANDBOXES_LOAD',
  SANDBOXES_LOAD_SUCCESS: 'SANDBOXES_LOAD_SUCCESS',
  SANDBOXES_LOAD_ERROR: 'SANDBOXES_LOAD_ERROR',

  SANDBOX_LOAD_SUCCESS: 'SANDBOX_LOAD_SUCCESS',

  SANDBOXES_DELETE: 'SANDBOXES_DELETE',
  SANDBOXES_DELETE_SUCCESS: 'SANDBOXES_DELETE_SUCCESS',

  SANDBOXES_RESTORE: 'SANDBOXES_RESTORE',
  SANDBOXES_RESTORE_SUCCESS: 'SANDBOXES_RESTORE_SUCCESS',

  SANDBOXES_TERMINATE: 'SANDBOXES_TERMINATE',
  SANDBOXES_TERMINATE_SUCCESS: 'SANDBOXES_TERMINATE_SUCCESS',

  SANDBOXES_SHARE: 'SANDBOXES_SHARE',
  SANDBOXES_SHARE_SUCCESS: 'SANDBOXES_SHARE_SUCCESS',

  SANDBOX_UPDATE_PARAMETERS: 'SANDBOX_UPDATE_PARAMETERS',
  SANDBOX_UPDATE_PARAMETERS_SUCCESS: 'SANDBOX_UPDATE_PARAMETERS_SUCCESS',

  SANDBOXES_LOAD_DATA: 'SANDBOXES_LOAD_DATA',
  SANDBOXES_LOAD_DATA_SUCCESS: 'SANDBOXES_LOAD_DATA_SUCCESS',

  SANDBOXES_UNLOAD_DATA: 'SANDBOXES_UNLOAD_DATA',
  SANDBOXES_UNLOAD_DATA_SUCCESS: 'SANDBOXES_UNLOAD_DATA_SUCCESS',

  SANDBOXES_PROCESSING_JOBS_LOADED: 'SANDBOXES_PROCESSING_JOBS_LOADED',
};

const PENDING_JOBS_QUERY =
  'component:docker AND params.component:keboola.sandboxes AND (status:processing OR status:waiting)';

const SANDBOX_TYPE = {
  SNOWFLAKE: 'snowflake',
  REDSHIFT: 'redshift',
  EXASOL: 'exasol',
  TERADATA: 'teradata',
  BIGQUERY: 'bigquery',
  JULIA: 'julia',
  PYTHON: 'python',
  PYTHON_MLFLOW: 'python-mlflow',
  PYTHON_DATABRICKS: 'python-databricks',
  PYTHON_SNOWPARK: 'python-snowpark',
  SYNAPSE: 'synapse',
  R: 'r',
  STREAMLIT: 'streamlit',
};

const SANDBOX_SIZES = {
  TINY: 'tiny',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const AUTO_SLEEP_DEFAULT = 1;
const AUTO_SLEEP_OPTIONS = [1, 2, 4, 8, 12, 16, 20, 24];

const SANDBOX_LABELS = {
  [SANDBOX_SIZES.TINY]: 'XSmall - 8 GB RAM, 1 CPU core, 1TB SSD shared',
  [SANDBOX_SIZES.SMALL]: 'Small - 16 GB RAM, 2 CPU cores, 1TB SSD shared',
  [SANDBOX_SIZES.MEDIUM]: 'Medium - 32 GB RAM, 4 CPU cores, 1TB SSD shared',
  [SANDBOX_SIZES.LARGE]: 'Large - 120 GB RAM, 14 CPU cores, 1TB dedicated',
};

const CONTAINER_BASED = [
  SANDBOX_TYPE.JULIA,
  SANDBOX_TYPE.PYTHON,
  SANDBOX_TYPE.PYTHON_DATABRICKS,
  SANDBOX_TYPE.PYTHON_MLFLOW,
  SANDBOX_TYPE.PYTHON_SNOWPARK,
  SANDBOX_TYPE.R,
  SANDBOX_TYPE.STREAMLIT,
];

const NEED_CLIENT_TO_CONNECT = [
  SANDBOX_TYPE.REDSHIFT,
  SANDBOX_TYPE.SYNAPSE,
  SANDBOX_TYPE.EXASOL,
  SANDBOX_TYPE.TERADATA,
  SANDBOX_TYPE.BIGQUERY,
];

const SUPPORT_SIZES = [
  SANDBOX_TYPE.JULIA,
  SANDBOX_TYPE.PYTHON,
  SANDBOX_TYPE.PYTHON_MLFLOW,
  SANDBOX_TYPE.PYTHON_DATABRICKS,
  SANDBOX_TYPE.PYTHON_SNOWPARK,
  SANDBOX_TYPE.STREAMLIT,
  SANDBOX_TYPE.R,
];

const BILLABLE = [
  SANDBOX_TYPE.JULIA,
  SANDBOX_TYPE.PYTHON,
  SANDBOX_TYPE.PYTHON_DATABRICKS,
  SANDBOX_TYPE.PYTHON_MLFLOW,
  SANDBOX_TYPE.PYTHON_SNOWPARK,
  SANDBOX_TYPE.R,
];

const SUPPORTS_READONLY_STORAGE_ACCESS_TOGGLE = [SANDBOX_TYPE.SNOWFLAKE];
const FORCE_READONLY_STORAGE_ACCESS = [SANDBOX_TYPE.PYTHON_SNOWPARK, SANDBOX_TYPE.BIGQUERY];

const ONLY_READONLY_STORAGE = [SANDBOX_TYPE.TERADATA];

const ENABLE_SHARING_MESSAGE =
  'You are about to enable sharing of this workspace. Workspace will be available to all project users.';
const DISABLE_SHARING_MESSAGE = 'You are about to disable sharing of this workspace.';

const DISABLED_WORKSPACE_ACTIONS_MESSAGE =
  'Unavailable for external tables and tables created in development branch';

const AUTO_SLEEP_NODE =
  'Workspace will automatically switch to sleep mode if inactive for selected period of time. Timeout can be changed only during the restoration from sleep mode with new settings.';

const FORM_STEPS = {
  SANDBOX_LIST: 'sandbox_list',
  SANDBOX_CREATE: 'sandbox_create',
  SANDBOX_UPDATE: 'sandbox_update',
};

export {
  routeNames,
  ActionTypes,
  PENDING_JOBS_QUERY,
  SANDBOX_TYPE,
  SANDBOX_SIZES,
  SANDBOX_LABELS,
  CONTAINER_BASED,
  SUPPORT_SIZES,
  AUTO_SLEEP_DEFAULT,
  AUTO_SLEEP_OPTIONS,
  BILLABLE,
  SUPPORTS_READONLY_STORAGE_ACCESS_TOGGLE,
  FORCE_READONLY_STORAGE_ACCESS,
  ONLY_READONLY_STORAGE,
  ENABLE_SHARING_MESSAGE,
  DISABLE_SHARING_MESSAGE,
  DISABLED_WORKSPACE_ACTIONS_MESSAGE,
  NEED_CLIENT_TO_CONNECT,
  FORM_STEPS,
  AUTO_SLEEP_NODE,
};
