import React from 'react';
import type { ComponentProps } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'design';
import type { Map } from 'immutable';

import { getFolderFromMetadata } from '@/modules/components/helpers';
import ComponentBoxModal from '@/modules/components-directory/components/ComponentBoxModal';

const NewFlowButton = (props: {
  component: Map<string, any>;
  componentMetadata: Map<string, any>;
  readOnly: boolean;
  size?: ComponentProps<typeof Button>['size'];
}) => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <Button size={props.size} onClick={() => setShowModal(true)} disabled={props.readOnly}>
        <FontAwesomeIcon icon="plus" />
        Create Flow
      </Button>
      <ComponentBoxModal
        show={showModal}
        allowFolder
        component={props.component}
        folders={props.componentMetadata
          .map((metadata) => getFolderFromMetadata(metadata))
          .filter(Boolean)
          .toSet()
          .toList()}
        readOnly={props.readOnly}
        onHide={() => setShowModal(false)}
      />
    </>
  );
};

export default NewFlowButton;
