import React from 'react';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { KEBOOLA_EX_MONGODB as componentId } from '@/constants/componentIds';
import ComponentDescription from '@/modules/components/react/components/ComponentDescription';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ConfigurationRowsActionCreators from '@/modules/configurations/ConfigurationRowsActionCreators';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import DeleteConfigurationRowButton from '@/modules/configurations/react/components/DeleteConfigurationRowButton';
import QueryEditor from '@/modules/ex-mongodb/react/components/QueryEditor';
import ConfigurationInfoPanel from '@/react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import Sidebar from '@/react/layout/Sidebar/Sidebar';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const Detail = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      RoutesStore,
      ConfigurationRowsStore,
      ComponentsStore,
      InstalledComponentsStore,
    ),
  ],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const rowId = RoutesStore.getCurrentRouteParam('row');
    const editing =
      ConfigurationRowsStore.getEditingConfiguration(componentId, configId, rowId) || Map();

    return {
      rowId,
      configId,
      editing,
      query: editing.get('parameters', Map()),
      row: ConfigurationRowsStore.get(componentId, configId, rowId),
      pendingActions: ConfigurationRowsStore.getPendingActions(componentId, configId, rowId),
      component: ComponentsStore.getComponent(componentId),
      configuration: InstalledComponentsStore.getConfig(componentId, configId),
      readOnly: ApplicationStore.isReadOnly(),
    };
  },

  render() {
    return (
      <>
        <ConfigurationTabs
          componentId={componentId}
          configId={this.state.configId}
          rowId={this.state.rowId}
        />
        <ConfigurationInfoPanel
          component={this.state.component}
          config={this.state.configuration}
        />
        <div className="row box-separator">
          <div className="col-sm-9">
            <ComponentDescription
              componentId={componentId}
              configId={this.state.configId}
              rowId={this.state.rowId}
            />
            <div className="box-separator">
              <QueryEditor
                isRow
                componentId={componentId}
                configId={this.state.configId}
                query={this.state.query}
                configState={this.state.row.get('state', Map())}
                onChange={this.handleChange}
                onResetState={this.handleResetState}
                readOnly={this.state.readOnly}
              />
            </div>
          </div>
          <div className="col-sm-3">
            <Sidebar
              componentId={componentId}
              configId={this.state.configId}
              rowId={this.state.rowId}
              delete={
                <DeleteConfigurationRowButton
                  onClick={this.handleDeleteRow}
                  isPending={this.state.pendingActions.has('delete')}
                  mode="link"
                />
              }
            />
          </div>
        </div>
      </>
    );
  },

  handleChange(parameters: Map<string, any>) {
    return ConfigurationRowsActionCreators.updateConfiguration(
      componentId,
      this.state.configId,
      this.state.rowId,
      this.state.editing.set('parameters', parameters),
    );
  },

  handleDeleteRow() {
    ConfigurationRowsActionCreators.delete(
      componentId,
      this.state.configId,
      this.state.rowId,
      true,
      `${this.state.row.get('name')} deleted`,
    );
  },

  handleResetState() {
    return ConfigurationRowsActionCreators.clearComponentState(
      componentId,
      this.state.configId,
      this.state.rowId,
    );
  },
});

export default Detail;
