import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, cn } from 'design';

import type { PostMessageResponse } from '@/api/routes/aiService';
import PromptInput from '@/react/common/AI/PromptInput';
import FullScreenModal, { FullScreenModalHeader } from '@/react/common/FullScreenModal';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { hideButton, showButton } from '@/utils/external/productFruits';
import Message from './interactive-elements/Mesage';
import { sendMessage } from './actions';
import { type MessageObject, roles, routeNames } from './constants';
import AutomationsStore from './store';

const Automation = () => {
  const store = useStores(
    () => {
      const id = RoutesStore.getCurrentRouteParam('id');
      const initialMessage = RoutesStore.getRouterState().getIn(['location', 'state', 'message']);

      return {
        initialMessage,
        automation: AutomationsStore.getStore().automations.find(
          (automation) => automation.id === id,
        ),
        admin: ApplicationStore.getCurrentAdmin(),
      };
    },
    [],
    [ApplicationStore, AutomationsStore, RoutesStore],
  );
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState<(MessageObject | PostMessageResponse)[]>([]);
  const conversationStarted = !!history.length;

  const onClose = () => RoutesStore.getRouter().transitionTo(routeNames.ROOT);

  const submitMessage = React.useCallback(
    (messageObject: MessageObject) => {
      if (isLoading || !store.automation?.id) return;

      if (messageObject.text) {
        setHistory((history) => [...history, messageObject]);
      }

      setPrompt('');
      setIsLoading(true);
      return sendMessage(store.automation.id, messageObject)
        .then((response) => setHistory((history) => [...history, response]))
        .finally(() => setIsLoading(false));
    },
    [isLoading, store.automation?.id],
  );

  React.useEffect(() => {
    if (store.initialMessage && history.length === 0) {
      submitMessage({ text: store.initialMessage, type: 'plainText', role: roles.USER });
    }
  }, [history, store.initialMessage, submitMessage]);

  React.useEffect(() => {
    setTimeout(() => hideButton(), 500); // TODO: Fix this workaround

    return () => showButton();
  }, []);

  if (!store.automation) {
    return null;
  }

  return (
    <FullScreenModal
      className={cn(
        'tw-pb-6 [&_.modal-content]:tw-h-full [&_.modal-content]:tw-p-0 [&_.modal-content_>_div]:tw-flex [&_.modal-content_>_div]:tw-h-full [&_.modal-content_>_div]:tw-flex-col',
        { '[&_.modal-content_>_div]:tw-justify-between': !!conversationStarted },
      )}
    >
      <FullScreenModalHeader
        title="Automation"
        onClose={onClose}
        className="tw-bg-white tw-px-5 tw-pt-5"
      >
        <Button onClick={onClose} variant="outline">
          <FontAwesomeIcon icon="xmark" />
        </Button>
      </FullScreenModalHeader>
      <Modal.Body className="tw-flex tw-flex-col tw-overflow-hidden tw-px-5">
        {conversationStarted && (
          <div className="-tw-mx-5 tw-mb-20 tw-overflow-auto tw-px-5">
            <div className="tw-mx-auto tw-flex tw-max-w-4xl tw-flex-col tw-gap-2">
              {history.map((messageObject: (typeof history)[number], index) => {
                if (!messageObject.text && !messageObject.data) return null;

                return (
                  <Message
                    key={index}
                    admin={store.admin}
                    messageObject={messageObject}
                    submitMessage={submitMessage}
                    isLastMessage={index === history.length - 1}
                  />
                );
              })}
            </div>
          </div>
        )}
        <PromptInput
          autoFocus
          multiLine
          placeholder="e.g.  I'd like to see how different restaurants from my chain perform"
          prompt={prompt}
          onChange={setPrompt}
          onSubmit={() => submitMessage({ text: prompt, type: 'plainText', role: roles.USER })}
          isLoading={isLoading}
          isDisabled={isLoading}
          className="tw-fixed tw-bottom-6 tw-left-0 tw-right-0 tw-mx-auto tw-max-w-[58rem] tw-px-4"
        />
      </Modal.Body>
    </FullScreenModal>
  );
};

export default Automation;
