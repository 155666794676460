import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Link } from 'design';
import { Map } from 'immutable';

import { APIFY_APIFY as COMPONENT_ID, KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import actionsProvisioning from '@/modules/apify/actionsProvisioning';
import storeProvisioning from '@/modules/apify/storeProvisioning';
import ComponentDescription from '@/modules/components/react/components/ComponentDescription';
import ComponentEmptyState from '@/modules/components/react/components/ComponentEmptyState';
import SapiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import { prepareTablesMetadataMap } from '@/modules/storage/helpers';
import CodeEditor from '@/react/common/CodeEditor';
import ConfigurationInfoPanel from '@/react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import Sidebar from '@/react/layout/Sidebar/Sidebar';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import SetupModal from './SetupModal';

const Index = createReactClass({
  mixins: [
    createStoreMixin(
      ComponentsStore,
      InstalledComponentsStore,
      StorageBucketsStore,
      StorageTablesStore,
      ApplicationStore,
    ),
  ],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const store = storeProvisioning(configId);
    const actions = actionsProvisioning(configId);

    return {
      store,
      actions,
      configId,
      component: ComponentsStore.getComponent(COMPONENT_ID),
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      parameters: store.parameters,
      allBuckets: StorageBucketsStore.getAll(),
      allTables: StorageTablesStore.getAll(),
      allComponents: ComponentsStore.getAll(),
      inputTableId: store.inputTable && store.inputTable.get('source'),
      localState: store.getLocalState(),
      readOnly: ApplicationStore.isReadOnly(),
    };
  },

  render() {
    return (
      <>
        <ConfigurationTabs componentId={COMPONENT_ID} configId={this.state.configId} />
        <ConfigurationInfoPanel
          component={this.state.component}
          allComponents={this.state.allComponents}
          config={this.state.store.config}
          flows={this.state.flows}
          tablesMetadataMap={prepareTablesMetadataMap(this.state.allTables)}
          metadata={this.state.componentsMetadata}
        />
        <div className="row">
          <div className="col-sm-9">
            <ComponentDescription componentId={COMPONENT_ID} configId={this.state.configId} />
            {this.isConfigured() ? this.renderStatic() : this.renderStartSetup()}
            {this.renderSetupModal()}
          </div>
          <div className="col-sm-3">
            <Sidebar
              componentId={COMPONENT_ID}
              configId={this.state.configId}
              run={{
                disabled: this.isConfigured() ? '' : 'Not configured yet',
                text: 'You are about to run the component.',
              }}
            />
          </div>
        </div>
      </>
    );
  },

  renderSetupModal() {
    return (
      <SetupModal
        inputTableId={this.state.inputTableId}
        parameters={this.state.parameters}
        show={this.state.localState.getIn(['SetupModal', 'show'], false)}
        onHideFn={this.hideSetupModal}
        {...this.state.actions.prepareLocalState(['SetupModal', 'data'])}
        loadActors={this.state.actions.loadActors}
        loadTasks={this.state.actions.loadTasks}
        onSave={(params, inputTable) =>
          this.state.actions.saveConfig(params, inputTable).then(this.hideSetupModal)
        }
        isSaving={this.state.localState.get('saving') || false}
        buckets={this.state.allBuckets}
        tables={this.state.allTables}
      />
    );
  },

  renderStartSetup() {
    return (
      <div className="box">
        <div className="box-content">
          <ComponentEmptyState>
            <p>Not configured yet</p>
            {!this.state.readOnly && (
              <Button bsStyle="success" onClick={this.showSetupModal}>
                Configure component
              </Button>
            )}
          </ComponentEmptyState>
        </div>
      </div>
    );
  },

  renderStatic() {
    return (
      <div className="box">
        <div className="box-header with-border big-padding">
          <h3 className="box-title">Configution Parameters</h3>
          {!this.state.readOnly && (
            <Button bsStyle="success" onClick={this.showSetupModal}>
              <FontAwesomeIcon icon="pen" className="icon-addon-right" />
              Edit Configuration
            </Button>
          )}
        </div>
        <div className="box-content">
          <div className="form-horizontal">
            {this.renderActionTypeDetails()}
            {this.renderOutputFields()}
            {this.renderStaticFormGroup(
              'Results table',
              <SapiTableLinkEx tableId={`${this.state.store.outputBucket}.dataset-items`} />,
            )}
          </div>
        </div>
      </div>
    );
  },

  renderActionTypeDetails() {
    if (this.state.parameters.get('actionType') === 'runActor') {
      return (
        <>
          {this.renderStaticFormGroup(
            'Actor ID',
            <Link href={`https://console.apify.com/acts/${this.state.parameters.get('actId')}`}>
              {this.state.parameters.get('actId')}
            </Link>,
          )}
          {this.renderStaticFormGroup(
            'Input Table',
            this.state.inputTableId ? <SapiTableLinkEx tableId={this.state.inputTableId} /> : 'N/A',
          )}
          {this.renderStaticFormGroup('Memory', this.state.parameters.get('memory'))}
          {this.renderStaticFormGroup('Build', this.state.parameters.get('build'))}
          {this.renderStaticFormGroup(
            'Actor Input',
            this.renderReadOnlyJsonEditor(this.state.parameters.get('input', Map()).toJS()),
          )}
        </>
      );
    }

    if (this.state.parameters.get('actionType') === 'getActorLastRunDatasetItems') {
      return (
        <>
          {this.renderStaticFormGroup(
            'Actor ID',
            <Link href={`https://console.apify.com/acts/${this.state.parameters.get('actId')}`}>
              {this.state.parameters.get('actId')}
            </Link>,
          )}
        </>
      );
    }

    if (this.state.parameters.get('actionType') === 'runTask') {
      return (
        <>
          {this.renderStaticFormGroup(
            'Task ID',
            <Link
              href={`https://console.apify.com/tasks/${this.state.parameters.get('actorTaskId')}`}
            >
              {this.state.parameters.get('actorTaskId')}
            </Link>,
          )}
          {this.renderStaticFormGroup(
            'Input Table',
            this.state.inputTableId ? <SapiTableLinkEx tableId={this.state.inputTableId} /> : 'N/A',
          )}
          {this.renderStaticFormGroup('Memory', this.state.parameters.get('memory'))}
          {this.renderStaticFormGroup('Build', this.state.parameters.get('build'))}
          {this.renderStaticFormGroup(
            'Input Overrides',
            this.renderReadOnlyJsonEditor(this.state.parameters.get('input', Map()).toJS()),
          )}
        </>
      );
    }

    if (this.state.parameters.get('actionType') === 'getTaskLastRunDatasetItems') {
      return (
        <>
          {this.renderStaticFormGroup(
            'Task ID',
            <Link
              href={`https://console.apify.com/tasks/${this.state.parameters.get('actorTaskId')}`}
            >
              {this.state.parameters.get('actorTaskId')}
            </Link>,
          )}
        </>
      );
    }

    if (this.state.parameters.get('actionType') === 'getDatasetItems') {
      return this.renderStaticFormGroup('Dataset', this.state.parameters.get('datasetId'));
    }

    return null;
  },

  renderOutputFields() {
    const fields = this.state.parameters.get('fields');

    if (!fields) {
      return null;
    }

    return this.renderStaticFormGroup(
      'Output fields',
      typeof fields === 'string' ? fields : fields.join(', '),
    );
  },

  renderStaticFormGroup(controlLabel, control) {
    return (
      <div className="form-group">
        <label className="col-xs-3 control-label">{controlLabel}</label>
        <div className="col-xs-9">
          <div className="form-control-static">{control}</div>
        </div>
      </div>
    );
  },

  renderReadOnlyJsonEditor(data) {
    return (
      <CodeEditor
        value={data ? JSON.stringify(data, null, '  ') : '{}'}
        options={{
          lineNumbers: false,
          readOnly: true,
          cursorHeight: 0,
        }}
      />
    );
  },

  isConfigured() {
    const action = this.state.parameters.get('actionType');
    const isConfigured =
      (action === 'getDatasetItems' && this.state.parameters.get('datasetId')) ||
      (['runActor', 'getActorLastRunDatasetItems'].includes(action) &&
        this.state.parameters.get('actId')) ||
      (['runTask', 'getTaskLastRunDatasetItems'].includes(action) &&
        this.state.parameters.get('actorTaskId'));
    return !!this.state.parameters.get('#token') && !!isConfigured;
  },

  showSetupModal() {
    this.updateLocalState('SetupModal', Map());
    this.updateLocalState(['SetupModal', 'show'], true);
  },

  hideSetupModal() {
    this.updateLocalState(['SetupModal', 'show'], false);
  },

  updateLocalState(path, data) {
    this.state.actions.updateLocalState(path, data);
  },
});

export default Index;
