import ApplicationStore from '@/stores/ApplicationStore';

const INTERNAL_PREFIX = '[_internal]';

const getCurrentHostname = () => {
  return new URL(ApplicationStore.getSapiUrl() as string).hostname;
};

const isInternal = (desciption?: string, specific?: 'schedule' | 'trigger' | 'app') => {
  if (!desciption) {
    return false;
  }

  switch (specific) {
    case 'schedule':
      return desciption.startsWith(`${INTERNAL_PREFIX} Scheduler for`);
    case 'trigger':
      return desciption.startsWith(`${INTERNAL_PREFIX} Token for triggering`);
    case 'app':
      return desciption.startsWith(`${INTERNAL_PREFIX}[app] App`);
    default:
      return desciption.startsWith(INTERNAL_PREFIX);
  }
};

export { getCurrentHostname, isInternal, INTERNAL_PREFIX };
