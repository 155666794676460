import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'design';

import AiBackground from '../../../images/illustrations/ai-background.png';

import { HIDE_AUTOMATION_STUDIO_WELCOME_WIDGET } from '@/constants/localStorageKeys';
import useLocalStorage from '@/react/hooks/useLocalStorage';

const WelcomeWidget = () => {
  const [isHide, setIsHide] = useLocalStorage(HIDE_AUTOMATION_STUDIO_WELCOME_WIDGET, false);

  if (isHide) {
    return null;
  }

  return (
    <div
      className="tw-relative tw-mb-12 tw-rounded-md tw-px-12 tw-py-9"
      style={{ backgroundImage: `url(${AiBackground})`, backgroundSize: 'cover' }}
    >
      <h2 className="tw-mb-2 tw-mt-0 tw-text-2xl tw-text-white">
        Unleash the Power of Automation with Automation Studio
      </h2>
      <p className="tw-text-white">
        Effortlessly develop and optimize your data pipelines with AI-driven guidance
      </p>
      <Link className="tw-mt-6 tw-inline-flex tw-text-base tw-text-white hover:tw-text-white focus:tw-text-white">
        Discover more about Automation Studio
      </Link>

      <Button
        bsStyle="link"
        className="btn-link-inline tw-absolute tw-right-3 tw-top-3 !tw-text-white hover:!tw-text-neutral-100"
        onClick={() => setIsHide(true)}
      >
        <FontAwesomeIcon icon="xmark" className="!tw-text-xl" />
      </Button>
    </div>
  );
};

export default WelcomeWidget;
