import type BluebirdPromise from 'bluebird';

import { SERVICE_DATA_SCIENCE } from '@/constants/serviceIds';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { SANDBOX_TYPE } from '@/modules/sandboxes/Constants';
import ServicesStore from '@/modules/services/Store';
import ApplicationStore from '@/stores/ApplicationStore';
import request from '@/utils/request';
import type { DataApp } from './DataAppsStore';

const DATA_APPS_LIMIT = 500;

export const createRequest = (method: 'GET' | 'POST' | 'PATCH' | 'DELETE', path: string) => {
  return request(method, `${ServicesStore.getServiceUrl(SERVICE_DATA_SCIENCE)}/${path}`).set(
    'X-StorageApi-Token',
    ApplicationStore.getSapiTokenString(),
  );
};

const DataAppsApi = {
  getDataApps() {
    const dataApps: DataApp[] = [];

    const loadApps = (offset = 0): BluebirdPromise<DataApp[]> => {
      return createRequest('GET', 'apps')
        .query({ limit: DATA_APPS_LIMIT, offset })
        .promise()
        .then((response) => {
          dataApps.push(...response.body);

          if (response.body.length === DATA_APPS_LIMIT) {
            return loadApps(offset + DATA_APPS_LIMIT);
          }

          return dataApps;
        });
    };

    return loadApps();
  },

  getDataApp(id: string): BluebirdPromise<DataApp> {
    return createRequest('GET', `apps/${id}`)
      .promise()
      .then((response) => response.body);
  },

  createDataApp(
    name: string,
    description: string,
    config: Record<string, any>,
  ): BluebirdPromise<DataApp> {
    return createRequest('POST', 'apps')
      .send({
        branchId: DevBranchesStore.isDevModeActive()
          ? DevBranchesStore.getCurrentId()?.toString()
          : null,
        type: SANDBOX_TYPE.STREAMLIT,
        name,
        description,
        config,
      })
      .promise()
      .then((response) => response.body);
  },

  updateDataApp(
    id: string,
    params:
      | { desiredState: 'stopped' }
      | { desiredState: 'running'; restartIfRunning: true; configVersion: string },
  ) {
    return createRequest('PATCH', `apps/${id}`).send(params).promise();
  },

  deleteDataApp(id: string) {
    return createRequest('DELETE', `apps/${id}`).promise();
  },

  loadPassword(id: string): BluebirdPromise<{ password: string }> {
    return createRequest('GET', `apps/${id}/password`)
      .promise()
      .then((response) => response.body);
  },

  resetPassword(id: string): BluebirdPromise<DataApp> {
    return createRequest('POST', `apps/${id}/reset-password`)
      .promise()
      .then((response) => response.body);
  },
};

export default DataAppsApi;
