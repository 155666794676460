import { SERVICE_DOCKER_RUNNER, SERVICE_SYNC_ACTIONS } from '@/constants/serviceIds';
import { REQUEST_ABORTED_ERROR } from '@/constants/superagent';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import ServicesStore from '@/modules/services/Store';
import ApplicationStore from '@/stores/ApplicationStore';
import request from '@/utils/request';
import ComponentsStore from './stores/ComponentsStore';

function createDockerRunnerUrl(componentId, action) {
  const dockerServiceUrl = ServicesStore.getServiceUrl(SERVICE_DOCKER_RUNNER);

  if (DevBranchesStore.isDevModeActive()) {
    return `${dockerServiceUrl}/docker/branch/${DevBranchesStore.getCurrentId()}/${componentId}/action/${action}`;
  }

  return `${dockerServiceUrl}/docker/${componentId}/action/${action}`;
}

function createRequest(method, url) {
  return request(method, url).set('X-StorageApi-Token', ApplicationStore.getSapiTokenString());
}

function resolveTag(component, body) {
  return component.get(
    'tag', // always prefer component override, then runtime tag, then old image_tag
    body?.configData?.runtime?.tag ?? body?.configData?.runtime?.image_tag,
  );
}

export default function (componentId, action, body) {
  return unhandledRequest(componentId, action, body).catch((err) => {
    // If request is not processed within specific timeout (defined in superagent constants file)
    // it's aborted and we throw that error, so it can be handled later
    if (err.code === REQUEST_ABORTED_ERROR) {
      throw err;
    }

    if (err.response) {
      // new sync actions do not send error in a message property, but in an error property
      if (
        ApplicationStore.hasNewQueue() &&
        err.response?.body?.error &&
        !err.response?.body?.message
      ) {
        err.response.body.message = err.response.body.error;
      }

      return err.response.body;
    }

    return err;
  });
}

export function unhandledRequest(componentId, action, body) {
  if (ApplicationStore.hasNewQueue()) {
    const component = ComponentsStore.getComponent(componentId);
    const tag = resolveTag(component, body);

    return createRequest('POST', `${ServicesStore.getServiceUrl(SERVICE_SYNC_ACTIONS)}/actions`)
      .send({
        ...body,
        componentId,
        action,
        branchId: DevBranchesStore.getCurrentId(),
        ...(!!tag && { tag }),
      })
      .promise()
      .then((response) => response.body);
  }

  return createRequest('POST', createDockerRunnerUrl(componentId, action))
    .send(body)
    .promise()
    .then((response) => response.body);
}
