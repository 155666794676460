import React from 'react';
import type { ReactNode } from 'react';

import { canAccessProject } from '@/modules/admin/privileges';
import type { ParsedFQID } from '@/modules/lineage/fqid';
import { prepareHref } from './helpers';
import LinkWithWindowIcon from './LinkWithWindowIcon';

const LineageLink = (props: {
  params: ParsedFQID | Record<string, never>;
  children: ReactNode;
}) => {
  const href = prepareHref(props.params);

  if (!href || !canAccessProject(props.params.projectId)) {
    return <span className="tw-break-all tw-text-right tw-text-neutral-400">{props.children}</span>;
  }

  return <LinkWithWindowIcon href={href}>{props.children}</LinkWithWindowIcon>;
};

export default LineageLink;
