import React from 'react';
import type { ReactElement, ReactNode } from 'react';
import classnames from 'classnames';

import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import ReadOnlyTooltip from '@/react/common/ReadOnlyTooltip';

export type Run =
  | ReactElement
  | {
      params?: () => Record<string, any>;
      text?: ReactNode;
      title?: string;
      disabled?: string;
      forceModal?: boolean;
    };

type Props = {
  run?: Run;
  isReadOnly: boolean;
  componentId: string;
  rowId?: string;
  configId: string;
  additionalPrimaryButtons?: boolean | ReactNode;
};

const isReactElement = (node: Run): node is ReactElement => React.isValidElement(node);

const RunButton = ({
  run,
  isReadOnly,
  componentId,
  rowId,
  configId,
  additionalPrimaryButtons,
}: Props) => {
  const getRunParams = () => {
    if (run && typeof run === 'object' && 'params' in run && run.params) {
      return run.params;
    }

    if (rowId) {
      return () => ({
        config: configId,
        row: rowId,
      });
    }

    return () => ({ config: configId });
  };

  if (!run) {
    return (
      <li>
        <RunComponentButton mode="sidebar" componentId={componentId} runParams={getRunParams()}>
          You are about to run the component.
        </RunComponentButton>
      </li>
    );
  }

  if (isReactElement(run)) {
    return (
      <li>
        <ReadOnlyTooltip readOnly={isReadOnly}>{run}</ReadOnlyTooltip>
        <hr />
      </li>
    );
  }

  return (
    <>
      <li
        className={classnames({
          disabled: !!run.disabled || isReadOnly,
          removeDivider: !!additionalPrimaryButtons,
        })}
      >
        <RunComponentButton
          mode="sidebar"
          componentId={componentId}
          title={run.title}
          runParams={getRunParams()}
          disabled={!!run.disabled}
          disabledReason={run.disabled}
          forceModal={run.forceModal}
        >
          {run.text || 'You are about to run the component.'}
        </RunComponentButton>
      </li>
      {!!additionalPrimaryButtons && (
        <>
          <li className="additionalPrimary">{additionalPrimaryButtons}</li>
          <hr />
        </>
      )}
    </>
  );
};

export default RunButton;
