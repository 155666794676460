import colors from 'tailwindcss/colors';

const UPPER_PANEL_HEIGHT = 100;

const COLUMNS_CHUNK_SIZE = 7;

const HIGHLIGHT_MUTED_OPACITY = 0.25;
const HIGHLIGHT_MUTED_EDGES_OPACITY = 0;

const METADATA_KEYS = {
  DESCRIPTION: 'KBC.description',
  OWNER_METADATA_KEY: 'KBC.owner.token.description',
  VARIABLES: 'KBC.variables',
};

const LLM_EDGE_COLOR = colors.cyan['500'];

export {
  UPPER_PANEL_HEIGHT,
  COLUMNS_CHUNK_SIZE,
  HIGHLIGHT_MUTED_OPACITY,
  HIGHLIGHT_MUTED_EDGES_OPACITY,
  METADATA_KEYS,
  LLM_EDGE_COLOR,
};
