import type { Map } from 'immutable';

import StorageActionCreators from '@/modules/components/StorageActionCreators';
// import { loadAutomations, loadAutomation } from './actions';
import Automation from './Automation';
import { routeNames } from './constants';
import Index from './Index';
import AutomationsStore from './store';

const routes = {
  name: routeNames.ROOT,
  title: 'Automation Studio',
  defaultRouteHandler: Index,
  // requireData: [() => loadAutomations()],
  childRoutes: [
    {
      name: routeNames.DETAIL,
      path: ':id',
      title(routerState: Map<string, any>) {
        return AutomationsStore.getStore().automations.find(
          (automation) => automation.id === routerState.get('params').get('id'),
        )?.id;
      },
      defaultRouteHandler: Automation,
      requireData: [
        // (params) => loadAutomation(params.id),
        () => StorageActionCreators.loadBucketsAndTables(),
      ],
    },
  ],
};

export default routes;
