import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, ControlLabel, FormGroup, Modal, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Promise } from 'bluebird';
import classNames from 'classnames';
import { Tooltip } from 'design';
import { List, Map, Set } from 'immutable';

import keyCodes from '@/constants/keyCodes';
import { getDefaultBucketName } from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import StorageApiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import { createBucket, createTable, uploadFile } from '@/modules/storage/actions';
import CsvUploadForm from '@/modules/storage/components/CsvUploadForm';
import { INITIAL_CSV_FORM_STATE } from '@/modules/storage/constants';
import { routeNames as tableBrowserRouteNames } from '@/modules/table-browser/constants';
import InputMappingSourceInput from '@/modules/transformations/react/components/mapping/InputMappingSourceInput';
import BucketStageLabel from '@/react/common/BucketStageLabel';
import CircleIcon from '@/react/common/CircleIcon';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ConfirmModal from '@/react/common/ConfirmModal';
import DevBranchLabel from '@/react/common/DevBranchLabel';
import { isValidName } from '@/react/common/helpers';
import ModalActionButton from '@/react/common/ModalActionButton';
import ModalIcon from '@/react/common/ModalIcon';
import MultiActionsHeader from '@/react/common/MultiActionsHeader';
import MultiActionsSelectCheckbox from '@/react/common/MultiActionsSelectCheckbox';
import RoutesStore from '@/stores/RoutesStore';
import string from '@/utils/string';
import { parse as parseTable } from '@/utils/tableIdParser';

const COMMON_PROP_TYPES = {
  allComponents: PropTypes.instanceOf(Map).isRequired,
  allConfigurations: PropTypes.instanceOf(Map).isRequired,
  allBuckets: PropTypes.instanceOf(Map).isRequired,
  allTables: PropTypes.instanceOf(Map).isRequired,
  configData: PropTypes.instanceOf(Map),
  componentId: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
  isCreatingTable: PropTypes.bool.isRequired,
  allUploadingProgress: PropTypes.instanceOf(Map).isRequired,
  commonState: PropTypes.object.isRequired,
  setCommonState: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isConfigured: PropTypes.bool,
};
const MODAL_STEPS = { LIST: 'LIST', CSV: 'CSV', STORAGE: 'STORAGE' };

const SelectedTablesList = ({
  selectedTables,
  allBuckets,
  allTables,
  handleRemoveTables,
  readOnly,
  isSaving,
}) => {
  const [selectedForDelete, setSelectedForDelete] = useState(Set());
  const [showMultipleDeleteModal, setShowMultipleDeleteModal] = useState(false);

  const redirectToTable = (tableId) => {
    RoutesStore.getRouter().transitionTo(
      tableBrowserRouteNames.ROOT,
      { tableId },
      { context: window.location.pathname },
    );
  };

  if (selectedTables.isEmpty()) return null;

  return (
    <>
      <Table className="overflow-break-anywhere table-hover">
        <thead>
          <tr>
            <th
              colSpan={readOnly ? '1' : '2'}
              className="pt-0 pl-2 pb-1 line-height-24 f-14 font-medium color-dark"
            >
              <MultiActionsHeader
                entity="table"
                hide={readOnly}
                totalCount={selectedTables.count()}
                selectedCount={selectedForDelete.count()}
                disabled={isSaving}
                onToggleAll={(checked) =>
                  setSelectedForDelete(!checked ? Set() : selectedTables.toSet())
                }
                placeholder="Table"
              >
                <div className="table-action-buttons">
                  <Tooltip placement="top" tooltip="Remove Selected">
                    <Button
                      bsStyle="link"
                      className="text-muted"
                      onClick={() => setShowMultipleDeleteModal(true)}
                      disabled={isSaving}
                    >
                      <FontAwesomeIcon icon="trash" fixedWidth />
                    </Button>
                  </Tooltip>
                </div>
              </MultiActionsHeader>
            </th>
          </tr>
        </thead>
        <tbody>
          {selectedTables
            .groupBy((tableId) => {
              if (!allTables.has(tableId)) {
                const { stage, bucket } = parseTable(tableId).parts;
                return `${stage}.${bucket}`;
              }

              return allTables.getIn([tableId, 'bucket', 'id']);
            })
            .map((tableIds, bucketId) => {
              const bucket = allBuckets.get(bucketId, Map());
              const areSomeBucketTablesSelected = tableIds.some((tableId) =>
                selectedForDelete.has(tableId),
              );
              const areAllBucketTablesSelected = tableIds.every((tableId) =>
                selectedForDelete.has(tableId),
              );

              return (
                <React.Fragment key={bucketId}>
                  <tr className="no-hover bucket-row">
                    <td className="pl-2 flex-container flex-start font-medium">
                      {!readOnly && (
                        <MultiActionsSelectCheckbox
                          entity="all tables in bucket"
                          isChecked={areAllBucketTablesSelected}
                          isIndeterminate={
                            areSomeBucketTablesSelected && !areAllBucketTablesSelected
                          }
                          isDisabled={isSaving}
                          onToggle={(checked) => {
                            if (!checked) {
                              return setSelectedForDelete(
                                selectedForDelete.filter((tableId) => !tableIds.includes(tableId)),
                              );
                            }

                            return setSelectedForDelete(selectedForDelete.concat(tableIds.toSet()));
                          }}
                        />
                      )}
                      <FontAwesomeIcon icon="folder" className="color-base icon-addon-right pl-1" />
                      <BucketStageLabel placement="left" stage={bucket.get('stage')} />
                      <DevBranchLabel bucket={bucket} />
                      {bucket.get('displayName', bucketId)}
                    </td>
                  </tr>
                  {tableIds
                    .map((tableId) => {
                      return (
                        <tr
                          key={tableId}
                          className="clickable hoverable-actions table-row"
                          onClick={() => redirectToTable(tableId)}
                          onKeyDown={(event) => {
                            if (event.key === keyCodes.ENTER) {
                              redirectToTable(tableId);
                            }
                          }}
                          role="button"
                          tabIndex="0"
                        >
                          <td className="no-wrap pl-2">
                            <div className="flex-container flex-start">
                              {!readOnly && (
                                <MultiActionsSelectCheckbox
                                  entity="table"
                                  isChecked={selectedForDelete.has(tableId)}
                                  isDisabled={isSaving}
                                  onToggle={(checked) =>
                                    setSelectedForDelete(
                                      checked
                                        ? selectedForDelete.add(tableId)
                                        : selectedForDelete.delete(tableId),
                                    )
                                  }
                                />
                              )}
                              <div className={classNames({ 'ml-1': readOnly })}>
                                <FontAwesomeIcon
                                  icon="table"
                                  className="pl-1 text-muted icon-addon-right"
                                />
                                <StorageApiTableLinkEx
                                  tableId={tableId}
                                  showLabels={false}
                                  showOnlyDisplayName
                                  className="link-inherit no-underline"
                                />
                              </div>
                            </div>
                          </td>
                          {!readOnly && (
                            <td className="pt-0 pb-0 pl-0 pr-1 no-wrap">
                              <Tooltip placement="top" tooltip="Remove Source Table">
                                <Button
                                  bsStyle="link"
                                  className="text-muted"
                                  onClick={(e) => {
                                    e.stopPropagation();

                                    handleRemoveTables([tableId]);
                                  }}
                                >
                                  <FontAwesomeIcon icon="trash" fixedWidth />
                                </Button>
                              </Tooltip>
                            </td>
                          )}
                        </tr>
                      );
                    })
                    .toArray()}
                </React.Fragment>
              );
            })
            .toArray()}
        </tbody>
      </Table>
      <ConfirmModal
        closeAfterResolve
        show={showMultipleDeleteModal}
        icon="trash"
        title="Remove Selected"
        text={`Are you sure you want to remove the ${
          selectedForDelete.count() > 1 ? 'selected tables' : 'table'
        }?`}
        buttonLabel="Remove"
        buttonType="danger"
        onConfirm={() =>
          handleRemoveTables(selectedForDelete.toArray()).then(() => setSelectedForDelete(Set()))
        }
        onHide={() => setShowMultipleDeleteModal(false)}
      />
    </>
  );
};

export class SimplifiedTableInputMapping extends React.Component {
  static propTypes = { ...COMMON_PROP_TYPES, isHeaderButton: PropTypes.bool };

  state = {
    tablesToBeSelected: List(),
    isSaving: false,
    showModal: false,
    modalStep: MODAL_STEPS.LIST,
    csvFormState: INITIAL_CSV_FORM_STATE,
  };

  render() {
    return (
      <>
        {this.renderSourcesSelector()}
        {!this.props.isHeaderButton && (
          <SelectedTablesList
            selectedTables={this.props.configData.getIn(['parameters', 'tables'], List())}
            allBuckets={this.props.allBuckets}
            allTables={this.props.allTables}
            handleRemoveTables={this.handleRemoveTables}
            isSaving={this.state.isSaving}
            readOnly={this.props.readOnly}
          />
        )}
      </>
    );
  }

  renderSourcesSelector() {
    const bucketName = `${string.sanitizeKbcTableIdString(this.props.componentId)}-${
      this.props.configId
    }`;
    const bucketId = `in.${getDefaultBucketName(bucketName)}`;
    const csvUploadProgress = this.props.isCreatingTable
      ? 100
      : this.props.allUploadingProgress?.get(bucketId) || this.state.isSaving
        ? 1
        : 0;
    const isSaving = csvUploadProgress > 0;

    if (
      this.props.readOnly ||
      (this.props.isHeaderButton ? !this.props.isConfigured : this.props.isConfigured)
    )
      return null;

    return (
      <>
        <Button
          bsStyle={this.props.isHeaderButton ? 'link' : 'success'}
          onClick={() => this.setState({ showModal: true })}
          {...(this.props.isHeaderButton && {
            className: 'header-inline-button color-success',
          })}
        >
          <FontAwesomeIcon icon="plus" className="icon-addon-right" />
          Select Data
        </Button>
        <Modal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          onEnter={() => {
            this.setState({ modalStep: MODAL_STEPS.LIST, csvFormState: INITIAL_CSV_FORM_STATE });
            this.handleTablesSelect(List());
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.modalStep === MODAL_STEPS.LIST
                ? 'Data'
                : this.state.modalStep === MODAL_STEPS.STORAGE
                  ? 'Select an Existing Table'
                  : 'Upload a CSV File'}
            </Modal.Title>
            <ModalIcon icon="plus" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            {this.state.modalStep === MODAL_STEPS.LIST && (
              <>
                <ModalActionButton
                  icon={<CircleIcon icon="warehouse" bigger bold />}
                  title="Select an Existing Table"
                  description="Select an existing table in Storage."
                  onClick={() => this.setState({ modalStep: MODAL_STEPS.STORAGE })}
                />
                <ModalActionButton
                  icon={<CircleIcon icon="upload" bigger bold />}
                  title="Upload from the Computer"
                  description="Import your data from a CSV file."
                  onClick={() => this.setState({ modalStep: MODAL_STEPS.CSV })}
                />
              </>
            )}
            {this.state.modalStep === MODAL_STEPS.STORAGE && (
              <FormGroup>
                <ControlLabel>Source Table</ControlLabel>
                <InputMappingSourceInput
                  mode="create"
                  groupByComponent
                  allComponents={this.props.allComponents}
                  allConfigurations={this.props.allConfigurations}
                  buckets={this.props.allBuckets}
                  tables={this.props.allTables.filter(
                    (table) =>
                      !this.props.configData
                        .getIn(['parameters', 'tables'], List())
                        .includes(table.get('id')),
                  )}
                  value={this.state.tablesToBeSelected}
                  onChange={this.handleTablesSelect}
                />
              </FormGroup>
            )}
            {this.state.modalStep === MODAL_STEPS.CSV && (
              <CsvUploadForm
                tables={this.props.allTables.filter(
                  (table) => table.getIn(['bucket', 'id']) === bucketId,
                )}
                values={this.state.csvFormState}
                setValue={(key, value) => {
                  this.setState((state) => ({
                    csvFormState: {
                      ...state.csvFormState,
                      [key]: value,
                    },
                  }));
                }}
                progress={csvUploadProgress}
              />
            )}
          </Modal.Body>
          {this.state.modalStep !== MODAL_STEPS.LIST && (
            <Modal.Footer>
              <ButtonToolbar className="block">
                <Button
                  onClick={() => this.setState({ modalStep: MODAL_STEPS.LIST })}
                  disabled={isSaving}
                >
                  <FontAwesomeIcon icon="arrow-left" fixedWidth />
                </Button>
                <ConfirmButtons
                  block
                  saveLabel={isSaving ? 'Selecting Data...' : 'Select Data'}
                  onSave={() => {
                    if (this.state.modalStep === MODAL_STEPS.STORAGE) {
                      this.handleTablesAdd();
                      return this.setState({ showModal: false });
                    }

                    this.setState((state) => ({
                      isSaving: true,
                      csvFormState: { ...state.csvFormState, error: null },
                    }));

                    return new Promise((resolve) => {
                      if (!this.props.allBuckets.get(bucketId, Map()).isEmpty()) {
                        return resolve(this.props.allBuckets.get(bucketId));
                      }

                      return resolve(createBucket({ name: bucketName }, null));
                    }).then((bucket) => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      const { file, warning, error, ...params } = this.state.csvFormState;

                      uploadFile(bucket.get('id'), file)
                        .then((dataFileId) =>
                          createTable(bucket.get('id'), { ...params, dataFileId }),
                        )
                        .then((tableId) => {
                          this.setState({ showModal: false, isSaving: false }, () =>
                            this.handleTablesSelect(List([tableId]), { autoSave: true }),
                          );
                        })
                        .catch((error) => {
                          this.setState((state) => ({
                            csvFormState: { ...state.csvFormState, error },
                            isSaving: false,
                          }));
                        });
                    });
                  }}
                  isSaving={isSaving}
                  isDisabled={isSaving || !this.isModalFormValid()}
                />
              </ButtonToolbar>
            </Modal.Footer>
          )}
        </Modal>
      </>
    );
  }

  isModalFormValid = () => {
    if (this.state.modalStep === MODAL_STEPS.STORAGE) {
      return !this.state.tablesToBeSelected.isEmpty();
    }

    const { file, name, delimiter, warning } = this.state.csvFormState;

    return !!(file && name.trim() && isValidName(name) && delimiter && !warning);
  };

  handleTablesSelect = (selected, options) => {
    this.setState({ tablesToBeSelected: selected }, options?.autoSave && this.handleTablesAdd);
  };

  handleTablesAdd = () => {
    const newTables = this.state.tablesToBeSelected
      .filter((id) => this.props.allTables.has(id))
      .toMap()
      .mapKeys((_, id) => id);

    let changedConfigData = this.props.configData.updateIn(
      ['parameters', 'tables'],
      Map(),
      (tables) => tables.merge(newTables),
    );

    if (changedConfigData.getIn(['parameters', 'tables'], Map()).count() === 1) {
      const firstTableId = changedConfigData.getIn(['parameters', 'tables']).first();
      const table = this.props.allTables.get(firstTableId, Map());

      changedConfigData = changedConfigData
        .setIn(['parameters', 'bucketId'], table.getIn(['bucket', 'id']))
        .setIn(['parameters', 'tableName'], table.get('name'));
    }

    return this.handleChange(changedConfigData, 'Select source table');
  };

  // TODO: Test table removal manually + add tests?
  handleRemoveTables = (tableIds) => {
    const currentSourceTableId = `${this.props.configData.getIn([
      'parameters',
      'bucketId',
    ])}.${this.props.configData.getIn(['parameters', 'tableName'])}`;
    // Filter out removed tables
    let changedConfigData = this.props.configData.updateIn(
      ['parameters', 'tables'],
      List(),
      (tables) => tables.filter((tableId) => !tableIds.includes(tableId)),
    );

    // Handle removal of currently used table - automatically use new table if there is only one available, reset otherwise
    if (tableIds.includes(currentSourceTableId)) {
      if (changedConfigData.getIn(['parameters', 'tables'], List()).count() === 1) {
        const loneTable = this.props.allTables.get(
          changedConfigData.getIn(['parameters', 'tables']).first(),
          Map(),
        );

        changedConfigData = changedConfigData
          .setIn(['parameters', 'bucketId'], loneTable.getIn(['bucket', 'id']))
          .setIn(['parameters', 'tableName'], loneTable.get('name'));
      } else {
        changedConfigData = changedConfigData
          .deleteIn(['parameters', 'bucketId'])
          .deleteIn(['parameters', 'tableName'])
          .deleteIn(['parameters', 'models']);
      }
    }

    // Remove saved data sample previews of source tables
    const sourceTablesDataSamples = {
      ...(this.props.commonState.dataSamples?.sourceTables || {}),
    };

    for (const tableId of tableIds) {
      delete sourceTablesDataSamples[tableId];
    }

    this.props.setCommonState?.({
      dataSamples: {
        sourceTables: sourceTablesDataSamples,
      },
    });

    return this.handleChange(
      changedConfigData,
      `Remove source ${string.pluralize(tableIds.length, 'table')}`,
    );
  };

  handleChange = (configData, changeDescription) => {
    this.setState({ isSaving: true });

    return InstalledComponentsActionCreators.saveComponentConfigData(
      this.props.componentId,
      this.props.configId,
      configData,
      changeDescription,
    ).finally(() => {
      this.setState({ tablesToBeSelected: List(), isSaving: false });
    });
  };
}

class SimplifiedTableInputMappingStepWrapper extends React.Component {
  static propTypes = { ...COMMON_PROP_TYPES };

  render() {
    return (
      <div className="simplified-table-input-mapping flex-container align-top">
        <div className="flex-container fill-space">
          <SimplifiedTableInputMapping {...this.props} />
        </div>
      </div>
    );
  }
}

export default SimplifiedTableInputMappingStepWrapper;
