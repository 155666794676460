import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Collapse, Link } from 'design';
import type { Map } from 'immutable';
import { List } from 'immutable';

import { componentTypes } from '@/constants/componentTypes';
import { findTemplateInstanceIdFromMetadata } from '@/modules/components/helpers';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { NOT_SUPPORTED_COMPONENTS } from '@/modules/lineage/constants';
import { constructFQID, FQID_TYPES } from '@/modules/lineage/fqid';
import StackFeaturesStore from '@/modules/stack-features/Store';
import { routeNames as templatesRouteNames } from '@/modules/templates/constants';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import CollapseButton from './CollapseButton';
import ComponentAuthor from './ComponentAuthor';
import ComponentBadges from './ComponentBadges';
import ComponentLicence from './ComponentLicence';
import ComponentType from './ComponentType';
import { UsedInFlowsModal } from './FlowsListModal';
import { hasStageFlag } from './helpers';
import Markdown from './Markdown';
import RouterLink from './RouterLink';
import ShowLineage from './ShowLineage';
import StorageData from './StorageData';

const ConfigurationInfoPanel = (props: {
  component: Map<string, any>;
  allComponents?: Map<string, any>;
  config?: Map<string, any>;
  flows?: Map<string, any>;
  metadata?: Map<string, any>;
  realComponent?: Map<string, any>;
  tablesMetadataMap?: Map<string, any>;
  className?: string;
}) => {
  const state = useStores(
    () => {
      return {
        hasFlows: ApplicationStore.hasFlows(),
        hasAiLineage: ApplicationStore.hasAiLineage(),
        currentProject: ApplicationStore.getCurrentProject(),
        hasAllowedAi: StackFeaturesStore.hasAllowedAi(),
        isDevModeActive: DevBranchesStore.isDevModeActive(),
      };
    },
    [],
    [ApplicationStore, StackFeaturesStore, DevBranchesStore],
  );

  const [expand, setExpand] = React.useState(false);
  const component = props.realComponent || props.component;
  const description = component.get('longDescription') || component.get('description');
  const licenceUrl = component.getIn(['data', 'vendor', 'licenseUrl']);
  const templateInstanceId =
    props.config &&
    findTemplateInstanceIdFromMetadata(component.get('id'), props.config.get('id'), props.metadata);

  const renderBoxItem = (
    label: React.ReactNode,
    body: React.ReactNode,
    options?: { aside?: boolean; hiddenSmall?: boolean },
  ) => {
    return (
      <div
        className={classNames('panel-info-item no-wrap', {
          'hidden-sm': options?.hiddenSmall,
          aside: options?.aside,
          'tw-self-end': !label,
        })}
      >
        {label && <span className="text-muted">{label}:</span>}
        <span className="flex-container flex-start">{body}</span>
      </div>
    );
  };

  const lineageComponentId = props.component.get('id') || props.realComponent?.get('id');

  return (
    <Collapse
      open={expand}
      className={classNames(
        'box box-separator panel-info tw-py-4 tw-pl-6 tw-pr-5',
        props.className,
      )}
      header={
        <div
          className="flex-container btn-collapse-area"
          onClick={(event: React.SyntheticEvent) => {
            if (
              event.nativeEvent.composedPath().some((element) => {
                return element instanceof Element && element.classList.contains('modal');
              })
            ) {
              return;
            }

            setExpand(!expand);
          }}
        >
          <div className="flex-container align-top line-height-24">
            {renderBoxItem(
              'Type',
              <ComponentType
                labelClassName="font-medium color-main"
                type={component.get('type')}
              />,
              { hiddenSmall: true },
            )}
            {component.get('type') !== componentTypes.TRANSFORMATION &&
              renderBoxItem(
                'Author',
                <>
                  <FontAwesomeIcon icon="user" className="icon-addon-right text-muted" />
                  <ComponentAuthor
                    is3rdParty={component.get('flags', List()).includes('3rdParty')}
                    contact={component.getIn(['data', 'vendor', 'contact'], List())}
                  />
                </>,
              )}
            {hasStageFlag(component.get('flags')) &&
              renderBoxItem('Stage', <ComponentBadges flags={component.get('flags')} />)}
            {props.config &&
              props.flows &&
              state.hasFlows &&
              renderBoxItem(
                'Used in',
                <>
                  <UsedInFlowsModal
                    config={props.config}
                    component={props.component}
                    flows={props.flows}
                  />
                  {templateInstanceId && (
                    <span className="text-muted pre">
                      {'  '}and{'  '}
                      <RouterLink
                        to={templatesRouteNames.INSTANCE_DETAIL}
                        params={{ instanceId: templateInstanceId }}
                        onClick={(event) => event.stopPropagation()}
                      >
                        <FontAwesomeIcon icon="book-open" className="f-16 btn-icon text-muted" />
                        Template
                      </RouterLink>
                    </span>
                  )}
                </>,
              )}
            {props.config &&
              props.tablesMetadataMap &&
              component.get('type') !== componentTypes.WRITER &&
              renderBoxItem(
                'Data',
                <StorageData
                  config={props.config}
                  component={props.component}
                  tablesMetadataMap={props.tablesMetadataMap}
                />,
              )}
            {state.hasAiLineage &&
              state.hasAllowedAi &&
              state.hasFlows &&
              props.config &&
              props.allComponents &&
              !state.isDevModeActive &&
              renderBoxItem(
                '',
                <ShowLineage
                  isLocked={!state.hasAiLineage}
                  isDisabled={NOT_SUPPORTED_COMPONENTS.includes(lineageComponentId)}
                  allComponents={props.allComponents}
                  startingPointFqid={constructFQID({
                    type: FQID_TYPES.config,
                    data: {
                      projectId: ApplicationStore.getCurrentProjectId(),
                      componentId: lineageComponentId,
                      configId: props.config.get('id'),
                    },
                  })}
                  projectName={state.currentProject.get('name', '')}
                  projectId={`${state.currentProject.get('id', '')}`}
                  configurationName={props.config.get('name', '')}
                />,
              )}
            {(component.get('documentationUrl') ||
              component.getIn(['data', 'vendor', 'licenseUrl'])) &&
              renderBoxItem(
                'Additional Links',
                <>
                  {component.get('documentationUrl') && (
                    <span className="mr-1 no-wrap">
                      <FontAwesomeIcon icon="book-blank" className="text-muted icon-addon-right" />
                      <Link href={component.get('documentationUrl')}>Documentation</Link>
                    </span>
                  )}
                  {component.getIn(['data', 'vendor', 'licenseUrl']) && (
                    <span className="no-wrap">
                      <FontAwesomeIcon icon="file-lines" className="text-muted icon-addon-right" />
                      <Link href={component.getIn(['data', 'vendor', 'licenseUrl'])}>License</Link>
                    </span>
                  )}
                </>,
                { aside: true },
              )}
          </div>
          {(description || licenceUrl) && (
            <CollapseButton
              entity="component info"
              isCollapsed={!expand}
              onToggle={() => setExpand(!expand)}
            />
          )}
        </div>
      }
    >
      {(description || licenceUrl) && (
        <div className="panel-info-content text-muted tw-pt-4">
          <Markdown source={description} collapsible={false} />
          <ComponentLicence href={licenceUrl} />
        </div>
      )}
    </Collapse>
  );
};

export default ConfigurationInfoPanel;
