import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import TableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import Loader from '@/react/common/Loader';
import ModalIcon from '@/react/common/ModalIcon';
import { timeInWords } from '@/utils/duration';

class MappingModal extends React.Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Mapping</Modal.Title>
          <ModalIcon icon="code-merge" color="violet" />
        </Modal.Header>
        <Modal.Body>{this.renderBody()}</Modal.Body>
      </Modal>
    );
  }

  renderBody() {
    if (!this.props.stats) {
      return (
        <p>
          <Loader /> Loading data...
        </p>
      );
    }

    return (
      <>
        {this.renderMapping('Input', this.props.stats.getIn(['tables', 'export', 'tables']))}
        <hr />
        {this.renderMapping('Output', this.props.stats.getIn(['tables', 'import', 'tables']))}
      </>
    );
  }

  renderMapping(title, tables) {
    return (
      <>
        <h3 className="font-mono font-semibold">{title}</h3>
        {this.renderTables(tables)}
      </>
    );
  }

  getTableListItem(table) {
    return (
      <li key={table.get('id')} className="flex-container">
        <TableLinkEx tableId={table.get('id')} />
        <span className="no-wrap pl-2 text-muted">
          {timeInWords(Math.round(table.get('durationTotalSeconds')))}
        </span>
      </li>
    );
  }

  renderTables(tables) {
    if (!tables.count()) {
      return <p className="font-mono text-muted">No tables</p>;
    }

    return (
      <div className="params-list">
        <ul>
          {tables
            .sortBy((table) => -table.get('durationTotalSeconds'))
            .map(this.getTableListItem)
            .toArray()}
        </ul>
      </div>
    );
  }
}

MappingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  stats: PropTypes.object,
};

export default MappingModal;
