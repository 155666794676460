import React from 'react';

import lineage from '../../../images/lineage.svg';

import Truncated from '@/react/common/Truncated';
import BetaTitle from './BetaTitle';

const className = 'tw-inline-flex tw-m-0 tw-text-sm tw-text-neutral-400';

const Breadcrumbs = ({
  organizationName = null,
  projectName = null,
  onOrganizationClick = null,
  nodeName = null,
}: {
  organizationName?: string | null;
  projectName?: string | null;
  onOrganizationClick?: null | (() => void);
  nodeName?: string | null;
}) => {
  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-flex tw-items-center tw-gap-2">
        <BetaTitle small>
          <img src={lineage} alt="lineage" className="tw-w-7" />
        </BetaTitle>
        <h2 className="tw-m-0 tw-text-2xl tw-text-neutral-900">
          <Truncated text={nodeName || projectName || organizationName} />
        </h2>
      </div>
      <span className="tw-flex tw-whitespace-pre-wrap">
        {organizationName ? (
          <>
            {onOrganizationClick ? (
              <Truncated
                text={<a onClick={onOrganizationClick}>View all projects</a>}
                tooltip={organizationName}
                className={className}
              />
            ) : (
              <p className="tw-mb-0 tw-text-sm tw-text-neutral-400">All projects</p>
            )}
          </>
        ) : (
          projectName && (
            <p className={className}>
              <Truncated text={projectName} />
            </p>
          )
        )}
      </span>
    </div>
  );
};

export default Breadcrumbs;
