import React from 'react';

import { cn } from '../utils/classNames';

type OptionalProps = {
  placement?: 'right' | 'left';
  variant?:
    | 'gray'
    | 'gray-darker'
    | 'green'
    | 'green-darker'
    | 'green-inverse'
    | 'blue'
    | 'blue-inverse'
    | 'red'
    | 'orange'
    | 'orange-inverse'
    | 'teal'
    | 'purple'
    | 'purple-inverse'
    | 'cyan';
  asTag?: boolean;
  className?: string;
  onClick?: () => void;
};

export type Props = ({ text: string } | { children: React.ReactNode }) & OptionalProps;

const BASIC_STYLES = {
  display: 'tw-inline-flex tw-justify-center tw-items-center tw-h-fit',
  text: 'tw-text-xs tw-font-medium tw-tracking-[1px] tw-text-white tw-whitespace-pre-wrap tw-text-center tw-underline',
  content: '[&>svg]:tw-self-center',
};

const TYPE_STYLE = {
  tag: 'tw-rounded tw-px-1 tw-py-0.5 tw-normal-case',
  pill: 'tw-rounded-full tw-px-2 tw-py-0.5 tw-uppercase',
};

export const Badge = (props: Props) => {
  const type = props.variant || 'gray';

  return (
    <span
      className={cn(
        BASIC_STYLES.display,
        BASIC_STYLES.text,
        BASIC_STYLES.content,
        props.asTag ? TYPE_STYLE.tag : TYPE_STYLE.pill,
        {
          'tw-bg-neutral-400 tw-decoration-neutral-400': type === 'gray',
          'tw-bg-neutral-500 tw-decoration-neutral-500': type === 'gray-darker',
          'tw-bg-primary-500 tw-decoration-primary-500': type === 'green',
          'tw-bg-primary-600 tw-decoration-primary-600': type === 'green-darker',
          'tw-bg-primary-100 tw-text-primary-500 tw-decoration-primary-100':
            type === 'green-inverse',
          'tw-bg-secondary-500 tw-decoration-secondary-500': type === 'blue',
          'tw-bg-secondary-100 tw-text-secondary-600 tw-decoration-secondary-100':
            type === 'blue-inverse',
          'tw-bg-error-500 tw-decoration-error-500': type === 'red',
          'tw-bg-warning-500 tw-decoration-warning-500': type === 'orange',
          'tw-bg-warning-100 tw-text-warning-500 tw-decoration-warning-100':
            type === 'orange-inverse',
          'tw-bg-teal-500 tw-decoration-teal-500': type === 'teal',
          'tw-bg-purple-500 tw-decoration-purple-500': type === 'purple',
          'tw-bg-purple-100 tw-text-purple-500 tw-decoration-purple-100': type === 'purple-inverse',
          'tw-bg-cyan-500 tw-decoration-cyan-500': type === 'cyan',
        },
        {
          'tw-ml-2': props.placement === 'right',
          'tw-mr-2': props.placement === 'left',
        },
        {
          'tw-cursor-pointer': props.onClick,
        },
        props.className,
      )}
      onClick={props.onClick}
    >
      {'children' in props ? props.children : props.text}
    </span>
  );
};
