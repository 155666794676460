import React from 'react';

// both stack and project features
const FEATURE_SANDBOXES_PYTHON_SPARK = 'sandboxes-python-spark';
const FEATURE_GCP_MARKETPLACE_BYODB = 'gcp-marketplace-byodb';
const FEATURE_GCP_MARKETPLACE_BYODB_EMPTY = 'gcp-marketplace-byodb-empty';

// stack features
const FEATURE_IS_SINGLE_TENANT = 'single-tenant';
const FEATURE_DISABLE_JULIA_AND_R = 'disable-julia-and-r';
const FEATURE_SAML_ROLES = 'saml-roles';
const FEATURE_NEW_OAUTH_SERVICE = 'new-oauth-service-enabled';
const FEATURE_DATA_APPS_ENFORCE_AUTH = 'data-apps-enforce-auth';
const FEATURE_ALLOW_AI = 'allow-ai';

// project features
const FEATURE_PAY_AS_YOU_GO = 'pay-as-you-go';
const FEATURE_NEW_TRANSFORMATIONS_ONLY = 'new-transformations-only';
const FEATURE_SHOW_TRANSFORMATION_MIGRATION = 'show-transformation-migration';
const FEATURE_TRANSFORMATION_PARALLEL_UNLOADS = 'transformation-parallel-unloads';
const FEATURE_TRANSFORMATIONS_MIXED_BACKENDS = 'transformations-mixed-backends';
const FEATURE_QUEUE_V2 = 'queuev2';
const FEATURE_GLOBAL_SEARCH = 'global-search';
const FEATURE_SHOW_VAULT = 'show-vault';
const FEATURE_DEPRECATED_GOOGLE_DRIVE_TRANSPOSE = 'deprecated-google-drive-transpose';
const FEATURE_SANDBOXES_PYTHON_MLFLOW = 'sandboxes-python-mlflow';
const FEATURE_SANDBOXES_DATA_APP = 'sandboxes-data-apps';
const FEATURE_SANDBOXES_PERSISTENT_VOLUME = 'sandboxes-persistent-volume';
const FEATURE_WORKSPACE_SNOWFLAKE_DYNAMIC_BACKEND_SIZE = 'workspace-snowflake-dynamic-backend-size';
const FEATURE_DYNAMIC_BACKEND_JOBS = 'dynamic-backend-jobs';
const FEATURE_WORKSPACE_VIEW_LOAD = 'workspace-view-load';
const FEATURE_HAS_SIMPLIFIED_COMPONENTS = 'has-simplified-components';
const FEATURE_HAS_FLOWS_ONLY = 'flows-without-fallback';
const FEATURE_ORACLE_TRANSFORMATIONS = 'oracle-transformations';
const FEATURE_EXTERNAL_BUCKETS = 'external-buckets';
const FEATURE_INPUT_MAPPING_READ_ONLY_STORAGE = 'input-mapping-read-only-storage';
const FEATURE_ARTIFACTS = 'artifacts';
const FEATURE_SNOWPARK_PYTHON = 'snowpark-python';
const FEATURE_SNOWFLAKE_PARTNER_CONNECT = 'snowflake-partner-connect';
const FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED = 'snowflake-partner-connect-limited';
const FEATURE_NEW_NATIVE_TYPES = 'new-native-types';
const FEATURE_DISABLE_LEGACY_BUCKET_PREFIX = 'disable-legacy-bucket-prefix';
const FEATURE_SOX_PROTECTED_DEFAULT_BRANCH = 'protected-default-branch';
const FEATURE_DATA_STREAMS = 'data-streams';
const FEATURE_AI_LINEAGE = 'ai-lineage';
const FEATURE_AI_CONVERSATIONS = 'ai-conversations';
const FEATURE_AI_COMPONENT_SUGGESTION = 'ai-component-suggestion';
const FEATURE_AI_AUTOMATIONS = 'ai-automations';
const FEATURE_LEGACY_TRANSFORMATIONS_NEW_QUEUE = 'legacy-transformations-new-queue';
const FEATURE_SNOWFLAKE_REGISTER_EXTERNAL_SECURE_DATA_SHARE =
  'snowflake-register-external-secure-data-share';

// admins features
const DISABLE_AUTOSAVING_IN_FLOWS = 'disable-autosaving-in-flows';
const FEATURE_CAN_UPDATE_PROJECT_SETTINGS = 'can-update-project-settings';
const FEATURE_CAN_MANAGE_FEATURES = 'can-manage-features';
const FEATURE_POWER_USER = 'power-user';

const BETA_FEATURES = [FEATURE_ALLOW_AI, FEATURE_SNOWPARK_PYTHON];

// List of features that user can request from UI
const REQUESTABLE_FEATURES = {
  FEATURE_WORKSPACE_SNOWFLAKE_DYNAMIC_BACKEND_SIZE: {
    key: FEATURE_WORKSPACE_SNOWFLAKE_DYNAMIC_BACKEND_SIZE,
    label: 'Dynamic backend size for Snowflake',
    buttonLabel: (
      <>
        Backend Size: <span className="tw-font-medium">Small</span>
      </>
    ),
    buttonIcon: 'rocket',
  },
  FEATURE_DYNAMIC_BACKEND_JOBS: {
    key: FEATURE_DYNAMIC_BACKEND_JOBS,
    label: 'Dynamic backend size for non-SQL transformations',
    buttonLabel: (
      <>
        Backend Size: <span className="tw-font-medium">Small</span>
      </>
    ),
    buttonIcon: 'rocket',
  },
  FEATURE_SANDBOXES_PYTHON_MLFLOW: {
    key: FEATURE_SANDBOXES_PYTHON_MLFLOW,
    label: 'MLflow models',
    buttonLabel: 'Unlock ML/AI feature',
    buttonIcon: 'rotate',
  },
  FEATURE_DATA_STREAMS: {
    key: FEATURE_DATA_STREAMS,
    label: 'Data Streams',
    buttonLabel: 'Unlock Data Streams',
    buttonIcon: 'webhook',
  },
  FEATURE_AI_LINEAGE: {
    key: FEATURE_AI_LINEAGE,
    label: 'Data Lineage',
    buttonLabel: 'Unlock Data Lineage',
    buttonIcon: 'rotate',
  },
} as const;
export type REQUESTABLE_FEATURE = (typeof REQUESTABLE_FEATURES)[keyof typeof REQUESTABLE_FEATURES];

export {
  BETA_FEATURES,
  FEATURE_ORACLE_TRANSFORMATIONS,
  FEATURE_TRANSFORMATIONS_MIXED_BACKENDS,
  FEATURE_PAY_AS_YOU_GO,
  FEATURE_NEW_TRANSFORMATIONS_ONLY,
  FEATURE_TRANSFORMATION_PARALLEL_UNLOADS,
  FEATURE_DISABLE_JULIA_AND_R,
  FEATURE_IS_SINGLE_TENANT,
  FEATURE_SANDBOXES_PYTHON_SPARK,
  FEATURE_SANDBOXES_PYTHON_MLFLOW,
  FEATURE_SANDBOXES_DATA_APP,
  FEATURE_SANDBOXES_PERSISTENT_VOLUME,
  FEATURE_DEPRECATED_GOOGLE_DRIVE_TRANSPOSE,
  FEATURE_QUEUE_V2,
  FEATURE_GLOBAL_SEARCH,
  FEATURE_SHOW_VAULT,
  FEATURE_SHOW_TRANSFORMATION_MIGRATION,
  FEATURE_WORKSPACE_SNOWFLAKE_DYNAMIC_BACKEND_SIZE,
  FEATURE_WORKSPACE_VIEW_LOAD,
  FEATURE_SAML_ROLES,
  FEATURE_NEW_OAUTH_SERVICE,
  FEATURE_DATA_APPS_ENFORCE_AUTH,
  FEATURE_ALLOW_AI,
  FEATURE_HAS_SIMPLIFIED_COMPONENTS,
  FEATURE_HAS_FLOWS_ONLY,
  FEATURE_EXTERNAL_BUCKETS,
  FEATURE_INPUT_MAPPING_READ_ONLY_STORAGE,
  FEATURE_ARTIFACTS,
  FEATURE_SNOWPARK_PYTHON,
  REQUESTABLE_FEATURES,
  FEATURE_SNOWFLAKE_PARTNER_CONNECT,
  FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED,
  DISABLE_AUTOSAVING_IN_FLOWS,
  FEATURE_CAN_UPDATE_PROJECT_SETTINGS,
  FEATURE_CAN_MANAGE_FEATURES,
  FEATURE_DISABLE_LEGACY_BUCKET_PREFIX,
  FEATURE_SOX_PROTECTED_DEFAULT_BRANCH,
  FEATURE_GCP_MARKETPLACE_BYODB,
  FEATURE_GCP_MARKETPLACE_BYODB_EMPTY,
  FEATURE_DATA_STREAMS,
  FEATURE_NEW_NATIVE_TYPES,
  FEATURE_AI_LINEAGE,
  FEATURE_AI_CONVERSATIONS,
  FEATURE_AI_COMPONENT_SUGGESTION,
  FEATURE_AI_AUTOMATIONS,
  FEATURE_LEGACY_TRANSFORMATIONS_NEW_QUEUE,
  FEATURE_POWER_USER,
  FEATURE_SNOWFLAKE_REGISTER_EXTERNAL_SECURE_DATA_SHARE,
};
