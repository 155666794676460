import React from 'react';
import createReactClass from 'create-react-class';
import { fromJS, List, Map, OrderedMap } from 'immutable';
import _ from 'underscore';

import { EXCLUDE_FROM_NEW_LIST } from '@/constants/componentFlags';
import {
  FEATURE_AI_COMPONENT_SUGGESTION,
  FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED,
} from '@/constants/features';
import * as AiApi from '@/modules/ai/api';
import { COMPONENT_SUGGESTION_FALLBACK_SOURCE } from '@/modules/ai/constants';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { SORT } from '@/react/common/constants';
import Directory from '@/react/common/Directory';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import ComponentBox from './components/ComponentBox';
import ComponentBoxModal from './components/ComponentBoxModal';
import { allowedTypes } from './constants';
import { getComponentsFiltered, mergeSampleDataToConfigurations, sortComponents } from './helpers';

const Index = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, InstalledComponentsStore, ComponentsStore)],

  getStateFromStores() {
    const allComponents = ComponentsStore.getAll();

    return {
      allComponents,
      readOnly: ApplicationStore.isReadOnly(),
      installedComponents: mergeSampleDataToConfigurations(
        InstalledComponentsStore.getAll(),
        allComponents,
      ),
      componentCategories: ComponentsStore.getComponentsCategories(),
      hasSnowflakePartnerConnectLimited: ApplicationStore.hasCurrentProjectFeature(
        FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED,
      ),
      hasAiComponentSuggestion: ApplicationStore.hasCurrentProjectFeature(
        FEATURE_AI_COMPONENT_SUGGESTION,
      ),
    };
  },

  getInitialState() {
    return {
      showDetailModal: false,
      detailComponentId: null,
      aiSearch: {
        components: List(),
        isFallbackComponentUsed: false,
        isLoading: false,
      },
      searchQuery: '',
    };
  },

  componentDidMount() {
    this.handleAiSearchDebounced = _.debounce(this.handleAiSearch, 1000);
  },

  render() {
    return (
      <>
        <Directory
          entity="Component"
          getItems={(filters) => {
            const components = this.state.allComponents.filter((component) => {
              return (
                component.get('id') === filters.get('q', '') ||
                (!component.get('flags').includes(EXCLUDE_FROM_NEW_LIST) &&
                  allowedTypes.includes(component.get('type')))
              );
            });

            return {
              items: sortComponents(
                getComponentsFiltered({
                  components,
                  query: filters.get('q', ''),
                  categories: filters.get('categories', List()),
                  types: filters.get('types', List()),
                  suggestedComponents: this.state.aiSearch.components,
                }),
                filters.get('sortBy'),
              ),
              types: getComponentsFiltered({
                components,
                query: filters.get('q', ''),
                categories: filters.get('categories', List()),
                suggestedComponents: this.state.aiSearch.components,
              }),
              categories: getComponentsFiltered({
                components,
                query: filters.get('q', ''),
                types: filters.get('types', List()),
                suggestedComponents: this.state.aiSearch.components,
              }),
            };
          }}
          renderItem={this.renderComponentBox}
          categories={this.state.componentCategories}
          types={allowedTypes}
          supportedSortOptions={[SORT.A_Z, SORT.Z_A, SORT.POPULAR]}
          aiSearch={this.state.aiSearch}
          withAiSearch={this.state.hasAiComponentSuggestion}
          onChange={(query) => {
            this.setState({
              searchQuery: query,
              aiSearch: {
                components: List(),
                isFallbackComponentUsed: false,
                isLoading: !!query.trim(),
              },
            });

            if (query.trim()) {
              this.handleAiSearchDebounced(query);
            }
          }}
        />
        <ComponentBoxModal
          readOnly={this.state.readOnly}
          show={this.state.showDetailModal}
          allComponents={this.state.allComponents}
          component={this.state.allComponents.get(this.state.detailComponentId, Map())}
          hasConfigurations={this.state.installedComponents.has(this.state.detailComponentId)}
          onHide={() => this.setState({ showDetailModal: false })}
        />
      </>
    );
  },

  renderComponentBox(component, query) {
    return (
      <ComponentBox
        key={component.get('id')}
        component={component}
        configurations={this.state.installedComponents.getIn(
          [component.get('id'), 'configurations'],
          OrderedMap(),
        )}
        readOnly={this.state.readOnly}
        showDetail={this.handleShowDetail}
        query={query}
        hasSnowflakePartnerConnectLimited={this.state.hasSnowflakePartnerConnectLimited}
      />
    );
  },

  handleShowDetail(component) {
    this.setState({ showDetailModal: true, detailComponentId: component.get('id') });
  },

  handleAiSearch(prompt) {
    return AiApi.suggestComponent(prompt)
      .then(({ components }) => {
        if (this.state.searchQuery === prompt) {
          this.setState((state) => ({
            aiSearch: {
              ...state.aiSearch,
              components: fromJS(components),
              isFallbackComponentUsed: components.some(
                (component) => component.source === COMPONENT_SUGGESTION_FALLBACK_SOURCE,
              ),
            },
          }));
        }
      })
      .finally(() => {
        if (this.state.searchQuery === prompt) {
          this.setState((state) => ({ aiSearch: { ...state.aiSearch, isLoading: false } }));
        }
      });
  },
});

export default Index;
