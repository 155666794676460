let _baseUrl: string = '';
let _authHeaders: Record<string, string> = {};
let _fetch: typeof window.fetch = window.fetch;
export function init(baseUrl: string, storageApiToken: string, fetch: typeof window.fetch) {
  _baseUrl = baseUrl;
  _authHeaders = { 'X-StorageApi-Token': storageApiToken };
  _fetch = fetch;
}
export type By = {
  tokenDesc?: string | undefined;
  tokenId?: string | undefined;
  type: 'system' | 'user';
  userId?: string | undefined;
  userName?: string | undefined;
};
export type CreatedEntity = {
  at: string;
  by: {
    tokenDesc?: string | undefined;
    tokenId?: string | undefined;
    type: 'system' | 'user';
    userId?: string | undefined;
    userName?: string | undefined;
  };
};
export type DisabledEntity = { at: string; by: By; reason: string };
export type Level = {
  compressedSize: number;
  firstRecordAt?: string | undefined;
  lastRecordAt?: string | undefined;
  recordsCount: number;
  uncompressedSize: number;
};
export type Levels = {
  local?:
    | {
        compressedSize: number;
        firstRecordAt?: string | undefined;
        lastRecordAt?: string | undefined;
        recordsCount: number;
        uncompressedSize: number;
      }
    | undefined;
  staging?: Level | undefined;
  target?: Level | undefined;
};
export type SinkStatisticsTotalResult = {
  levels: {
    local?:
      | {
          compressedSize: number;
          firstRecordAt?: string | undefined;
          lastRecordAt?: string | undefined;
          recordsCount: number;
          uncompressedSize: number;
        }
      | undefined;
    staging?: Level | undefined;
    target?: Level | undefined;
  };
  total: Level;
};
export type SinkFile = {
  closingAt?: string | undefined;
  importedAt?: string | undefined;
  importingAt?: string | undefined;
  openedAt: string;
  retryAfter?: string | undefined;
  retryAttempt?: number | undefined;
  retryReason?: string | undefined;
  state: 'writing' | 'closing' | 'importing' | 'imported';
  statistics?:
    | {
        levels: {
          local?:
            | {
                compressedSize: number;
                firstRecordAt?: string | undefined;
                lastRecordAt?: string | undefined;
                recordsCount: number;
                uncompressedSize: number;
              }
            | undefined;
          staging?: Level | undefined;
          target?: Level | undefined;
        };
        total?: Level;
      }
    | undefined;
};
export type AggregatedStatistics = {
  files: {
    closingAt?: string | undefined;
    importedAt?: string | undefined;
    importingAt?: string | undefined;
    openedAt: string;
    retryAfter?: string | undefined;
    retryAttempt?: number | undefined;
    retryReason?: string | undefined;
    state: 'writing' | 'closing' | 'importing' | 'imported';
    statistics?:
      | {
          levels: {
            local?:
              | {
                  compressedSize: number;
                  firstRecordAt?: string | undefined;
                  lastRecordAt?: string | undefined;
                  recordsCount: number;
                  uncompressedSize: number;
                }
              | undefined;
            staging?: Level | undefined;
            target?: Level | undefined;
          };
          total: Level;
        }
      | undefined;
  }[];
  levels: Levels;
  total: Level;
};
export type TableColumnTemplate = { content: string; language: 'jsonnet' };
export type TableColumn = {
  name: string;
  template?: { content: string; language: 'jsonnet' } | undefined;
  path?: string;
  defaultValue?: string;
  rawString?: boolean;
  type: 'uuid' | 'datetime' | 'ip' | 'body' | 'headers' | 'path' | 'template';
};
export type TableMapping = {
  columns: {
    name: string;
    template?: { content: string; language: 'jsonnet' } | undefined;
    path?: string;
    defaultValue?: string;
    rawString?: boolean;
    type: 'uuid' | 'datetime' | 'ip' | 'body' | 'headers' | 'path' | 'template';
  }[];
};
export type TableSinkCreate = {
  mapping: {
    columns: {
      name: string;
      template?: { content: string; language: 'jsonnet' } | undefined;
      path?: string;
      defaultValue?: string;
      rawString?: boolean;
      type: 'uuid' | 'datetime' | 'ip' | 'body' | 'headers' | 'path' | 'template';
    }[];
  };
  tableId: string;
  type: 'keboola';
};
export type Version = { at: string; by: By; description: string; hash: string; number: number };
export type AggregatedSink = {
  branchId: number;
  created: {
    at: string;
    by: {
      tokenDesc?: string | undefined;
      tokenId?: string | undefined;
      type: 'system' | 'user';
      userId?: string | undefined;
      userName?: string | undefined;
    };
  };
  deleted?: CreatedEntity | undefined;
  description: string;
  disabled?: { at: string; by: By; reason: string } | undefined;
  name: string;
  projectId: number;
  sinkId: string;
  sourceId: string;
  statistics?:
    | {
        files: {
          closingAt?: string | undefined;
          importedAt?: string | undefined;
          importingAt?: string | undefined;
          openedAt: string;
          retryAfter?: string | undefined;
          retryAttempt?: number | undefined;
          retryReason?: string | undefined;
          state: 'writing' | 'closing' | 'importing' | 'imported';
          statistics?:
            | {
                levels: {
                  local?:
                    | {
                        compressedSize: number;
                        firstRecordAt?: string | undefined;
                        lastRecordAt?: string | undefined;
                        recordsCount: number;
                        uncompressedSize: number;
                      }
                    | undefined;
                  staging?: Level | undefined;
                  target?: Level | undefined;
                };
                total: Level;
              }
            | undefined;
        }[];
        levels: Levels;
        total: Level;
      }
    | undefined;
  table?:
    | {
        mapping: {
          columns: {
            name: string;
            template?: { content: string; language: 'jsonnet' } | undefined;
            path?: string;
            defaultValue?: string;
            rawString?: boolean;
            type: 'uuid' | 'datetime' | 'ip' | 'body' | 'headers' | 'path' | 'template';
          }[];
        };
        tableId: string;
        type: 'keboola';
      }
    | undefined;
  type: 'table';
  version: { at: string; by: By; description: string; hash: string; number: number };
};
export type HTTPSource = { url: string };
export type AggregatedSource = {
  branchId: number;
  created: CreatedEntity;
  deleted?: CreatedEntity | undefined;
  description: string;
  disabled?: DisabledEntity | undefined;
  http?: { url: string } | undefined;
  name: string;
  projectId: number;
  sinks: AggregatedSink[];
  sourceId: string;
  type: 'http';
  version: Version;
};
export type PaginatedResponse = {
  afterId: string;
  lastId: string;
  limit: number;
  totalCount: number;
};
export type AggregatedSourcesResult = {
  branchId: number;
  page: { afterId: string; lastId: string; limit: number; totalCount: number };
  projectId: number;
  sources: AggregatedSource[];
};
export type CreateSinkRequestBody = {
  description?: string | undefined;
  name: string;
  sinkId?: string | undefined;
  table?: TableSinkCreate | undefined;
  type: 'table';
};
export type CreateSourceRequestBody = {
  description?: string | undefined;
  name: string;
  sourceId?: string | undefined;
  type: 'http';
};
export type GenericError = { error: string; message: string; statusCode: number };
export type ServiceDetail = { api: string; documentation: string };
export type SettingPatch = { key: string; value?: any | undefined };
export type SettingResult = {
  defaultValue: any;
  description: string;
  key: string;
  overwritten: boolean;
  protected: boolean;
  type: 'string' | 'int' | 'float' | 'bool' | '[]string' | '[]int' | '[]float';
  validation?: string | undefined;
  value: any;
};
export type SettingsResult = { settings?: SettingResult[] | undefined };
export type Sink = {
  branchId: number;
  created: CreatedEntity;
  deleted?: CreatedEntity | undefined;
  description: string;
  disabled?: DisabledEntity | undefined;
  name: string;
  projectId: number;
  sinkId: string;
  sourceId: string;
  table?: TableSinkCreate | undefined;
  type: 'table';
  version: Version;
};
export type SinkStatisticsFilesResult = { files: SinkFile[] };
export type SinksList = {
  branchId: number;
  page: PaginatedResponse;
  projectId: number;
  sinks: Sink[];
  sourceId: string;
};
export type Source = {
  branchId: number;
  created: CreatedEntity;
  deleted?: CreatedEntity | undefined;
  description: string;
  disabled?: DisabledEntity | undefined;
  http?: HTTPSource | undefined;
  name: string;
  projectId: number;
  sourceId: string;
  type: 'http';
  version: Version;
};
export type SourcesList = {
  branchId: number;
  page: PaginatedResponse;
  projectId: number;
  sources: Source[];
};
export type TableSinkUpdate = {
  mapping?: TableMapping | undefined;
  tableId?: string | undefined;
  type?: 'keboola' | undefined;
};
export type TaskOutputs = {
  branchId?: number | undefined;
  projectId?: number | undefined;
  sinkId?: string | undefined;
  sourceId?: string | undefined;
  url?: string | undefined;
};
export type Task = {
  createdAt: string;
  duration?: number | undefined;
  error?: string | undefined;
  finishedAt?: string | undefined;
  isFinished: boolean;
  outputs?:
    | {
        branchId?: number | undefined;
        projectId?: number | undefined;
        sinkId?: string | undefined;
        sourceId?: string | undefined;
        url?: string | undefined;
      }
    | undefined;
  result?: string | undefined;
  status: 'processing' | 'success' | 'error';
  taskId: string;
  type: string;
  url: string;
};
export type TestResultColumn = { name: string; value: string };
export type TestResultRow = { columns: { name: string; value: string }[] };
export type TestResultTable = {
  rows: { columns: { name: string; value: string }[] }[];
  sinkId: string;
  tableId: string;
};
export type TestResult = {
  branchId: number;
  projectId: number;
  sourceId: string;
  tables: {
    rows: { columns: { name: string; value: string }[] }[];
    sinkId: string;
    tableId: string;
  }[];
};
export type UpdateSinkRequestBody = {
  changeDescription?: string | undefined;
  description?: string | undefined;
  name?: string | undefined;
  table?: TableSinkUpdate | undefined;
  type?: 'table' | undefined;
};
export type UpdateSourceRequestBody = {
  changeDescription?: string | undefined;
  description?: string | undefined;
  name?: string | undefined;
  type?: 'http' | undefined;
};
export type UpdateSourceSettingsRequestBody = {
  changeDescription?: string | undefined;
  settings?: SettingPatch[] | undefined;
};
/**
 * Redirect to /v1.
 */
export function ApiRootIndex(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/';
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * List API name and link to documentation.
 */
export function ApiVersionIndex(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1';
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Details about sources for the UI.
 */
export function AggregationSources(params: {
  afterId: string | undefined;
  limit: number | undefined;
  branchId: string;
}): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/branches/{branchId}/aggregation/sources'.replace(
    '{branchId}',
    params['branchId'],
  );
  url.search = new URLSearchParams({
    ...(params['afterId'] && { afterId: params['afterId'].toString() }),
    ...(params['limit'] && { limit: params['limit'].toString() }),
  }).toString();
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * List all sources in the branch.
 */
export function ListSources(params: {
  afterId?: string | undefined;
  limit?: number | undefined;
  branchId: string;
}): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/branches/{branchId}/sources'.replace('{branchId}', params['branchId']);
  url.search = new URLSearchParams({
    ...(params['afterId'] && { afterId: params['afterId'].toString() }),
    ...(params['limit'] && { limit: params['limit'].toString() }),
  }).toString();
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Create a new source in the branch.
 */
export function CreateSource(
  params: { branchId: string },
  body: CreateSourceRequestBody,
): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/branches/{branchId}/sources'.replace('{branchId}', params['branchId']);
  return _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
/**
 * Get the source definition.
 */
export function GetSource(params: { branchId: string; sourceId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/branches/{branchId}/sources/{sourceId}'
    .replace('{branchId}', params['branchId'])
    .replace('{sourceId}', params['sourceId']);
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Delete the source.
 */
export function DeleteSource(params: { branchId: string; sourceId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/branches/{branchId}/sources/{sourceId}'
    .replace('{branchId}', params['branchId'])
    .replace('{sourceId}', params['sourceId']);
  return _fetch(url.toString(), { method: 'delete', headers: { ..._authHeaders } });
}
/**
 * Update the source.
 */
export function UpdateSource(
  params: { branchId: string; sourceId: string },
  body: UpdateSourceRequestBody,
): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/branches/{branchId}/sources/{sourceId}'
    .replace('{branchId}', params['branchId'])
    .replace('{sourceId}', params['sourceId']);
  return _fetch(url.toString(), {
    method: 'patch',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
/**
 * Get source settings.
 */
export function GetSourceSettings(params: {
  branchId: string;
  sourceId: string;
}): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/branches/{branchId}/sources/{sourceId}/settings'
    .replace('{branchId}', params['branchId'])
    .replace('{sourceId}', params['sourceId']);
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Update source settings.
 */
export function UpdateSourceSettings(
  params: { branchId: string; sourceId: string },
  body: UpdateSourceSettingsRequestBody,
): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/branches/{branchId}/sources/{sourceId}/settings'
    .replace('{branchId}', params['branchId'])
    .replace('{sourceId}', params['sourceId']);
  return _fetch(url.toString(), {
    method: 'patch',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
/**
 * List all sinks in the source.
 */
export function ListSinks(params: {
  afterId?: string | undefined;
  limit?: number | undefined;
  branchId: string;
  sourceId: string;
}): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/branches/{branchId}/sources/{sourceId}/sinks'
    .replace('{branchId}', params['branchId'])
    .replace('{sourceId}', params['sourceId']);
  url.search = new URLSearchParams({
    ...(params['afterId'] && { afterId: params['afterId'].toString() }),
    ...(params['limit'] && { limit: params['limit'].toString() }),
  }).toString();
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Create a new sink in the source.
 */
export function CreateSink(
  params: { branchId: string; sourceId: string },
  body: CreateSinkRequestBody,
): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/branches/{branchId}/sources/{sourceId}/sinks'
    .replace('{branchId}', params['branchId'])
    .replace('{sourceId}', params['sourceId']);
  return _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
/**
 * Get the sink definition.
 */
export function GetSink(params: {
  branchId: string;
  sourceId: string;
  sinkId: string;
}): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/branches/{branchId}/sources/{sourceId}/sinks/{sinkId}'
    .replace('{branchId}', params['branchId'])
    .replace('{sourceId}', params['sourceId'])
    .replace('{sinkId}', params['sinkId']);
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Delete the sink.
 */
export function DeleteSink(params: {
  branchId: string;
  sourceId: string;
  sinkId: string;
}): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/branches/{branchId}/sources/{sourceId}/sinks/{sinkId}'
    .replace('{branchId}', params['branchId'])
    .replace('{sourceId}', params['sourceId'])
    .replace('{sinkId}', params['sinkId']);
  return _fetch(url.toString(), { method: 'delete', headers: { ..._authHeaders } });
}
/**
 * Update the sink.
 */
export function UpdateSink(
  params: { branchId: string; sourceId: string; sinkId: string },
  body: UpdateSinkRequestBody,
): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/branches/{branchId}/sources/{sourceId}/sinks/{sinkId}'
    .replace('{branchId}', params['branchId'])
    .replace('{sourceId}', params['sourceId'])
    .replace('{sinkId}', params['sinkId']);
  return _fetch(url.toString(), {
    method: 'patch',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
/**
 * Get the sink settings.
 */
export function GetSinkSettings(params: {
  branchId: string;
  sourceId: string;
  sinkId: string;
}): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/branches/{branchId}/sources/{sourceId}/sinks/{sinkId}/settings'
    .replace('{branchId}', params['branchId'])
    .replace('{sourceId}', params['sourceId'])
    .replace('{sinkId}', params['sinkId']);
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Update sink settings.
 */
export function UpdateSinkSettings(
  params: { branchId: string; sourceId: string; sinkId: string },
  body: UpdateSourceSettingsRequestBody,
): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/branches/{branchId}/sources/{sourceId}/sinks/{sinkId}/settings'
    .replace('{branchId}', params['branchId'])
    .replace('{sourceId}', params['sourceId'])
    .replace('{sinkId}', params['sinkId']);
  return _fetch(url.toString(), {
    method: 'patch',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
/**
 * Clears all statistics of the sink.
 */
export function SinkStatisticsClear(params: {
  branchId: string;
  sourceId: string;
  sinkId: string;
}): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/branches/{branchId}/sources/{sourceId}/sinks/{sinkId}/statistics/clear'
    .replace('{branchId}', params['branchId'])
    .replace('{sourceId}', params['sourceId'])
    .replace('{sinkId}', params['sinkId']);
  return _fetch(url.toString(), { method: 'delete', headers: { ..._authHeaders } });
}
/**
 * Get files statistics of the sink.
 */
export function SinkStatisticsFiles(params: {
  branchId: string;
  sourceId: string;
  sinkId: string;
}): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/branches/{branchId}/sources/{sourceId}/sinks/{sinkId}/statistics/files'
    .replace('{branchId}', params['branchId'])
    .replace('{sourceId}', params['sourceId'])
    .replace('{sinkId}', params['sinkId']);
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Get total statistics of the sink.
 */
export function SinkStatisticsTotal(params: {
  branchId: string;
  sourceId: string;
  sinkId: string;
}): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/branches/{branchId}/sources/{sourceId}/sinks/{sinkId}/statistics/total'
    .replace('{branchId}', params['branchId'])
    .replace('{sourceId}', params['sourceId'])
    .replace('{sinkId}', params['sinkId']);
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Clears all statistics of the source.
 */
export function SourceStatisticsClear(params: {
  branchId: string;
  sourceId: string;
}): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/branches/{branchId}/sources/{sourceId}/statistics/clear'
    .replace('{branchId}', params['branchId'])
    .replace('{sourceId}', params['sourceId']);
  return _fetch(url.toString(), { method: 'delete', headers: { ..._authHeaders } });
}
/**
 * Tests configured mapping of the source and its sinks.
 */
export function TestSource(
  params: {
    branchId: string;
    sourceId: string;
  },
  body: Record<string, unknown>,
): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/branches/{branchId}/sources/{sourceId}/test'
    .replace('{branchId}', params['branchId'])
    .replace('{sourceId}', params['sourceId']);
  return _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
export function OpenapiJson(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/documentation/openapi.json';
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
export function OpenapiYaml(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/documentation/openapi.yaml';
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
export function Openapi3Json(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/documentation/openapi3.json';
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
export function Openapi3Yaml(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/documentation/openapi3.yaml';
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Get details of a task.
 */
export function GetTask(params: { taskId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/v1/tasks/{taskId}'.replace('{taskId}', params['taskId']);
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
