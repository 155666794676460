import { Map } from 'immutable';

import dispatcher from '@/Dispatcher';
import * as InstalledComponentsConstants from '@/modules/components/Constants';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import StoreUtils, { initStore } from '@/utils/StoreUtils';
import * as Constants from './Constants';
import { parseCredentialsId } from './OauthUtils';

let _store = initStore(
  'OauthV2Store',
  Map({
    credentialsByBranchScope: Map(),
    credentials: Map(),
  }),
);

const OAuthStore = StoreUtils.createStore({
  hasCredentials(componentId, credentialsId) {
    const parsedId = parseCredentialsId(credentialsId);

    return _store.hasIn(['credentials', componentId, parsedId]);
  },

  getCredentialsById(componentId, credentialsId) {
    const parsedId = parseCredentialsId(credentialsId);

    return _store.getIn(['credentials', componentId, parsedId], Map());
  },

  getCredentials(componentId, configData) {
    return this.getCredentialsById(
      componentId,
      configData.getIn(['authorization', 'oauth_api', 'id'], ''),
    );
  },

  /* WARNING: 
  If using this method, make sure you access specific credentials using 
  `parseCredentialsId` helper in order to parse correct ID from variables */
  getAllCredentials() {
    /* TODO: This is temporary workaround for templates and should be removed after all stack migrate to new OAuth service.
    The templates should be refactored to use `loadAllCredentials` action instead of loading all credentials separately. */
    if (_store.get('credentialsByBranchScope', Map()).isEmpty()) {
      return _store.get('credentials', Map()).flatten(1);
    }

    return _store.get('credentialsByBranchScope', Map()).flatten(1);
  },
});

dispatcher.register(function (payload) {
  const { action } = payload;

  switch (action.type) {
    case Constants.ActionTypes.OAUTHV2_LOAD_ALL_CREDENTIALS_SUCCESS:
      _store = _store.set(
        'credentialsByBranchScope',
        action.credentials.map((credentialsByBranch) =>
          credentialsByBranch
            .toMap()
            .mapKeys((key, credentialsObject) => credentialsObject.get('id')),
        ),
      );
      return OAuthStore.emitChange();

    case Constants.ActionTypes.OAUTHV2_LOAD_CREDENTIALS_SUCCESS:
      _store = _store
        .setIn(['credentials', action.componentId, action.id], action.credentials)
        .setIn(
          [
            'credentialsByBranchScope',
            action.credentials.get('branchId', null) === null
              ? 'projectWide'
              : action.credentials.get('branchId') ===
                  DevBranchesStore.getDefaultBranchId()?.toString()
                ? 'production'
                : 'current',
            action.id,
          ],
          action.credentials,
        );
      return OAuthStore.emitChange();

    case InstalledComponentsConstants.ActionTypes.INSTALLED_COMPONENTS_CONFIGDATA_LOAD_SUCCESS:
      if (
        !action.data.configuration.authorization &&
        OAuthStore.hasCredentials(action.componentId, action.configId)
      ) {
        _store = _store.deleteIn(['credentials', action.componentId, action.configId]);
        return OAuthStore.emitChange();
      }
      return;

    default:
  }
});

export default OAuthStore;
