import React from 'react';
import { Button, ControlLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Link } from 'design';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import ComponentDescription from '@/modules/components/react/components/ComponentDescription';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import storageTablesStore from '@/modules/components/stores/StorageTablesStore';
import actionsProvisioning from '@/modules/ex-facebook/actionsProvisioning';
import storeProvisioning, {
  DEFAULT_API_VERSION,
  storeMixins,
} from '@/modules/ex-facebook/storeProvisioning';
import { deleteCredentialsAndConfigAuth } from '@/modules/oauth-v2/OauthUtils';
import AuthorizationRow from '@/modules/oauth-v2/react/AuthorizationRow';
import { prepareTablesMetadataMap } from '@/modules/storage/helpers';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import ConfigurationInfoPanel from '@/react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import SaveButtons from '@/react/common/SaveButtons';
import Select from '@/react/common/Select';
import Sidebar from '@/react/layout/Sidebar/Sidebar';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import getDefaultBucket from '@/utils/getDefaultBucket';
import AccountsManagerModal from './AccountsManagerModal';
import AccountsTable from './AccountsTable';
import AddQueryModal from './AddQueryModal';
import QueriesTable from './QueriesTable';

// https://developers.facebook.com/docs/graph-api/changelog
const FB_API_VERSIONS = ['v19.0', 'v18.0', 'v17.0'];

const Index = function (COMPONENT_ID) {
  return createReactClass({
    mixins: [
      createStoreMixin(...storeMixins, ApplicationStore, ComponentsStore, storageTablesStore),
    ],

    getStateFromStores() {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const store = storeProvisioning(COMPONENT_ID, configId);
      const actions = actionsProvisioning(COMPONENT_ID, configId);
      const component = ComponentsStore.getComponent(COMPONENT_ID);

      return {
        allTables: storageTablesStore.getAll(),
        store: store,
        actions: actions,
        component: component,
        configId: configId,
        oauthCredentials: store.oauthCredentials,
        componentsMetadata: InstalledComponentsStore.getAllMetadata(),
        flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
        localState: store.getLocalState(),
        readOnly: ApplicationStore.isReadOnly(),
        admins: ApplicationStore.getAdmins(),
        allComponents: ComponentsStore.getAll(),
      };
    },

    getInitialState() {
      return {
        showAccountsManager: false,
      };
    },

    render() {
      return (
        <>
          <ConfigurationTabs componentId={COMPONENT_ID} configId={this.state.configId} />
          <ConfigurationInfoPanel
            component={this.state.component}
            allComponents={this.state.allComponents}
            config={this.state.store.config}
            flows={this.state.flows}
            tablesMetadataMap={prepareTablesMetadataMap(this.state.allTables)}
            metadata={this.state.componentsMetadata}
          />
          <div className="row">
            {this.renderAccountsSelector()}
            {this.renderNewQueryModal()}
            <div className="col-sm-9">
              <ComponentDescription componentId={COMPONENT_ID} configId={this.state.configId} />
              {this.renderAuthorizedInfo()}
              {this.renderApiVersionEdit()}
              {this.renderAccountsInfo()}
              {this.renderQueries()}
            </div>
            <div className="col-sm-3">
              <Sidebar
                componentId={COMPONENT_ID}
                configId={this.state.configId}
                run={{
                  disabled: !this.isAuthorized() ? 'No Facebook account authorized' : '',
                  text: 'You are about to run an extraction.',
                }}
              />
            </div>
          </div>
        </>
      );
    },

    renderQueries() {
      if (!this.isAuthorized() || !this.state.store.hasAccounts) {
        return null;
      }

      if (!this.state.store.hasQueries) {
        return (
          <div className="box-separator">
            <h2 className="tw-m-0 tw-mb-4 tw-text-base">Queries</h2>
            <div className="box">
              <div className="box-content text-center">
                <p>No queries configured yet.</p>
                {this.renderAddQueryLink()}
              </div>
            </div>
          </div>
        );
      }

      return (
        <div className="box-separator">
          <div className="tw-mb-4 tw-flex tw-items-center tw-justify-between">
            <h2 className="tw-m-0 tw-text-base">Queries</h2>
            {this.renderAddQueryLink()}
          </div>
          <div className="box">
            <QueriesTable
              readOnly={this.state.readOnly}
              componentId={COMPONENT_ID}
              bucketId={getDefaultBucket('in', COMPONENT_ID, this.state.configId)}
              allTables={this.state.allTables}
              queries={this.state.store.queries}
              configId={this.state.configId}
              accounts={this.state.store.accounts}
              deleteQueryFn={this.state.actions.deleteQuery}
              isPendingFn={this.state.store.isPending}
              toggleQueryEnabledFn={this.state.actions.toggleQueryEnabledFn}
              getRunSingleQueryDataFn={this.state.store.getRunSingleQueryData}
            />
          </div>
        </div>
      );
    },

    handleApiVersionEdit(version) {
      this.state.actions.updateLocalState('version', version);
    },

    renderApiVersionEdit() {
      if (!this.isAuthorized()) {
        return null;
      }

      const version = this.state.localState.get('version', this.state.store.version);
      const isChanged = version !== this.state.store.version;

      return (
        <CollapsibleBox
          title="Facebook API Version"
          collapsePrefix={
            <span className={'tw-mr-2 tw-text-xs tw-font-medium tw-text-neutral-400'}>
              {this.state.store.version}
            </span>
          }
          additionalActions={() => {
            if (this.state.readOnly) {
              return null;
            }

            return (
              <SaveButtons
                isChanged={!!isChanged}
                onReset={() =>
                  this.state.actions.updateLocalState('version', this.state.store.version)
                }
                onSave={() => this.state.actions.saveApiVersion(version)}
                isSaving={!!this.state.store.isPending('version')}
              />
            );
          }}
        >
          <ControlLabel>API Version</ControlLabel>
          <Select
            value={version}
            options={FB_API_VERSIONS.map((v) => ({ value: v, label: v }))}
            allowCreate
            promptTextCreator={(input) => `Add version "${input}"`}
            isValidNewOption={(input) => input.match(/^v\d+(?:.\d+)?$/)}
            clearable={false}
            isDisabled={this.state.readOnly}
            onChange={this.handleApiVersionEdit}
          />
          <span className="tw-mt-3 tw-block tw-text-xs tw-text-neutral-400">
            Facebook has its own specific platform{' '}
            <Link href="https://developers.facebook.com/docs/apps/versions">versioning</Link>. If
            you change the API version, some API calls specified in queries may not work, producing
            an error, or no data or data with different columns might be retrieved. To review the
            API changes, see{' '}
            <Link href="https://developers.facebook.com/docs/apps/changelog">changelog</Link>. The
            default API version is {DEFAULT_API_VERSION}.
          </span>
        </CollapsibleBox>
      );
    },

    isAuthorized() {
      return this.state.store.isAuthorized();
    },

    renderAccountsInfo() {
      if (!this.isAuthorized() && !this.state.store.hasAccounts) {
        return null;
      }

      const { accounts } = this.state.store;

      return (
        <div className="box-separator">
          <div className="tw-mb-4 tw-flex tw-items-center tw-justify-between">
            <h2 className="tw-m-0 tw-text-base">Accounts</h2>
            {this.state.store.hasAccounts && this.renderAddButton()}
          </div>
          {!this.state.store.hasAccounts ? (
            <div className="box">
              <div className="box-content text-center">
                <p>No accounts selected yet.</p>
                {this.renderAddButton()}
              </div>
            </div>
          ) : (
            <AccountsTable
              accounts={accounts}
              readOnly={this.state.readOnly}
              onSave={this.state.actions.saveAccounts}
              componentId={COMPONENT_ID}
            />
          )}
        </div>
      );
    },

    renderAddButton() {
      if (this.state.readOnly) {
        return null;
      }

      return (
        <Button bsSize="sm" bsStyle="success" onClick={this.openAccountsSelector}>
          Add account
        </Button>
      );
    },

    openAccountsSelector() {
      this.setState({ showAccountsManager: true });
    },

    renderAccountsSelector() {
      return (
        <AccountsManagerModal
          accounts={this.state.store.accounts.toMap()}
          loadAccounts={this.state.actions.loadAccounts}
          loadedAccountsData={this.state.store.syncAccounts}
          show={this.state.showAccountsManager}
          error={this.state.localState.getIn([...this.state.store.syncAccountsPath, 'error'], null)}
          isSaving={!!this.state.store.isSavingAccounts()}
          onHideFn={() => this.setState({ showAccountsManager: false })}
          onSave={this.state.actions.saveAccounts}
        />
      );
    },

    renderAuthorizedInfo() {
      return (
        <AuthorizationRow
          readOnly={this.state.readOnly}
          configId={this.state.configId}
          componentId={COMPONENT_ID}
          credentials={this.state.oauthCredentials}
          onResetCredentials={this.deleteCredentials}
          admins={this.state.admins}
        />
      );
    },

    renderAddQueryLink() {
      if (this.state.readOnly) {
        return null;
      }

      return (
        <Button
          bsSize="sm"
          bsStyle="success"
          onClick={() => {
            this.state.actions.updateLocalState('showQueryModal', true);
          }}
        >
          <FontAwesomeIcon icon="plus" className="icon-addon-right" />
          New Query
        </Button>
      );
    },

    renderNewQueryModal() {
      return (
        <AddQueryModal
          component={this.state.component}
          configId={this.state.configId}
          show={this.state.localState.get('showQueryModal', false)}
          onHide={() => this.state.actions.updateLocalState('showQueryModal', false)}
        />
      );
    },

    deleteCredentials() {
      return deleteCredentialsAndConfigAuth(COMPONENT_ID, this.state.configId);
    },
  });
};

export default Index;
