import React from 'react';

import { KEBOOLA_DATA_APPS } from '@/constants/componentIds';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';
import StatusLabel from './components/StatusLabel';
import DataAppsStore from './DataAppsStore';

const DetailStatusLabel = () => {
  const store = useStores(
    () => {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const configData = InstalledComponentsStore.getConfigData(KEBOOLA_DATA_APPS, configId);

      return {
        app: DataAppsStore.getDataApp(configData.getIn(['parameters', 'id'])),
      };
    },
    [],
    [RoutesStore, InstalledComponentsStore, DataAppsStore],
  );

  if (!store.app) {
    return null;
  }

  return <StatusLabel app={store.app} className="tw-text-[11px] tw-leading-3" />;
};

export default DetailStatusLabel;
