import React from 'react';
import type { AnchorHTMLAttributes, PropsWithChildren } from 'react';

type Props = PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>;

export const Link = ({ children, ...rest }: Props) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      onClick={(event) => event.stopPropagation()}
      {...rest}
    >
      {children}
    </a>
  );
};
