import React from 'react';
import { Button, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { Alert, HelpBlock } from 'design';
import type { Map } from 'immutable';

import type { ACTIVE_MENU } from '@/modules/ex-generic/constants';
import { MENUS } from '@/modules/ex-generic/constants';
import { nameWarning } from '@/modules/storage/constants';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import InfoTooltip from '@/react/common/InfoTooltip';
import string from '@/utils/string';
import DocumentationLink from './DocumentationLink';
import MappingEditor from './MappingEditor';
import SwitchControl from './SwitchControl';

const Mapping = (props: {
  readOnly: boolean;
  endpointPath: number[];
  editing: Map<string, any>;
  parameters: Map<string, any>;
  allTables: Map<string, any>;
  setMenu: (menu: ACTIVE_MENU) => void;
  setEditing: (editing: Map<string, any>) => void;
}) => {
  const renderMapping = () => {
    const hasMapping = !!props.editing.get('mapping');
    const hasIncrementalOutput = props.parameters.getIn(['config', 'incrementalOutput'], false);

    return (
      <>
        <SwitchControl
          label="Create Mapping"
          readOnly={props.readOnly}
          checked={hasMapping}
          onChange={(checked: boolean) => {
            const editing = checked
              ? props.editing.set('mapping', props.editing.get('savedMapping') || '{}')
              : props.editing.delete('mapping');

            props.setEditing(editing);
          }}
        />
        {!hasMapping && hasIncrementalOutput && (
          <Alert variant="warning">Mapping is required when incremental output is enabled.</Alert>
        )}
        {hasMapping && !hasIncrementalOutput && !props.readOnly && (
          <Alert className="tw-mb-4">
            Mapping is enabled, but{' '}
            <Button
              bsStyle="link"
              className="btn-link-inline"
              onClick={() => props.setMenu(MENUS.BASE)}
            >
              incremental output
            </Button>{' '}
            is disabled. Consider enabling incremental loading to{' '}
            <DocumentationLink path="incremental/">improve performance</DocumentationLink>.
          </Alert>
        )}
        {hasMapping && (
          <MappingEditor
            readOnly={props.readOnly}
            parameters={props.parameters}
            editing={props.editing}
            setEditing={props.setEditing}
            endpointPath={props.endpointPath}
            allTables={props.allTables}
          />
        )}
      </>
    );
  };
  return (
    <CollapsibleBox
      defaultOpen
      title="Mapping"
      titleSuffix={<DocumentationLink path="configuration/config/mappings/" />}
    >
      <div className="tw-grid tw-grid-cols-3 tw-items-start tw-gap-4">
        <FormGroup>
          <ControlLabel>
            Result Name
            <InfoTooltip tooltip="Defines the result table's name and serves as a reference for data mapping. If the data is nested and unmapped, each child table name will be prefixed with this name." />
          </ControlLabel>
          <FormControl
            type="text"
            placeholder="my-table"
            disabled={props.readOnly}
            value={props.editing.get('dataType')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const tableName = string.sanitizeKbcTableIdString(e.target.value);

              props.setEditing(props.editing.set('dataType', tableName));
            }}
          />
          <HelpBlock>{nameWarning}</HelpBlock>
        </FormGroup>
        <FormGroup>
          <ControlLabel>Data Selector</ControlLabel>
          <InfoTooltip
            tooltip={
              <>
                <p>
                  Navigate nested properties using a dot by default, e.g., response.items. Change
                  the delimiter by adjusting the JSON Path Delimiter property.
                </p>
                <p>If left empty, the following rules apply by default:</p>
                <ul>
                  <li>If the response is a single array, use the whole response.</li>
                  <li>
                    If the response is an object with a single array property, use that property.
                  </li>
                  <li>
                    If the response is an object with none or multiple array properties, it is
                    required that dataField is configured.
                  </li>
                </ul>
              </>
            }
          />
          <FormControl
            type="text"
            disabled={props.readOnly}
            value={props.editing.get('dataFieldPath')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setEditing(props.editing.set('dataFieldPath', e.target.value));
            }}
          />
          <HelpBlock>
            Set the JSON path for extracting result data from the response.{' '}
            <DocumentationLink format="text" path="configuration/config/jobs/#data-field" />
          </HelpBlock>
        </FormGroup>
        <FormGroup>
          <ControlLabel>
            JSON Path Delimiter
            <InfoTooltip tooltip="For example, a path members.active refers to the property active nested inside the property members. Change the delimiter if the property name may contain the delimiter character." />
          </ControlLabel>
          <FormControl
            type="text"
            disabled={props.readOnly || !props.editing.get('dataFieldPath')}
            value={props.editing.get('dataFieldDelimeter')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setEditing(props.editing.set('dataFieldDelimeter', e.target.value));
            }}
          />
          <HelpBlock>
            The delimiter that is used to join nested objects in the path, e.g., <code>.</code> by
            default.
          </HelpBlock>
        </FormGroup>
      </div>
      {renderMapping()}
    </CollapsibleBox>
  );
};

export default Mapping;
