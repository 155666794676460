import React from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import { Button } from 'design';
import type { Map } from 'immutable';

import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';
import useStores from '@/react/hooks/useStores';

// TODO: Add support for multiselect
const ComponentSelection = ({
  componentsIds,
  onClick,
}: {
  componentsIds?: string[];
  onClick: (componentId?: string) => void;
}) => {
  const { allComponents } = useStores(
    () => ({ allComponents: ComponentsStore.getAll() as Map<string, any> }),
    [],
    [ComponentsStore],
  );

  if (!componentsIds?.length) return null;

  return (
    <div className="tw-flex tw-flex-wrap tw-gap-3">
      {componentsIds.map((id) => {
        const component = allComponents.get(id);

        if (!component) return null;

        return (
          <Button key={id} variant="outline" onClick={() => onClick(id)}>
            <ComponentIcon component={component} size="20" />
            <ComponentName component={component} />
          </Button>
        );
      })}
      <BootstrapButton bsStyle="link" className="btn-link-inline" onClick={() => onClick()}>
        Not Relevant
      </BootstrapButton>
    </div>
  );
};

export default ComponentSelection;
