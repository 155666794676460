import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ControlButton } from '@xyflow/react';
import { Tooltip } from 'design';

const LockButton = (props: { lock: boolean; onClick: () => void }) => {
  return (
    <Tooltip placement="left" tooltip={props.lock ? 'Unlock' : 'Lock'} type="action">
      <ControlButton onClick={props.onClick} className="tw-rounded-b">
        <FontAwesomeIcon icon={props.lock ? 'lock-keyhole' : 'lock-keyhole-open'} />
      </ControlButton>
    </Tooltip>
  );
};

export default LockButton;
