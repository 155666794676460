import type { PostMessageRequest } from '@/api/routes/aiService';

const routeNames = {
  ROOT: 'automations',
  DETAIL: 'automations-detail',
} as const;

const actionTypes = {
  CREATE_AUTOMATION: 'CREATE_AUTOMATION',
  LOAD_AUTOMATIONS: 'LOAD_AUTOMATIONS',
  LOAD_AUTOMATION: 'LOAD_AUTOMATION',
  DELETE_AUTOMATION: 'DELETE_AUTOMATION',
} as const;

const roles = {
  USER: 'user',
  AGENT: 'agent',
} as const;

type MessageObject = Omit<PostMessageRequest, 'data'> & Partial<Pick<PostMessageRequest, 'data'>>;

export { routeNames, actionTypes, roles, MessageObject };
