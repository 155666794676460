import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import createReactClass from 'create-react-class';
import { Tooltip } from 'design';
import { List, Map, OrderedMap } from 'immutable';

import {
  KEBOOLA_ORCHESTRATOR,
  KEBOOLA_SANDBOXES,
  KEBOOLA_SCHEDULER,
} from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED } from '@/constants/features';
import {
  canManageNotifications,
  canManageSchedule,
  canManageTriggers,
} from '@/modules/admin/privileges';
import {
  findTemplateInstanceIdFromMetadata,
  getAllowedPatternComponents,
  getAllowedTransformations,
  prepareFoldersFromMetadata,
} from '@/modules/components/helpers';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import TablesStore from '@/modules/components/stores/StorageTablesStore';
import TriggersStore from '@/modules/components/stores/StorageTriggersStore';
import { routeNames as componentRouteNames } from '@/modules/components-directory/constants';
import { mergeSampleDataToConfigurations } from '@/modules/components-directory/helpers';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import Notifications from '@/modules/notifications/components/Notifications';
import NotificationsStore from '@/modules/notifications/store';
import ScheduleModal from '@/modules/orchestrations-v2/components/ScheduleModal';
import { prepareOrchestration, stringifyCron } from '@/modules/orchestrations-v2/helpers';
import { getLocalStateValue } from '@/modules/orchestrations-v2/localState';
import { displayTimezone } from '@/modules/orchestrations-v2/timezones';
import JobsGraphWithPaging from '@/modules/queue/components/JobsGraphWithPaging';
import { filterLatestJobs } from '@/modules/queue/helpers';
import JobsStore from '@/modules/queue/store';
import { updateScheduler } from '@/modules/scheduler/actions';
import { SCHEDULE_STATE, SOX_BRANCH_TOOLTIP_MESSAGE } from '@/modules/scheduler/constants';
import StackFeaturesStore from '@/modules/stack-features/Store';
import { prepareTablesMetadataMap } from '@/modules/storage/helpers';
import { routeNames as templatesRouteNames } from '@/modules/templates/constants';
import LinkMenuItem from '@/react/admin/project/LinkMenuItem';
import ActivateDeactivateSwitch from '@/react/common/ActivateDeactivateSwitch';
import CopyButton from '@/react/common/ConfigurationsTable/CopyButton';
import DeleteConfigurationButton from '@/react/common/DeleteConfigurationButton';
import ReadOnlyTooltip from '@/react/common/ReadOnlyTooltip';
import RouterLink from '@/react/common/RouterLink';
import RowActionDropdown from '@/react/common/RowActionDropdown';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';
import TabLink from '@/react/common/TabLink';
import TabNav from '@/react/common/TabNav';
import Truncated from '@/react/common/Truncated';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import ActivateFlowSwitch from './components/ActivateFlowSwitch';
import Builder from './components/Builder';
import JobsTable from './components/JobsTable';
import { routeNames, tabs } from './constants';
import {
  getRunningFlowStatus,
  getScheduleTooltipMessage,
  insertEmptyPhase,
  jobVersionMatch,
  prepareVisualizationPhases,
} from './helpers';
import Versions from './Versions';

const NAV_TABS = [
  { key: tabs.BUILDER, icon: 'box', title: 'Builder' },
  { key: tabs.ALL_RUNS, icon: 'rotate', title: 'All Runs' },
  { key: tabs.NOTIFICATIONS, icon: 'bell-on', title: 'Notifications' },
  { key: tabs.VERSIONS, icon: 'clone', title: 'Versions' },
];

const Detail = createReactClass({
  mixins: [
    createStoreMixin(
      RoutesStore,
      TablesStore,
      BucketsStore,
      TriggersStore,
      ApplicationStore,
      ComponentsStore,
      NotificationsStore,
      InstalledComponentsStore,
      StackFeaturesStore,
      JobsStore,
    ),
  ],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const config = InstalledComponentsStore.getConfig(KEBOOLA_ORCHESTRATOR, configId);
    const flow = prepareOrchestration(
      config,
      InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SCHEDULER),
    );

    const sapiToken = ApplicationStore.getSapiToken();
    const tasksFromConfig = config.getIn(['configuration', 'tasks'], List());
    const phasesFromConfig = config.getIn(['configuration', 'phases'], List());
    const tasks = getLocalStateValue(configId, ['tasks'], tasksFromConfig);
    const phases = getLocalStateValue(configId, ['phases'], phasesFromConfig);
    const phasesWithSomeTasks = phases.filter((phase) => {
      return tasks.some((task) => task.get('phase') === phase.get('id'));
    });
    const isChanged =
      !tasks.equals(tasksFromConfig) || !phasesWithSomeTasks.equals(phasesFromConfig);

    const allComponents = ComponentsStore.getAll();
    const allowedTransformationComponents = getAllowedTransformations(
      ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
      sapiToken,
      ApplicationStore.getCurrentProjectFeatures(),
      StackFeaturesStore.getAll(),
    );
    const allInstalledComponents = InstalledComponentsStore.getAll();
    const allAllowedComponents = allComponents.filter((component, componentId) => {
      return (
        component.get('type') !== componentTypes.TRANSFORMATION ||
        allowedTransformationComponents.has(componentId) ||
        !allInstalledComponents.getIn([componentId, 'configurations'], Map()).isEmpty()
      );
    });

    const allJobs = JobsStore.getAll();
    const latestJobData = JobsStore.getLatestJobs(KEBOOLA_ORCHESTRATOR, configId);
    const latestJobs = latestJobData.get('jobs') ?? OrderedMap();
    const latestJob = latestJobs.first();
    const runningJob = !isChanged
      ? getLocalStateValue(
          configId,
          ['runningJob'],
          latestJob && jobVersionMatch(config, latestJob) ? latestJob : null,
        )
      : null;
    const flowStatus = runningJob ? getRunningFlowStatus(allJobs, runningJob) : null;
    const hasSnowflakeDynamicBackendSize = ApplicationStore.hasSnowflakeDynamicBackendSize();
    const hasJobsDynamicBackendSize = ApplicationStore.hasJobsDynamicBackendSize();
    const componentsMetadata = InstalledComponentsStore.getAllMetadata();
    const configurations = mergeSampleDataToConfigurations(
      allInstalledComponents,
      allAllowedComponents,
    ).deleteIn([KEBOOLA_ORCHESTRATOR, 'configurations', configId]);
    const schedule = flow.getIn(['schedulerConfiguration', 'configuration', 'schedule'], Map());
    const readOnly = ApplicationStore.isReadOnly();

    return {
      readOnly,
      configId,
      config,
      phases,
      tasks,
      configurations,
      componentsMetadata,
      flow,
      isChanged,
      allJobs,
      schedule,
      sapiToken,
      flowStatus,
      latestJobData,
      hasSnowflakeDynamicBackendSize,
      hasJobsDynamicBackendSize,
      activeTab: RoutesStore.getCurrentRouteParam('tab'),
      canManageSchedule: canManageSchedule(sapiToken),
      canManageTriggers: canManageTriggers(sapiToken, schedule),
      canSetupNotifications: canManageNotifications(sapiToken),
      projectId: ApplicationStore.getCurrentProjectId(),
      hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
      component: ComponentsStore.getComponent(KEBOOLA_ORCHESTRATOR),
      components: allAllowedComponents,
      allowedPatternComponents: getAllowedPatternComponents(
        ComponentsStore.getAllForType(componentTypes.CODE_PATTERN),
        DevBranchesStore.isDevModeActive(),
      ),
      availableDatabricksClusters: InstalledComponentsStore.getLocalState(
        KEBOOLA_SANDBOXES,
        null,
      ).get('clusters', List()),
      folders: prepareFoldersFromMetadata(componentsMetadata),
      tablesMetadataMap: prepareTablesMetadataMap(TablesStore.getAll()),
      otherFlows: configurations.getIn([KEBOOLA_ORCHESTRATOR, 'configurations'], Map()),
      visualizationPhases: prepareVisualizationPhases(
        readOnly ? phases : insertEmptyPhase(phases, phases.count(), { isFake: true }),
        tasks,
        allComponents,
        allInstalledComponents,
        InstalledComponentsStore.getAllDeleted(),
      ),
      tables: TablesStore.getAll(),
      buckets: BucketsStore.getAll(),
      allNotifications: NotificationsStore.getAll(),
      notifications: NotificationsStore.getNotifications(KEBOOLA_ORCHESTRATOR, configId),
      trigger: TriggersStore.get(KEBOOLA_ORCHESTRATOR, configId),
      jobs: latestJobs,
      admins: ApplicationStore.getAdmins(),
      hasFlows: ApplicationStore.hasFlows(),
      jobsPendingActions: JobsStore.getPendingActions(),
      hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
      showBackendSize: hasSnowflakeDynamicBackendSize || hasJobsDynamicBackendSize,
      hasDataApps: ApplicationStore.hasDataApps(),
      hasSnowflakePartnerConnectLimited: ApplicationStore.hasCurrentProjectFeature(
        FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED,
      ),
      hasTemplates: ApplicationStore.hasTemplates(),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      hasDataStreams: ApplicationStore.hasDataStreams(),
    };
  },

  getInitialState() {
    return {
      showScheduleModal: false,
      isSchedulePending: false,
    };
  },

  render() {
    const activeTab = this.state.activeTab || tabs.BUILDER;

    return (
      <>
        <div className="tabs-with-border-wrapper flex-container flex-nowrap align-top">
          <TabNav className="no-shrink pr-2">
            {NAV_TABS.map((tab) => (
              <TabLink
                key={tab.key}
                to={routeNames.DETAIL}
                active={activeTab === tab.key}
                params={{
                  config: this.state.configId,
                  tab: [tabs.ALL_RUNS, tabs.NOTIFICATIONS, tabs.VERSIONS].includes(tab.key)
                    ? tab.key
                    : '',
                }}
              >
                <FontAwesomeIcon icon={tab.icon} className="icon-addon-right" />
                {tab.title}
              </TabLink>
            ))}
          </TabNav>
          {this.renderControls()}
        </div>
        {this.renderTabContent()}
      </>
    );
  },

  renderTabContent() {
    if (this.state.activeTab === tabs.ALL_RUNS) {
      return <div className="mt-2">{this.renderAllRuns()}</div>;
    }

    if (this.state.activeTab === tabs.NOTIFICATIONS) {
      return <div className="mt-1">{this.renderNotifications()}</div>;
    }

    if (this.state.activeTab === tabs.VERSIONS) {
      return <div className="mt-2">{this.renderVersions()}</div>;
    }

    return this.renderBuilder();
  },

  renderControls() {
    const templateInstanceId = findTemplateInstanceIdFromMetadata(
      KEBOOLA_ORCHESTRATOR,
      this.state.configId,
      this.state.componentsMetadata,
    );

    return (
      <>
        <div className="flow-navigation-controls flex-container flex-end">
          {templateInstanceId && (
            <div className="navigation-control">
              <span className="text-muted">Created from </span>
              <RouterLink
                to={templatesRouteNames.INSTANCE_DETAIL}
                params={{ instanceId: templateInstanceId }}
                className="font-medium"
              >
                Template
              </RouterLink>
            </div>
          )}
          {this.renderScheduleControl()}
          <RowActionDropdown toggleClassName="in-navigation">
            <ReadOnlyTooltip readOnly={this.state.readOnly} className="tw-block">
              <ActivateFlowSwitch isDisabled={this.state.readOnly} flow={this.state.flow} />
            </ReadOnlyTooltip>
            <RowActionMenuItem divider />
            {!this.state.readOnly && (
              <CopyButton
                hasFlows
                configuration={this.state.config}
                component={this.state.component}
              />
            )}
            <LinkMenuItem
              to={componentRouteNames.GENERIC_CONFIG_RAW}
              params={{ component: KEBOOLA_ORCHESTRATOR, config: this.state.configId }}
            >
              <FontAwesomeIcon icon="bug" className="icon-addon-right" fixedWidth />
              Debug mode
            </LinkMenuItem>
            {!this.state.readOnly && (
              <>
                <RowActionMenuItem divider />
                <DeleteConfigurationButton
                  forceTransition
                  mode="menuitem"
                  componentId={KEBOOLA_ORCHESTRATOR}
                  flows={this.state.otherFlows}
                  config={this.state.flow}
                />
              </>
            )}
          </RowActionDropdown>
        </div>
        {this.renderScheduleModal()}
      </>
    );
  },

  renderBuilder() {
    return (
      <Builder
        config={this.state.config}
        configId={this.state.configId}
        readOnly={this.state.readOnly}
        hasPayAsYouGo={this.state.hasPayAsYouGo}
        showBackendSize={this.state.showBackendSize}
        patternComponents={this.state.allowedPatternComponents}
        hasJobsDynamicBackendSize={this.state.hasJobsDynamicBackendSize}
        hasSnowflakeDynamicBackendSize={this.state.hasSnowflakeDynamicBackendSize}
        availableDatabricksClusters={this.state.availableDatabricksClusters}
        hasDataApps={this.state.hasDataApps}
        flowStatus={this.state.flowStatus}
        components={this.state.components}
        configurations={this.state.configurations}
        visualizationPhases={this.state.visualizationPhases}
        tablesMetadataMap={this.state.tablesMetadataMap}
        tasks={this.state.tasks}
        phases={this.state.phases}
        folders={this.state.folders}
        isChanged={this.state.isChanged}
        hasSnowflakePartnerConnectLimited={this.state.hasSnowflakePartnerConnectLimited}
        hasTemplates={this.state.hasTemplates}
        isDevModeActive={this.state.isDevModeActive}
      />
    );
  },

  renderAllRuns() {
    const filteredJobs = filterLatestJobs(this.state.jobs);

    return (
      <>
        <JobsGraphWithPaging job={filteredJobs.first()} />
        <JobsTable
          jobs={filteredJobs}
          configId={this.state.configId}
          allJobs={this.state.allJobs}
          admins={this.state.admins}
          sapiToken={this.state.sapiToken}
          isLoading={this.state.latestJobData.get('isLoading', false)}
          terminatingPendingActions={this.state.jobsPendingActions.get('terminating', Map())}
        />
      </>
    );
  },

  renderNotifications() {
    return (
      <Notifications
        configId={this.state.configId}
        componentId={KEBOOLA_ORCHESTRATOR}
        notifications={this.state.notifications}
        allNotifications={this.state.allNotifications}
        admins={this.state.admins}
        readOnly={!this.state.canSetupNotifications}
      />
    );
  },

  renderVersions() {
    return <Versions />;
  },

  renderScheduleModal() {
    return (
      <ScheduleModal
        show={this.state.showScheduleModal}
        canManageSchedule={this.state.canManageSchedule}
        canManageTriggers={this.state.canManageTriggers}
        hasProtectedDefaultBranch={this.state.hasProtectedDefaultBranch}
        hasDataStreams={this.state.hasDataStreams}
        onHide={() => this.setState({ showScheduleModal: false })}
        orchestration={this.state.flow}
        tables={this.state.tables}
        buckets={this.state.buckets}
        trigger={this.state.trigger}
        componentName="Flow"
      />
    );
  },

  renderScheduleControl() {
    return (
      <div className="navigation-control">
        <span className="text-muted btn-icon">Schedule:</span>
        {!!this.state.schedule.get('cronTab') && (
          <ReadOnlyTooltip readOnly={!this.state.canManageSchedule}>
            <ActivateDeactivateSwitch
              className="p-0 in-navigation btn-icon"
              buttonDisabled={!this.state.canManageSchedule}
              activateTooltip={
                this.state.hasProtectedDefaultBranch
                  ? SOX_BRANCH_TOOLTIP_MESSAGE
                  : 'Disabled (click to enable)'
              }
              deactivateTooltip={
                this.state.hasProtectedDefaultBranch
                  ? SOX_BRANCH_TOOLTIP_MESSAGE
                  : 'Enabled (click to disable)'
              }
              isActive={this.state.schedule.get('state') === SCHEDULE_STATE.ENABLED}
              isPending={this.state.isSchedulePending}
              onChange={this.handleToggleSchedule}
            />
          </ReadOnlyTooltip>
        )}
        {this.renderScheduleButton()}
      </div>
    );
  },

  renderScheduleButton() {
    const isButtonDisabled = !this.state.canManageSchedule && !this.state.canManageTriggers;
    const scheduleText = !!this.state.schedule.get('cronTab')
      ? `${stringifyCron(this.state.schedule.get('cronTab'))} (${displayTimezone(
          this.state.schedule.get('timezone'),
        )})`
      : !this.state.trigger.isEmpty()
        ? `Tables: ${this.state.trigger
            .get('tables', List())
            .count()} | Cooldown: ${this.state.trigger.get('coolDownPeriodMinutes', 0)} minutes`
        : null;

    const tooltipMessage = getScheduleTooltipMessage(
      this.state.hasProtectedDefaultBranch,
      this.state.isDevModeActive,
      isButtonDisabled,
    );

    return (
      <Tooltip
        placement="top"
        type="explanatory"
        tooltip={
          scheduleText ? (
            <>
              <div>{scheduleText}</div>
              {!!tooltipMessage && (
                <>
                  <hr />
                  <div>{tooltipMessage}</div>
                </>
              )}
            </>
          ) : (
            tooltipMessage
          )
        }
        forceHide={!isButtonDisabled && !scheduleText}
      >
        <Button
          bsStyle="link"
          className={classNames('btn-link-inline font-medium', { disabled: isButtonDisabled })}
          onClick={() => !isButtonDisabled && this.setState({ showScheduleModal: true })}
        >
          {scheduleText ? <Truncated text={scheduleText} noTooltip /> : 'Set Schedule'}
        </Button>
      </Tooltip>
    );
  },

  handleToggleSchedule() {
    const isDisabled = this.state.schedule.get('state') !== SCHEDULE_STATE.ENABLED;

    this.setState({ isSchedulePending: true });
    return updateScheduler(
      this.state.flow.getIn(['schedulerConfiguration', 'id']),
      this.state.flow
        .get('schedulerConfiguration', Map())
        .setIn(
          ['configuration', 'schedule', 'state'],
          isDisabled ? SCHEDULE_STATE.ENABLED : SCHEDULE_STATE.DISABLED,
        ),
    ).finally(() => this.setState({ isSchedulePending: false }));
  },
});

export default Detail;
