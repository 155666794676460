import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn, Tooltip } from 'design';

const InfoTooltip = ({
  tooltip,
  className,
  triggerClassName,
}: {
  tooltip: React.ReactNode;
  className?: string;
  triggerClassName?: string;
}) => {
  return (
    <Tooltip
      placement="top"
      tooltip={tooltip}
      type="explanatory"
      triggerClassName={triggerClassName}
    >
      <FontAwesomeIcon
        icon="circle-info"
        className={cn('tw-ml-1.5 tw-text-base tw-text-neutral-400', className)}
      />
    </Tooltip>
  );
};

export default InfoTooltip;
