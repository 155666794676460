import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Map } from 'immutable';

import SandboxesActions from '@/modules/sandboxes/Actions';
import { CONTAINER_BASED } from '@/modules/sandboxes/Constants';
import Confirm from '@/react/common/Confirm';
import Loader from '@/react/common/Loader';
import LoadOptions from './LoadOptions';

class LoadDataButton extends React.Component {
  state = {
    preserve: false,
  };

  render() {
    const isLoadingData = this.props.pendingActions.hasIn([
      'load-data',
      this.props.sandbox.get('id'),
    ]);

    return (
      <Confirm
        icon="database"
        buttonType="success"
        title="Load Data to Workspace"
        text={
          CONTAINER_BASED.includes(this.props.sandbox.get('type')) ? (
            <p>
              You are about to load data based on the input mapping. The existing tables will be
              overwritten.
            </p>
          ) : (
            <>
              <p>You are about to load data based on the input mapping.</p>
              <LoadOptions preserve={this.state.preserve} onChange={this.handlePreserve} />
            </>
          )
        }
        buttonLabel="Load Data"
        onConfirm={this.handleLoadData}
        isDisabled={
          isLoadingData ||
          !this.props.sandbox.get('active') ||
          !this.props.configData.getIn(['storage', 'input'], Map()).count() > 0 ||
          this.props.readOnly
        }
        disabledReason={
          this.props.readOnly
            ? ''
            : !this.props.sandbox.get('active')
              ? 'Workspace is inactive'
              : isLoadingData
                ? ''
                : 'No input mapping defined'
        }
        childrenRootElement="a"
        childrenRootElementClass="tw-flex"
      >
        {isLoadingData ? <Loader /> : <FontAwesomeIcon icon="database" fixedWidth />}
        Load data
      </Confirm>
    );
  }

  handlePreserve = (preserve) => {
    this.setState({ preserve });
  };

  handleLoadData = () => {
    return SandboxesActions.loadData(
      this.props.sandbox.get('id'),
      this.props.configId,
      this.props.configData.getIn(['storage', 'input'], Map()),
      {
        preserve: CONTAINER_BASED.includes(this.props.sandbox.get('type')) || this.state.preserve,
      },
    );
  };
}

LoadDataButton.propTypes = {
  configId: PropTypes.string.isRequired,
  sandbox: PropTypes.instanceOf(Map).isRequired,
  configData: PropTypes.instanceOf(Map).isRequired,
  pendingActions: PropTypes.instanceOf(Map).isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default LoadDataButton;
