import dispatcher from '@/Dispatcher';
import StoreUtils, { initStore } from '@/utils/StoreUtils';
import { ActionTypes } from './constants';

export type DataApp = {
  id: string;
  name: string;
  projectId: string;
  componentId: string;
  branchId: string | null;
  configId: string;
  configVersion: string;
  state: 'created' | 'running' | 'starting' | 'stopped' | 'stopping' | 'deleted' | 'deleting';
  desiredState: 'running' | 'stopped' | 'deleted' | null;
  url: string;
  lastRequestTimestamp: string | null;
};

type Store = {
  dataApps: DataApp[];
};

const defaultState: Store = { dataApps: [] };

let _store = initStore('DataAppsStore', defaultState);

const DataAppsStore = StoreUtils.createStore({
  getDataApps() {
    return _store.dataApps;
  },

  getDataApp(id: string) {
    return _store.dataApps.find((app) => app.id === id);
  },
});

dispatcher.register(({ action }) => {
  switch (action.type) {
    case ActionTypes.DATA_APPS_LOAD_SUCCESS:
      _store = {
        ..._store,
        dataApps: action.dataApps,
      };
      return DataAppsStore.emitChange();

    case ActionTypes.DATA_APP_LOAD_SUCCESS:
      _store = {
        ..._store,
        dataApps: [
          ..._store.dataApps.filter((app) => app.id !== action.dataApp.id),
          action.dataApp,
        ],
      };
      return DataAppsStore.emitChange();

    case ActionTypes.DATA_APPS_CREATE_SUCCESS:
      _store = {
        ..._store,
        dataApps: [..._store.dataApps, action.dataApp],
      };
      return DataAppsStore.emitChange();

    case ActionTypes.DATA_APP_DELETE_SUCCESS:
      _store = {
        ..._store,
        dataApps: _store.dataApps.filter((app) => app.id !== action.id),
      };
      return DataAppsStore.emitChange();

    default:
      break;
  }
});

export default DataAppsStore;
