import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { Map } from 'immutable';

import type { DataApp } from '@/modules/data-apps/DataAppsStore';
import CredentialsModal from './CredentialsModal';

const OpenDataApp = (props: {
  mode: 'sidebar' | 'list';
  app: DataApp;
  config: Map<string, any>;
}) => {
  const [showCredentialsModal, setShowCredentialsModal] = React.useState(false);

  const isList = props.mode === 'list';

  if (!props.app.url) {
    return isList ? <span className="tw-text-neutral-400">-</span> : null;
  }

  return (
    <span onClick={(event: React.MouseEvent) => event.stopPropagation()}>
      <Button
        block={props.mode === 'sidebar'}
        bsStyle={props.mode === 'sidebar' ? 'default' : 'link'}
        className={classNames({ 'btn-link-inline': isList })}
        onClick={() => setShowCredentialsModal(true)}
      >
        <FontAwesomeIcon
          icon="arrow-up-right-from-square"
          className={classNames('icon-addon-right', { 'text-muted': isList })}
        />
        Open Data App
      </Button>
      <CredentialsModal
        show={showCredentialsModal}
        onHide={() => setShowCredentialsModal(false)}
        app={props.app}
        config={props.config}
      />
    </span>
  );
};

export default OpenDataApp;
