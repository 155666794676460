import { fromJS, List, Map } from 'immutable';

const prepareConfigForWorkspace = (config: Map<string, any>) => {
  return fromJS({
    configuration: {
      storage: {
        input: { tables: config.get('input', Map()) },
        output: { tables: config.get('output', Map()) },
      },
      parameters: { packages: config.get('packages', List()) },
    },
  });
};

export { prepareConfigForWorkspace };
