import React, { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DataSampleItem } from '@/modules/storage/components/DataSample';
import actions from '@/modules/stream/actions';
import CodeEditor from '@/react/common/CodeEditor';
import Loader from '@/react/common/Loader';
import { isValidJsonConfig } from '@/utils/validation';

const INITIAL_TEST_PAYLOAD = `{
    "id": "example-event-id",
    "time": "${new Date().toISOString()}",
    "data": "Example data body"
}`;

type Props = {
  sourceId: string;
  sourceUrl: string;
  hasUnsavedChanges: boolean;
};

const PayloadTest = (props: Props) => {
  const [payload, setPayload] = useState(INITIAL_TEST_PAYLOAD);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [testOutput, setTestOutput] = useState<{ columns: string[]; rows: string[][] } | null>(
    null,
  );
  const [isTestingPayload, setIsTestingPayload] = useState(false);

  return (
    <>
      <div>
        <p className="mtp-1 mbp-4 text-muted f-12 line-height-16">
          Run the example script with the payload to test the Data Stream and check the results in
          the table.
        </p>
        <div className="overflow-auto max-h-250 mtp-2">
          <CodeEditor
            value={payload}
            onChange={setPayload}
            editorDidMount={(editor: any) => editor?.setSize('100%', 200)}
          />
        </div>
        <div className="flex-container flex-start gap-16 mtp-4">
          <Button
            onClick={() => {
              setErrorMessage(null);
              setTestOutput(null);
              setIsTestingPayload(true);

              actions
                .testSource(props.sourceId, JSON.parse(payload))
                .then((tables) => {
                  if (!tables) return;

                  const columns = [...new Set(tables[0].rows[0].columns.map((col) => col.name))];
                  const rows = tables[0].rows.map((row) =>
                    row.columns.map((column) => column.value),
                  );

                  setTestOutput({ columns, rows });
                })
                .catch((error) => {
                  if (!error?.message) throw error;
                  setErrorMessage(error.message);
                })
                .finally(() => setIsTestingPayload(false));
            }}
            disabled={!isValidJsonConfig(payload) || isTestingPayload}
          >
            {isTestingPayload ? (
              <>
                <Loader className="icon-addon-right" />
                Testing Payload...
              </>
            ) : (
              <>
                <FontAwesomeIcon icon="play-circle" className="icon-addon-right" />
                Send Test Payload
              </>
            )}
          </Button>
          <div className="tw-flex tw-flex-col tw-text-xs tw-font-medium">
            {props.hasUnsavedChanges && (
              <p className="mb-0">
                <FontAwesomeIcon
                  icon="exclamation-circle"
                  className="icon-addon-right text-warning"
                />
                You have unsaved changes in table settings! Save them before running Payload Test.
              </p>
            )}
            {!!errorMessage && (
              <p className="mb-0 text-danger">
                <FontAwesomeIcon icon="exclamation-circle" className="icon-addon-right" />
                {errorMessage}
              </p>
            )}
            {!!testOutput && (
              <p className="mb-0 text-success">
                <FontAwesomeIcon icon="check-circle" className="icon-addon-right" />
                Success
              </p>
            )}
          </div>
        </div>
        {!!testOutput && <hr className="tw-my-6" />}
        {!!testOutput && (
          <div className="-tw-mx-6 -tw-mb-5 tw-mt-5">
            <span className="tw-ml-6 tw-text-xs tw-font-semibold tw-uppercase tw-text-neutral-400">
              Table Preview
            </span>
            <div className="table-responsive">
              <Table>
                <thead>
                  <tr>
                    {testOutput.columns.map((column) => (
                      <th key={column}>{column}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {testOutput.rows.map((row, index) => (
                    <tr key={index}>
                      {row.map((value, index) => (
                        <td key={index} className="!tw-py-4">
                          <DataSampleItem source={value} isNotDownloadable />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PayloadTest;
