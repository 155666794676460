import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Tooltip } from 'design';
import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import {
  KEBOOLA_ORCHESTRATOR,
  RADEKTOMASEK_EX_DROPBOX_V_2 as componentId,
} from '@/constants/componentIds';
import dayjs from '@/date';
import actions from '@/modules/components/InstalledComponentsActionCreators';
import ComponentDescription from '@/modules/components/react/components/ComponentDescription';
import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import SapiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import { prepareTablesMetadataMap } from '@/modules/storage/helpers';
import ConfigurationInfoPanel from '@/react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import FileSize from '@/react/common/FileSize';
import RowActionDropdown from '@/react/common/RowActionDropdown';
import Sidebar from '@/react/layout/Sidebar/Sidebar';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { MD5 } from '@/utils/crypto';
import getDefaultBucket from '@/utils/getDefaultBucket';
import string from '@/utils/string';
import {
  getDestinationName,
  sortTimestampsInAscendingOrder,
  sortTimestampsInDescendingOrder,
} from './actions/ApplicationActions';
import FileSelectorModal from './components/DropboxFileSelectorModal';

const Index = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      InstalledComponentsStore,
      ComponentsStore,
      StorageTablesStore,
    ),
  ],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const config = InstalledComponentsStore.getConfig(componentId, configId);
    const configData = InstalledComponentsStore.getConfigData(componentId, configId);
    const localState = InstalledComponentsStore.getLocalState(componentId, configId);

    return {
      componentId,
      configId,
      config,
      configData,
      localState,
      allTables: StorageTablesStore.getAll(),
      component: ComponentsStore.getComponent(componentId),
      allComponents: ComponentsStore.getAll(),
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      defaultBucket: getDefaultBucket('in', componentId, configId),
      flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      isSaving: InstalledComponentsStore.isSavingConfigData(componentId, configId),
      parameters: configData.get('parameters', Map()),
      readOnly: ApplicationStore.isReadOnly(),
    };
  },

  getInitialState() {
    return {
      showFileSelectorModal: false,
    };
  },

  openFileSelectorModal() {
    this.setState({
      showFileSelectorModal: true,
    });
  },

  closeFileSelectorModal() {
    this.setState({
      showFileSelectorModal: this.state.isSaving,
    });
    this.updateLocalState(['selectedDropboxFiles'], fromJS([]));
  },

  render() {
    return (
      <>
        <ConfigurationTabs componentId={this.state.componentId} configId={this.state.configId} />
        <ConfigurationInfoPanel
          component={this.state.component}
          allComponents={this.state.allComponents}
          config={this.state.config}
          flows={this.state.flows}
          tablesMetadataMap={prepareTablesMetadataMap(this.state.allTables)}
          metadata={this.state.componentsMetadata}
        />
        <div className="row">
          <div className="col-sm-9">{this.renderMainContent()}</div>
          <div className="col-sm-3">{this.renderSideBar()}</div>
        </div>
      </>
    );
  },

  hasFiles() {
    const hasFiles = this.state.configData.hasIn(['parameters', 'config', 'dropboxFiles']);
    const filesCount = hasFiles
      ? this.state.configData.getIn(['parameters', 'config', 'dropboxFiles']).count()
      : 0;
    return filesCount > 0;
  },

  renderMainContent() {
    return (
      <>
        {this.renderComponentDescription()}
        {this.renderInitialSelectionOfFiles()}
        {this.renderConfigSummary()}
      </>
    );
  },

  renderComponentDescription() {
    return <ComponentDescription componentId={componentId} configId={this.state.configId} />;
  },

  renderFileSelectorModal() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <>
        <Button bsStyle="success" bsSize="sm" onClick={this.openFileSelectorModal}>
          <FontAwesomeIcon icon="plus" className="icon-addon-right" />
          New file
        </Button>
        <FileSelectorModal
          configId={this.state.configId}
          saveConfig={this.saveConfig}
          isSaving={this.state.isSaving}
          cancelConfig={this.cancelConfig}
          canSaveConfig={this.canSaveConfig}
          onHide={this.closeFileSelectorModal}
          show={this.state.showFileSelectorModal}
          handleCsvSelectChange={this.handleCsvSelectChange}
          selectedDropboxFiles={this.getSelectedCsvFiles()}
        />
      </>
    );
  },

  renderConfigSummary() {
    const hasSelectedFiles = this.state.configData.hasIn(['parameters', 'config', 'dropboxFiles']);
    const selectedFiles = hasSelectedFiles
      ? this.state.configData.getIn(['parameters', 'config', 'dropboxFiles'])
      : List();

    if (!hasSelectedFiles || selectedFiles.isEmpty()) {
      return null;
    }

    return (
      <div className="box-separator">
        <div className="tw-mb-4 tw-flex tw-items-center tw-justify-between">
          <h2 className="tw-m-0 tw-text-base">Files</h2>
          {this.renderFileSelectorModal()}
        </div>
        <div className="box">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Dropbox File</th>
                <th>Size</th>
                <th />
                <th>Output Table</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {selectedFiles
                .map((table, index) => {
                  return (
                    <tr key={index} className="hoverable-actions">
                      <td>{table.get('name')}</td>
                      <td>
                        <FileSize size={table.get('bytes')} />
                      </td>
                      <td>&gt;</td>
                      <td>
                        <SapiTableLinkEx
                          tableId={this.state.defaultBucket + '.' + table.get('output')}
                        />
                      </td>
                      <td className="pl-0 pr-1 no-wrap">
                        {this.renderActionButtons(table, index)}
                      </td>
                    </tr>
                  );
                })
                .toArray()}
            </tbody>
          </table>
        </div>
      </div>
    );
  },

  renderActionButtons(table, index) {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <>
        <Tooltip tooltip="Delete" placement="top">
          <Button
            bsStyle="link"
            className="text-muted"
            disabled={this.state.isSaving}
            onClick={this.handleDeletingSingleElement.bind(this, index)}
          >
            <FontAwesomeIcon icon="trash" fixedWidth />
          </Button>
        </Tooltip>
        <RowActionDropdown>
          <RunComponentButton
            mode="menuitem"
            title="Run Extraction"
            componentId={componentId}
            runParams={this.handleUploadingSingleElement.bind(this, index)}
          >
            You are about to run an extraction of <strong>{table.get('name')}</strong> from Dropbox.
          </RunComponentButton>
        </RowActionDropdown>
      </>
    );
  },

  renderInitialSelectionOfFiles() {
    if (this.hasFiles()) {
      return null;
    }

    return (
      <div className="box-separator">
        <h2 className="tw-m-0 tw-mb-4 tw-text-base">Files</h2>
        <div className="box">
          <div className="box-content text-center">
            <p>No files selected yet.</p>
            {this.renderFileSelectorModal()}
          </div>
        </div>
      </div>
    );
  },

  renderSideBar() {
    return (
      <Sidebar
        componentId={componentId}
        configId={this.state.configId}
        run={{
          disabled: !this.canRunUpload() ? 'A Dropbox file must be selected.' : '',
          text: 'You are about to run an extraction of all configured CSV files from Dropbox.',
        }}
      />
    );
  },

  canSaveConfig() {
    // We can save new config whether user changed files selection.
    // On the other hand the bucket may be changed, but we also have to make sure the bucket is set.
    return !(this.getLocalConfigDataFilesCount() > 0);
  },

  getLocalConfigDataFilesCount() {
    if (!this.state.localState.has('selectedDropboxFiles')) {
      return 0;
    }
    return fromJS(this.state.localState.get('selectedDropboxFiles')).size;
  },

  updateParameters(newParameters) {
    this.updateAndSaveConfigData(['parameters'], newParameters);
  },

  updateAndSaveConfigData(path, data, changeDescription) {
    let newData = this.state.configData.setIn(path, data);
    return actions.saveComponentConfigData(
      componentId,
      this.state.configId,
      newData,
      changeDescription,
    );
  },

  saveConfig(forceData) {
    const hasSelectedDropboxFiles =
      !!forceData || this.state.localState.has('selectedDropboxFiles');
    let changeDescription = '';
    const data = forceData || this.state.localState.get('selectedDropboxFiles');
    if (hasSelectedDropboxFiles) {
      const localState = data.map((dropboxFile) => {
        changeDescription = `Create file ${dropboxFile.name}`;
        const output = dropboxFile.manualInsert
          ? dropboxFile.name
          : string.sanitizeKbcTableIdString(getDestinationName(dropboxFile.name));
        return {
          bytes: dropboxFile.bytes,
          '#link': dropboxFile.link,
          name: dropboxFile.name,
          timestamp: dayjs().unix(),
          hash: MD5(dropboxFile.name).toString(),
          output: output,
        };
      });

      const oldState = this.state.configData
        .getIn(['parameters', 'config', 'dropboxFiles'], List())
        .toJS();
      const mergedState = [...oldState, ...localState];

      // We need to dedup the state in case there has been selected the same combination of file + bucket.
      // We also need to keep the older files to make sure we keep consistent file names in case of duplicate in names.
      const dedupState = _.values(
        _.indexBy(mergedState.sort(sortTimestampsInDescendingOrder), 'hash'),
      );

      // We need to make sure the final name will be unique.
      const newState = _.flatten(
        _.values(_.groupBy(dedupState, 'output')).map((arrayOfFileNames) => {
          if (arrayOfFileNames.length === 1) {
            return arrayOfFileNames;
          } else {
            return arrayOfFileNames.sort(sortTimestampsInAscendingOrder).map((fileName, index) => {
              if (index > 0) {
                return Object.assign({}, fileName, {
                  output: fileName.output + fileName.hash.slice(0, 4),
                });
              }
              return fileName;
            });
          }
        }),
      ).sort(sortTimestampsInAscendingOrder);

      return this.updateAndSaveConfigData(
        ['parameters', 'config', 'dropboxFiles'],
        fromJS(newState),
        changeDescription,
      );
    }
  },

  cancelConfig() {
    this.updateLocalState(['selectedDropboxFiles'], fromJS([]));
  },

  canRunUpload() {
    return (
      this.state.configData.hasIn(['parameters', 'config', 'dropboxFiles']) &&
      this.state.configData.getIn(['parameters', 'config', 'dropboxFiles']).count() > 0
    );
  },

  getSelectedCsvFiles() {
    let selectedDropboxFiles = [];
    let localConfigDataFiles = this.state.localState.get('selectedDropboxFiles');
    let hasLocalConfigDataFiles = this.state.localState.has('selectedDropboxFiles');

    if (hasLocalConfigDataFiles) {
      localConfigDataFiles.map((fileName) => {
        selectedDropboxFiles.push(fileName);
      });
    }

    return selectedDropboxFiles;
  },

  handleDeletingSingleElement(element) {
    if (this.state.configData.hasIn(['parameters', 'config', 'dropboxFiles'])) {
      const name = this.state.configData
        .getIn(['parameters', 'config', 'dropboxFiles', element])
        .get('name');
      const changeDescription = `Delete file ${name}`;
      let newConfig = this.state.configData
        .getIn(['parameters', 'config', 'dropboxFiles'])
        .delete(element);

      this.updateAndSaveConfigData(
        ['parameters', 'config', 'dropboxFiles'],
        newConfig,
        changeDescription,
      );
    }
  },

  handleUploadingSingleElement(element) {
    if (this.state.configData.hasIn(['parameters', 'config', 'dropboxFiles'])) {
      const selectedFile = this.state.configData
        .getIn(['parameters', 'config', 'dropboxFiles'])
        .get(element)
        .toJS();
      return {
        config: this.state.configId,
        configData: {
          parameters: {
            config: {
              dropboxFiles: [
                {
                  '#link': selectedFile['#link'],
                  name: selectedFile.name,
                  output: selectedFile.output,
                },
              ],
            },
          },
        },
      };
    }
  },

  handleCsvSelectChange(values) {
    this.updateLocalState(['selectedDropboxFiles'], values);
  },

  updateLocalState(path, data) {
    let newLocalState = this.state.localState.setIn(path, data);
    actions.updateLocalState(componentId, this.state.configId, newLocalState, path);
  },
});

export default Index;
