import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { routeNames as transformationsRouteNames } from '@/modules/transformations-v2/constants';
import TabLink from '@/react/common/TabLink';
import TabNav from '@/react/common/TabNav';
import ApplicationStore from '@/stores/ApplicationStore';

const NAV_TABS = [
  { icon: 'gear', title: 'Transformations', routeName: transformationsRouteNames.ROOT },
  { icon: 'code', title: 'Shared Code', routeName: transformationsRouteNames.SHARED_CODES },
  { icon: 'rotate', title: 'ML/AI Services', routeName: transformationsRouteNames.MODELS },
];

class TransformationTabs extends React.Component {
  render() {
    return (
      <>
        <div className="tabs-with-border-wrapper">
          <TabNav>
            {NAV_TABS.filter((tab) => {
              return tab.routeName === transformationsRouteNames.MODELS
                ? ApplicationStore.hasModelsAvailable()
                : true;
            }).map((tab) => (
              <TabLink
                key={tab.routeName}
                to={tab.routeName}
                active={this.props.activeTab === tab.routeName}
              >
                <FontAwesomeIcon icon={tab.icon} className="icon-addon-right" />
                {tab.title}
              </TabLink>
            ))}
          </TabNav>
        </div>
        {this.props.children}
      </>
    );
  }
}

TransformationTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default TransformationTabs;
