import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';

const ActionTypes = {
  JOBS_LOAD_START: 'QUEUE_JOBS_LOAD_START',
  JOBS_LOAD_SUCCESS: 'QUEUE_JOBS_LOAD_SUCCESS',
  JOBS_LOAD_ERROR: 'QUEUE_JOBS_LOAD_ERROR',

  JOB_LOAD: 'QUEUE_JOB_LOAD',
  JOB_LOAD_SUCCESS: 'QUEUE_JOB_LOAD_SUCCESS',

  JOB_TERMINATE: 'QUEUE_JOB_TERMINATE',
  JOB_TERMINATE_SUCCESS: 'QUEUE_JOB_TERMINATE_SUCCESS',
  JOB_TERMINATE_ERROR: 'QUEUE_JOB_TERMINATE_ERROR',

  JOBS_LATEST_LOAD_START: 'QUEUE_JOBS_LATEST_LOAD_START',
  JOBS_LATEST_LOAD_ERROR: 'QUEUE_JOBS_LATEST_LOAD_ERROR',
  JOBS_LATEST_LOAD_SUCCESS: 'QUEUE_JOBS_LATEST_LOAD_SUCCESS',

  CONFIG_LATEST_JOB_LOAD_SUCCESS: 'QUEUE_CONFIG_LATEST_JOB_LOAD_SUCCESS',
} as const;

const JOBS_STATUS = {
  CREATED: 'created',
  WAITING: 'waiting',
  PROCESSING: 'processing',
  SUCCESS: 'success',
  ERROR: 'error',
  TERMINATING: 'terminating',
  TERMINATED: 'terminated',
  CANCELLED: 'cancelled',
  WARNING: 'warning',
} as const;

const JOB_RUNNING_STATUSES = [
  JOBS_STATUS.CREATED,
  JOBS_STATUS.WAITING,
  JOBS_STATUS.PROCESSING,
  JOBS_STATUS.TERMINATING,
] as const;
const JOB_FINISHED_STATUSES = [
  JOBS_STATUS.SUCCESS,
  JOBS_STATUS.ERROR,
  JOBS_STATUS.WARNING,
  JOBS_STATUS.TERMINATED,
  JOBS_STATUS.CANCELLED,
] as const;
const JOB_FAILED_STATUSES = [
  JOBS_STATUS.ERROR,
  JOBS_STATUS.TERMINATED,
  JOBS_STATUS.CANCELLED,
] as const;
const JOB_SUCCESS_STATUSES = [JOBS_STATUS.SUCCESS, JOBS_STATUS.WARNING] as const;

const JOBS_TYPES = {
  CONTAINER: 'container',
  ORCHESTRATION_CONTAINER: 'orchestrationContainer',
  PHASE_CONTAINER: 'phaseContainer',
  STANDARD: 'standard',
} as const;

const BEHAVIOR_TYPES = {
  WARNING: 'warning',
  FAIL: 'fail',
  STOP: 'stop',
} as const;

const JOBS_LIMIT = 100;
const LATEST_JOBS_LIMIT = 3;
const MAX_JOBS_LIMIT = 500;

const JOBS_LIMIT_FOR_GRAPH = 18;
const JOBS_LIMIT_FOR_RUN_RESULTS = 10;

const JOB_IS_SLOWER_PERCENTAGE = 110;
const JOB_IS_FASTER_PERCENTAGE = 90;
const JOB_MINIMUM_DURATION_SECONDS_TO_SHOW_STATS = 120;
const JOB_MINIMUM_DIFFERENCE_SECONDS_TO_SHOW_STATS = 30;

const COMPONENTS_WITH_RUN_RESULTS_COLUMN = [KEBOOLA_ORCHESTRATOR];

const JOB_SLOW_TEXT = 'Looks like your job took longer than usual to finish';
const JOB_FAST_TEXT =
  'Your job finished normally and has been running without any issues for some time!';

const routeNames = {
  JOBS: 'queue',
  JOB_DETAIL: 'queue-job',
} as const;

const allowedJobsQueryParams = [
  'limit',
  'offset',
  'status',
  'componentType',
  'component',
  'config',
  'row',
  'tokenDescription',
  'createdTimeFrom',
  'endTimeTo',
  'parentRunId',
  'runId',
  'sortBy',
  'sortOrder',
  'durationSecondsFrom',
  'durationSecondsTo',
] as const;

export {
  ActionTypes,
  routeNames,
  JOBS_STATUS,
  JOB_RUNNING_STATUSES,
  JOB_FINISHED_STATUSES,
  JOB_SUCCESS_STATUSES,
  JOB_FAILED_STATUSES,
  JOBS_TYPES,
  JOBS_LIMIT,
  BEHAVIOR_TYPES,
  JOBS_LIMIT_FOR_GRAPH,
  JOBS_LIMIT_FOR_RUN_RESULTS,
  LATEST_JOBS_LIMIT,
  MAX_JOBS_LIMIT,
  JOB_IS_SLOWER_PERCENTAGE,
  JOB_IS_FASTER_PERCENTAGE,
  JOB_MINIMUM_DURATION_SECONDS_TO_SHOW_STATS,
  JOB_MINIMUM_DIFFERENCE_SECONDS_TO_SHOW_STATS,
  JOB_SLOW_TEXT,
  JOB_FAST_TEXT,
  allowedJobsQueryParams,
  COMPONENTS_WITH_RUN_RESULTS_COLUMN,
};
