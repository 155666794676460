import React from 'react';
import PropTypes from 'prop-types';
import { Button, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Promise from 'bluebird';
import classnames from 'classnames';
import { Badge, Tooltip } from 'design';
import { fromJS, List, Map } from 'immutable';

import SandboxActions from '@/modules/sandboxes/Actions';
import {
  AUTO_SLEEP_NODE,
  CONTAINER_BASED,
  DISABLE_SHARING_MESSAGE,
  ENABLE_SHARING_MESSAGE,
  SANDBOX_TYPE,
} from '@/modules/sandboxes/Constants';
import {
  canCreateTransformation,
  prepareSandboxTypeLabel,
  resolveComponentIdFromSandboxType,
} from '@/modules/sandboxes/helpers';
import DescriptionButton from '@/react/common/ConfigurationsTable/DescriptionButton';
import ConfigurationsTable from '@/react/common/ConfigurationsTable/Table';
import ConfirmMenuItem from '@/react/common/ConfirmMenuItem';
import Loader from '@/react/common/Loader';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';
import { getComponentIconUrl } from '@/utils/componentIconFinder';
import { windowOpen } from '@/utils/windowOpen';
import CreateTransformationModal from './CreateTransformationModal';
import CredentialsModal from './CredentialsModal';
import OwnerAndSharing from './OwnerAndSharing';
import RestoreWorkspace from './RestoreWorkspace';
import UpdateTransformationModal from './UpdateTransformationModal';

const CUSTOM_CLASSES = fromJS({
  owner_sharing: { th: 'w-250 text-right', td: 'w-250 text-right text-muted' },
  status: { th: 'w-175', td: 'w-175 no-wrap' },
});

const STATUS_CLASS = 'tw-min-w-16 tw-rounded tw-py-1.5';

class Sandboxes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCreateTransformationModal: false,
      showUpdateTransformationModal: false,
      showCredentialsModal: false,
      sandboxId: null,
    };

    this.renderAdditionalActions = this.renderAdditionalActions.bind(this);
    this.renderAdditionalMultiActions = this.renderAdditionalMultiActions.bind(this);
    this.renderIcon = this.renderIcon.bind(this);
  }

  render() {
    return (
      <>
        {this.renderSandboxes()}
        {this.renderModals()}
      </>
    );
  }

  renderSandboxes() {
    const sandboxes = this.props.creatingSandboxes
      .map((sandbox) => sandbox.setIn(['configuration', 'isCreating'], true))
      .concat(this.props.sandboxes)
      .filter((sandbox) => !this.props.pendingActions.hasIn(['delete', sandbox.get('id')]))
      .map((sandbox) =>
        sandbox.get('configuration', Map()).set('isUnselectable', this.hasPendingActions(sandbox)),
      )
      .toMap();

    return (
      <ConfigurationsTable
        forceShowAll
        hideCommonActions
        showComponentIcon
        entity="workspace"
        customClasses={CUSTOM_CLASSES}
        isLoading={sandboxes.isEmpty() && this.props.isLoading}
        readOnly={this.props.readOnly}
        hasNewQueue={this.props.hasNewQueue}
        hasFlows={this.props.hasFlows}
        admins={this.props.admins}
        currentAdmin={this.props.currentAdmin}
        component={this.props.sandboxComponent}
        configurations={sandboxes}
        allConfigurations={this.props.allConfigurations}
        componentsMetadata={this.props.componentsMetadata}
        additionalColumns={this.additionalColumns}
        renderAdditionalActions={this.renderAdditionalActions}
        renderCustomConfigurationIcon={this.renderIcon}
        renderNameLabel={this.renderNameLabel}
        renderAdditionalMultiActions={this.renderAdditionalMultiActions}
      />
    );
  }

  renderModals() {
    const selectedSandbox = this.props.sandboxes.get(this.state.sandboxId, Map());
    const tranformationId = resolveComponentIdFromSandboxType(selectedSandbox.get('type'));

    return (
      <>
        <CreateTransformationModal
          show={this.state.showCreateTransformationModal}
          sandbox={selectedSandbox}
          config={selectedSandbox.get('configuration', Map())}
          onHide={() => this.setState({ showCreateTransformationModal: false, sandboxId: null })}
          transformationComponent={this.props.transformationComponents.get(tranformationId, Map())}
        />
        <UpdateTransformationModal
          config={selectedSandbox.get('configuration', Map())}
          metadata={this.props.componentsMetadata}
          transformationComponent={this.props.transformationComponents.get(tranformationId, Map())}
          existingTransformations={this.props.allConfigurations.getIn(
            [tranformationId, 'configurations'],
            Map(),
          )}
          show={this.state.showUpdateTransformationModal}
          onHide={() => this.setState({ showUpdateTransformationModal: false, sandboxId: null })}
        />
        <CredentialsModal
          show={this.state.showCredentialsModal}
          sandbox={this.props.sandboxes.get(this.state.sandboxId, Map())}
          onHide={() => this.setState({ showCredentialsModal: false, sandboxId: null })}
        />
      </>
    );
  }

  renderIcon(row) {
    const sandbox = this.getSandboxForConfig(row.original.data.config);
    const transformation = this.props.transformationComponents.get(
      resolveComponentIdFromSandboxType(sandbox.get('type')),
      this.props.sandboxComponent,
    );

    return (
      <div className="icon-with-icon">
        <Image
          width={36}
          src={getComponentIconUrl(transformation)}
          alt={prepareSandboxTypeLabel(sandbox.get('type'))}
          className="icon-addon-right"
        />
        {this.hasPendingActions(sandbox) ? (
          <FontAwesomeIcon
            spin
            icon="spinner"
            className="bg-color-orange align-bottom small-icon"
            color="#fff"
          />
        ) : (
          !sandbox.get('active') &&
          CONTAINER_BASED.includes(sandbox.get('type')) && (
            <FontAwesomeIcon
              icon="moon"
              transform="shrink-6"
              className="bg-color-purple align-bottom small-icon"
              color="#fff"
            />
          )
        )}
      </div>
    );
  }

  renderNameLabel = (data) => {
    const sandbox = this.getSandboxForConfig(data.config);

    if (
      sandbox.get('isCreating') ||
      sandbox.get('active') ||
      !CONTAINER_BASED.includes(sandbox.get('type'))
    )
      return null;

    return <span className="color-purple font-medium">Sleeping mode:&nbsp;</span>;
  };

  renderStatusLabel(sandbox) {
    const { isCreating, isRestoring, isTerminating } = this.getPendingActionsForSandbox(sandbox);

    if (isCreating) {
      return (
        <Badge variant="orange" className={STATUS_CLASS}>
          <Loader className="icon-addon-right" />
          Creating
        </Badge>
      );
    }

    if (isTerminating || isRestoring) {
      return (
        <Badge variant="orange" className={STATUS_CLASS}>
          <Loader className="icon-addon-right" />
          {isTerminating ? 'Stopping' : 'Starting'}
        </Badge>
      );
    }

    if (!sandbox.get('active') && CONTAINER_BASED.includes(sandbox.get('type'))) {
      return (
        <Badge variant="purple" className={STATUS_CLASS}>
          Sleeping
        </Badge>
      );
    }

    return (
      <Badge variant={sandbox.get('active') ? 'green' : 'gray'} className={STATUS_CLASS}>
        {sandbox.get('active') ? 'Active' : 'Inactive'}
      </Badge>
    );
  }

  renderRestoreButton(sandbox, isRestoring) {
    if (
      sandbox.get('active') ||
      !CONTAINER_BASED.includes(sandbox.get('type')) ||
      this.props.readOnly
    ) {
      return null;
    }

    return (
      <RestoreWorkspace
        mode="menuitem"
        sandbox={sandbox}
        config={sandbox.get('configuration')}
        configData={sandbox.getIn(['configuration', 'configuration'])}
        isRestoring={isRestoring}
        hasPayAsYouGo={this.props.hasPayAsYouGo}
      />
    );
  }

  renderTerminateButton(sandbox, isTerminating) {
    if (
      !sandbox.get('active') ||
      !CONTAINER_BASED.includes(sandbox.get('type')) ||
      this.props.readOnly
    ) {
      return null;
    }

    return (
      <ConfirmMenuItem
        icon="circle-pause"
        title="Sleep Workspace"
        text={
          <>
            <p>{AUTO_SLEEP_NODE}</p>
            <p>
              Are you sure you want to sleep the workspace{' '}
              <strong>{sandbox.getIn(['configuration', 'name'])}</strong> now?
            </p>
          </>
        }
        buttonLabel="Sleep Workspace"
        isDisabled={isTerminating}
        onConfirm={() => this.terminateSandbox(sandbox)}
      >
        {isTerminating ? (
          <>
            <Loader />
            Stopping workspace...
          </>
        ) : (
          <>
            <FontAwesomeIcon icon="circle-pause" fixedWidth />
            Sleep workspace
          </>
        )}
      </ConfirmMenuItem>
    );
  }

  renderConnectButton(sandbox) {
    return (
      <RowActionMenuItem
        disabled={!sandbox.get('active')}
        onSelect={() => this.setState({ showCredentialsModal: true, sandboxId: sandbox.get('id') })}
      >
        <FontAwesomeIcon icon="circle-play" fixedWidth />
        Connect
      </RowActionMenuItem>
    );
  }

  renderMlFlowButton(sandbox) {
    if (
      SANDBOX_TYPE.PYTHON_MLFLOW !== sandbox.get('type') ||
      !this.props.mlflowInstanceUrl ||
      this.props.isDevModeActive
    ) {
      return null;
    }

    return (
      <RowActionMenuItem onSelect={() => windowOpen(this.props.mlflowInstanceUrl, 'noreferrer')}>
        <FontAwesomeIcon icon="arrow-up-right-from-square" fixedWidth />
        Open MLflow
      </RowActionMenuItem>
    );
  }

  renderShareButton(sandbox, isSharePending) {
    if (sandbox.get('tokenId') !== this.props.sapiToken.get('id') || this.props.readOnly) {
      return null;
    }

    if (sandbox.get('shared')) {
      return (
        <ConfirmMenuItem
          closeAfterResolve
          buttonType="success"
          icon="share"
          title="Disable Sharing"
          text={DISABLE_SHARING_MESSAGE}
          buttonLabel="Disable sharing"
          isDisabled={isSharePending}
          isLoading={isSharePending}
          onConfirm={() => SandboxActions.unshareSandbox(sandbox.get('id'))}
        >
          <FontAwesomeIcon icon="share" fixedWidth />
          Disable sharing
        </ConfirmMenuItem>
      );
    }

    return (
      <ConfirmMenuItem
        closeAfterResolve
        buttonType="success"
        icon="share"
        title="Enable Sharing"
        text={ENABLE_SHARING_MESSAGE}
        buttonLabel="Enable sharing"
        isDisabled={isSharePending}
        isLoading={isSharePending}
        onConfirm={() => SandboxActions.shareSandbox(sandbox.get('id'))}
      >
        <FontAwesomeIcon icon="share" fixedWidth />
        Enable sharing
      </ConfirmMenuItem>
    );
  }

  renderCreateTransformationButton(sandbox) {
    if (!canCreateTransformation(sandbox.get('type')) || this.props.readOnly) {
      return null;
    }

    return (
      <RowActionMenuItem
        onSelect={() =>
          this.setState({ showCreateTransformationModal: true, sandboxId: sandbox.get('id') })
        }
      >
        <span className="fa-layers fa-fw f-16">
          <FontAwesomeIcon icon="circle" />
          <FontAwesomeIcon icon="gear" transform="shrink-8" style={{ color: '#fff' }} />
        </span>
        Create new transformation
      </RowActionMenuItem>
    );
  }

  renderCopyToExistingTransformationButton(sandbox) {
    if (!canCreateTransformation(sandbox.get('type')) || this.props.readOnly) {
      return null;
    }

    return (
      <RowActionMenuItem
        onSelect={() =>
          this.setState({ showUpdateTransformationModal: true, sandboxId: sandbox.get('id') })
        }
      >
        <span className="fa-layers fa-fw f-16">
          <FontAwesomeIcon icon="circle" />
          <FontAwesomeIcon icon="gear" transform="shrink-8" style={{ color: '#fff' }} />
        </span>
        Copy to existing transformation
      </RowActionMenuItem>
    );
  }

  renderDescriptionButton(sandbox) {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <DescriptionButton
        configuration={sandbox.get('configuration', Map())}
        component={this.props.sandboxComponent}
      />
    );
  }

  renderDeleteButton(sandbox) {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <>
        <RowActionMenuItem divider />
        <ConfirmMenuItem
          icon="trash"
          title="Delete Workspace"
          text={
            <>
              Are you sure you want to delete the workspace{' '}
              <strong>{sandbox.getIn(['configuration', 'name'])}</strong>?
            </>
          }
          buttonLabel="Delete workspace"
          closeAfterResolve
          onConfirm={() => {
            return SandboxActions.deleteSandbox(
              sandbox.get('id'),
              sandbox.getIn(['configuration', 'id']),
            );
          }}
        >
          <FontAwesomeIcon icon="trash" fixedWidth />
          Delete workspace
        </ConfirmMenuItem>
      </>
    );
  }

  renderAdditionalActions(configuration) {
    const sandbox = this.getSandboxForConfig(configuration);
    const { isRestoring, isTerminating, isSharePending } =
      this.getPendingActionsForSandbox(sandbox);

    return (
      <>
        {this.renderConnectButton(sandbox)}
        <RowActionMenuItem divider />
        {this.renderMlFlowButton(sandbox)}
        {this.renderShareButton(sandbox, isSharePending)}
        {this.renderCreateTransformationButton(sandbox)}
        {this.renderCopyToExistingTransformationButton(sandbox)}
        {this.renderRestoreButton(sandbox, isRestoring)}
        {this.renderTerminateButton(sandbox, isTerminating)}
        {this.renderDescriptionButton(sandbox)}
        {this.renderDeleteButton(sandbox)}
      </>
    );
  }

  getSelectedSandboxes(selectedConfigurations) {
    return List(selectedConfigurations).map(({ config }) => this.getSandboxForConfig(config));
  }

  renderAdditionalMultiActions(selectedRows) {
    const selectedSandboxes = this.getSelectedSandboxes(selectedRows);
    const selectedContainerSandboxes = selectedSandboxes.filter((sandbox) =>
      CONTAINER_BASED.includes(sandbox.get('type')),
    );

    if (selectedContainerSandboxes.isEmpty()) {
      return null;
    }

    const hasNonContainer = selectedSandboxes.count() > selectedContainerSandboxes.count();
    const hasTerminatable = selectedContainerSandboxes.some((sandbox) => sandbox.get('active'));
    const hasRestorable = selectedContainerSandboxes.some((sandbox) => !sandbox.get('active'));

    const disabledTooltip =
      hasTerminatable && hasRestorable
        ? 'Multiple status selected.\n Change selection.'
        : hasNonContainer
          ? 'Sleep & Restore is available only for non-SQL workspaces.'
          : '';
    const isRestoreAction = !hasTerminatable && hasRestorable;

    return (
      <>
        <Tooltip
          placement="top"
          tooltip={disabledTooltip || `${isRestoreAction ? 'Restore' : 'Sleep'} Selected`}
        >
          <Button
            bsStyle="link"
            className={classnames('btn-link-inline btn-link-muted', {
              disabled: !!disabledTooltip,
            })}
            onClick={() => {
              if (!!disabledTooltip) {
                return;
              }

              return Promise.map(
                selectedContainerSandboxes,
                (sandbox) => {
                  return isRestoreAction
                    ? this.restoreSandbox(sandbox)
                    : this.terminateSandbox(sandbox);
                },
                { concurrency: 5 },
              );
            }}
          >
            <div>
              {(disabledTooltip || !isRestoreAction) && (
                <FontAwesomeIcon
                  icon="moon"
                  color="#fff"
                  transform="shrink-5"
                  className={classnames('bg-color-purple align-bottom img-rounded', {
                    'bg-color-muted-light icon-addon-right': disabledTooltip,
                  })}
                />
              )}
              {(disabledTooltip || isRestoreAction) && (
                <FontAwesomeIcon
                  icon="arrow-rotate-left"
                  className={classnames({
                    'text-muted-light icon-addon-left': disabledTooltip,
                  })}
                />
              )}
            </div>
          </Button>
        </Tooltip>
      </>
    );
  }

  additionalColumns = [
    {
      enableSorting: false,
      accessorKey: 'owner_sharing',
      header: 'Owner, Sharing',
      cell: ({ row }) => {
        const sandbox = this.getSandboxForConfig(row.original.data.config);

        if (sandbox.get('isCreating')) {
          return <span className="font-medium color-orange">Creating</span>;
        }

        return (
          <OwnerAndSharing
            sandbox={sandbox}
            config={sandbox.get('configuration', Map())}
            sapiToken={this.props.sapiToken}
            admins={this.props.admins}
          />
        );
      },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row }) => this.renderStatusLabel(this.getSandboxForConfig(row.original.data.config)),
      sortingFn: (rowA, rowB) => {
        const sandboxA = this.getSandboxForConfig(rowA.original.data.config);
        const sandboxB = this.getSandboxForConfig(rowB.original.data.config);

        if (!this.hasPendingActions(sandboxA) && this.hasPendingActions(sandboxB)) {
          return 1;
        }

        return this.hasPendingActions(sandboxA) || sandboxA.get('active') ? -1 : 1;
      },
      isLastColumn: true,
    },
  ];

  hasPendingActions(sandbox) {
    return Object.values(this.getPendingActionsForSandbox(sandbox)).some(Boolean);
  }

  getPendingActionsForSandbox(sandbox) {
    return {
      isCreating: sandbox.get('isCreating'),
      isSharePending: this.props.pendingActions.hasIn(['share', sandbox.get('id')]),
      isRestoring: this.props.pendingActions.hasIn(['restore', sandbox.get('id')]),
      isTerminating: this.props.pendingActions.hasIn(['terminate', sandbox.get('id')]),
    };
  }

  getSandboxForConfig(configuration) {
    if (configuration.get('isCreating')) {
      return (
        this.props.creatingSandboxes
          .find((sandbox) => sandbox?.getIn(['configuration', 'id']) === configuration.get('id'))
          .set('isCreating', true) || Map()
      );
    }

    return (
      this.props.sandboxes
        .concat(this.props.creatingSandboxes)
        .find(
          (sandbox) =>
            sandbox?.get('id', sandbox?.getIn(['configuration', 'id'])) ===
            configuration.getIn(['configuration', 'parameters', 'id']),
        ) || Map()
    );
  }

  terminateSandbox(sandbox) {
    return SandboxActions.terminateSandbox(
      sandbox.get('id'),
      sandbox.getIn(['configuration', 'id']),
    );
  }

  restoreSandbox(sandbox) {
    return SandboxActions.restoreSandbox(
      sandbox.get('id'),
      sandbox.getIn(['configuration', 'id']),
      sandbox.getIn(['configuration', 'configuration', 'storage', 'input'], Map()).toJS(),
    );
  }
}

Sandboxes.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  admins: PropTypes.instanceOf(Map).isRequired,
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  sandboxes: PropTypes.instanceOf(Map).isRequired,
  sandboxComponent: PropTypes.instanceOf(Map).isRequired,
  componentsMetadata: PropTypes.instanceOf(Map).isRequired,
  transformationComponents: PropTypes.instanceOf(Map).isRequired,
  pendingActions: PropTypes.instanceOf(Map).isRequired,
  creatingSandboxes: PropTypes.instanceOf(List).isRequired,
  allConfigurations: PropTypes.instanceOf(Map).isRequired,
  currentAdmin: PropTypes.instanceOf(Map).isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasNewQueue: PropTypes.bool.isRequired,
  hasPayAsYouGo: PropTypes.bool.isRequired,
  hasFlows: PropTypes.bool.isRequired,
  isDevModeActive: PropTypes.bool.isRequired,
  mlflowInstanceUrl: PropTypes.string,
};

export default Sandboxes;
