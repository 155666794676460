import React from 'react';
import { Tooltip } from 'design';

const NotAvailable = (props: { entity: string }) => {
  return (
    <Tooltip
      placement="top"
      tooltip={
        <>
          This information is not
          <br />
          available for {props.entity}.
        </>
      }
      triggerClassName="dashed-underline cursor-help"
    >
      N/A
    </Tooltip>
  );
};

export default NotAvailable;
