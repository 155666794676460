import React, { useState } from 'react';
import type { Iterable, Map } from 'immutable';

import { canPurgeTrash } from '@/modules/admin/privileges';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { mergeSampleDataToConfigurations } from '@/modules/components-directory/helpers';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { FILTERS } from '@/modules/trash/constants';
import AdditionalActions from '@/modules/trash/react/components/AdditionalActions';
import DeletedComponentRow from '@/modules/trash/react/components/DeletedComponentRow';
import type { FilterValues } from '@/modules/trash/types';
import { getFilteredComponents } from '@/modules/trash/utils';
import FilterPanel from '@/react/common/FilterPanel';
import LazyList from '@/react/common/LazyList';
import Loader from '@/react/common/Loader';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const TrashIndex = () => {
  const state = useStores(
    () => {
      const allComponents = ComponentsStore.getAll() as Map<string, any>;
      return {
        allComponents,
        isDeletedLoading: InstalledComponentsStore.getIsDeletedLoading(),
        isDevModeActive: DevBranchesStore.isDevModeActive(),
        deletedComponents: mergeSampleDataToConfigurations(
          InstalledComponentsStore.getAllDeleted(),
          allComponents,
        ) as Map<string, any>,
        sapiToken: ApplicationStore.getSapiToken(),
        readOnly: ApplicationStore.isReadOnly(),
        hasFlows: ApplicationStore.hasFlows(),
        adminEmail: ApplicationStore.getCurrentAdmin().get('email'),
        admins: ApplicationStore.getAdmins(),
      };
    },
    [],
    [InstalledComponentsStore, DevBranchesStore, ApplicationStore, ComponentsStore],
  );

  const [searchQuery, setSearchQuery] = useState(
    RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
  );
  const [filter, setFilter] = useState<FilterValues>(FILTERS.ALL);

  const renderRows = (components: Iterable<string, any>) => {
    if (!components.count()) {
      return (
        <div className="box">
          <div className="box-content">
            {searchQuery || filter === FILTERS.COMPONENTS
              ? 'No removed configurations found'
              : 'Trash is empty'}
          </div>
        </div>
      );
    }

    return components
      .map((component) => {
        return (
          <DeletedComponentRow
            key={component.get('id')}
            readOnly={state.readOnly}
            component={component}
            allComponents={state.allComponents}
            searchQuery={searchQuery}
            isDeleteEnabled={canPurgeTrash(state.sapiToken) && !state.isDevModeActive}
            hasFlows={state.hasFlows}
            admins={state.admins}
          />
        );
      })
      .toArray();
  };

  return (
    <>
      <FilterPanel
        placeholder="Search by name, description or id"
        query={searchQuery}
        onChange={(query) => setSearchQuery(query)}
        additionalActions={<AdditionalActions filter={filter} onSetFilter={setFilter} />}
      />
      {state.deletedComponents.isEmpty() && state.isDeletedLoading ? (
        <div className="box">
          <div className="box-content">
            <Loader className="icon-addon-right" />
            Loading...
          </div>
        </div>
      ) : (
        <LazyList
          limit={5}
          items={getFilteredComponents(
            filter,
            searchQuery,
            state.deletedComponents,
            state.adminEmail,
          )}
          render={renderRows}
        />
      )}
    </>
  );
};

export default TrashIndex;
