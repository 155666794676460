import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { parseFQID } from '@/modules/lineage/fqid';
import type { OnSelectProject, TableData } from '@/modules/lineage/rfTypes';
import Truncated from '@/react/common/Truncated';

type Props = {
  data: TableData;
  onSelectProject: OnSelectProject;
};

const SourceProjectLink = ({ data, onSelectProject }: Props) => {
  return (
    <Truncated
      tooltip={data.projectName}
      className="tw-flex tw-items-center tw-justify-start"
      text={
        <>
          <FontAwesomeIcon icon="link" className="tw-mr-1 tw-shrink-0 tw-text-neutral-400" />
          <a
            onClick={(event) => {
              const { projectId } = parseFQID(data.fqid);

              if (projectId) {
                onSelectProject(data.projectName, projectId, data.fqid, event);
              }
            }}
          >
            {data.projectName}
          </a>
        </>
      }
    />
  );
};

export default SourceProjectLink;
