import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from 'design';
import { List, Map } from 'immutable';

import { EXPERIMENTAL } from '@/constants/componentFlags';
import { HEADER_BUTTONS } from '@/constants/external';
import { APP_ROUTE } from '@/constants/routeNames';
import { isComponentDeprecated } from '@/modules/components/helpers';
import ComponentNameEdit from '@/modules/components/react/components/ComponentName';
import { HasOverridesLabel } from '@/modules/components/react/pages/GenericComponentRaw';
import ComponentTypeIcon from '@/modules/components-directory/components/ComponentTypeIcon';
import { routeNames as componentsRoutes } from '@/modules/components-directory/constants';
import ConfigurationRowName from '@/modules/configurations/react/components/ConfigurationRowName';
import BackToFlowButton from '@/modules/flows/components/BackToFlowButton';
import { routeNames as flowsRouteNames } from '@/modules/flows/constants';
import { routeNames as orchestrationsRouteNames } from '@/modules/orchestrations-v2/constants';
import { isPhaseJob } from '@/modules/queue/helpers';
import QueueJobs from '@/modules/queue/store';
import { routeNames as snowflakePartnerConnectRouteNames } from '@/modules/snowflake-partner-connect/constants';
import TransformationType from '@/modules/transformations/react/components/TransformationType';
import { routeNames as transformationsRouteNames } from '@/modules/transformations-v2/constants';
import CollapsibleAlert from '@/react/common/CollapsibleAlert';
import ComponentIcon from '@/react/common/ComponentIcon';
import ExperimentalComponentInfoAlert from '@/react/common/ExperimentalComponentInfoAlert';
import ForwardTokenLabel from '@/react/common/ForwardTokenLabel';
import Link from '@/react/common/RouterLink';
import RoutePendingIndicator from '@/react/layout/RoutePendingIndicator';
import contactSupport from '@/utils/contactSupport';
import { getComponentTypeIcon, getComponentTypeLabel } from './helpers';

const PageTitle = (props: {
  component: Map<string, any>;
  backFlow: Map<string, any>;
  allFlows: List<any>;
  breadcrumbs: List<any>;
  currentLocationQuery: Map<string, any>;
  currentRouteConfig: Map<string, any>;
  currentRouteParams: Map<string, any>;
  hasInvalidCustomBackend: boolean;
  hasProtectedDefaultBranch: boolean;
  hasNewQueue: boolean;
  hasOverrides: boolean;
  isDemoPreview: boolean;
  realComponentId: string;
  routerError: boolean;
}) => {
  const pageName = props.breadcrumbs.last()?.get('name');
  const isBackFlowEmpty = props.backFlow.isEmpty();

  const getBackFlowBreadcrumbs = React.useCallback(() => {
    const configIndex = props.breadcrumbs.findIndex((part: Map<string, any>) => {
      return part.hasIn(['link', 'params', 'config']);
    });

    return props.breadcrumbs.slice(configIndex);
  }, [props.breadcrumbs]);

  const getCurrentRouteQueryParams = React.useCallback(() => {
    return props.currentRouteConfig
      .get('persistQueryParams', List())
      .reduce((result: Map<string, any>, item: string) => {
        return result.set(item, props.currentLocationQuery.get(item));
      }, Map())
      .filter(Boolean);
  }, [props.currentLocationQuery, props.currentRouteConfig]);

  const renderName = () => {
    if (!props.breadcrumbs.count()) {
      return null;
    }

    const page = props.breadcrumbs.last();
    const linkTo = page.getIn(['link', 'to']);

    if (
      [
        props.component.get('id'),
        flowsRouteNames.DETAIL,
        orchestrationsRouteNames.DETAIL,
        transformationsRouteNames.GENERIC_TRANSFORMATION_CONFIG,
        componentsRoutes.GENERIC_CONFIG,
      ].includes(linkTo)
    ) {
      return (
        <h1 className={cn(!isBackFlowEmpty && 'reduce-size')}>
          <ComponentNameEdit
            componentId={props.realComponentId || props.component.get('id')}
            configId={props.currentRouteParams.get('config')}
          />
        </h1>
      );
    }

    if (
      [`${props.component.get('id')}-row`, componentsRoutes.GENERIC_CONFIG_ROW].includes(linkTo)
    ) {
      return (
        <h1>
          <ConfigurationRowName
            componentId={props.realComponentId || props.component.get('id')}
            configId={props.currentRouteParams.get('config')}
            rowId={props.currentRouteParams.get('row')}
          />
        </h1>
      );
    }

    if (props.currentRouteConfig.get('nameEdit')) {
      return <h1>{props.currentRouteConfig.get('nameEdit')(props.currentRouteParams.toJS())}</h1>;
    }

    return <h1>{page.get('title')}</h1>;
  };

  const renderFlowsButtons = () => {
    const backButton = getBackFlowBreadcrumbs().get(-2); // get route before last route
    const exploreButton = props.breadcrumbs.get(-1);

    return (
      <ButtonToolbar>
        {!!backButton && (
          <Link
            onlyActiveOnIndex
            className="btn btn-default"
            to={backButton.getIn(['link', 'to'])}
            params={backButton.getIn(['link', 'params'], Map()).toJS()}
          >
            <FontAwesomeIcon icon="chevron-left" className="icon-addon-right" />
            Back
          </Link>
        )}

        {!!exploreButton && (
          <Link
            className="btn btn-default"
            activeClassName=""
            to={exploreButton.getIn(['link', 'to'])}
            params={exploreButton.getIn(['link', 'params'], Map()).toJS()}
            state={{ resetBackFlow: true }}
          >
            <FontAwesomeIcon
              icon={getComponentTypeIcon(props.component)}
              className="icon-addon-right"
            />
            Explore in {getComponentTypeLabel(props.component)}s
          </Link>
        )}
        <BackToFlowButton flow={props.backFlow} />
      </ButtonToolbar>
    );
  };

  const renderIcon = () => {
    if (props.component.has('backend')) {
      return (
        <span className="breadcrumb-component-icon">
          <TransformationType showLabel={false} transformation={props.component} imageSize="64" />
        </span>
      );
    }

    if (!props.component.isEmpty()) {
      return (
        <div className="breadcrumb-component-icon component-icon-with-type">
          <ComponentIcon
            component={props.component}
            isPhase={
              props.hasNewQueue &&
              props.currentRouteParams.has('jobId') &&
              isPhaseJob(QueueJobs.get(props.currentRouteParams.get('jobId')))
            }
          />
          <ComponentTypeIcon type={props.component.get('type')} size="18" />
        </div>
      );
    }

    return null;
  };

  const renderLabels = () => {
    if (props.hasOverrides) {
      return <HasOverridesLabel componentId={props.component.get('id')} />;
    }

    if (!props.currentRouteConfig.get('labelHandler')) {
      return null;
    }

    return React.createElement(props.currentRouteConfig.get('labelHandler'));
  };

  const renderComponentLabels = () => {
    if (
      !props.hasProtectedDefaultBranch ||
      !props.component.getIn(['data', 'forward_token'], false)
    ) {
      return null;
    }

    return <ForwardTokenLabel />;
  };

  const renderBreadcrumbs = () => {
    const breadcrumbs: React.ReactNode[] = [];
    const filteredBreadcrumbs = isBackFlowEmpty ? props.breadcrumbs : getBackFlowBreadcrumbs();

    filteredBreadcrumbs.forEach((part: Map<string, any>, i) => {
      if (i !== filteredBreadcrumbs.count() - 1) {
        breadcrumbs.push(
          <Link
            className="dark muted"
            key={`${i}-${part.get('name')}`}
            to={part.getIn(['link', 'to'])}
            params={part.getIn(['link', 'params'], Map()).toJS()}
            query={part.getIn(['link', 'query'], getCurrentRouteQueryParams()).toJS()}
          >
            {part.get('title')}
          </Link>,
        );

        if (i !== filteredBreadcrumbs.count() - 2) {
          breadcrumbs.push(
            <FontAwesomeIcon
              icon={['far', 'angle-right']}
              key={`arrow-${i}-${part.get('name')}`}
            />,
          );
        }
      }
    });

    if (props.currentRouteConfig.get('breadcrumbHandler')) {
      return (
        <div className="breadcrumb">
          {React.createElement(props.currentRouteConfig.get('breadcrumbHandler'), {
            breadcrumbs,
            params: props.currentRouteParams.toJS(),
          })}
        </div>
      );
    }

    const subtitle = filteredBreadcrumbs.last()?.get('subtitle');

    if (props.routerError || (!breadcrumbs.length && !subtitle)) {
      return null;
    }

    return (
      <div className="breadcrumb">
        {breadcrumbs.length < 2 && subtitle ? (
          <span className="active">{subtitle}</span>
        ) : (
          breadcrumbs
        )}
        <RoutePendingIndicator />
      </div>
    );
  };

  const renderInfoAlert = () => {
    const infoAlertHandler = props.currentRouteConfig.get('infoAlertHandler');
    const isExperimental = props.component.get('flags', List()).includes(EXPERIMENTAL);

    if (!infoAlertHandler && !isExperimental) {
      return null;
    }

    return (
      <>
        {isExperimental && (
          <div className="container tw-mt-5">
            <ExperimentalComponentInfoAlert />
          </div>
        )}
        {infoAlertHandler && React.createElement(infoAlertHandler)}
      </>
    );
  };

  const renderButtons = () => {
    const showCustomHeaderButtons =
      !props.routerError && props.currentRouteConfig.get('headerButtonsHandler');
    const showBackToFlowButton = !isBackFlowEmpty;

    if (!showCustomHeaderButtons && !showBackToFlowButton) {
      return null;
    }

    return (
      <div className={`top-buttons ${HEADER_BUTTONS}`}>
        {showCustomHeaderButtons &&
          React.createElement(props.currentRouteConfig.get('headerButtonsHandler'), {
            descriptionIconOnly: showBackToFlowButton,
          })}
        {showCustomHeaderButtons && showBackToFlowButton && <span className="btn-separator" />}
        {showBackToFlowButton && renderFlowsButtons()}
      </div>
    );
  };

  if (
    (props.allFlows.isEmpty() && pageName === flowsRouteNames.ROOT) ||
    (props.isDemoPreview && pageName === APP_ROUTE) ||
    pageName === snowflakePartnerConnectRouteNames.UPGRADE_PAGE
  ) {
    return null;
  }

  return (
    <>
      {!props.routerError && renderInfoAlert()}
      {!props.routerError &&
        isComponentDeprecated(props.component) &&
        props.currentRouteParams.get('config') && (
          <div className="container tw-mt-5">
            <CollapsibleAlert
              id="deprecated-component"
              title="This component has been deprecated"
              variant="warning"
            >
              New configuration cannot be created. Consider switching to another component to ensure
              a functionality in the future.{' '}
              <Button bsStyle="link" className="btn-link-inline" onClick={() => contactSupport()}>
                Contact our support
              </Button>{' '}
              if you have any questions.
            </CollapsibleAlert>
          </div>
        )}
      {!props.hasInvalidCustomBackend && (
        <div className="container flex-container breadcrumb-container">
          {!props.routerError && (
            <div className="title">
              {renderIcon()}
              <div className="flex-container flex-column align-top justify-center">
                {renderBreadcrumbs()}
                <div className="flex-container flex-start pr-2">
                  {renderName()}
                  {renderLabels()}
                  {renderComponentLabels()}
                </div>
              </div>
            </div>
          )}
          {renderButtons()}
        </div>
      )}
    </>
  );
};

export default PageTitle;
