import React from 'react';
import createReactClass from 'create-react-class';
import { fromJS, List } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import ComponentDescription from '@/modules/components/react/components/ComponentDescription';
import GenericRows from '@/modules/components/react/pages/GenericRows';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import columnTypes from '@/modules/configurations/utils/columnTypeConstants';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { deleteCredentialsAndConfigAuth } from '@/modules/oauth-v2/OauthUtils';
import AuthorizationRow from '@/modules/oauth-v2/react/AuthorizationRow';
import OauthStore from '@/modules/oauth-v2/Store';
import { shouldShowSimplifiedUi } from '@/modules/simplified-ui/helpers';
import SimplifiedUi from '@/modules/simplified-ui/Index';
import { prepareTablesMetadataMap } from '@/modules/storage/helpers';
import ConfigurationInfoPanel from '@/react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import Sidebar from '@/react/layout/Sidebar/Sidebar';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import Profiles from './components/Profiles';
import { prepareProfilesData } from './helpers';
import LegacyIndex from './LegacyIndex';

const Index = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      DevBranchesStore,
      OauthStore,
      ComponentsStore,
      InstalledComponentsStore,
      StorageBucketsStore,
      StorageTablesStore,
      ConfigurationRowsStore,
    ),
  ],

  getStateFromStores() {
    const componentId = RoutesStore.getCurrentRouteComponentId();
    const configId = RoutesStore.getCurrentRouteParam('config');
    const config = InstalledComponentsStore.getConfig(componentId, configId);
    const configData = InstalledComponentsStore.getConfigData(componentId, configId);

    return {
      componentId,
      configId,
      config,
      configData,
      allTables: StorageTablesStore.getAll(),
      allBuckets: StorageBucketsStore.getAll(),
      component: ComponentsStore.getComponent(componentId),
      allComponents: ComponentsStore.getAll(),
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      rows: ConfigurationRowsStore.getRows(componentId, configId),
      flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      oauthCredentials: OauthStore.getCredentials(componentId, configData),
      readOnly: ApplicationStore.isReadOnly(),
      admins: ApplicationStore.getAdmins(),
    };
  },

  render() {
    if (
      this.state.rows.isEmpty() &&
      !this.state.configData.getIn(['parameters', 'queries'], List()).isEmpty()
    ) {
      return <LegacyIndex />;
    }

    if (shouldShowSimplifiedUi(this.state.component, this.state.configData)) {
      return <SimplifiedUi />;
    }

    return (
      <>
        <ConfigurationTabs componentId={this.state.componentId} configId={this.state.configId} />
        <ConfigurationInfoPanel
          component={this.state.component}
          allComponents={this.state.allComponents}
          config={this.state.config}
          flows={this.state.flows}
          tablesMetadataMap={prepareTablesMetadataMap(this.state.allTables)}
          metadata={this.state.componentsMetadata}
        />
        <div className="row">
          <div className="col-sm-9">
            <ComponentDescription
              componentId={this.state.componentId}
              configId={this.state.configId}
            />
            <AuthorizationRow
              componentId={this.state.componentId}
              configId={this.state.configId}
              credentials={this.state.oauthCredentials}
              onResetCredentials={this.handleResetCredentials}
              readOnly={this.state.readOnly}
              admins={this.state.admins}
            />
            <Profiles
              componentId={this.state.componentId}
              profiles={this.state.configData
                .getIn(['parameters', 'profiles'], List())
                .concat(this.state.configData.getIn(['parameters', 'properties'], List()))}
              hasCredentialsId={this.state.configData.hasIn(['authorization', 'oauth_api', 'id'])}
              oauthCredentials={this.state.oauthCredentials}
              handleSave={this.handleSaveProfiles}
              readOnly={this.state.readOnly}
            />
            <GenericRows
              rows={this.state.rows}
              buckets={this.state.allBuckets}
              tables={this.state.allTables}
              config={this.state.config}
              component={this.state.component}
              readOnly={this.state.readOnly}
              rowLinkTo={`${this.state.componentId}-row`}
              rowsHeader={['Name', 'Output Table', 'Description']}
              rowsColumns={fromJS([
                {
                  name: 'Output Table',
                  type: columnTypes.TABLE_LINK,
                  value: (row) => {
                    return `${this.state.configData.getIn(
                      ['parameters', 'outputBucket'],
                      '',
                    )}.${row.getIn(['parameters', 'outputTable'], '')}`;
                  },
                },
              ])}
            />
          </div>
          <div className="col-sm-3">
            <Sidebar
              componentId={this.state.componentId}
              configId={this.state.configId}
              documentation={this.state.component.get('documentationUrl')}
              readOnly={this.state.readOnly}
            />
          </div>
        </div>
      </>
    );
  },

  handleSaveProfiles(profiles) {
    return InstalledComponentsActionCreators.saveComponentConfigData(
      this.state.componentId,
      this.state.configId,
      prepareProfilesData(this.state.configData, profiles),
      'Update profiles',
    );
  },

  handleResetCredentials() {
    return deleteCredentialsAndConfigAuth(this.state.componentId, this.state.configId);
  },
});

export default Index;
