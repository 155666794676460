import React from 'react';
import PropTypes from 'prop-types';

import changedSinceConstants from '@/react/common/changedSinceConstants';

const LoadTypeSectionTitle = ({ value }) => (
  <span>
    Load type:{' '}
    {!value.changedSince
      ? 'Full load'
      : value.changedSince === changedSinceConstants.ADAPTIVE_VALUE
        ? 'Automatic incremental load'
        : 'Manual incremental load'}
    {value.changedSince && value.grain.length > 0 && ' with fact grain'}
  </span>
);

LoadTypeSectionTitle.propTypes = {
  value: PropTypes.object,
};

export default LoadTypeSectionTitle;
