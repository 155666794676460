import type { DurationUnitType } from 'dayjs/plugin/duration';
import type { Map } from 'immutable';
import { List } from 'immutable';
import { trim } from 'underscore.string';

import dayjs from '@/date';
import {
  DEFAULT_EXPIRATION,
  EXPIRATION_PATH,
  EXPIRATION_PATH_FALLBACK,
  OIDC_PROVIDERS,
} from './constants';
import type { DataApp } from './DataAppsStore';

const parepareDataApps = (configs: Map<string, any>, apps: DataApp[]) => {
  const nonDeletedApps = apps.filter((app) => !['deleted', 'deleting'].includes(app.state));

  return configs
    .map((config) => {
      return config.set(
        'app',
        nonDeletedApps.find(({ configId }) => configId === config.get('id')),
      );
    })
    .filter((config) => !!config?.get('app')) as Map<string, any>;
};

const isAppConfigured = (config: Map<string, any>) => {
  return (
    !!config.getIn(['configuration', 'parameters', 'dataApp', 'git', 'repository']) ||
    !!config.getIn(['configuration', 'parameters', 'script'], List()).join('').trim()
  );
};

const prepareSlug = (name: string) => {
  return trim(name.replace(/[^a-zA-Z0-9-]/g, '-'), ' -').toLowerCase();
};

const resolveProvider = (formData: Map<string, any>) => {
  return (
    Object.values(OIDC_PROVIDERS).find(
      (provider) => provider.url && formData.get('issuer_url', '').includes(provider.url),
    ) || OIDC_PROVIDERS.oidc
  );
};

const prepareProviderFormData = (formData: Map<string, any>) => {
  return formData.withMutations((data) => {
    const provider = resolveProvider(formData);

    if (!provider?.values) {
      return;
    }

    Object.entries(provider.values).forEach(([key, { property, regex }]) => {
      data.set(key, formData.get(property)?.match(regex)?.[1] || '');
    });
  });
};

const prepareValueFromMapping = (
  formData: Map<string, any>,
  property: string,
  mappings?: Record<string, string>,
) => {
  return mappings?.[property]
    ? mappings[property].replace(/{([^}]+)}/g, (match, key) => formData.get(key, ''))
    : formData.get(property, '');
};

const prepareExpiration = (config: Map<string, any>) => {
  if (config.hasIn(EXPIRATION_PATH)) {
    return config.getIn(EXPIRATION_PATH);
  }

  if (config.hasIn(EXPIRATION_PATH_FALLBACK)) {
    return config.getIn(EXPIRATION_PATH_FALLBACK) * 3600;
  }

  return DEFAULT_EXPIRATION;
};

const prepareExpirationOption = (value: number, unit?: DurationUnitType) => {
  return { value, label: !!unit ? dayjs.duration(value, unit).humanize() : value };
};

export {
  isAppConfigured,
  prepareSlug,
  resolveProvider,
  parepareDataApps,
  prepareExpiration,
  prepareProviderFormData,
  prepareValueFromMapping,
  prepareExpirationOption,
};
