import React from 'react';
import { cn } from 'design';
import type { Map } from 'immutable';

import type { PostMessageResponse } from '@/api/routes/aiService';
import { type MessageObject, roles } from '@/modules/automations/constants';
import Gravatar from '@/react/common/Gravatar';
import Markdown from '@/react/common/Markdown';
import KeboolaLogo from '@/react/layout/KeboolaLogo';
import InteractiveElement from './InteractiveElement';

const Message = ({
  messageObject,
  admin,
  isLastMessage,
  submitMessage,
}: {
  messageObject: MessageObject | PostMessageResponse;
  admin: Map<string, any>;
  isLastMessage: boolean;
  submitMessage: (messageObject: MessageObject) => void;
}) => {
  const elementRef = React.useRef<HTMLDivElement>(null);

  const isClientMessage = messageObject.role === roles.USER;

  React.useEffect(() => {
    if (isLastMessage && elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isLastMessage]);

  return (
    <div ref={elementRef} className={cn('tw-flex tw-gap-3', { 'tw-self-end': isClientMessage })}>
      {isClientMessage ? (
        <div className="tw-order-last tw-flex-shrink-0">
          <Gravatar user={admin} />
        </div>
      ) : (
        <KeboolaLogo className="tw-flex-shrink-0" withoutText />
      )}
      <div
        className={cn(
          'tw-flex tw-max-w-[640px] tw-flex-col tw-gap-5 tw-rounded-lg tw-p-4',
          isClientMessage
            ? 'tw-self-end tw-rounded-tr-none tw-bg-secondary-600 tw-text-white'
            : 'tw-rounded-tl-none tw-bg-white',
        )}
      >
        {!!messageObject.text && (
          <Markdown overflow={false} collapsible={false} source={messageObject.text} />
        )}
        {!!messageObject.data && (
          <InteractiveElement messageObject={messageObject} submitMessage={submitMessage} />
        )}
      </div>
    </div>
  );
};

export default Message;
