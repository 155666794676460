import './sentry';
import './utils/promise';
import './utils/ReactErrorHandler';
import './utils/font-awesome/setup';
import './utils/ReactBootstrapOverrides';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { rtrim } from 'underscore.string';

import '../styles/app.less';

import { Constants as OAuthConstants } from './modules/oauth-v2/Constants';
import { RedirectionPlaceholder } from './modules/oauth-v2/react/RedirectionPlaceholder';
import * as helpers from './helpers';
import Root from './Root';

const startApp = (appOptions) => {
  if (window.matchMedia('screen and (max-width: 991px)').matches) {
    document.querySelector('meta[name=viewport]').setAttribute('content', 'width=992');
  }

  // When on OAuth redirection callback route, load placeholder instead of whole app
  if (
    rtrim(window.location.pathname, '/') ===
    `${appOptions?.data?.kbc?.projectBaseUrl}/${OAuthConstants.ROUTE_NAME}`
  ) {
    createRoot(appOptions.rootNode).render(<RedirectionPlaceholder />);
    return;
  }

  createRoot(appOptions.rootNode).render(
    <Root
      data={appOptions.data}
      isPreview={appOptions.isPreview}
      isDemoPreview={appOptions.isDemoPreview}
    />,
  );
};

export default {
  start: startApp,
  helpers,
};
