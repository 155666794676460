import React from 'react';
import classnames from 'classnames';

import RouterLink from '@/react/common/RouterLink';

type Props = {
  to: string;
  active?: boolean;
  children: React.ReactNode;
  params?: Record<string, any>;
  forceProduction?: boolean;
  // these two props are passed by dropdown menu
  onSelect?: (to: string, event: React.SyntheticEvent) => void;
  onKeyDown?: React.KeyboardEventHandler;
};

const LinkMenuItem = (props: Props) => {
  return (
    <li role="presentation" className={classnames({ active: props.active })}>
      <RouterLink
        role="menuitem"
        tabIndex={-1}
        to={props.to}
        params={props.params}
        onKeyDown={props.onKeyDown}
        onClick={(event) => props.onSelect?.(props.to, event)}
        forceProduction={props.forceProduction}
      >
        {props.children}
      </RouterLink>
    </li>
  );
};

export default LinkMenuItem;
