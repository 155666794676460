import React, { useEffect, useState } from 'react';
import { Button, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Promise } from 'bluebird';
import classNames from 'classnames';
import { Badge, Clipboard, copyToClipboard, HelpBlock, Tooltip } from 'design';
import type { List, Map } from 'immutable';
import memoizeOne from 'memoize-one';
import _ from 'underscore';
import { capitalize } from 'underscore.string';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import type { Variable, VariableWithHash } from '@/api/routes/vaultService';
import { canManageVariable } from '@/modules/admin/privileges';
import {
  createFolder,
  createVariable,
  deleteVariable,
  loadVariables,
  updateVariable,
} from '@/modules/vault/actions';
import { type FilterType, VARIABLE_TYPE } from '@/modules/vault/constants';
import {
  filterProductionVariables,
  getUniqueGroups,
  getVariablesByType,
  groupVariables,
  isVariableEncrypted,
  prepareVariableToCopy,
} from '@/modules/vault/helpers';
import ActivateDeactivateSwitch from '@/react/common/ActivateDeactivateSwitch';
import FolderName from '@/react/common/ConfigurationsTable/FolderName';
import ConfirmModal from '@/react/common/ConfirmModal';
import EncryptedValue from '@/react/common/EncryptedValue';
import MarkedText from '@/react/common/MarkedText';
import MultiActionsHeader from '@/react/common/MultiActionsHeader';
import MultiActionsSelectCheckbox from '@/react/common/MultiActionsSelectCheckbox';
import RowActionDropdown from '@/react/common/RowActionDropdown';
import RowActionMenuItem from '@/react/common/RowActionMenuItem';
import Select from '@/react/common/Select';
import { folderLabel } from '@/react/common/selectLabels';
import SortIcon from '@/react/common/SortIcon';
import TableCollapseButton from '@/react/common/TableCollapseButton';
import Truncated from '@/react/common/Truncated';
import matchByWords from '@/utils/matchByWords';
import string from '@/utils/string';
import FullScreenTable from './FullScreenTable';
import Search from './Search';

type Sorter = { column: string; order: -1 | 1 };

const getFilteredVariables = memoizeOne(
  (variables: VariableWithHash[], query: string, type: FilterType) => {
    let filteredVariables = getVariablesByType(variables, type);

    if (query) {
      filteredVariables = filteredVariables.filter((variable) =>
        matchByWords([variable.key, variable.group ?? ''], query),
      );
    }

    return {
      filteredVariables,
      filteredVariablesByGroups: groupVariables(filteredVariables),
    };
  },
);

type Props = {
  sapiToken: Map<string, any>;
  variables: VariableWithHash[];
  allVariables: VariableWithHash[];
  isDevModeActive: boolean;
  canManageVariables: boolean;
  currentDevBranchId: number | null;
  defaultDevBranchId: number;
  showForm: null | 'folder' | 'variable';
  setShowForm: (show: boolean) => void;
};

const VariablesTable = (props: Props) => {
  const [sort, setSort] = useState<Sorter>({ column: 'name', order: 1 });
  const [editingVariable, setEditingVariable] = useState<VariableWithHash | null>(null);
  const [cloningVariable, setCloningVariable] = useState<VariableWithHash | null>(null);
  const [selectedVariables, setSelectedVariables] = useState<string[]>([]);
  const [expandedFolders, setExpandedFolders] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [variablesType, setVariablesType] = useState<FilterType>(VARIABLE_TYPE.ALL);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const variablesByGroups = groupVariables(props.variables);
  const productionVariablesByGroups = groupVariables(filterProductionVariables(props.allVariables));
  const { filteredVariables, filteredVariablesByGroups } = getFilteredVariables(
    props.variables,
    searchQuery,
    variablesType,
  );

  const toggleFolder = (folderName: string) => {
    if (!!searchQuery) {
      return;
    }

    setExpandedFolders(
      expandedFolders.includes(folderName)
        ? expandedFolders.filter((name) => name !== folderName)
        : [...expandedFolders, folderName],
    );
  };

  const isVariableSelectable = (variable: VariableWithHash) => {
    return props.canManageVariables && canManageVariable(props.sapiToken, variable);
  };

  const renderSortColumn = (column: string) => {
    const isActive = sort.column === column;

    return (
      <span
        className="clickable"
        title={`Sort by ${column}`}
        onClick={() => setSort({ column, order: isActive ? (sort.order === 1 ? -1 : 1) : 1 })}
      >
        {capitalize(column)}
        <SortIcon
          isSorted={isActive}
          isSortedDesc={isActive && sort.order === -1}
          className="icon-addon-left"
        />
      </span>
    );
  };

  const getFilterPlaceholder = () => {
    const variablesCount = filteredVariables.length;
    const foldersCount = Object.keys(filteredVariablesByGroups).filter(Boolean).length;

    return `Search variables (${variablesCount}) or folders (${foldersCount})`;
  };

  const hasVariables = !!props.variables.length;
  const hasSelectableVariables = !!filteredVariables.filter(isVariableSelectable).length;
  const existingFolders = getUniqueGroups(variablesByGroups, productionVariablesByGroups);

  return (
    <>
      <Search
        query={searchQuery}
        setSearchQuery={setSearchQuery}
        variablesType={variablesType}
        setVariablesType={setVariablesType}
        placeholder={getFilterPlaceholder()}
        isDevModeActive={props.isDevModeActive}
      />
      <FullScreenTable>
        {hasVariables && (
          <thead>
            <tr className="is-sticky bg-color-white">
              <th className="w-600">
                <MultiActionsHeader
                  entity="variable"
                  hide={!hasSelectableVariables}
                  disabled={!hasSelectableVariables}
                  selectedCount={selectedVariables.length}
                  totalCount={filteredVariables.length}
                  onToggleAll={(checked) => {
                    setSelectedVariables(
                      checked
                        ? filteredVariables
                            .filter(isVariableSelectable)
                            .map((variable) => variable.hash)
                        : [],
                    );
                  }}
                  placeholder={renderSortColumn('name')}
                >
                  <div className="table-action-buttons">
                    <Tooltip placement="top" tooltip="Delete Selected">
                      <Button
                        bsStyle="link"
                        className="text-muted"
                        onClick={() => setShowDeleteModal(true)}
                      >
                        <FontAwesomeIcon icon="trash" fixedWidth />
                      </Button>
                    </Tooltip>
                    <Tooltip placement="top" tooltip="Move Selected">
                      <Button
                        bsStyle="link"
                        className="text-muted"
                        onClick={() => setShowMoveModal(true)}
                      >
                        <FontAwesomeIcon icon="folder-arrow-up" />
                      </Button>
                    </Tooltip>
                  </div>
                </MultiActionsHeader>
              </th>
              <th>{renderSortColumn('value')}</th>
              <th className="w-200"></th>
            </tr>
          </thead>
        )}
        <tbody>
          {!hasVariables && (
            <tr className="no-hover">
              <td colSpan={3}>No variables created yet.</td>
            </tr>
          )}
          {hasVariables && !filteredVariables.length && (
            <tr className="no-hover">
              <td colSpan={3}>No variables found.</td>
            </tr>
          )}
          {Object.entries(filteredVariablesByGroups)
            .sort(([folderA], [folderB]) => {
              // keep folders always on top
              const order = sort.column === 'name' ? sort.order : 1;
              if (folderA && !folderB) return -1;
              if (!folderA && folderB) return 1;
              return folderA.localeCompare(folderB) * order;
            })
            .map(([folderName, folderVariables]: [string, VariableWithHash[]]) => {
              const isExpanded = expandedFolders.includes(folderName) || !!searchQuery;
              const isAllSelected = folderVariables.every((variable) => {
                return selectedVariables.includes(variable.hash);
              });
              const isSomeSelected = folderVariables.some((variable) => {
                return selectedVariables.includes(variable.hash);
              });
              const editableVariablesHashes = folderVariables
                .filter(isVariableSelectable)
                .map((varible) => varible.hash);

              const toggleSelectedVariables = (checked: boolean) => {
                return setSelectedVariables(
                  checked
                    ? [...new Set([...selectedVariables, ...editableVariablesHashes])]
                    : selectedVariables.filter((hash) => {
                        return !editableVariablesHashes.includes(hash);
                      }),
                );
              };

              return (
                <React.Fragment key={folderName}>
                  {folderName && (
                    <tr
                      className={classNames('folder-row', {
                        'no-hover': searchQuery,
                        'btn-collapse-area': !searchQuery,
                      })}
                      onClick={() => toggleFolder(folderName)}
                    >
                      <td colSpan={3}>
                        <div className="flex-container flex-start">
                          {hasSelectableVariables && (
                            <span className="icon-addon-right">
                              <MultiActionsSelectCheckbox
                                entity="folder"
                                isChecked={isAllSelected}
                                isIndeterminate={isSomeSelected && !isAllSelected}
                                isDisabled={
                                  !props.canManageVariables || !editableVariablesHashes.length
                                }
                                onToggle={toggleSelectedVariables}
                              />
                            </span>
                          )}
                          <span className="with-expand flex-container flex-start overflow-break-anywhere">
                            <TableCollapseButton
                              entity="folder"
                              isCollapsed={!isExpanded}
                              isDisabled={!!searchQuery}
                            />
                            <FontAwesomeIcon
                              fixedWidth
                              icon={isExpanded ? 'folder-open' : 'folder'}
                              className="f-16 text-muted icon-addon-left icon-addon-right"
                            />
                            <FolderName
                              name={folderName}
                              label={<MarkedText source={folderName} mark={searchQuery} />}
                              readOnly={
                                !!searchQuery.trim() ||
                                variablesType !== VARIABLE_TYPE.ALL ||
                                !props.canManageVariables ||
                                !folderVariables.every(isVariableSelectable)
                              }
                              onSave={(newName) =>
                                createFolder(newName, filteredVariablesByGroups[folderName]).then(
                                  () =>
                                    setExpandedFolders((expandedFolders) =>
                                      expandedFolders.map((folder) =>
                                        folder === folderName ? newName : folder,
                                      ),
                                    ),
                                )
                              }
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                  )}
                  {(isExpanded || !folderName) &&
                    folderVariables
                      .sort((a, b) => {
                        // when sorting by value, encrypted variables should be always at the bottom
                        const key = sort.column === 'value' ? 'value' : 'key';
                        if (key === 'value' && isVariableEncrypted(a) && !isVariableEncrypted(b))
                          return 1;
                        if (key === 'value' && !isVariableEncrypted(a) && isVariableEncrypted(b))
                          return -1;
                        return a[key].localeCompare(b[key]) * sort.order;
                      })
                      .map((variable) => (
                        <VariableRow
                          key={`${variable.key}-${variable.attributes?.branchId}`}
                          variable={variable}
                          variables={props.variables}
                          folderName={folderName}
                          searchQuery={searchQuery}
                          selected={selectedVariables}
                          setSelected={setSelectedVariables}
                          canManageVariables={props.canManageVariables}
                          startEditing={() => {
                            setEditingVariable(variable);
                            props.setShowForm(true);
                          }}
                          startCloning={() => {
                            setCloningVariable(variable);
                            props.setShowForm(true);
                          }}
                          defaultDevBranchId={props.defaultDevBranchId}
                          currentDevBranchId={props.currentDevBranchId}
                          canManageVariable={isVariableSelectable(variable)}
                          hasSelectableVariables={hasSelectableVariables}
                        />
                      ))}
                </React.Fragment>
              );
            })}
        </tbody>
      </FullScreenTable>
      <DeleteVariablesModal
        show={showDeleteModal}
        selected={selectedVariables}
        onConfirm={() => {
          return Promise.each(selectedVariables, (hash) => {
            const variable = props.variables.find((variable) => variable.hash === hash);
            return variable ? deleteVariable(variable) : null;
          })
            .then(() => setSelectedVariables([]))
            .then(loadVariables);
        }}
        onHide={() => setShowDeleteModal(false)}
      />
      <MoveVariablesModal
        show={showMoveModal}
        onHide={() => setShowMoveModal(false)}
        folders={existingFolders}
        onConfirm={(group) => {
          return Promise.each(selectedVariables, (hash) => {
            const variable = props.variables.find((variable) => variable.hash === hash);
            return variable ? updateVariable({ ...variable, group }) : null;
          })
            .then(() => setSelectedVariables([]))
            .then(loadVariables);
        }}
      />
      <FolderForm
        show={props.showForm === 'folder'}
        sapiToken={props.sapiToken}
        variables={props.variables}
        existingFolders={existingFolders}
        onHide={() => props.setShowForm(false)}
      />
      <VariableForm
        show={props.showForm === 'variable'}
        variable={editingVariable ?? cloningVariable}
        isCloning={!!cloningVariable}
        variables={props.variables}
        existingFolders={existingFolders}
        isDevModeActive={props.isDevModeActive}
        defaultDevBranchId={props.defaultDevBranchId}
        currentDevBranchId={props.currentDevBranchId}
        onHide={() => {
          props.setShowForm(false);
          setEditingVariable(null);
          setCloningVariable(null);
        }}
      />
    </>
  );
};

const VariableRow = ({
  variable,
  variables,
  folderName,
  searchQuery,
  canManageVariable,
  hasSelectableVariables,
  defaultDevBranchId,
  currentDevBranchId,
  canManageVariables,
  selected,
  setSelected,
  startEditing,
  startCloning,
}: {
  variable: VariableWithHash;
  variables: VariableWithHash[];
  folderName: string;
  searchQuery: string;
  canManageVariable: boolean;
  hasSelectableVariables: boolean;
  defaultDevBranchId: number;
  currentDevBranchId: number | null;
  canManageVariables: boolean;
  selected: string[];
  setSelected: (selected: string[]) => void;
  startEditing: () => void;
  startCloning: () => void;
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
  const isScoped = !!variable.attributes?.branchId;
  const isScopedToCurrentBranch =
    isScoped && variable.attributes?.branchId !== defaultDevBranchId.toString();
  const showCloneVariable =
    canManageVariables && !canManageVariable && isScoped && !isScopedToCurrentBranch;
  const currentBranchId = currentDevBranchId?.toString() ?? defaultDevBranchId.toString();
  const alreadyExistingInScope = variables.some((savedVariable) => {
    return (
      savedVariable.key === variable.key && savedVariable.attributes?.branchId === currentBranchId
    );
  });

  return (
    <tr
      className={classNames('variable-row', {
        clickable: canManageVariable,
        'in-folder': !!folderName,
      })}
      onClick={(event: React.BaseSyntheticEvent) => {
        if (!canManageVariable || event.target.closest('button, .btn')) return;

        copyToClipboard(prepareVariableToCopy(variable)).then(() => {
          setShowCopiedTooltip(true);
          setTimeout(() => setShowCopiedTooltip(false), 500);
        });
      }}
    >
      <td>
        <div className="flex-container flex-start">
          {hasSelectableVariables && (
            <span className="icon-addon-right">
              <MultiActionsSelectCheckbox
                entity="variable"
                isDisabled={!canManageVariable}
                isChecked={selected.includes(variable.hash)}
                onToggle={(checked: boolean) => {
                  setSelected(
                    checked
                      ? [...selected, variable.hash]
                      : selected.filter((hash) => hash !== variable.hash),
                  );
                }}
              />
            </span>
          )}
          <Truncated
            tooltip={variable.key}
            text={<MarkedText source={variable.key} mark={searchQuery} />}
          />
          {canManageVariable && (
            <Tooltip
              tooltip="Copied to Clipboard"
              forceShow={showCopiedTooltip}
              forceHide={!showCopiedTooltip}
            >
              <Clipboard
                text={prepareVariableToCopy(variable)}
                tooltipText="Copy Reference to Clipboard"
              />
            </Tooltip>
          )}
        </div>
      </td>
      <td>
        <div className="flex-container">
          {isVariableEncrypted(variable) ? <EncryptedValue /> : <Truncated text={variable.value} />}
          {isScoped && (
            <Badge variant={isScopedToCurrentBranch ? 'orange' : 'blue'} placement="right">
              {isScopedToCurrentBranch ? 'Branch Specific' : 'Production Only'}
            </Badge>
          )}
        </div>
      </td>
      <td>
        {canManageVariable && (
          <RowActionDropdown inModal showLoading={isDeleting}>
            <RowActionMenuItem onSelect={() => startEditing()}>
              <FontAwesomeIcon fixedWidth icon="pen" />
              Edit variable
            </RowActionMenuItem>
            <RowActionMenuItem divider />
            <RowActionMenuItem
              onSelect={() => {
                setIsDeleting(true);
                deleteVariable(variable)
                  .then(loadVariables)
                  .finally(() => setIsDeleting(false));
              }}
              disabled={isDeleting}
            >
              <FontAwesomeIcon fixedWidth icon="trash" />
              Delete variable
            </RowActionMenuItem>
          </RowActionDropdown>
        )}
        {showCloneVariable && (
          <Tooltip
            placement="top"
            type="explanatory"
            tooltip={
              alreadyExistingInScope ? (
                <>
                  Already Exists
                  <br />
                  in Current Branch
                </>
              ) : (
                <>
                  Clone Variable
                  <br />
                  to Current Branch
                </>
              )
            }
          >
            <Button
              bsStyle="link"
              className={classNames('text-muted', { disabled: alreadyExistingInScope })}
              onClick={() => !alreadyExistingInScope && startCloning()}
            >
              <FontAwesomeIcon icon="clone" />
            </Button>
          </Tooltip>
        )}
      </td>
    </tr>
  );
};

const FolderForm = (props: {
  show: boolean;
  sapiToken: Map<string, any>;
  variables: VariableWithHash[];
  existingFolders: string[];
  onHide: () => void;
}) => {
  const initData = { name: '', variables: [] as VariableWithHash[] };

  const [isCreating, setLoading] = useState(false);
  const [formData, setFormData] = useState(initData);

  const folderExists = props.existingFolders.some((folderName) => folderName === formData.name);
  const allowedVariables = props.variables.filter((variable) =>
    canManageVariable(props.sapiToken, variable),
  );

  const onHide = () => {
    props.onHide();
    setFormData(initData);
  };

  if (!props.show) {
    return null;
  }

  return (
    <ConfirmModal
      closeAfterResolve
      show={props.show}
      isLoading={isCreating}
      isDisabled={isCreating || !formData.name.trim() || !formData.variables.length || folderExists}
      onHide={onHide}
      icon="plus"
      buttonType="success"
      buttonLabel="Create Folder"
      title="Create Folder"
      text={
        <>
          <FormGroup>
            <ControlLabel>Name</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              placeholder="Folder name"
              value={formData.name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFormData({ ...formData, name: event.target.value });
              }}
              disabled={isCreating}
            />
            {folderExists && (
              <HelpBlock variant="danger">The folder with this name is already existing</HelpBlock>
            )}
          </FormGroup>
          <FormGroup>
            <ControlLabel>Variables to add</ControlLabel>
            <Select
              multi
              hideSelectAllOptions
              placeholder="Select variables to add"
              value={formData.variables.map((variable) => JSON.stringify(variable))}
              options={[
                {
                  label: (
                    <span className="f-12 font-medium uppercase">Varibles without folder</span>
                  ),
                  options: allowedVariables
                    .filter((variable) => !variable.group)
                    .map((variable) => ({
                      value: JSON.stringify(variable),
                      label: variable.key,
                    }))
                    .sort((optionA, optionB) => optionA.label.localeCompare(optionB.label)),
                },
                {
                  label: <span className="f-12 font-medium uppercase">Already in folder</span>,
                  options: allowedVariables
                    .filter((variable) => !!variable.group)
                    .map((variable) => ({
                      value: JSON.stringify(variable),
                      selectedLabel: variable.key,
                      label: folderLabel(variable.key, variable.group),
                      name: `${variable.group} ${variable.key}`.toLocaleLowerCase(),
                    }))
                    .sort((optionA, optionB) => optionA.name.localeCompare(optionB.name)),
                },
              ].filter((option) => option.options.length > 0)}
              onChange={(selected: List<any>) => {
                return setFormData({
                  ...formData,
                  variables: selected.map((value: string) => JSON.parse(value)).toArray(),
                });
              }}
            />
          </FormGroup>
        </>
      }
      onConfirm={() => {
        setLoading(true);

        return createFolder(formData.name, formData.variables)
          .then(() => {
            onHide();

            ApplicationActionCreators.sendNotification({
              type: 'success',
              message: 'Folder created.',
            });
          })
          .finally(() => setLoading(false));
      }}
    />
  );
};

const VariableForm = (props: {
  show: boolean;
  variables: VariableWithHash[];
  existingFolders: string[];
  isDevModeActive: boolean;
  defaultDevBranchId: number;
  currentDevBranchId: number | null;
  onHide: () => void;
  variable?: VariableWithHash | null;
  isCloning?: boolean;
}) => {
  const INITIAL_VARIABLE_STATE: Variable = {
    key: '',
    value: '',
    group: '',
    flags: ['encrypted'],
    attributes: {
      branchId: `${props.currentDevBranchId ?? props.defaultDevBranchId}`,
    },
  };
  const [isLoading, setLoading] = useState(false);
  const [variable, setVariable] = useState<VariableWithHash | Variable>(INITIAL_VARIABLE_STATE);

  useEffect(() => {
    if (props.variable) {
      setVariable({
        ...props.variable,
        ...(isVariableEncrypted(props.variable) && { value: '' }),
        ...(props.isCloning && {
          attributes: { ...props.variable.attributes, branchId: `${props.currentDevBranchId}` },
        }),
      });
    }
  }, [props.variable, props.isCloning, props.currentDevBranchId]);

  const onHide = () => {
    props.onHide();
    setVariable(INITIAL_VARIABLE_STATE);
  };

  const hasConflict = (savedVariable: VariableWithHash, variable: Variable) => {
    return (
      savedVariable.key === variable.key &&
      (savedVariable.attributes?.branchId ?? '') === variable.attributes?.branchId
    );
  };

  if (!props.show) {
    return null;
  }

  return (
    <ConfirmModal
      closeAfterResolve
      show={props.show}
      isLoading={isLoading}
      isDisabled={
        isLoading ||
        variable.key === '' ||
        variable.value === '' ||
        props.variables.some((savedVariable) => {
          return savedVariable.hash === props.variable?.hash
            ? _.isEqual(savedVariable, variable)
            : hasConflict(savedVariable, variable);
        })
      }
      onHide={onHide}
      icon={props.variable ? (props.isCloning ? 'clone' : 'pen') : 'plus'}
      buttonType="success"
      buttonLabel={
        props.variable ? (props.isCloning ? 'Clone Variable' : 'Save Variable') : 'Create Variable'
      }
      title={
        props.variable
          ? props.isCloning
            ? 'Clone Variable to Current Branch'
            : 'Edit Variable'
          : 'Create Variable'
      }
      text={
        <>
          <FormGroup>
            <ControlLabel>Name</ControlLabel>
            <FormControl
              autoFocus={!props.isCloning}
              type="text"
              placeholder="Variable name"
              value={variable.key}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (!/^\p{L}/u.test(event.target.value[0])) return;
                setVariable({
                  ...variable,
                  key: string.sanitizeKbcTableIdString(event.target.value),
                });
              }}
              disabled={props.isCloning || isLoading}
              maxLength={200}
              className={classNames({ 'form-control-static': props.isCloning })}
            />
            {(!props.variable?.key || props.variable.key !== variable.key) &&
              props.variables.some((savedVariable) => hasConflict(savedVariable, variable)) && (
                <HelpBlock variant="danger">
                  The variable in this scope is already existing
                </HelpBlock>
              )}
          </FormGroup>
          <FormGroup>
            <ControlLabel>Value</ControlLabel>
            <FormControl
              autoFocus={props.isCloning}
              type="text"
              placeholder="Variable value"
              value={variable.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setVariable({ ...variable, value: event.target.value })
              }
              disabled={isLoading}
            />
          </FormGroup>
          <FormGroup>
            <ActivateDeactivateSwitch
              withLabel
              noTooltips
              customLabel="Encrypted"
              isActive={isVariableEncrypted(variable)}
              onChange={(isActive: boolean) =>
                setVariable({
                  ...variable,
                  flags: (isActive
                    ? [...new Set(variable.flags).add('encrypted')]
                    : variable.flags?.filter((flag) => flag !== 'encrypted')
                  )?.filter(Boolean),
                })
              }
              buttonDisabled={props.isCloning || isLoading}
              className="tw-pl-0"
            />
          </FormGroup>
          {!props.isDevModeActive && (
            <FormGroup>
              <ActivateDeactivateSwitch
                withLabel
                noTooltips
                customLabel="Production Only"
                isActive={variable.attributes?.branchId === props.defaultDevBranchId.toString()}
                onChange={(isActive: boolean) =>
                  setVariable({
                    ...variable,
                    attributes: {
                      ...variable.attributes,
                      branchId: isActive ? `${props.defaultDevBranchId}` : '',
                    },
                  })
                }
                buttonDisabled={isLoading}
                className="tw-pl-0"
              />
            </FormGroup>
          )}
          <FormGroup>
            <ControlLabel>Folder</ControlLabel>
            <Select
              allowCreate
              placeholder="Select folder"
              value={variable.group ?? ''}
              options={props.existingFolders.map((group) => ({ value: group, label: group }))}
              onChange={(groupName: any) => setVariable({ ...variable, group: groupName })}
            />
          </FormGroup>
        </>
      }
      onConfirm={() => {
        setLoading(true);

        return Promise.resolve()
          .then(() => {
            return props.variable && !props.isCloning
              ? updateVariable(variable as VariableWithHash)
              : createVariable(variable);
          })
          .then(loadVariables)
          .then(() => {
            onHide();

            if (variable.group && !props.existingFolders.includes(variable.group)) {
              ApplicationActionCreators.sendNotification({
                type: 'success',
                message: 'Folder created.',
              });
            }
          })
          .finally(() => setLoading(false));
      }}
    />
  );
};

const DeleteVariablesModal = (props: {
  show: boolean;
  selected: string[];
  onHide: () => void;
  onConfirm: () => Promise<any>;
}) => {
  const [isDeleting, setDeleting] = React.useState(false);

  return (
    <ConfirmModal
      closeAfterResolve
      show={props.show}
      icon="trash"
      title="Delete Selected"
      text={
        <>
          Are you sure you want to delete the <b>{props.selected.length}</b> selected{' '}
          {string.pluralize(props.selected.length, 'variable')}?
        </>
      }
      buttonLabel="Delete"
      buttonType="danger"
      isLoading={isDeleting}
      onConfirm={() => {
        setDeleting(true);
        return props.onConfirm().finally(() => setDeleting(false));
      }}
      onHide={props.onHide}
    />
  );
};

const MoveVariablesModal = (props: {
  show: boolean;
  folders: string[];
  onHide: () => void;
  onConfirm: (group: string) => Promise<any>;
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedFolder, setSelectedFolder] = React.useState('');

  return (
    <ConfirmModal
      closeAfterResolve
      show={props.show}
      isLoading={isLoading}
      onHide={props.onHide}
      onEnter={() => setSelectedFolder('')}
      icon="folder-arrow-up"
      buttonType="success"
      buttonLabel="Move"
      title="Move Variables"
      text={
        <FormGroup>
          <ControlLabel>Folder</ControlLabel>
          <Select
            autoFocus
            allowCreate
            value={selectedFolder}
            placeholder="Select existing folder or create new one"
            promptTextCreator={(label) => `Create new folder "${label}"`}
            onChange={setSelectedFolder}
            options={props.folders.map((folder) => ({ label: folder, value: folder }))}
          />
          <HelpBlock>Leave empty for moving selected variables out of folders.</HelpBlock>
        </FormGroup>
      }
      onConfirm={() => {
        setIsLoading(true);
        return props
          .onConfirm(selectedFolder)
          .then(() => {
            if (selectedFolder && !props.folders.includes(selectedFolder)) {
              ApplicationActionCreators.sendNotification({
                type: 'success',
                message: 'Folder created.',
              });
            }
          })
          .finally(() => setIsLoading(false));
      }}
    />
  );
};

export default VariablesTable;
