import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Map } from 'immutable';

import CircleIcon from '@/react/common/CircleIcon';
import ModalActionButton from '@/react/common/ModalActionButton';
import ModalIcon from '@/react/common/ModalIcon';
import ShareExistingBucket from './ShareExistingBucket';
import ShareSelectedTables from './ShareSelectedTables';

const SHARES_TYPES = {
  EXISTING_BUCKET: 'EXISTING_BUCKET',
  SELECTED_TABLES: 'SELECTED_TABLES',
};

class ShareModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: null,
    };

    this.onBack = this.onBack.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  render() {
    return (
      <Modal bsSize="large" show={this.props.showModal} onHide={this.onHide}>
        {this.renderModalBody()}
      </Modal>
    );
  }

  renderModalBody() {
    if (this.state.type === SHARES_TYPES.EXISTING_BUCKET) {
      return (
        <ShareExistingBucket
          onHide={this.onHide}
          onBack={this.onBack}
          availableUsersOptions={this.props.availableUsersOptions}
          availableProjectsOptions={this.props.availableProjectsOptions}
          allBuckets={this.props.allBuckets}
        />
      );
    }

    if (this.state.type === SHARES_TYPES.SELECTED_TABLES) {
      return (
        <ShareSelectedTables
          onHide={this.onHide}
          onBack={this.onBack}
          sapiToken={this.props.sapiToken}
          availableUsersOptions={this.props.availableUsersOptions}
          availableProjectsOptions={this.props.availableProjectsOptions}
          allTables={this.props.allTables}
          allBuckets={this.props.allBuckets}
        />
      );
    }

    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>Share Bucket</Modal.Title>
          <ModalIcon icon="folder" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          <ModalActionButton
            icon={<CircleIcon icon="folder-open" color="blue" bigger bold />}
            title="Share existing bucket"
            description="Choose this if you already have a prepared bucket you want to share."
            onClick={() => this.setState({ type: SHARES_TYPES.EXISTING_BUCKET })}
          />
          <ModalActionButton
            icon={<CircleIcon icon="clone" color="blue" bigger bold />}
            title="Select tables you want to share"
            description="Tables from multiple buckets can be selected and a new bucket will be created."
            onClick={() => this.setState({ type: SHARES_TYPES.SELECTED_TABLES })}
          />
        </Modal.Body>
      </>
    );
  }

  onBack() {
    this.setState({ type: null });
  }

  onHide() {
    this.setState({ type: null });
    this.props.onHide();
  }
}

ShareModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  allBuckets: PropTypes.instanceOf(Map).isRequired,
  allTables: PropTypes.instanceOf(Map).isRequired,
  availableUsersOptions: PropTypes.array.isRequired,
  availableProjectsOptions: PropTypes.array.isRequired,
};

export default ShareModal;
