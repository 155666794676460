import React from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';

import {
  AUTO_SLEEP_NODE,
  AUTO_SLEEP_OPTIONS,
  CONTAINER_BASED,
} from '@/modules/sandboxes/Constants';
import InfoTooltip from '@/react/common/InfoTooltip';
import Select from '@/react/common/Select';
import string from '@/utils/string';

const TIMEOUT_OPTIONS = AUTO_SLEEP_OPTIONS.map((timeout) => {
  return {
    value: timeout,
    label: `${timeout} ${string.pluralize(timeout, 'hour')}`,
  };
});

const WorkspaceExpirationControl = (props: {
  type: string;
  value: number;
  onChange: (value: number) => void;
  isDisabled: boolean;
}) => {
  if (!CONTAINER_BASED.includes(props.type)) {
    return null;
  }

  return (
    <FormGroup>
      <ControlLabel>
        Auto Sleep Timeout
        <InfoTooltip tooltip={AUTO_SLEEP_NODE} />
      </ControlLabel>
      <Select
        clearable={false}
        options={TIMEOUT_OPTIONS}
        value={props.value}
        onChange={props.onChange}
        disabled={props.isDisabled}
      />
    </FormGroup>
  );
};

export default WorkspaceExpirationControl;
