import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Alert } from 'design';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import ComponentDescription from '@/modules/components/react/components/ComponentDescription';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import { createActions } from '@/modules/ex-mongodb/actionsProvisioning';
import { routeNames } from '@/modules/ex-mongodb/constants';
import { migrateToRows } from '@/modules/ex-mongodb/helpers';
import ConfigRowTables from '@/modules/ex-mongodb/react/components/ConfigRowTables';
import CreateQueryElement from '@/modules/ex-mongodb/react/components/CreateQueryElement';
import MigrateToRowsButton from '@/modules/ex-mongodb/react/components/MigrateToRowsButton';
import { createStore } from '@/modules/ex-mongodb/storeProvisioning';
import { prepareTablesMetadataMap } from '@/modules/storage/helpers';
import ConfigurationInfoPanel from '@/react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import CredentialsSetupBox from '@/react/common/CredentialsSetupBox';
import FilterPanel from '@/react/common/FilterPanel';
import NoResultsFound from '@/react/common/NoResultsFound';
import Link from '@/react/common/RouterLink';
import Sidebar from '@/react/layout/Sidebar/Sidebar';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import getDefaultBucket from '@/utils/getDefaultBucket';
import QueryTable from './QueryTable';

const Index = function (componentId) {
  const actionCreators = createActions(componentId);

  return createReactClass({
    mixins: [
      createStoreMixin(
        ApplicationStore,
        InstalledComponentsStore,
        StorageTablesStore,
        StorageBucketsStore,
        ConfigurationRowsStore,
      ),
    ],

    UNSAFE_componentWillReceiveProps() {
      return this.setState(this.getStateFromStores());
    },

    getStateFromStores() {
      const configId = RoutesStore.getRouterState().getIn(['params', 'config']);
      const ExDbStore = createStore(componentId, configId);
      const queries = ExDbStore.getQueries();
      const credentials = ExDbStore.getCredentials();

      // state
      return {
        queries,
        configId,
        config: ExDbStore.config,
        component: ComponentsStore.getComponent(componentId),
        allComponents: ComponentsStore.getAll(),
        configRows: ConfigurationRowsStore.getRows(componentId, configId),
        componentsMetadata: InstalledComponentsStore.getAllMetadata(),
        flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
        newCredentials: ExDbStore.getNewCredentials(),
        pendingActions: ExDbStore.getQueriesPendingActions(),
        hasCredentials: ExDbStore.hasValidCredentials(credentials),
        allTables: StorageTablesStore.getAll(),
        queriesFilter: ExDbStore.getQueriesFilter(),
        queriesFiltered: ExDbStore.getQueriesFiltered(),
        hasEnabledQueries: queries.filter((query) => query.get('enabled')).count() > 0,
        readOnly: ApplicationStore.isReadOnly(),
      };
    },

    getInitialState() {
      return {
        isProcessing: false,
      };
    },

    _handleFilterChange(query) {
      return actionCreators.setQueriesFilter(this.state.configId, query);
    },

    render() {
      return (
        <>
          <ConfigurationTabs componentId={componentId} configId={this.state.configId} />
          <ConfigurationInfoPanel
            component={this.state.component}
            allComponents={this.state.allComponents}
            config={this.state.config}
            flows={this.state.flows}
            tablesMetadataMap={prepareTablesMetadataMap(this.state.allTables)}
            metadata={this.state.componentsMetadata}
          />
          <div className="row">
            {this.renderMain()}
            {this.renderSidebar()}
          </div>
        </>
      );
    },

    renderMain() {
      return (
        <div className="col-sm-9">
          <ComponentDescription componentId={componentId} configId={this.state.configId} />
          {this.renderMigrationToRowsButton()}
          {this.renderMissingDatabaseCredentials()}
          {this.renderTables()}
        </div>
      );
    },

    renderMigrationToRowsButton() {
      if (
        this.state.readOnly ||
        !this.state.config.hasIn(['configuration', 'parameters', 'exports'])
      ) {
        return null;
      }

      return (
        <div className="box">
          <div className="box-content text-center">
            {this.state.configRows.isEmpty() || this.state.isProcessing ? (
              <p>
                Please migrate the configuration to the newest format to unlock the latest features.
              </p>
            ) : (
              <Alert variant="warning" className="tw-mb-5">
                Migration was not fully completed. Please run it again.
              </Alert>
            )}
            <MigrateToRowsButton
              isProcessing={this.state.isProcessing}
              onConfrim={() => {
                this.setState({ isProcessing: true });
                return migrateToRows(this.state.configId).finally(() => {
                  this.setState({ isProcessing: false });
                });
              }}
            />
          </div>
        </div>
      );
    },

    renderMissingDatabaseCredentials() {
      return (
        <CredentialsSetupBox
          show={!this.state.hasCredentials}
          readOnly={this.state.readOnly}
          onClick={this.handleCredentialsSetup}
        />
      );
    },

    renderTables() {
      if (!this.hasActiveConfigRows()) {
        if (this.state.queries.isEmpty() && !this.state.hasCredentials) {
          return null;
        }

        if (this.state.queries.isEmpty()) {
          return (
            <div className="box-separator">
              <h2 className="tw-m-0 tw-mb-4 tw-text-base">Exports</h2>
              <div className="box">
                <div className="box-content text-center">
                  <p>No exports configured yet.</p>
                  {this.renderAdditionalActions()}
                </div>
              </div>
            </div>
          );
        }

        return (
          <div className="box-separator">
            <div className="tw-mb-4 tw-flex tw-items-center tw-justify-between">
              <h2 className="tw-m-0 tw-text-base">Exports</h2>
              {this.renderAdditionalActions()}
            </div>
            <FilterPanel
              query={this.state.queriesFilter}
              onChange={this._handleFilterChange}
              placeholder="Search exports"
            />
            {this.renderQueryTable()}
          </div>
        );
      }

      return (
        <ConfigRowTables
          configId={this.state.configId}
          configName={this.state.config.get('name')}
          rows={this.state.configRows}
          defaultBucketId={this.state.config.getIn(
            ['configuration', 'storage', 'output', 'default_bucket'],
            getDefaultBucket('in', componentId, this.state.configId),
          )}
          component={this.state.component}
          readOnly={this.state.readOnly}
        />
      );
    },

    renderAdditionalActions() {
      if (this.state.readOnly) {
        return null;
      }

      return (
        <CreateQueryElement
          actionCreators={actionCreators}
          componentId={componentId}
          configurationId={this.state.configId}
          isNav={false}
        />
      );
    },

    renderQueryTable() {
      if (!this.state.queriesFiltered.count()) {
        return <NoResultsFound entityName="exports" />;
      }

      return (
        <QueryTable
          readOnly={this.state.readOnly}
          queries={this.state.queriesFiltered}
          configurationId={this.state.configId}
          componentId={componentId}
          pendingActions={this.state.pendingActions}
          actionCreators={actionCreators}
        />
      );
    },

    renderSidebar() {
      return (
        <div className="col-sm-3">
          <Sidebar
            componentId={componentId}
            configId={this.state.configId}
            run={{
              disabled:
                this.state.configRows.isEmpty() && !this.state.hasEnabledQueries
                  ? 'There are no exports to be executed.'
                  : '',
              text: 'You are about to run an extraction.',
            }}
            additionalButtons={
              <Link to={routeNames.CREDENTIALS} params={{ config: this.state.configId }}>
                <FontAwesomeIcon icon="user" fixedWidth />
                Database credentials
              </Link>
            }
          />
        </div>
      );
    },

    hasActiveConfigRows() {
      return (
        this.state.config.hasIn(['configuration', 'parameters']) &&
        !this.state.config.hasIn(['configuration', 'parameters', 'exports'])
      );
    },

    handleCredentialsSetup() {
      actionCreators.updateEditingCredentials(this.state.configId, this.state.newCredentials);
      RoutesStore.getRouter().transitionTo(routeNames.CREDENTIALS, {
        config: this.state.configId,
      });
    },
  });
};

export default Index;
